import Modal from 'react-bootstrap/Modal';
import MenuBrand from '../Menu/MenuBrand';

function ModalBrandHeader({invert=false}) {
    return (
        <Modal.Header closeButton>
            <Modal.Title>
                <MenuBrand bar={false} invert={invert} />
            </Modal.Title>
        </Modal.Header>
    );
}

export default ModalBrandHeader