import { Link } from "react-router-dom";
import Translate from "../AppContext/Translate";
import Valoration from "./Valoration";
import MagButton from "../Components/MagButton";

function CourseCard({course, top}) {
    const addr = "/load/course/" + course.id;
    const pic = "/courses/cover" + course.id + ".jpg";
    return <div className="pad16">
        <div className='mainCard'>
            <div className="shineHover">
                <Link to={addr}>
                    <figure style={{overflow: "hidden"}}><img alt="" src={pic} width="100%" /></figure>
                </Link>
                {top && <div className="topCourse">ТОП КУРС</div>}
            </div>
            <div style={{padding: "0px 12px 0px 12px"}}>
                <Link to={addr} className="blackTextHover"><p className="fkenyan bold large">{course.title}</p></Link>
                <p className="fmak top8 small">
                    <Translate>Курс від</Translate>
                    {' '}<Link className="schoolLink" to={"/school/" + course.schoolId}>{course.school}</Link>
                </p>
                {course.shortDescription && <p className="top8 small">{course.shortDescription}</p>}
                <div className="top12" style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <div>
                        {course.numStudents !== null && course.numStudents > 0 &&
                        <div style={{display: "flex", alignItems: "baseline"}}>
                            <img alt="" src="/bkg/user2.png" width="16"/>
                            <p className="small">{course.numStudents}</p>
                        </div>}
                        {course.numOpinions === 0 ?
                        <div className="newLabelDiv">
                            <p><Translate>Новий</Translate></p>
                        </div> : <Valoration score={course.score} numOpinions={course.numOpinions}/>
                        }
                        <p className="small bot8 italic"><Translate>Від</Translate> {course.startPrice} грн.</p>
                    </div>
                    <MagButton text="детальніше" bkgColor="white" color="#f94646" margin="0px" size="smaller"
                        clickEff={{link: addr}}/>
                </div>
                <MagButton text="купити курс" margin="8px auto 8px auto" bkgColor="#12c191" icon="/bkg/cart.png"
                    size="large" clickEff={{link: addr + "-bp"}}/>
            </div>
        </div>
    </div>
}

export default CourseCard