import UserPhotoIcon from "../../Components/UserPhotoIcon"
import Translate from "../../AppContext/Translate"
import ActivityDate from "./ActivityDate"

function RegisterActivity({date, user, userName}) {
    return <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
        <p style={{margin: "12px 0px 0px 0px"}}><UserPhotoIcon user={user} userName={userName}/></p>
        <p style={{margin: "0px"}}>{userName}
        <span style={{fontWeight: "600", marginLeft: "6px"}}><Translate>приєднався/-лася до Magistrika</Translate></span></p>
        <p><ActivityDate date={date}/></p>
    </div>
}

export default RegisterActivity