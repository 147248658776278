import { useEffect, useState, useContext } from "react";
import { getAPICall } from "../../Components/APICall";
import { AppContext } from "../../AppContext/AppContext";
import BeatLoader from "react-spinners/BeatLoader";
import Translate from "../../AppContext/Translate";
import StudentTask from "./StudentTask";

function TaskReview({student, pkgId, courseId}) {
    var { userId, token } = useContext(AppContext);
    const [tasks, setTasks] = useState(null);
    const [reviews, setReviews] = useState(null);

    useEffect(() => {
        getAPICall('student/tasks', {userId: userId, token: encodeURIComponent(token), pkg: pkgId,
            student: student, course: courseId}, (data) => {
                if (data) setTasks(data);
            });
        getAPICall('student/reviews', {userId: userId, token: encodeURIComponent(token), pkg: pkgId,
            student: student, course: courseId}, (data) => {
                if (data) setReviews(data);
            });
    }, [userId, token, student, pkgId, courseId]);

    const findReview = (taskId) => {
        for (var i = 0; i < reviews.length; i++) {
            if (reviews[i].taskId === taskId) return reviews[i]; 
        }
        return null;
    }

    var reviewedTasks = 0;
    var unreviewedTasks = 0;
    if (tasks && reviews) {
        for (var i = 0; i < tasks.length; i++) {
            if (findReview(tasks[i].id) === null) unreviewedTasks++;
            else reviewedTasks++;
        }
    }

    return <div className="studentExpRow">
        {tasks && reviews ? (tasks.length === 0 ?
            <p style={{margin: "0px"}}><Translate>Ніякого завдання він не дав</Translate></p> : <>
            {unreviewedTasks > 0 && <>
                <p style={{color: "maroon"}}><Translate>Неперевірені завдання</Translate>:</p>
                <div style={{display: "flex", flexWrap: "wrap"}}>
                {tasks.map((task, index) => {
                    const review = findReview(task.id);
                    return review === null ? <StudentTask key={index} student={student} pkgId={pkgId} courseId={courseId}
                        task={task} review={review}/> : null;
                })}
                </div>
            </>
            }
            {reviewedTasks > 0 && <>
                <p style={{color: "cadetblue"}}><Translate>Перевірені завдання</Translate>:</p>
                <div style={{display: "flex", flexWrap: "wrap"}}>
                {tasks.map((task, index) => {
                    const review = findReview(task.id);
                    return review !== null ? <StudentTask key={index} student={student} pkgId={pkgId} courseId={courseId}
                        task={task} review={review}/> : null;
                })}
                </div>
            </>
            }
        </>) : <BeatLoader/>}
    </div>
}

export default TaskReview