import { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { getAPICall } from "../Components/APICall";
import Menu from "../Menu/Menu";
import UserCover from "./UserCover";
import Translate from "../AppContext/Translate";
import Footer from "../Components/Footer";
import MyProfileButton from "../Components/MyProfileButton";
import HomeButton from "../Components/HomeButton";
import Item from "../Shop/Item";

function PublicProfileProducts() {
    const user = useParams()["id"];
    const [items, setItems] = useState([]);
    const [userName, setUserName] = useState("");

    useEffect(() => {
        const itemsLoaded = (data) => {
            if (data) {
                setItems(data);
            }
        }
        const getNameSuccess = (data) => {
            if (data.length === 1) setUserName(data[0].name);
        }
        if (user && user >= 0) {
            getAPICall('user/name', {user: user}, getNameSuccess);
            getAPICall('user/items', {userId: user, allData: 1}, itemsLoaded);
        }
    }, [user]);

    return !user || user < 0 ? <Navigate to="/" replace={true}/> : 
    <div className='mainContainer' style={{backgroundColor: "#fef5de"}}>
        <Menu/>
        <UserCover user={user} allowChange={false}/>
        <h1 style={{textAlign: "right"}} className="pad12"><Translate>Продукти</Translate></h1>
        <div className="userProfileContent" style={{marginBottom: "32px"}}>
            <div style={{height: "16px"}}/>
            {items.length === 0 ?
            <p className="large top8">{userName} <Translate>ще не пропонує жодної продукції</Translate>.</p>
            : <>
                <p className="large">
                    <img alt="" src="/icons/products.png" height="28px" className="right6"/>
                    {userName} <Translate>пропонує наступні продукти</Translate>:</p>
                <div style={{backgroundColor: "black", height: "2px", width: "80%", margin: "4px 0px 16px 0px"}}/>
                <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-around"}}>
                    {items.map((item, i) => {
                        return <Item key={i} data={item}/>
                    })}
                </div>
            </>
            }
        </div>
        <div style={{display: "flex", justifyContent: "space-evenly", flexWrap: "wrap",
            backgroundColor: "white", margin: "8px", borderRadius: "4px"}} className="pad12">
            <div style={{margin: "4px"}}><MyProfileButton/></div>
            <div style={{margin: "4px"}}><HomeButton/></div>
        </div>
        <Footer/>
    </div>
}

export default PublicProfileProducts