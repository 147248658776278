import Translate from "../../AppContext/Translate"
import dateFormat from "../../Components/DateFormat"
import Button from "react-bootstrap/Button";
import { BiTrash } from 'react-icons/bi';

function StudentGroup({data, color="floralwhite", customText=null, onAccess=null, onDelete=null}) {
    return <div className="classGroupDiv" style={{backgroundColor: color}}>
        <div>
            {customText ? <p style={{margin: "0px"}}><Translate>{customText}</Translate></p> : <>
            <p><Translate>Дата початку</Translate>: {dateFormat(data.startDate)}</p>
            <p><Translate>Дата закінчення</Translate>: {dateFormat(data.endDate)}</p>
            </>}
        </div>
        <div style={{marginLeft: "12px"}}>
            {onDelete ? <Button variant="outline-dark" onClick={() => { onDelete(data.id); }}><BiTrash style={{marginTop: "-4px"}}/></Button> :
            <Button variant="outline-dark" onClick={() => { onAccess(data.id); }}><Translate>Увійти</Translate></Button>}
        </div>
    </div>
}

export default StudentGroup