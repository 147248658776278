import Button from "react-bootstrap/Button"
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

function ExpandButton({expanded, setExpanded}) {
    return <Button size="sm" variant="outline-secondary" style={{height: "24px", paddingTop: "0px"}}
        onClick={() => { setExpanded(!expanded); }}>
        {expanded ? <IoIosArrowUp style={{marginTop: "-3px"}}/> : <IoIosArrowDown style={{marginTop: "-2px"}}/>}
    </Button>
}

export default ExpandButton