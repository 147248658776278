import Button from "react-bootstrap/Button"
import Footer from "../Components/Footer"
import Menu from "../Menu/Menu"
import { FaFilePdf } from "react-icons/fa6";
import { useWindowSize } from "../AppContext/WindowSize";
import { useEffect, useState } from "react";
import { PiArrowUpBold } from "react-icons/pi";

function Privacy() {
    const [width,] = useWindowSize();
    const [showUp, setShowUp] = useState(false);

    const handleDownload = () => {
        const pdfUrl = '/docs/privacy.pdf';
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = 'privacy.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    useEffect(() => {
        const handleScroll = () => {
          if (window.scrollY > 200) {
            setShowUp(true);
          } else {
            setShowUp(false);
          }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return <div className='mainContainer'>
        <Menu/>
        <div className="terms-div">
            {width > 960 && <div className="terms-circle" style={{left: "calc(50% + 360px + 48px)",
                top: "84px"}} onClick={handleDownload}>
                <FaFilePdf size="24" style={{transform: "translateX(2px)"}}/>
            </div>}
            {width > 960 && <div className={showUp ? "terms-circle fadeIn2" : "terms-circle fadeOut2"}
                style={{left: "calc(50% - 360px - 120px)", bottom: "84px"}} onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                }}><PiArrowUpBold size="24"/>
            </div>}
            <h1 className="center">MAGISTRIKA</h1>
            <p className="center bold large">ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ</p>
            <div className="center top12 bot16 fkenyan">
                <Button variant="light" style={{color: "#595e69"}} onClick={handleDownload}>
                    <FaFilePdf style={{marginRight: "6px"}}/>PDF версія
                </Button>
            </div>
            <p className="pterms"><strong>Політика Конфіденційності Magistrika</strong></p>
            <p className="pterms">У Magistrika ми цінуємо та поважаємо вашу конфіденційність. Ця Політика Конфіденційності описує, як ми збираємо, використовуємо, зберігаємо та захищаємо вашу особисту інформацію під час використання нашої освітньої платформи. Відвідуючи та використовуючи Magistrika, ви погоджуєтесь із пунктами, описаними в цій політиці.</p>
            <ol>
                <li><strong> Зібрана Інформація</strong></li>
            </ol>
            <p className="pterms">При реєстрації та використанні Magistrika ми збираємо наступні особисті дані:</p>
            <ul>
                <li><strong>Реєстраційна Інформація</strong>: Ім'я, адреса електронної пошти та IP-адреса.</li>
                <li><strong>Профільні та Професійні Дані</strong>: Інформація, пов'язана з вашим профілем та професійним досвідом.</li>
                <li><strong>Акаунти в Соціальних Мережах та Вебсайт</strong>: Якщо ви вирішите підключити свої акаунти в соціальних мережах (Google, Facebook) та/або вебсайт до свого профілю в Magistrika.</li>
                <li><strong>Хеш Пароля</strong>: Ми зберігаємо хеш вашого пароля для безпечної верифікації входу.</li>
                <li><strong>Інформація про Населення</strong>: Інформація, пов'язана з населенням користувачів.</li>
                <li><strong>Інформація про Покупки та Курси</strong>: Деталі про здійснені покупки, придбані курси та вашу історію використання платформи.</li>
                <li><strong>Взаємодія на Платформі</strong>: Коментарі, надіслані та отримані повідомлення, і будь-яка інша взаємодія з платформою, необхідна для надання послуги.</li>
            </ul>
            <p className="pterms"><strong>Для директорів шкіл:</strong></p>
            <ul>
                <li><strong>Банківська Інформація</strong>: IBAN вашого банківського рахунку для здійснення платежів.</li>
            </ul>
            <ol start="2">
                <li><strong>Використання Інформації</strong></li>
            </ol>
            <p className="pterms">Ми використовуємо зібрану інформацію для:</p>
            <ul>
                <li>Надання, підтримки та покращення наших послуг.</li>
                <li>Персоналізації вашого досвіду на платформі.</li>
                <li>Обробки транзакцій та управління платежами.</li>
                <li>Спілкування з вами щодо вашого акаунту, придбаних курсів та будь-якої іншої важливої інформації.</li>
                <li>Забезпечення цілісності та безпеки платформи.</li>
            </ul>
            <ol start="3">
                <li><strong> Сторонні Сервіси</strong></li>
            </ol>
            <p className="pterms">Magistrika дозволяє вхід через акаунти Google і Facebook. Використовуючи ці опції, ці сторонні сервіси можуть збирати та обробляти дані відповідно до їхніх власних політик конфіденційності. Ми рекомендуємо вам переглянути політики конфіденційності Google та Facebook, щоб зрозуміти, як вони обробляють вашу інформацію.</p>
            <ol start="4">
                <li><strong> Зберігання та Безпека Даних</strong></li>
            </ol>
            <p className="pterms">Ваші дані зберігаються на захищених серверах, і ми використовуємо технічні та організаційні заходи для захисту вашої інформації від несанкціонованого доступу, втрати або знищення. Хеш вашого пароля зберігається безпечно, щоб запобігти несанкціонованому доступу до вашого акаунту.</p>
            <ol start="5">
                <li><strong> Зберігання та Видалення Даних</strong></li>
            </ol>
            <p className="pterms">Magistrika зберігає ваші особисті дані лише стільки часу, скільки потрібно для виконання цілей, для яких вони були зібрані, або відповідно до вимог закону. Ви маєте право запросити видалення своїх даних у будь-який час. Для цього ви можете написати до нашої служби підтримки за адресою <strong>support@magistrika.com</strong>. Ми обробимо ваш запит і видалимо ваші дані в розумні терміни, якщо немає законного обов&rsquo;язку їх зберігати.</p>
            <ol start="6">
                <li><strong> Зміни до Політики Конфіденційності</strong></li>
            </ol>
            <p className="pterms">Magistrika залишає за собою право змінювати цю Політику Конфіденційності у будь-який час. Ми повідомимо вас про будь-які значні зміни, опублікувавши нову версію на платформі. Ми рекомендуємо періодично переглядати цю політику, щоб бути в курсі будь-яких оновлень.</p>
            <ol start="7">
                <li><strong> Контакт</strong></li>
            </ol>
            <p className="pterms">Якщо у вас є запитання або занепокоєння щодо цієї Політики Конфіденційності, будь ласка, зв&rsquo;яжіться з нами за адресою <strong>support@magistrika.com.</strong></p>
            <p className="pterms">Ця політика конфіденційності має на меті захистити вашу особисту інформацію та забезпечити прозорість у тому, як вона обробляється у Magistrika.</p>
            <p className="center top24 italic">Дата останнього оновлення: 1 вересня 2024 року</p>
        </div>
        <Footer/>
    </div>
}

export default Privacy