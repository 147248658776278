import { useEffect, useState } from "react";
import { getAPICall } from "../../Components/APICall";
import SchoolHeader from "./SchoolHeader";
import ReportSubtotal from "./ReportSubtotal";

function AdminReport({month, year}) {
    const [schools, setSchools] = useState([]);
    const [total, setTotal] = useState(0);
    const [counter, setCounter] = useState(0);

    useEffect(() => {
        getAPICall('school/list', {}, (data) => {
            if (data) {
                setSchools(data);
            }
        });
    }, []);

    useEffect(() => {
        setTotal(0);
        setCounter(0);
    }, [month, year]);

    const updateTotal = (subtotal) => {
        setTotal(prev => prev + subtotal);
        setCounter(prev => prev + 1);
    }

    return <>  
        {schools.map((school, i) => {
            return <SchoolHeader key={i} schoolId={school.id} schoolName={school.title} month={month + 1}
                year={year} updateTotal={updateTotal} role="admin"/>
        })}
        {counter === schools.length &&
            <ReportSubtotal total={total} text="Комісія місяця"/>
        }
    </>
}

export default AdminReport