import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../AppContext/AppContext";
import StudentsFilter from "./StudentsFilter"
import { RiEqualizerLine } from 'react-icons/ri';
import { getAPICall } from "../../Components/APICall";
import SyncLoader from "react-spinners/SyncLoader";
import StudentInfo from "./StudentInfo";
import InfiniteScroll from "react-infinite-scroll-component";

function CabStaticPlan({courseId}) {
    var { userId, token, dicLang } = useContext(AppContext);
    const [showFilter, setShowFilter] = useState(false);
    const [filter, setFilter] = useState(0);
    const [students, setStudents] = useState({items: [], hasMore: true, offset: 0});
    const limit = 48;

    const studentsError = (data) => {
        setStudents({items: students.items, hasMore: false, offset: students.offset});
    }

    const studentsSuccess = (data, reset=false) => {
        if (data) {
            if (reset)  
                setStudents({
                    items: data,
                    hasMore: data.length >= limit,
                    offset: data.length});
            else
                setStudents({
                    items: students.items.concat(data),
                    hasMore: data.length >= limit,
                    offset: students.offset + data.length
                });
        } else studentsError(data);
    }

    const getStudents = (courseId, reset=false) => {
        var offset = reset ? 0 : students.offset;
        getAPICall('students/planList', {userId: userId, token: encodeURIComponent(token), course: courseId,
            plan: 0, offset: offset, limit: limit, filter: filter}, (data) => { studentsSuccess(data, reset); },
            studentsError);
    }

    useEffect(() => {
        getStudents(courseId, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [courseId, filter]);

    return <div>
        <div style={{textAlign: "right", marginTop: "-16px"}}>
            <RiEqualizerLine className="userLink" size="24px" onClick={()=>{setShowFilter(!showFilter)}}/>
        </div>
        <StudentsFilter show={showFilter} setFilter={setFilter} dicLang={dicLang}/>
        <div id="itemsScrollableDiv">
            <InfiniteScroll
                dataLength={students.items.length}
                next={() => { getStudents(courseId); }}
                hasMore={students.hasMore}
                loader={<p><SyncLoader size={18} color="#73694c"/></p>}
                scrollableTarget="itemsScrollableDiv"
                endMessage={null}>
                {students.items.map((data, index) => 
                    <StudentInfo key={index} data={data} courseId={courseId}/>)}
            </InfiniteScroll>
        </div>
    </div>
}

export default CabStaticPlan