import { useContext } from "react";
import { AppContext } from "./AppContext";
import { Link } from "react-router-dom";

export function translate(text, dicLang) {
  if (dicLang === null) {
    return text;
  } else {
    return dicLang[text] || text;
  }
}

export function translateWithNumber(text, number, dicLang) {
  var s = dicLang === null ? text : (dicLang[text] || text);
  const pos = s.indexOf('*');
  return (pos > 0 ? s.substr(0, pos) : '') + number + (pos < s.length - 1 ? s.substr(pos + 1) : '');
}

export const Translate = ({children}) => {
  const { dicLang } = useContext(AppContext);
  // return the translated text or the original text
  if (dicLang === null || dicLang === undefined) {
    return children;
  } else {
    return children in dicLang ? dicLang[children] : children;
  }
};

export const TranslateWithLink = ({children, link}) => {
  const { dicLang } = useContext(AppContext);
  var translated = dicLang === null ? children : (dicLang[children] || children);
  var p1 = translated.indexOf('|');
  if (p1 < 0) return translated;
  var p2 = translated.indexOf('|', p1 + 1);
  if (p2 < 0) return translated;
  return <>{translated.substr(0, p1)}<Link to={link}>{translated.substr(p1 + 1, p2 - p1 - 1)}</Link>{translated.substr(p2 + 1)}</>
}

export function chooseRowByLang(data, lang) {
  if (data.length === 0) return null;
  for (var i = 0; i < data.length; i++) { 
    if (data[i]["lang"] === lang) return data[i];
  }
  for (i = 0; i < data.length; i++) { 
    if (data[i]["lang"] === "ua") return data[i];
  }
  for (i = 0; i < data.length; i++) { 
    if (data[i]["lang"] === "es") return data[i];
  }
  return data[0];
}

export function chooseStringSplitByLang(s, lang) { // lang|desc|…
  const items = s.split('|');
  if (items.length < 2) return null;
  var dic = {};
  for (var i = 0; i + 1 < items.length; i += 2)
    dic[items[i]] = items[i + 1];
  if (lang in dic) return dic[lang];
  if ('ua' in dic) return dic['ua'];
  return items[1];
}

export function getLanguages() {
  return {"ua": "Українська", "es": "Español"};
}

export function getLanguageName(lang) {
  const languages = getLanguages();
  return lang in languages ? languages[lang] : "Невідомий";
}

export function getExtendedLanguages() {
  return ['ua', 'en', 'es', 'de', 'fr', 'it', 'pt'];
}

export function getExtendedLanguageName(lang) {
  switch (lang) {
    case 'uk':
    case 'ua': return "Українська";   
    case 'en': return "English";
    case 'es': return "Español";
    case 'de': return "Deutsch";
    case 'fr': return "Français";
    case 'it': return "Italiano";
    case 'pt': return "Português";
    default:
      return lang;
  }
}

export function formatDate(date) {
  if (date instanceof Date) 
    return date.toLocaleDateString('en-GB');
  return new Date(date).toLocaleDateString('en-GB');
}

export function formatDateTime(date) {
  var d = date instanceof Date ? date : new Date(date);
  const day = String(d.getDate()).padStart(2, '0');
  const month = String(d.getMonth() + 1).padStart(2, '0');
  const year = String(d.getFullYear()).slice(-2);
  const hours = String(d.getHours()).padStart(2, '0');
  const minutes = String(d.getMinutes()).padStart(2, '0'); 
  return `${day}/${month}/${year} ${hours}:${minutes}`;
}

export const getLanguagesData = () => {
  const l = getExtendedLanguages();
  var data = [];
  for (var i = 0; i < l.length; i++) {
      data.push({id: l[i], name: getExtendedLanguageName(l[i])});
  }
  return data;
}

export default Translate