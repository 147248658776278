import { useContext, useEffect, useState } from "react"
import { AppContext } from "../../AppContext/AppContext";
import { getAPICall } from "../../Components/APICall";
import BeatLoader from "react-spinners/BeatLoader";
import ReportSubtotal from "./ReportSubtotal";
import CourseOrder from "./CourseOrder";
import ItemOrder from "./ItemOrder";

function SchoolHeader({schoolId, schoolName, month, year, updateTotal, role}) {
    const { userId, token } = useContext(AppContext);
    const [lines, setLines] = useState(null);

    useEffect(() => {
        const api = role === "teacher" ? 'orders/teacher' : 'orders/director';
        getAPICall(api, {userId: userId, token: encodeURIComponent(token),
            school: schoolId, month: month, year: year}, (data) => {
            if (data) {
                setLines(data);
                updateTotal(role === "admin" ? data.commission : data.total);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schoolId, month, year, userId, token, role]);

    return <div style={{border: "solid black thin", borderRadius: "12px", margin: "24px 0px"}} className="pad12">
        <div style={{display: "flex", alignItems: "center", justifyContent: "left"}}>     
            <img className="smallSchoolLogo" style={{marginRight: "16px"}} 
                alt="Logo" src={'/schools/logo' + schoolId + '.png'}/>     
            <p className="large bold">{schoolName}</p>
        </div>
        <hr/>
        {lines ? <>
            {lines.courses && lines.courses.map((order, i) => {
                return <CourseOrder key={i} data={order} platformPercentage={lines.platformPercentage}
                    role={role}/>
            })}
            {lines.items && lines.items.map((order, i) => {
                return <ItemOrder key={i} data={order} platformPercentage={lines.platformPercentage}
                    role={role}/>
            })}
            <ReportSubtotal total={role === "admin" ? lines.commission : lines.total}/>
        </> : <BeatLoader/> }
    </div>
}

export default SchoolHeader