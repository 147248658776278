import { useContext, useState, useEffect } from "react";
import { AppContext } from "../AppContext/AppContext";
import { Navigate, useParams } from "react-router-dom";
import Menu from "../Menu/Menu";
import Footer from "../Components/Footer";
import Container from "react-bootstrap/Container";
import Translate from "../AppContext/Translate";
import CourseList from "../Lists/CourseList";
import { getAPICall } from "../Components/APICall";
import Order from "./Orders/Order";
import HomeButton from "../Components/HomeButton";
import Banner from "../Components/Banner";
import { useWindowSize } from "../AppContext/WindowSize";
import MyProfileButton from "../Components/MyProfileButton";

function UserOrders() {
    var expandedOrder = useParams()["id"];
    if (expandedOrder) {
        expandedOrder = parseInt(expandedOrder);
    }
    const { userId, token } = useContext(AppContext);
    const [orders, setOrders] = useState([]);
    const [width,] = useWindowSize();

    useEffect(() => {
        getAPICall('orders/client', {userId: userId, token: encodeURIComponent(token)}, (data) => {
            if (data) setOrders(data);
        });
    }, [userId, token]);

    return userId >= 0 ? 
    <div className='mainContainer' style={{backgroundColor: "#fef5de"}}>
        <Menu/>
        <Banner srcImg='/bkg/banners/orders.jpg' bkgColor="#fef5de" down={false}
            title={<h1 className={width > 500 ? "huge" : "xxlarge"}><Translate>ЗАМОВЛЕННЯ</Translate></h1>}/>
        <Container className="userProfileForm">
            <h1><Translate>Мої замовлення</Translate></h1>
            {orders.length === 0 ? <>
                <p style={{fontWeight: "600", fontStyle: "italic", marginTop: "16px"}}><Translate>Ви ще не купили жодного курсу</Translate>.</p>
                <p><Translate>Додайте курси в кошик і почніть навчання з найкращими професіоналами</Translate>.</p>
                <div style={{marginLeft: "-12px", marginRight: "-24px"}}>
                    <CourseList title="Курси, які можуть вас зацікавити"/>
                </div>
            </> : <div>
                {orders.map((data, i) => {
                    return <Order key={i} data={data} expandedOrder={expandedOrder}/>
                })}
                <div style={{height: "8px"}}/>
            </div>
            }
        </Container>
        <div style={{display: "flex", justifyContent: "space-evenly", flexWrap: "wrap",
            backgroundColor: "white", margin: "8px", borderRadius: "4px"}} className="pad12">
            <div style={{margin: "4px"}}><MyProfileButton/></div>
            <div style={{margin: "4px"}}><HomeButton/></div>
        </div>
        <Footer/>
    </div> :
    <Navigate to="/" replace={true} />
}

export default UserOrders