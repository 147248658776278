import { useContext, useState, useEffect } from "react";
import { AppContext } from "../AppContext/AppContext";
import Menu from "../Menu/Menu";
import Container from "react-bootstrap/Container";
import { Navigate } from "react-router-dom";
import Translate, { formatDate } from "../AppContext/Translate";
import { getAPICall } from "../Components/APICall";
import BeatLoader from "react-spinners/BeatLoader";
import Footer from "../Components/Footer";
import { IoFootstepsSharp } from "react-icons/io5";
import { IoFootstepsOutline } from "react-icons/io5";
import { BsXLg } from "react-icons/bs";
import { CgArrowLongRight } from "react-icons/cg";
import ProgressBar from 'react-bootstrap/ProgressBar';
import CountUp from "../Components/CountUp";
import { useNavigate } from "react-router-dom";
import { translateWithNumber } from "../AppContext/Translate";
import PostMessage from "../Components/PostMessage";
import { Link } from "react-router-dom";
import HomeButton from "../Components/HomeButton";
import Banner from "../Components/Banner";
import { useWindowSize } from "../AppContext/WindowSize";
import MyProfileButton from "../Components/MyProfileButton";

function Reward({item}) {
    const { dicLang } = useContext(AppContext);
    const img = <img alt="" src={item.kind === 0 ? "/icons/discount.png" : "/items/item" + item.item + ".jpg"}
        style={{height: "96px", border: item.kind === 0 ? "none" : "ridge white"}}/>
    return <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center", 
        backgroundColor: item.rank % 2 === 0 ? "floralwhite" : "ghostwhite",
        marginTop: "16px", padding: "2px 12px 2px 12px"}}>
        <div style={{fontFamily: "Mak", marginRight: "8px", fontSize: "1.2rem"}}>Rank {item.rank}</div>
        <div style={{width: "140px", textAlign: "center", marginRight: "8px"}}>
            {item.kind === 0 ? img : <Link to={"/product/" + item.item}>{img}</Link>}
        </div>
        {item.kind === 0 ?
        <p className="cartItemTitle">{translateWithNumber("*% знижка на наступну покупку", item.discount, dicLang)}</p> :
        <div>
            <PostMessage msg={item.description} open={false} sep={false} textStyle="cartItemTitle"/>
            <p><Link to={"/school/" + item.school}>{item.schoolName}</Link></p>
        </div>
        }
    </div>
}

function UserRewards() {
    const { userId, token, lang } = useContext(AppContext);
    const [rank, setRank] = useState(null);
    const [stepsDone, setStepsDone] = useState(0);
    const [achivements, setAchivements] = useState([0, 0, 0, 0]);
    const [delay, setDelay] = useState(null);
    const [totalNewSteps, setTotalNewSteps] = useState(0);
    const [rewards, setRewards] = useState(null);
    const [width,] = useWindowSize();
    const navigate = useNavigate(); 

    const achivText = [<Translate>придбано нові курси</Translate>,
        <Translate>створено нові робочі місця</Translate>,
        <Translate>нові відгуки про курси</Translate>,
        <Translate>завершено нові курси</Translate>];
    const mult = [3, 1, 1, 2];

    const achivementsSuccess = (data) => {
        if (data !== null) {
            var v = [0, 0, 0, 0];
            var total = 0;
            var d = [];
            var w = 2;
            for (var i = 0; i < data.length; i++) {
                const row = data[i];
                const kind = row.kind - 1;
                if (kind >= 0 && kind < v.length) {
                    v[kind] = row.value;
                }
            }
            for (i = 0; i < v.length; i++) {
                d.push(w);
                if (v[i] > 0) w += 2;
                total += v[i] * mult[i];
            }
            setTotalNewSteps(counter => counter + total);
            setAchivements(v);
            setDelay(d);
        }
    }

    const rankSuccess = (data) => {
        if (data && data.length > 0) {
            setTotalNewSteps(data[0].remainingSteps);
            setRank(data[0]);
            var steps = data[0].stepsDone;
            if (steps > 100) steps = 100;
            setStepsDone(steps);
            getAPICall('user/achivements', {userId: userId, token: encodeURIComponent(token),
                date: data[0].lastCheck}, achivementsSuccess);
        }
    }

    const rewardsSuccess = (data) => {
        if (data && data.length > 0)
            setRewards(data)
    }

    useEffect(() => {
        if (userId >= 0) {
            getAPICall('user/rank', {user: userId}, rankSuccess);
            getAPICall('game/rewards', {userId: userId, token: encodeURIComponent(token), lang: encodeURIComponent(lang)},
                rewardsSuccess);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, token, lang]);

    const goToMap = () => {
        navigate("/game");
    }

    return userId >= 0 ?
    <div className='mainContainer' style={{backgroundColor: "#fef5de"}}>
        <Menu />
        <Banner srcImg='/bkg/banners/rewards.jpg' bkgColor="#fef5de" down={false}
            title={<h1 className={width > 500 ? "huge" : "xxlarge"}><Translate>ВИНАГОРОДИ</Translate></h1>}/>
        <Container className="userProfileForm">
            <h2><Translate>Мої винагороди</Translate></h2>
            {rank ? <> 
            <div style={{fontSize: "x-large", fontFamily: "Mak", color: "#434476"}}>
                <img src={"/icons/rank" + rank.rank + ".png"} alt="" width="48px" style={{marginRight: "8px",
                    filter: "drop-shadow(2px 2px 1px #00000042)"}}/>
                <Translate>Рівень</Translate> {rank.rank + 1}
            </div>
            <hr/>
            <p><span style={{fontWeight: "600"}}><Translate>Остання перевірка</Translate>:</span> {formatDate(rank.lastCheck)}</p>
            <div>
                <p className="bot8 top8"><IoFootstepsSharp size="36px"/> <span style={{fontWeight: "600"}}><Translate>Кількість зроблених кроків</Translate>:</span>
                <span style={{marginLeft: "8px", fontFamily: "Mak", fontSize: "large"}}>{rank.stepsDone}</span></p>
                <ProgressBar striped variant="primary" now={stepsDone} label={`${stepsDone}%`} />
            </div>
            <div style={{textAlign: "center", marginTop: "24px"}}>
                <h2><Translate>Доступні нові кроки</Translate> <IoFootstepsOutline size="32px"/></h2>
                <ul style={{textAlign: "left"}}>
                {rank && <li>{rank.remainingSteps} <Translate>невикористані попередні кроки</Translate>.</li>}
                {delay !== null && achivements.map((value, index) => (
                    <li key={index}><CountUp delay={delay[index]} end={value} duration={5}/>
                    {' '}{achivText[index]}
                    <span style={{marginLeft: "8px", fontSize: "larger", color: "royalblue"}}>
                        <BsXLg size="14px"/>
                        {mult[index]}
                    </span><CgArrowLongRight size="18px" style={{margin: "0px 12px 0px 12px"}}/>
                    <CountUp delay={delay[index]} end={value * mult[index]} duration={5}/>
                    {' '}<Translate>кроки</Translate>.
                    </li>
                    ))}
                </ul>
                {delay &&
                    <div style={{fontSize: "xx-large", backgroundColor: "lavender", width: "fit-content",
                        margin: "auto", padding: "8px 24px 8px 24px", borderRadius: "12px", boxShadow: "2px 2px 2px #00000021"}}>
                        <CountUp delay={2} end={totalNewSteps} duration={5}/>
                    </div>}
                <div style={{width: "50%", maxWidth: "320px", aspectRatio: "2.98", margin: "auto",
                    backgroundSize: "cover", backgroundImage: "url('/icons/map.png')", display: "flex",
                    justifyContent: "center", alignItems: "center", fontFamily: "Mak", fontSize: "large",
                    cursor: "pointer", marginTop: "24px"}} onClick={goToMap}>
                    <p style={{margin: "0px 16px 0px 16px", lineHeight: "20px"}}>
                        {totalNewSteps > 0 && stepsDone < 100 ?
                        <Translate>Просуватися по мапі</Translate> :
                        <Translate>Переглянути мапу</Translate> }
                    </p>
                </div>
            </div>
            {rewards && <div>
                <hr/>
                <h4><Translate>Отримані винагороди</Translate>:</h4>
                {rewards.map((item, i) => {
                    return <Reward key={i} item={item}/>
                })
                }
            </div>}
            </> :
            <BeatLoader/>
            }
        </Container>
        <div style={{display: "flex", justifyContent: "space-evenly", flexWrap: "wrap",
            backgroundColor: "white", margin: "8px", borderRadius: "4px"}} className="pad12">
            <div style={{margin: "4px"}}><MyProfileButton/></div>
            <div style={{margin: "4px"}}><HomeButton/></div>
        </div>
        <Footer/>
    </div> :
    <Navigate to="/" replace={true} />
}

export default UserRewards