import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Translate from '../AppContext/Translate';
import CourseInfo from './CourseInfo';
import ShowProjects from './Projects/ShowProjects';
import CourseMaterials from './CourseMaterials';
import CourseTasks from './CourseTasks';
import { useState } from 'react';
import CourseOptions from './CourseOptions';

function CourseContent({info, student, teacher, params, setParams}) {
    const [selectedPackage, setSelectedPackage] = useState(student.length < 1 ? -1 : student[0].package);

    const handleSelect = (eventKey) => {
        setParams({...params, tab: eventKey});
    };

    const handleClick = (event, eventKey) => {
        event.preventDefault();
        handleSelect(eventKey);
    };

    const getPackage = (student, selectedPackage) => {
        if (selectedPackage && student) {
            for (var i = 0; i < student.length; i++) {
                if (student[i].package === selectedPackage) {
                    return student[i];
                }
            }
        }
        return null;
    }

    const activeTab = params && params.tab !== '' ? params.tab : 'c';
    var numOptions = 0;
    var pkg = getPackage(student, selectedPackage);
    if (pkg) {
        for (var j = 0; j < pkg.options.length; j++) {
            if (pkg.options[j] !== 'Y' && pkg.options[j] !== 'Z') numOptions++;
        }
    }

    return <>
    <Navbar bg="dark" data-bs-theme="dark" className="py-0 fprag" style={{width: "100%", borderTop: "solid thin #3d444b"}}>
        <Nav activeKey={"/" + activeTab} style={{flexWrap: "wrap"}}>
            <Nav.Item>
                <Nav.Link href="/c" onClick={(event) => handleClick(event, 'c')}><Translate>Матеріали</Translate></Nav.Link>
            </Nav.Item>
            {student.length > 0 &&
            <Nav.Item>
                <Nav.Link href="/t" onClick={(event) => handleClick(event, 't')}><Translate>Домашнє завдання</Translate></Nav.Link>
            </Nav.Item>}
            {numOptions > 0 &&
            <Nav.Item>
                <Nav.Link href="/o" onClick={(event) => handleClick(event, 'o')}><Translate>Варіанти пакетів</Translate></Nav.Link>
            </Nav.Item>}
            <Nav.Item>
                <Nav.Link href="/j" onClick={(event) => handleClick(event, 'j')}><Translate>Проекти</Translate></Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link href="/d" onClick={(event) => handleClick(event, 'd')}><Translate>Про курс</Translate></Nav.Link>
            </Nav.Item>
        </Nav>
    </Navbar>
    {activeTab === 'c' && <CourseMaterials course={info} student={student} teacher={teacher} params={params} 
        setParams={setParams} selectedPackage={selectedPackage} setSelectedPackage={setSelectedPackage}/>}
    {activeTab === 't' && <CourseTasks course={info} student={student} params={params} selectedPackage={selectedPackage}
        setSelectedPackage={setSelectedPackage}/>}
    {activeTab === 'd' && <CourseInfo info={info} student={student}/>}
    {activeTab === 'p' && <CourseInfo info={info} student={student} goToPlans={true}/>}
    {activeTab === 'o' && selectedPackage >= 0 && student.length > 0 && numOptions > 0 && 
        <CourseOptions course={info} pkg={pkg}/>}
    {activeTab === 'j' && <ShowProjects courseId={info.id}/>}
    </>
}

export default CourseContent