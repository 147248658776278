import UserPhotoIcon from "../Components/UserPhotoIcon"
import UserRank from "../User/UserRank"

function CabHeader({user, userName}) {
    return <div className="pad12" style={{display: "flex"}}>
        <UserPhotoIcon user={user} userName={userName} big={true}/>
        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center",
            marginLeft: "6px", width: "100%"}}>
            <div style={{flex: 1, display: 'flex'}}>
                <h2 style={{width: "100%", position: "relative"}}>{userName}
                <span style={{position: 'absolute', bottom: '-4px', left: '0px', width: '90%',
                    height: '2px', backgroundColor: 'black', content: '""'}}/>
                </h2>
            </div>
            <UserRank user={user}/>
        </div>
    </div>
}

export default CabHeader