export const control = [0.07, 0.14, 0.2, 0.26, 0.33, 0.42, 0.51, 0.57, 0.65, 0.71, 0.78, 0.85, 0.9, 0.95, 1];

export function getRankBySteps(steps) {
    for (var i = 0; i < control.length; i++) {
        const value = Math.round(control[i] * 100);
        if (value === steps) return i + 1;
        if (value > steps) return -1; 
    }
    return -1;
}
