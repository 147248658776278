import CourseList from '../Lists/CourseList';
import { FcInfo } from 'react-icons/fc';
import Translate from '../AppContext/Translate';

function EmptyCart() {
    return <div className='pad16'>
        <div className="top-arrow">
            <div style={{display: "flex", alignItems: "center"}}>
                <FcInfo size="45"/>
                <div style={{marginLeft: "16px"}}>
                    <h4><Translate>Ваш кошик порожній</Translate></h4>
                    <p><Translate>Додайте курси в кошик і почніть навчання з найкращими професіоналами</Translate>.</p>
                </div>
            </div>
        </div>
        <hr/>
        <CourseList title="Курси, які можуть вас зацікавити"/>
    </div>
}

export default EmptyCart