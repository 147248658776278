import { useWindowSize } from "../AppContext/WindowSize";
import { useEffect, useState, useContext } from "react";
import { AppContext } from "../AppContext/AppContext";
import { getAPICall } from "../Components/APICall";
import { translate } from "../AppContext/Translate";
import { TiDeleteOutline } from 'react-icons/ti';
import PostMessage from "../Components/PostMessage";
import NumericSpinner from "../Components/NumericSpinner";
import dateFormat from "../Components/DateFormat";

function CartItem({index, item, updateSubtotal}) {
    const [width,] = useWindowSize();
    const [itemDesc, setItemDesc] = useState(null);
    const { lang, dicLang, removeFromCart, updateCartAmount } = useContext(AppContext);
    const [stock, setStock] = useState(null);
    const small = width < 720;

    function getItemImage(item) {
        const ext = ".jpg";
        switch (item.kind) {
        case 'plan':
            return "/courses/cover" + item.courseId + ext;
        case 'item':
            return "/items/item" + item.itemId + ".jpg";
        default:
            return "/courses/nocover" + ext;
        }
    }
    const srcImg = getItemImage(item);

    function setPlanDescription(data, setItemDesc, item, dicLang, updateSubtotal) {
        if (data && data.length > 0 && data[0].active > 0) {
            const row = data[0];
            if (item.groupId >= 0) {
                getAPICall('group/date', {groupId: item.groupId}, (info) => {
                    if (info && info.length > 0) {
                        setPlanDescription2(row, setItemDesc, item, dicLang, updateSubtotal,
                            new Date(info[0].startDate).getTime());
                    } else setPlanDescription2(row, setItemDesc, item, dicLang, updateSubtotal, null);
                });
            } else setPlanDescription2(row, setItemDesc, item, dicLang, updateSubtotal, null);    
        }
    }

    function setPlanDescription2(data, setItemDesc, item, dicLang, updateSubtotal, startDate) {
        var comment = null;
        var price = data.price;
        if ('discount' in item && item.discount > 0) {
            if (startDate) comment = <>{translate('Дата початку', dicLang) + ': ' + dateFormat(startDate)}<br/>{translate("Знижка", dicLang) + ": " + item.discount + "%"}</>
            else comment = translate("Знижка", dicLang) + ": " + item.discount + "%";
            price = price - (price * (item.discount / 100));
            price = Math.round(price * 100) / 100;
        } else if (startDate) comment = translate('Дата початку', dicLang) + ': ' + dateFormat(startDate);
        setItemDesc({title: data.courseTitle, subtitle: data.title, comment: comment, price: price});
        updateSubtotal(index, price);
    }

    function setItemDescription(data, setItemDesc, dicLang, updateSubtotal) {
        if (data && data.length > 0) {
            const row = data[0];
            updateSubtotal(index, row.price);
            setItemDesc({title: <PostMessage msg={row.name} open={false} sep={false} textStyle="cartItemTitle"/>,
                subtitle: row.schoolName, comment: row.digital === 1 ?
                translate("Цифровий продукт", dicLang) : null, price: row.price});
        }
    }

    function getItemDescription(item, lang, setItemDesc, dicLang, updateSubtotal) {
        switch (item.kind) {
            case 'plan':
                getAPICall('package/data', {packageId: item.packageId},
                    (data) => {
                        setPlanDescription(data, setItemDesc, item, dicLang, updateSubtotal); 
                    });
                break;
            case 'item':
                getAPICall('shop/item', {item: item.itemId},
                    (data) => { 
                        setItemDescription(data, setItemDesc, dicLang, updateSubtotal);
                     });
                break;
            default:
        }
    }

    useEffect(() => {
        if (itemDesc && 'remove' in itemDesc) {
            removeFromCart(index);
        } else {
            getItemDescription(item, lang, setItemDesc, dicLang, updateSubtotal);
        }
        if (item.digital === 0 && item.kind === "item") {
            getAPICall('item/info', {item: item.itemId}, (data) => {
                if (data && data.length > 0) {
                    const row = data[0];
                    setStock(row.stock);
                }
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item, index, lang, dicLang]);

    const onChange = (n) => {
        updateSubtotal(n * itemDesc.price);
        updateCartAmount(index, n);
    }

    return itemDesc === null || 'remove' in itemDesc ? null : (small ?
      <tr style={{verticalAlign: "middle"}} className="preventSelect">
        <td colSpan={3} style={{textAlign: "center"}}>
            <div>
                <TiDeleteOutline className="cartRemoveItem" size="24" onClick={() => { removeFromCart(index); }}/>
                <img className="cartItemImage" alt="" src={srcImg}
                    style={{marginLeft: "8px", width: "128px", height: "128px"}}/>
            </div>
            <div>
                <div className="cartItemTitle">{itemDesc.title}</div>
                {itemDesc.subtitle && <p className="cartItemSubtitle">{itemDesc.subtitle}</p>}
                {itemDesc.comment && <p className="cartItemComment">{itemDesc.comment}</p>}
            </div>
        </td>
        <td style={{textAlign: "center"}}>
            {item.digital === 0 && <NumericSpinner amount={item.amount} onChange={onChange}
                max={stock === null ? 30 : stock}/>}
            <p className="cartItemPrice">{itemDesc.price.toFixed(2)} грн</p>
        </td>
      </tr>
    : <tr style={{verticalAlign: "middle"}} className="preventSelect">
        <td>
            <TiDeleteOutline className="cartRemoveItem" size="24" onClick={() => { removeFromCart(index); }}/>
        </td>
        <td style={{width: "228px"}}>
            <img className="cartItemImage" alt="" src={srcImg} style={{marginLeft: "8px"}}/>
        </td>
        <td>
            <div className="cartItemTitle">{itemDesc.title}</div>
            {itemDesc.subtitle && <p className="cartItemSubtitle">{itemDesc.subtitle}</p>}
            {itemDesc.comment && <p className="cartItemComment">{itemDesc.comment}</p>}
        </td>
        <td style={{textAlign: "center"}}>
            {item.digital === 0 && <NumericSpinner amount={item.amount} onChange={onChange}
                max={stock === null ? 30 : stock}/>}
            <p className="cartItemPrice">{itemDesc.price.toFixed(2)} грн</p>
        </td>
    </tr>)
}

export default CartItem