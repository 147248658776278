import { useContext, useState, useEffect } from "react";
import { AppContext } from "../../AppContext/AppContext";
import { MediaPath } from "../../Components/MediaPath";
import WriteHelper from "../../Components/WriteHelper";
import DocPreview from "../../Components/DocPreview";
import Card from 'react-bootstrap/Card';
import Translate, { formatDate } from "../../AppContext/Translate";
import { MimeIconSmall } from "../../Components/MimeIcon";
import { MdFeedback } from "react-icons/md";
import Review from "./Review";

function StudentTask({student, pkgId, courseId, task, review}) {
    var { userId, token } = useContext(AppContext);
    const [comment, setComment] = useState(null);
    const [preview, setPreview] = useState(null);

    useEffect(() => {
        async function updateComment(text, path) {
            const elements = await WriteHelper.parseElements(text, path, userId, token, 'textPost', () => { });
            setComment(elements);
        }
        var path = new MediaPath(MediaPath.kind_privateTaskDelivery);
        path.add(student);
        path.add(pkgId);
        path.add(task.post);
        updateComment(task.comment, path);
    }, [task.comment, task.post, userId, token, pkgId, student]);

    const onPreview = (data) => {
        setPreview(data);
    }

    var path = new MediaPath(MediaPath.kind_privateTaskDelivery);
    path.add(student);
    path.add(pkgId);
    path.add(task.post);

    return <>
        {preview && <DocPreview kind={preview["kind"]} name={preview["name"]} setPreview={setPreview} path={path}/>}
        <Card style={{width: "420px", maxWidth: "100%", margin: "6px"}}>
            <Card.Header>{task.taskName}</Card.Header>
            <Card.Body style={{paddingTop: "4px", backgroundColor: "#f8f8f8"}}>
                <div className="chatUserName" style={{textAlign: "right"}}>{formatDate(task.lastUpdate)}</div>
                {comment}
                {task.file && <div style={{display: "flex", justifyContent: "center"}}>
                    <MimeIconSmall name={task.file} onPreview={onPreview}/>
                </div> }
                <hr/>
                {task.options.includes('Y') || task.options.includes('Z') ? 
                <Review student={student} pkgId={pkgId} courseId={courseId} task={task} review={review}/> :
                <p style={{margin: "0px", fontStyle: "italic"}}><MdFeedback style={{marginRight: "6px"}} color="crimson" /><Translate>Студент не набув можливості перегляду</Translate>.</p>}
            </Card.Body>
        </Card>
    </>
}

export default StudentTask