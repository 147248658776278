import UserPhotoIcon from "../../Components/UserPhotoIcon";
import { useContext } from "react";
import { AppContext } from "../../AppContext/AppContext";

function TaskParticipant({user, name, index, selected, setSelected}) {
    const { userId, userName } = useContext(AppContext);
    const cname = selected ? "taskUserCardSel" : "taskUserCard";
    return <div className={cname} onClick={() => {setSelected(index)}}>
        <UserPhotoIcon user={user} userName={user===userId ? userName : name}/>
        {name}
    </div>
}

export default TaskParticipant