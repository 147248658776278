import { useContext, useEffect, useState } from "react";
import { BsBox2Fill } from "react-icons/bs";
import { getAPICall, postAPICall, postAPIUploadProgress } from "../Components/APICall";
import { PiArrowFatLinesRightDuotone } from "react-icons/pi";
import { AppContext } from "../AppContext/AppContext";
import PostMessage from "../Components/PostMessage";
import Button from "react-bootstrap/Button";
import Translate, { formatDate, translate } from "../AppContext/Translate";
import { BsSendPlus } from "react-icons/bs";
import ChooseFileButton from "../Components/ChooseFileButton";
import UploadProgress from "../Components/UploadProgress";
import { FiLink } from "react-icons/fi";
import { MediaPath } from "../Components/MediaPath";
import DocPreview from "../Components/DocPreview";
import { MimeIconSmall } from "../Components/MimeIcon";

function onRequestError(dicLang) {
    alert(translate("Помилка", dicLang) + ": " + translate("не вдалося зберегти зміни", dicLang));
}

function RequestedOptionFile({option, request}) {
    const { userId } = useContext(AppContext);
    const [preview, setPreview] = useState(null);

    var path = new MediaPath(option.share === 0 ? MediaPath.kind_privateOptionShared :
        MediaPath.kind_privateOptionInd);
    if (option.share === 1) {
        path.add(userId);
    }
    path.add(option.pkgId);
    path.addStr(option.code);

    const onPreview = (data) => {
        setPreview(data);
    }

    return <>
        <p style={{fontWeight: "600"}}><Translate>Ви отримали від викладачів наступний файл</Translate>:</p>
        {preview && <DocPreview kind={preview["kind"]} name={preview["name"]} setPreview={setPreview} path={path}/>}
        <MimeIconSmall name={request.link} onPreview={onPreview}/>
    </>
}

function RequestedOptionLink({request}) {
    return <>
        <p style={{fontWeight: "600"}}><Translate>Ви отримали наступне посилання від викладачів</Translate>:</p>
        <p><FiLink style={{marginRight: "8px"}}/><a href={request.link} target="_blank" rel="noreferrer">{request.link}</a></p>
    </>
}

function RequestedOption({option, request}) {
    return request.sentDate ? (option.kind === 0 ? <RequestedOptionLink request={request}/> :
        <RequestedOptionFile option={option} request={request}/>)
    : <ul>
        <li><Translate>Заява надіслана</Translate> {formatDate(request.requestDate)}</li>
        <li><Translate>Ви отримаєте сповіщення, коли викладачі передадуть вам матеріал</Translate>.</li>
    </ul>
}

function UnrequestedOption({letter, option, forceUpdate}) {
    const { userId, token, dicLang } = useContext(AppContext);
    const [link, setLink] = useState(null);
    const [progress, setProgress] = useState(null);

    const doRequest = () => {
        postAPICall('student/optionRequest', {userId: userId, token: token, pkgId: option.pkgId,
            code: letter, courseId: option.course}, (data) => {
                if (data && 'error' in data && data.error === 'none') forceUpdate();
                else onRequestError(dicLang); 
            }, () => { onRequestError(dicLang); });
    }

    const fileChange = (evnt) => {
        if (!evnt) setLink(null);
        else {
            const file = evnt.target.files[0];
            if (!file) { return; }
            const size = file.size / 1048576; 
            if (size > 10) {
                alert(translate("Помилка", dicLang) + ": " + translate("файли розміром понад 10 Мб не можна завантажувати", dicLang));
                return;
            }
            setLink({name: file.name, size: size, file: file});
        }
    }

    const sendFile = () => {
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
            const { result } = e.target;
            if (result) {
                //console.log(JSON.stringify());
                postAPIUploadProgress('student/optionFileRequest', {name: link.name, buffer: result}, 
                    {userId: userId, token: token, pkgId: option.pkgId, code: letter, courseId: option.course,
                        link: link.name}, (data) => {
                        setProgress(null);
                        if (data && 'error' in data && data.error === 'none') forceUpdate();
                        else onRequestError(dicLang); 
                    }, () => { setProgress(null); onRequestError(dicLang); },
                    (percent) => { setProgress({file: link.name, percent: percent, action: 'upl'}); }
                );
            } else {
                onRequestError(dicLang);
            }
        }
        fileReader.readAsDataURL(link.file);
    }

    return option.request === 2 ? <>
        <ChooseFileButton ext={null} fileSrc={link} fileChange={(e) => { fileChange(e); }} align="flex-start"/>
        {link && <Button style={{margin: "8px 0px 8px 0px"}} onClick={sendFile}>
            <BsSendPlus style={{marginRight: "6px"}}/><Translate>Запит</Translate>
            </Button>}
        <UploadProgress progress={progress}/>
    </> : <Button style={{margin: "8px 0px 8px 0px"}} onClick={doRequest}><BsSendPlus style={{marginRight: "6px"}}/><Translate>Запит</Translate></Button>
}

function Option({letter, option, request, forceUpdate}) {
    return letter === 'Y' || letter === 'Z' || !option ? null : <>
        <p style={{fontSize: "large", color: "cadetblue", fontWeight: "600", marginTop: "18px"}}>
            <PiArrowFatLinesRightDuotone style={{marginRight: "6px", marginTop: "-2px"}}/>{option.title}</p>
        <PostMessage msg={option.description} textStyle="smallTextPost" open={false} sep={false}/>
        {request ? <RequestedOption option={option} request={request}/>
        : <UnrequestedOption letter={letter} option={option} forceUpdate={forceUpdate}/>}
    </>
}

function CourseOptions({course, pkg}) {
    const { userId, token } = useContext(AppContext);
    const [options, setOptions] = useState([]);
    const [requests, setRequests] = useState([]);

    useEffect(() => {
        getAPICall('course/options', {course: course.id}, (data) => {
            if (data) {
                setOptions(data);
            }
        });
        getAPICall('student/optionRequest', {userId: userId, token: encodeURIComponent(token), 
            pkgId: pkg.package}, (data) => {
                if (data) {
                    setRequests(data);
                }
            });
    }, [course.id, userId, token, pkg.package]);

    const getOption = (letter, options) => {
        for (var i = 0; i < options.length; i++) {
            if (options[i].code === letter) return {...options[i], pkgId: pkg.package};
        }
        return null;
    }

    const getRequest = (letter) => {
        for (var i = 0; i < requests.length; i++) {
            if (requests[i].code === letter) return requests[i];
        }
        return null;
    }

    const forceUpdate = () => {
        getAPICall('student/optionRequest', {userId: userId, token: encodeURIComponent(token), 
            pkgId: pkg.package}, (data) => {
                if (data) {
                    setRequests(data);
                }
            });
    }

    return <div style={{padding: "12px", backgroundColor: "white"}}>
        <h4><BsBox2Fill style={{marginRight: "8px", marginTop: "-4px"}}/>{pkg.title}</h4>
        {pkg.options.split('').map((letter, index) => (
            <Option key={index} letter={letter} option={getOption(letter, options)}
                request={getRequest(letter)} forceUpdate={forceUpdate}/>
        ))}
    </div>
}

export default CourseOptions