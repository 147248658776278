import MenuUserIcon from "./MenuUserIcon";
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from "react-router-dom";
import { FaRegUser } from "react-icons/fa";
import { RiLogoutCircleLine } from "react-icons/ri";
import { GiTeacher } from 'react-icons/gi';
import { MdOutlineMessage } from 'react-icons/md';
import { TbAward } from 'react-icons/tb';
import Translate from "../../AppContext/Translate";
import { AiOutlineGift } from "react-icons/ai";
import { FcSettings } from 'react-icons/fc';
import { MdOutlineBorderColor } from "react-icons/md";

function MenuLoggedUser({size, userName, userId}) {
    return <MenuUserIcon size={size} userName={userName} dropDown={true}>
        <NavDropdown.Item as={Link} to={'/profile/' + userId} ><FaRegUser />{' '}<Translate>Мої профіль</Translate></NavDropdown.Item>
        <NavDropdown.Item as={Link} to={'/user/courses'} ><GiTeacher />{' '}<Translate>Мої курси</Translate></NavDropdown.Item>
        <NavDropdown.Item as={Link} to={'/user/messages'} ><MdOutlineMessage />{' '}<Translate>Мої повідомлення</Translate></NavDropdown.Item>
        <NavDropdown.Item as={Link} to={'/user/rewards'} ><TbAward />{' '}<Translate>Мої винагороди</Translate></NavDropdown.Item>
        <NavDropdown.Item as={Link} to={'/user/products'} ><AiOutlineGift />{' '}<Translate>Мої товари</Translate></NavDropdown.Item>
        <NavDropdown.Item as={Link} to={'/user/orders'} ><MdOutlineBorderColor />{' '}<Translate>Мої замовлення</Translate></NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item as={Link} to={'/cabinet/menu'} ><FcSettings />{' '}<Translate>Особистий кабінет</Translate></NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item as={Link} to={'/user/logout'} ><RiLogoutCircleLine />{' '}<Translate>Вийти</Translate></NavDropdown.Item>
    </MenuUserIcon>
}

export default MenuLoggedUser