import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Translate from '../AppContext/Translate';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';

function CourseMenu({courseId}) {
    return <Navbar bg="dark" data-bs-theme="dark" className="py-0" style={{height: '32px', width: "100%",
        borderTop: "solid thin #3d444b"}}>
        <Container fluid style={{padding: "0px"}}>
            <Nav className="me-auto">
                <Nav.Link as={Link} to={'/course/' + courseId}><Translate>Про курс</Translate></Nav.Link>
                <Nav.Link as={Link} to={'/projects/' + courseId}><Translate>Проекти</Translate></Nav.Link>
            </Nav>
        </Container>
    </Navbar>
}

export default CourseMenu