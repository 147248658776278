import { useContext, useEffect, useState } from "react";
import ChoosePackage from "./ChoosePackage"
import { AppContext } from "../AppContext/AppContext";
import { getAPICall } from "../Components/APICall";
import BeatLoader from "react-spinners/BeatLoader";
import CalendarCountdown from "./Calendar/CalendarCountdown";
import TasksList from "./Tasks/TasksList";
import Task from "./Tasks/Task";

function CourseTasks({course, student, params, selectedPackage, setSelectedPackage}) {
    const { userId, token } = useContext(AppContext);
    const [groupData, setGroupData] = useState(null);
    const [studentData, setStudentData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [taskListExpanded, setTaskListExpanded] = useState(params.taskId < 0);
    const [tasks, setTasks] = useState([]);
    const [selectedTask, setSelectedTask] = useState(null);

    useEffect(() => {
        const getTasksSuccess = (data) => {
            setLoading(false);
            setTasks(data);
            if (data && params.taskId > 0) {
                for (var i = 0; i < data.length; i++) {
                    if (data[i].post === params.taskId) {
                        setSelectedTask(data[i]);
                        break;
                    }
                }
            }
        }

        const getTasksError = (data) => {
            setLoading(false);
            setTasks(null);
            setSelectedTask(null);
        }

        var sData = null;
        for (var i = 0; i < student.length; i++) {
            if (student[i].package === selectedPackage) {
                sData = {id: userId, package: selectedPackage, plan: student[i].plan, options: student[i].options,
                    group: student[i].classgroup, student: true};
                break;
            }
        }
        setStudentData(sData);
        if (sData.group && sData.group >= 0 && sData.plan === 1) {
            getAPICall('group/date', {groupId: sData.group}, (info) => {
                if (info && info.length > 0) {
                    var g = info[0];
                    const startDate = new Date(g.startDate).getTime();
                    const currentDate = new Date(g.today).getTime();
                    g['difference'] = currentDate - startDate;
                    setGroupData(g);
                    getAPICall('tasks/list', {userId: userId, token: encodeURIComponent(token), courseId: course.id,
                        packageId: selectedPackage}, getTasksSuccess, getTasksError);
                }
            });    
        } else {
            setGroupData(null);
            getAPICall('tasks/list', {userId: userId, token: encodeURIComponent(token), courseId: course.id,
                packageId: selectedPackage}, getTasksSuccess, getTasksError);
        }
    }, [selectedPackage, student, userId, course.id, token, params.taskId]);

    const onEndCountdown = () => {
        getAPICall('group/date', {groupId: student.group}, (info) => {
            if (info && info.length > 0) {
                var g = info[0];
                g['startDate'] = new Date(g.startDate).getTime();
                g['today'] = new Date(g.today).getTime();
                g['difference'] = g['today'] - g['startDate'];
                setGroupData(g);
            }
        });
    }

    function markTaskAsSent(postId) {
        const newTasks = tasks.map(obj => {
            if (obj.post === postId) {
              return {...obj, sentFiles: 1};
            }
            return obj;
        });
        setTasks(newTasks);
        for (var i = 0; i < newTasks.length; i++) {
            if (newTasks[i].post === postId) {
                setSelectedTask(newTasks[i]);
                break;
            }
        }
    }

    const selectTask = (data) => {
        setSelectedTask(data);
        setTaskListExpanded(false);
    }

    const dayNumber = groupData ? 1 + Math.floor(groupData["difference"] / 86400000) : 365000;

    return <>
        <ChoosePackage student={student} selectedPackage={selectedPackage} setSelectedPackage={setSelectedPackage}/>
        {loading ? <div><BeatLoader size={24} color="#0d6efd" /></div> : 
            studentData && <>
            {groupData && groupData["difference"] < 0 && 
                <CalendarCountdown groupData={groupData} onEndCountdown={onEndCountdown} /> }
            <TasksList expanded={taskListExpanded} setExpanded={setTaskListExpanded} tasks={tasks} dayNumber={dayNumber}
                selectTask={selectTask}/>
            {selectedTask && selectedTask.day <= dayNumber &&
             <Task task={selectedTask} courseId={course.id} groupId={studentData.group} packageId={selectedPackage}
                markTaskAsSent={markTaskAsSent} options={studentData.options}/>
            }
        </>}
    </>
}

export default CourseTasks