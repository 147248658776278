import { useEffect, useState } from "react";
import { getAPICall } from "../Components/APICall";
import Project from "../Course/Projects/Project";
import DocPreview from "../Components/DocPreview";
import MagButton from "../Components/MagButton";
import HorizontalScroll from "../Components/HorizontalScroll";

function SectionProjects() {
    const [projects, setProjects] = useState([]);
    const [preview, setPreview] = useState(null);

    useEffect(() => {
        getAPICall('tasks/projects', {courseId: 0, offset: 0, limit: 8},
            (data) => { setProjects(data); });
    }, []);

    const onPreview = (data) => {
        setPreview(data);
    }

    return <div style={{backgroundColor: "white"}}>
        {preview && <DocPreview kind={preview["kind"]} name={preview["name"]} setPreview={setPreview} 
            path={preview["path"]}/>}
        <img alt="" src="/bkg/decor/dec11.png" style={{position: "absolute", width: "120px",
                bottom: "-128px", right: "0px", zIndex: 1}}/>
        <div style={{backgroundColor: "#fee6dd", marginLeft: "16px",
            marginRight: "16px", borderRadius: "48px"}}>
            <img alt="" src="/bkg/decor/dec4.png" style={{position: "absolute", width: "4%", maxWidth: "32px",
                minWidth: "16px", top: "54px", right: "min(25%, 250px)", filter: "hue-rotate(282deg)"}}/>
            <h1 className="pad32">роботи<br/>наших студентів</h1>
            <div style={{paddingLeft: "4px", paddingRight: "4px"}}>
                <HorizontalScroll itemSize={232}>
                    {projects.map((prj, i) => {
                        return <Project key={i} data={prj} onPreview={onPreview} small={true}/>
                    })}
                </HorizontalScroll>
            </div>
            <div className="pad32">
                <MagButton text="більше робіт" clickEff={{link: "/projects"}}/>
            </div>
        </div>
    </div>
}

export default SectionProjects