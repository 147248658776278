import Translate from "../AppContext/Translate"
import { useContext, useEffect, useState } from "react";
import { getAPICall } from "../Components/APICall";
import { AppContext } from "../AppContext/AppContext";
import BeatLoader from "react-spinners/BeatLoader";
import CategoryCard from "./CategoryCard";
import HorizontalScroll from "../Components/HorizontalScroll";

function SectionCategories() {
  const { lang } = useContext(AppContext);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    getAPICall('categories/count', {lang: lang}, (data) => {
      if (data) setCategories(data);
    });
  }, [lang]);

  return <div style={{padding: "28px", backgroundColor: "white"}}>
    <img alt="" src="/bkg/decor/dec4.png" style={{position: "absolute", width: "4%", maxWidth: "32px",
      minWidth: "16px", top: "54px", left: "min(25%, 250px)"}}/>
    <h1 style={{textAlign: "right", marginTop: "72px", marginBottom: "48px"}}><Translate>обирай напрямок навчання</Translate></h1>
    {categories.length === 0 ? <BeatLoader/> : 
      <HorizontalScroll itemSize={256}>
        {categories.map((cat, i) => {
            return <CategoryCard key={i} category={cat}/>
        })}
      </HorizontalScroll>
    }
    </div>
}

export default SectionCategories