import { useState, useRef } from "react";
import Translate from "../AppContext/Translate";
import { Link } from "react-router-dom";
import { RiCloseLine } from 'react-icons/ri';

function SearchSubitem(props) {
  const addr = "/load/course/" + props.id;
  const pic = "/courses/cover" + props.id + props.ext;
  if (props.size === "tiny") {
    return (
      <li className="col-sm-6 col-md-3 col-lg-2" style={{padding: '10px'}}>
        <Link to={addr}>
          <div style={{width: '100%', display: 'flex'}}>
            <img alt="" src={pic} width="50%" style={{marginRight: '12px'}} />
            <div>
              <p className="boldWhiteHoverRed" style={{marginTop: '10px'}}>{props.title}</p>
              <p style={{color: '#b8b7b7', marginTop: '24px'}}>
                <Translate>Курс від</Translate>
                {' '}{props.school}
              </p>
            </div>
          </div>
        </Link>
      </li>
    );
  } else {
    return (
      <li className="col-sm-6 col-md-3 col-lg-2" style={{padding: '10px'}}>
        <Link to={addr}>
          <div className="col-5 col-sm-12 boldWhiteHoverRed">
            <img alt="" src={pic} width="100%" />
            <p style={{marginTop: '10px'}}>{props.title}</p>
          </div>
        </Link>
        <p style={{color: '#b8b7b7'}}>
          <Translate>Курс від</Translate>
          {' '}{props.school}
        </p>
      </li>
    );
  }
}
  
function SearchBar({ placeholder, data, size }) {
    const maxElements = 48;
    const imgExt = ".jpg"; 
    const [filteredData, setFilteredData] = useState([]);
    const [wordEntered, setWordEntered] = useState("");
    const [searchFullBar, setSearchFullBar] = useState(0);
    const inputRef = useRef();

    const handleFilter = (event) => {
        const searchWord = event.target.value;
        setWordEntered(searchWord);
        const newFilter = data.filter((value) => {
            return value.title.toLowerCase().includes(searchWord.toLowerCase());
            });
        if (searchWord === "") {
            setFilteredData([]);
        } else {
            setFilteredData(newFilter);
        }
    };

    const iconSearchClick = (event) => {
        setSearchFullBar(1);
    };

    const iconCloseSearchClick = (event) => {
        setSearchFullBar(0);
        clearInput();
    };

    const clearInput = () => {
        setFilteredData([]);
        setWordEntered("");
    };
    
    if (size !== "big") {
        if (searchFullBar === 0 && filteredData.length === 0) {
            return <div className='menuDivIcon'>
              <img src="/bkg/lensw.png" className="align-top pointer" height="26" alt=""
                onClick={iconSearchClick} />
            </div>
        } else {
            return (<div className="fullSearchBar bg-dark">
              <div className="search">
                <input autoFocus 
                    type="text"
                    placeholder={placeholder}
                    value={wordEntered}
                    onChange={handleFilter}
                    style={{marginLeft: '12px', width: 'calc(100vw - 54px)', marginTop: "22px"}}
                    ref={inputRef} />
                <RiCloseLine onClick={iconCloseSearchClick} className="closeButtonIcon" color="white" />
                {filteredData.length !== 0 && (
                  <div className="dataResult">
                    <div style={{marginTop: '16px'}}>
                      <p>{filteredData.length > maxElements ? maxElements : filteredData.length}{' '}
                      <Translate>результати для</Translate> '{wordEntered}'
                      </p>
                    </div>
                    <ol className="olDataResult">
                    {
                      filteredData.slice(0, maxElements).map((element, index) => {
                          return <SearchSubitem size={size} key={index} title={element.title} 
                            id={element.id} school={element.school} ext={imgExt} />
                      })
                    }
                    </ol>
                  </div>
                )}
              </div>  
            </div>
          );
        }
      } else {
        return (
          <div className="search">
            <div className="searchInputs">
              <input
                type="text"
                placeholder={placeholder}
                value={wordEntered}
                onChange={handleFilter}
                ref={inputRef}
              />
              <div className="searchIcon">
                {filteredData.length === 0 ?
                  <img alt="" src="/bkg/lens.png" height="22" onClick={() => {inputRef.current.focus()}} />
                  : <RiCloseLine id="clearBtn" onClick={clearInput} />
                }
              </div>
            </div>
            {filteredData.length !== 0 && (
              <div className="dataResult">
                <div style={{marginTop: '16px'}}>
                  <p>{filteredData.length > maxElements ? maxElements : filteredData.length}{' '}
                  <Translate>результати для</Translate> '{wordEntered}'
                  </p>
                </div>
                <ol className="olDataResult">
                {
                  filteredData.slice(0, maxElements).map((element, index) => {
                      return <SearchSubitem size={size} key={index} title={element.title} id={element.id} 
                        school={element.school} ext={imgExt} />
                  })
                }
                </ol>
              </div>
            )}
          </div>
        );
    }
}

export default SearchBar