import { useParams } from "react-router-dom"
import { Navigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import Menu from "../Menu/Menu";
import { AppContext } from "../AppContext/AppContext";
import { getAPICall } from "../Components/APICall";
import Translate from "../AppContext/Translate";
import Footer from "../Components/Footer";
import BeatLoader from "react-spinners/BeatLoader";
import CertificateLanguage from "./CertificateLanguage";

function Certificate() {
    const { userId, token } = useContext(AppContext);
    const [error, setError] = useState(null);
    const [courseInfo, setCourseInfo] = useState(null);
    var packageId = useParams()['id'];
    var diploma = true;
    if (packageId && packageId.startsWith('c')) {
        diploma = false;
        packageId = packageId.substring(1);
    }    

    useEffect(() => {
        const courseDataOk = (data) => {
            if (data && data.length === 1) {
                setCourseInfo(data[0]);
            } else courseDataError();
        }
        const courseDataError = () => {
            setError(<><p><b><Translate>Помилка</Translate></b>:</p><p><Translate>Неможливо отримати доступ до інформації</Translate>.<br/><Translate>Спробуйте пізніше або напишіть до нашої служби підтримки</Translate>.</p></>);
        }
        if (packageId) {
            if (diploma) {
                getAPICall('diploma/courseData', {userId: userId, token: encodeURIComponent(token),
                    packageId: packageId}, courseDataOk, courseDataError);
            } else {
                getAPICall('diploma/certificate', {userId: userId, token: encodeURIComponent(token),
                    packageId: packageId}, courseDataOk, courseDataError);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, token, packageId]);

    return !packageId ? <Navigate to="/" replace={true} /> :
    <div className='mainContainer'>
        <Menu />
        <div className="userProfileForm" style={{margin: "16px", minHeight: "unset"}}>
        {error !== null ? error : <>
            <h1><Translate>{diploma ? "Диплом" : "Сертифікат"}</Translate></h1>
            <hr/>
            {courseInfo ? <>
                <h2>{courseInfo.courseTitle}</h2>
                <h4 style={{marginBottom: "16px"}}>{courseInfo.planTitle}</h4>
                <CertificateLanguage packageId={packageId} diploma={diploma}/>
            </> : <BeatLoader/>
            }
        </>
        }
        </div>
        <Footer/>
    </div>
}

export default Certificate