import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import Menu from "../Menu/Menu";
import SchoolCover from "./SchoolCover";
import { getAPICall } from "../Components/APICall";
import BeatLoader from "react-spinners/BeatLoader";
import SchoolTeachers from "./SchoolTeachers";
import { useNavigate } from "react-router-dom";
import Translate from "../AppContext/Translate";
import InfiniteScroll from 'react-infinite-scroll-component';
import SyncLoader from "react-spinners/SyncLoader";
import Item from "../Shop/Item";
import Footer from "../Components/Footer";
import PostMessage from "../Components/PostMessage";
import { MediaPath } from "../Components/MediaPath";
import SchoolCourses from "./SchoolCourses.js";
import HomeButton from "../Components/HomeButton.js";

function School() {
    const id = useParams()["id"];
    const [schoolId, setSchoolId] = useState(() => {
        if (id !== null) {
            var sid = parseInt(id);
            return isNaN(sid) ? null : sid;
        }
        return id;
    });
    const [schoolData, setSchoolData] = useState(null);
    const [teachers, setTeachers] = useState(null);
    const [courses, setCourses] = useState(null);
    const [products, setProducts] = useState({items: [], hasMore: true, offset: 0});
    const limit = 16;
    let navigate = useNavigate();

    const itemsSuccess = (data) => {
        if (data && data.length > 0) {
            setProducts({
                items: products.items.concat(data),
                hasMore: data.length >= limit,
                offset: products.offset + data.length
            });
        } else itemsError(data);
    }
    const itemsError = (data) => {
        setProducts({items: products.items, hasMore: false, offset: products.offset});
    }

    const fetchMoreData = () => {
        getAPICall('shop/items', {lang: '', school: schoolId, kind: 0, offset: products.offset, limit: limit},
            itemsSuccess, itemsError);
    }

    useEffect(() => {
        const getTeachersOk = (data) => {
            if (data && data.length > 0)
                setTeachers(data);
            }
        const getCoursesOk = (data) => {
            if (data && data.length > 0)
                setCourses(data);
        }
        if (schoolId !== null) {
            getAPICall('school/data', {school: schoolId}, (data) => {
                if (data && data.length > 0) {
                    setSchoolData(data[0]);
                    getAPICall('school/teachers', {school: schoolId}, getTeachersOk);
                    getAPICall('school/courses', {school: schoolId, active: 1}, getCoursesOk);
                    fetchMoreData();
                } else setSchoolId(null);
            }, () => { setSchoolId(null); });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schoolId]);
    
    const routeChange = (path) => { 
        navigate(path);
    }
    
    var path = new MediaPath(MediaPath.kind_publicSchool);
    path.add(schoolId);
    path.addStr('desc');

    return !schoolId || schoolId < 0 ? <Navigate to="/" replace={true}/> :
    <div className='mainContainer' style={{backgroundColor: "white"}}>
        <Menu />
        <SchoolCover school={schoolId} allowChange={false}/>
        <div className="userProfileContent">
            {!schoolData ? <p style={{marginTop: "16px"}}><BeatLoader color="gray" size={36}/></p> : <>
                <div style={{display: "flex", alignItems: "flex-start"}}>     
                    <img className="medSchoolLogo" style={{marginRight: "16px"}} alt="Logo" 
                        src={'/schools/logo' + schoolId + '.png'}/>     
                    <h1>{schoolData.title}</h1>
                </div>
                {schoolData.description && schoolData.description.length > 0 && 
                    <PostMessage msg={schoolData.description} path={path} open={false} sep={false} textStyle="textPost"/>
                }
                {teachers && <SchoolTeachers teachers={teachers} routeChange={routeChange}/>}
                {courses && <SchoolCourses courses={courses} school={schoolId} schoolName={schoolData.title}/>}
                {products.items.length > 0 && <>
                    <hr/>
                    <h2><Translate>Магазин</Translate></h2>
                    <div id="itemsScrollableDiv">
                    <InfiniteScroll
                        dataLength={products.items.length}
                        next={fetchMoreData}
                        hasMore={products.hasMore}
                        loader={<p><SyncLoader size={18} color="#73694c"/></p>}
                        style={{display: "flex", flexWrap: "wrap", justifyContent: "space-around", alignItems: "center"}}
                        scrollableTarget="itemsScrollableDiv"
                        endMessage={null}>
                        {products.items.map((data, index) => 
                            <Item key={index} data={data}/>)}
                    </InfiniteScroll>
                </div></>}
            </>}
        </div>
        <div style={{height: "16px"}}/>
        <HomeButton/>
        <Footer/>
    </div>
}

export default School