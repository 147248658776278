import { Link } from "react-router-dom"

function MessageButton({unreadMessages}) {
    return <Link to="/user/messages">
        <div style={{padding: "4px"}}>
            <img alt="повідомлення" src="/icons/message.png" height="30px"/>
            {unreadMessages > 0 && <div style={{fontSize: "x-small", backgroundColor: "#ff2f43", position: "absolute",
                padding: "1px 5px 0px 5px", color: "white", borderRadius: "50%", top: "0px", right: "-4px"}}>
                {unreadMessages}
            </div>}
        </div>
    </Link>
}

export default MessageButton