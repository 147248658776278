import { Link } from "react-router-dom"
import Translate from "../../AppContext/Translate"

function CabinetButton() {
    return <Link to="/cabinet/menu">
        <button style={{backgroundColor: "#fac032", color: "black", border: "none", padding: "3px 10px"}}>
            <img alt="cabinet" src="/icons/bag.png" width="22px" className="right6"/><Translate>Особистий кабінет</Translate>
        </button>
    </Link>
}

export default CabinetButton