import { useState } from "react";
import { getAPIurl } from "../Components/APICall";
import { randomColor } from "./RandomColor";
import { Link } from "react-router-dom";

function UserPhotoIcon({user, userName, big=false}) {
    const [foundImg, setFoundImg] = useState(true);
    const url = getAPIurl('media/userPhoto', {user: user});
    const initial = userName && userName.length > 0 ? userName.charAt(0) : "?";
    const sty = big ? "chatPhotoBig" : "chatPhoto";

    return <Link to={"/load/profile/" + user}> 
    { foundImg ?
    <img className={sty} src={url} alt="" onError={()=>setFoundImg(false)} 
        style={{transform: "translateX(-2px)"}} /> :
    <div className={sty} style={{paddingRight: "1px", backgroundColor: randomColor(user),
        paddingTop: "0.1em"}}>{initial}</div>
    }
    </Link>
}

export default UserPhotoIcon;