import Navbar from 'react-bootstrap/Navbar';
import { useContext } from 'react';
import { AppContext } from '../AppContext/AppContext';
import { useNavigate } from "react-router-dom";

function MenuCart() {
    const { cart } = useContext(AppContext);
    const navigate = useNavigate(); 

    const routeChange = () =>{ 
        navigate("/cart");
    }
    
    return (
        <Navbar.Text onClick={routeChange}>
            <div className='menuDivIcon'>
                {cart.length > 0 ?
                 <><img alt="" src="/bkg/cart.png" height="26"/>
                 <span style={{color: "white", fontSize: "14px"}}>{cart.length}</span></> :
                 <img alt="" src="/bkg/cart.png" height="26"/>
                }
            </div>
        </Navbar.Text>
    );
}

export default MenuCart