import { useEffect, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";
import BeatLoader from "react-spinners/BeatLoader";
import { Navigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import isStrongPassword from 'validator/es/lib/isStrongPassword';
import { AppContext } from "../../AppContext/AppContext";
import { translate } from "../../AppContext/Translate";
import ModalAlert from "../../Components/ModalAlert";
import Translate from "../../AppContext/Translate";
import ImageBackground from "../../Components/ImageBackground";
import PasswordForm from "./PasswordForm";
import ModalBrandHeader from "../../Components/ModalBrandHeader";
import LeftLoginImage from "./LeftLoginImage";
import { postAPICall } from "../../Components/APICall";
import { useWindowSize } from "../../AppContext/WindowSize";

const mode_loading = 0;
const mode_link_error = 1;
const mode_close = 2;
const mode_modal = 3;
const mode_pass_error = 4;
const mode_change_ok = 5;
const mode_change_error = 6;

function FormNewPassword({state, handleClose, formSubmit, handlePasswordChange, passwordInput, loading}) {
    var { dicLang } = useContext(AppContext);
    const [width, ] = useWindowSize();
    const size = width >= 768 ? "big" : (width < 576 ? "tiny" : "small");
    const [passwordType, setPasswordType] = useState("password");

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }

    switch (state) {
    case mode_loading:
        return <div style={{position: "fixed", width: "100vw", height: "100vh", zIndex: "99", backgroundColor: "#0000008c",
                    display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <BeatLoader size={48} color="white" />
                </div>
    case mode_link_error:
        return <ModalAlert type="modal-danger" show={true} handleClose={handleClose} title={translate("Посилання неправильне", dicLang)}
            msg={translate("Посилання неправильне або застаріле", dicLang) + "." + translate("Спробуйте створити нове посилання або зверніться до нашої служби підтримки", dicLang) + "."}
            buttonCaption={translate("Прийняти", dicLang)} />
    case mode_change_ok:
        return <ModalAlert type="modal-success" show={true} handleClose={handleClose} title={translate("Пароль успішно змінено", dicLang)}
            msg={translate("Тепер ви можете увійти, використовуючи новий пароль", dicLang) + "."}
            buttonCaption={translate("Прийняти", dicLang)} />
    case mode_change_error:
        return <ModalAlert type="modal-danger" show={true} handleClose={handleClose} title={translate("Помилка зміни пароля", dicLang)}
            msg={translate("Не вдалося оновити пароль", dicLang) + ". " + translate("Спробуйте пізніше або напишіть до нашої служби підтримки", dicLang) + "."}
            buttonCaption={translate("Прийняти", dicLang)} />
    default:
        return (
        <Modal show={true} onHide={handleClose} backdrop="static" keyboard={false} size="xl" centered >
        <Container>
            <Row>
                {(size === "big") && <LeftLoginImage/>}
                <Col style={{paddingLeft: '0px', paddingRight: '0px'}}>
                   <ModalBrandHeader/>
                   <Modal.Body>
                        <p><Translate>Введіть новий пароль</Translate>:</p>
                        <form className="Auth-form" onSubmit={formSubmit}>
                            <div className="input-group-btn" style={{position: "relative"}}>
                                <PasswordForm onChangeEvent={handlePasswordChange} onClickEye={togglePassword} 
                                    error={state === mode_pass_error} passwordType={passwordType}
                                    value={passwordInput} isLogin={false}/>
                            </div>
                            <hr style={{marginTop: "32px"}}/>
                            <div style={{display: "flex", justifyContent: "flex-end", gap: "12px"}}>
                                <Button variant="primary" type="submit">
                                    { loading ? <BeatLoader size={24} color="white" /> : translate("Прийняти", dicLang) }
                                </Button>
                                <Button variant="secondary" type="button" onClick={handleClose}>{translate("Скасувати", dicLang)}</Button>
                            </div>
                        </form>
                   </Modal.Body>
                </Col>
            </Row>
        </Container>
    </Modal>);
    }
}

function ChangePassword() {
    const [searchParams, ] = useSearchParams();
    const [state, setState] = useState(mode_loading);
    const [id, setId] = useState(-1);
    const [passwordInput, setPasswordInput] = useState("");
    const [loading, setLoading] = useState(false);
    const token = searchParams.get("t");

    useEffect(() => {
        const validateSuccess = (data) => {
            const error = data['error'];
            if (error === 'none') {
                setId(data["id"]);
                setState(mode_modal);
            } else setState(mode_link_error);
        }
        const validateError = (data) => {
            setState(mode_link_error);
        }
        postAPICall('validateTimedToken', {token: token},
            validateSuccess, validateError);
    }, [token]);
    const handleClose = () => {
        setState(mode_close);
    }
    const formSubmit = (evnt) => {
        evnt.preventDefault();
        if (!isStrongPassword(passwordInput, {minLength: 8, minLowercase: 1, minUppercase: 1, minSymbols: 0, minNumbers: 0})) {
            setState(mode_pass_error);
        } else {
            setLoading(true);
            postAPICall('user/recoverPass', {id: id, token: token, password: passwordInput},
                changeSuccess, changeError);
        }
    }
    const handlePasswordChange = (evnt) => {
        setPasswordInput(evnt.target.value);
    }
    const changeSuccess = (data) => {
        setLoading(false);
        setState(mode_change_ok);
    }
    const changeError = (data) => {
        setLoading(false);
        setState(mode_change_error);
    }
    return (state === mode_close ? 
        <Navigate to="/" replace={true} /> :
        <ImageBackground>
            <FormNewPassword state={state} handleClose={handleClose} formSubmit={formSubmit}
                handlePasswordChange={handlePasswordChange} passwordInput={passwordInput} loading={loading} />
        </ImageBackground>
    );
}

export default ChangePassword