import { useState, useContext } from 'react';
import Form from 'react-bootstrap/Form';
import Translate, { translate } from '../../AppContext/Translate';
import Switch from 'react-switch';
import { AppContext } from '../../AppContext/AppContext';
import NumericInput from '../../Components/NumericInput';
import Button from "react-bootstrap/Button";
import { BiTrash } from 'react-icons/bi';
import { confirm } from '../../Components/ConfirmFunction';
import { postAPICall } from '../../Components/APICall';
import { MdOutlineAddBox } from "react-icons/md";
import { PiCaretDoubleRightFill } from "react-icons/pi";
import { FiMinusSquare } from "react-icons/fi";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import WriteHelper from '../../Components/WriteHelper';
import InfoButton from '../../Components/InfoButton';

function CabEditPackage({pkg, setField, forceUpdate, options}) {
    var { userId, token, dicLang } = useContext(AppContext);
    const [basicChanges, setBasicChanges] = useState(false);
    const [hardChanges, setHardChanges] = useState(false);
    const color = pkg.active === 1 ? "black" : ('new' in pkg ? 'darkcyan' : 'gray');

    const set = (field, value) => {
        setField(pkg.id, field, value);
        if (field === 'title' || field === 'price' || field === 'active') setBasicChanges(true);
        else setHardChanges(true);
    }

    const deleteError = () => {
        alert(translate("Помилка", dicLang) + ": " + 
            translate("пакет неможливо видалити, оскільки є студенти, які його придбали", dicLang) + ". " +
            translate("Ви можете вимкнути його, щоб він не з’являвся в пакетах курсу", dicLang) + ".");
    }

    const deletePkg = async () => {
        if (await confirm({confirmation: translate('Ви впевнені, що хочете видалити пакет?', dicLang)})) {
            postAPICall('course/deletePackage', {userId: userId, token: token, pkgId: pkg.id}, (data) => {
                if (data && 'error' in data && data.error === 'none') forceUpdate();
                else deleteError();
            }, deleteError);
        }
    }

    const getOpt = (letter) => {
        if (letter in options) return options[letter].title;
        else if (letter === 'Y') return translate("Домашні завдання зі зворотнім зв'язком", dicLang);
        else if (letter === 'Z') return translate("Перевірка роботи тренером (зворотній зв'язок) та диплом", dicLang);
        else return translate("Помилка", dicLang);
    }

    const addOption = (code) => {
        document.body.click();
        if (!pkg.options.includes(code))
            set('options', pkg.options + code);
    }

    const delOption = (code) => {
        if (pkg.options.includes(code))
            set('options', pkg.options.replace(code, ""));
    }

    const getOptionButton = (code, i) => {
        return pkg.options.includes(code) ? null :
            <Button key={i} variant="light" style={{marginBottom: "2px"}} onClick={() => { addOption(code) }}>
                {getOpt(code)}
            </Button>
    }

    const optionChooser = (
        <Popover id="popover-opt" style={{width: "90vw", maxWidth: "600px", padding: "8px"}}>
        <Popover.Body style={{display: "flex", flexDirection: "column", maxHeight: "250px", overflowY: "auto"}}>
            <p style={{fontWeight: "600"}}><Translate>Натисніть на послугу, яку ви хочете додати</Translate>:</p>
            {Object.keys(options).map((code, i) => {
                return getOptionButton(code, i);
            })}
            {getOptionButton('Y', Object.keys(options).length)}
            {getOptionButton('Z', Object.keys(options).length + 1)}
        </Popover.Body>
      </Popover>
    );

    const saveError = () => {
        alert(translate("Помилка", dicLang) + ": " + translate("не вдалося зберегти зміни", dicLang));
    }

    const saveHardError = () => {
        alert(translate("Помилка", dicLang) + ": " + 
            translate("модальність, параметри та термін дії не можна змінити, оскільки є студенти, які вже придбали цей пакет", dicLang)
            + ". " + translate("У вас є можливість деактивувати цей пакет і створити новий", dicLang) + ".");
        setHardChanges(false);
        forceUpdate();
    }

    const saveHardChanges = () => {
        postAPICall('course/updatePackage', {userId: userId, token: token, pkgId: pkg.id, 
            plan: pkg.plan, options: pkg.options, daysToExpire: pkg.daysToExpire}, (data) => {
            if (data && 'error' in data && data.error === 'none') {
                setHardChanges(false);
                alert(translate('Зміни успішно збережено', dicLang));
            } else saveHardError();
        }, saveHardError);
    }

    const save = () => {
        if (basicChanges) {
            postAPICall('course/basicUpdatePackage', {userId: userId, token: token, pkgId: pkg.id, 
                title: WriteHelper.filterSimpleText(pkg.title), price: pkg.price, active: pkg.active}, (data) => {
                if (data && 'error' in data && data.error === 'none') {
                    setBasicChanges(false);
                    if (hardChanges) {
                        saveHardChanges();
                    } else alert(translate('Зміни успішно збережено', dicLang));
                } else saveError();
            }, saveError);
        } else if (hardChanges) saveHardChanges();
    }

    return <div style={{position: "relative", borderBottom: "solid thin #cfe2ff", marginBottom: "8px", 
        paddingBottom: "8px", color: color}}>
        <div style={{position: "absolute", right: "-8px", top: "-8px"}}>
            <Button variant="link" onClick={() => { deletePkg(); }}><BiTrash size={18}/></Button>
        </div>
        <div style={{display: "flex", alignItems: "baseline", maxWidth: "90%"}}>
            <Form.Label style={{fontWeight: "600", marginRight: "8px"}}><Translate>Назва</Translate></Form.Label>
            <Form.Control value={pkg.title} type="text" id={"inputTitle"} style={{color: color}}
            onChange={(e) => { set('title', e.target.value); }}/>
        </div>
        <div style={{display: "flex", alignItems: "center"}}>
            <Form.Label style={{marginRight: "8px"}}><Translate>Актив</Translate></Form.Label>
            <Switch checked={pkg.active === 1} onChange={() => { set('active', pkg.active === 0 ? 1 : 0); }}
            onColor="#86d3ff" onHandleColor="#2693e6" handleDiameter={22}
            uncheckedIcon={false} checkedIcon={false} disabled={false} boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)" height={14} width={36} 
            className="react-switch" id="material-switch"/>
            <InfoButton text="Активуйте пакет, якщо студент може ним скористуватися. Не активовуйте пакет, якщо він тимчасово недоступний."/>
        </div>
        <div className="mb-3">
            <Form.Check inline label={<><Translate>Статичний курс</Translate><InfoButton text="Студент отримує доступ до усіх матеріалів одночасно та опрацьовує матеріали самостійно."/></>}
                name={"group" + pkg.id} type="radio" id="static" checked={pkg.plan === 0}
                onChange={() => { set('plan', 0); }}/>
            <Form.Check inline label={<><Translate>Групові заняття з доступом до чату</Translate><InfoButton text='Студент працює в групі з чатом. Отримує матеріали за певним розкладом (розклад ви зазначите в секції "Матеріали")'/></>}
                name={"group" + pkg.id} type="radio" id="dynamic" checked={pkg.plan === 1}
                onChange={() => { set('plan', 1); }}/>
        </div>
        <div style={{display: "flex", alignItems: "baseline"}}>
            <Form.Label style={{marginTop: "8px", marginRight: "8px"}}><Translate>Ціна</Translate></Form.Label>
            <NumericInput value={pkg.price} onChange={(e) => { set('price', e); }} maxWidth={164}/>
        </div>
        <div style={{display: "flex", alignItems: "baseline"}}>
            <Form.Label style={{marginTop: "8px", marginRight: "8px"}}><Translate>Термін дії (днів)</Translate></Form.Label>
            <NumericInput value={pkg.daysToExpire === 365000 ? 0 : pkg.daysToExpire}
                onChange={(e) => { set('daysToExpire', e); }} maxWidth={84} precision={0} step={1}/>
            <p style={{margin: "0px 0px 0px 8px", fontSize: "small"}}><Translate>Впишіть нуль, якщо матеріали будуть доступними назавжди</Translate></p>
        </div>
        <div style={{display: "flex", alignItems: "baseline"}}>
            <Form.Label style={{marginRight: "8px"}}><Translate>Послуги для пакетів</Translate></Form.Label>
            <OverlayTrigger rootClose trigger="click" placement="bottom" overlay={optionChooser}>
                <Button variant="link"><MdOutlineAddBox style={{marginTop: "-2px"}}/></Button>
            </OverlayTrigger>
            <InfoButton text="Якщо пакет включає перевірку викладачем фінальної роботи, оберіть зі списку: Перевірка роботи тренером (зворотній зв'язок) та диплом. Після вашої перевірки та зарахування, студент автоматично отримає диплом. Якщо пакет не передбачає перевірки фінальної роботи, студент автоматично отримає сертифікат слухача курсу."/>
        </div>
        {pkg.options.split('').map((letter, index) => (
            <div key={index} style={{display: "flex", alignItems: "baseline"}}>
                <p style={{marginBottom: "4px"}}><PiCaretDoubleRightFill color="cornflowerblue"/> {getOpt(letter)}</p>
                <Button variant="link" onClick={() => { delOption(letter) }}><FiMinusSquare style={{marginTop: "-2px"}}/></Button>
            </div>
        ))}
        <div style={{textAlign: "center", marginTop: "16px", marginBottom: "8px"}}>
            <Button size="sm" disabled={!basicChanges && !hardChanges} variant="outline-primary" onClick={save}>
                <Translate>Зберегти зміни</Translate></Button>
        </div>
    </div>
}

export default CabEditPackage