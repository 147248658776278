import { useSearchParams } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import { AppContext } from "../../AppContext/AppContext";
import { postAPICall } from "../../Components/APICall";
import { Navigate } from "react-router-dom";
import Home from "../../Home/Home";
import BeatLoader  from "react-spinners/BeatLoader";
import ModalAlert from "../../Components/ModalAlert";
import { translate } from "../../AppContext/Translate";

const mode_checking = 0;
const mode_validated = 1;
const mode_error = 2;
const mode_exit = 3;

function ValidateModal({state, handleClose}) {
    var {dicLang} = useContext(AppContext);
    return state === mode_checking ?
        <div style={{position: "fixed", width: "100vw", height: "100vh", zIndex: "99", backgroundColor: "#0000008c",
            display: "flex", alignItems: "center", justifyContent: "center"}}>
            <BeatLoader size={48} color="white" />
        </div> :
        (state === mode_error ?
            <ModalAlert type="modal-danger" show={true} handleClose={handleClose} title={translate("Помилка підтвердження електронної адреси", dicLang)}
                msg={translate("Під час перевірки електронної адреси сталася помилка", dicLang) + ". " +
                    translate("Якщо проблема не зникає, зверніться до нашої служби підтримки", dicLang) + "."}
                    buttonCaption={translate("Прийняти", dicLang)} />
            : <ModalAlert type="modal-success" show={true} handleClose={handleClose} title={translate("Електронну адресу успішно підтверджено", dicLang)}
                msg={translate("Дякуємо за підтвердження електронної адреси", dicLang) + ". " + translate("Тепер ви можете придбати будь-який з наших курсів", dicLang) + "."}
                buttonCaption={translate("Прийняти", dicLang)} />
        )
}

function ValidateEmail() {
    const [searchParams, ] = useSearchParams();
    const [state, setState] = useState(mode_checking);
    const [loginData, setLoginData] = useState(null);
    var { login } = useContext(AppContext);

    const handleClose = () => {
        if (loginData != null) {
            login(loginData["id"], loginData["name"], loginData["token"]);
        }
        setState(mode_exit);
    }

    useEffect(() => {    
        const validateError = (data) => {
            setState(mode_error);
        }
        const validateSuccess = (data) => {
            const error = data['error'];
            if (error === "none") {
                setLoginData(data);
                setState(mode_validated);
            } else {
                validateError(data);
            }
        }
        postAPICall('user/validateEmail', {token: searchParams.get("t")}, validateSuccess, validateError);    
    }, [searchParams]);

    return state === mode_exit ? <Navigate to="/" replace={true} />
    : <>
        <ValidateModal state={state} handleClose={handleClose} />
        <Home />
    </>
}

export default ValidateEmail