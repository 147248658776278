import Translate from "../../AppContext/Translate"
import PostMessage from "../../Components/PostMessage"

function AboutMe({text}) {
    return text && text.length > 0 && <div className="bot32">
        <p className="fmak bold large bot8" style={{borderBottom: "solid black", padding: "0px 24px 2px 0px",
            display: "inline-block"}}><Translate>Про мене</Translate></p>
        <PostMessage msg={text} open={false} textStyle="smallTextPost" sep={false}/>
    </div>
}

export default AboutMe