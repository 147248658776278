import { useEffect, useContext, useState } from "react"
import { getAPICall, postAPICall } from "../../Components/APICall";
import { AppContext } from "../../AppContext/AppContext";
import { isValid } from "iban";
import Form from 'react-bootstrap/Form';
import Translate, { formatDate, translate } from "../../AppContext/Translate";
import Button from 'react-bootstrap/Button';

function PaymentStatus({month, year, total}) {
    const { userId, token, userName, dicLang } = useContext(AppContext);
    const [payData, setPayData] = useState(null); 
    const [iban, setIban] = useState('');
    const [holder, setHolder] = useState(userName);
    const [show, setShow] = useState(false);

    useEffect(() => {
        getAPICall('check/today', {}, (data) => {
            if (data && 'today' in data) {
                const currentDay = new Date(data.today);
                const currentMonth = currentDay.getMonth() + 1;
                const currentYear = currentDay.getFullYear();
                if (year < currentYear || (year === currentYear && month < currentMonth)) {
                    setShow(total > 0);
                    getAPICall('payment/check', {userId: userId, token: encodeURIComponent(token),
                        month: month, year: year}, (data) => {
                        if (data) {
                            if (data.length === 0) {
                                getAPICall('payment/lastRequest', {userId: userId, token: encodeURIComponent(token)},
                                    (r) => {
                                    if (r && r.length > 0) {
                                        setIban(r[0].iban);
                                        setHolder(r[0].holder);
                                    }
                                });
                            } else {
                                setPayData(data[0]);
                                setIban(data[0].iban);
                                setHolder(data[0].holder);
                            }
                        }
                    });
                } else setShow(false);
            }
        });
    }, [userId, token, month, year, total]);

    const requestError = (error = "не вдалося зберегти зміни") => {
        alert(translate("Помилка", dicLang) + ": " + translate(error, dicLang));
    }

    const requestPayment = () => {
        postAPICall('payment/request', {userId: userId, token: token, month: month, year: year,
            total: total, iban: iban, holder: holder}, (data) => {
            if (data && 'error' in data) {
                if (data.error === 'none') {
                    getAPICall('payment/check', {userId: userId, token: encodeURIComponent(token),
                        month: month, year: year}, (data) => {
                        if (data && data.length > 0) {
                            setPayData(data[0]);
                        } else requestError();
                    });
                } else requestError(data.error);
            } else requestError();
        }, requestError);
    }

    const validIban = isValid(iban);

    return show ? (payData ? <div className="bold" style={{color: "steelblue"}}>
        {payData.paymentDate ? <><Translate>Оплачено</Translate>{' '}{formatDate(payData.paymentDate)}.</>  
        : <><Translate>Запит на оплату подано</Translate>{' '}{formatDate(payData.paymentRequest)}.</>}
    </div> 
    : <div style={{display: "flex", justifyContent: "center"}}>
        <div className="creditCardBase">
            <div className="creditCardInnerWrap">
                <Form.Group className="mb-3" controlId="formIban">
                    <Form.Label><Translate>IBAN</Translate></Form.Label>
                    <Form.Control name="iban" type="text" placeholder="" maxLength={29} 
                        value={iban} onChange={e => setIban(e.target.value)}/>
                    {!validIban && <Form.Text style={{color: "lightcoral"}}> 
                        <Translate>IBAN неправильний</Translate>.
                    </Form.Text>}
                </Form.Group>
                <Form.Group className="mb-3" controlId="formHolder">
                    <Form.Label><Translate>Бенефіціар</Translate></Form.Label>
                    <Form.Control name="holder" type="text" placeholder="" maxLength={42} 
                        value={holder} onChange={e => setHolder(e.target.value)}/>
                </Form.Group>
                <div style={{display: "flex", justifyContent: "center", marginTop: "28px"}}>
                    <Button variant="light" disabled={!validIban || holder.length < 3 || !holder.includes(' ')}
                        onClick={requestPayment}>
                        <Translate>Запит на оплату</Translate>
                    </Button>
                </div>
            </div>
        </div>
    </div>) : null
}

export default PaymentStatus