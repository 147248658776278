import { MediaPath } from "../../Components/MediaPath";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PostMessage from "../../Components/PostMessage";
import TaskParticipants from "./TaskParticipants";

function Task({task, courseId, groupId, packageId, markTaskAsSent, options}) {
    var path = new MediaPath(MediaPath.kind_privateCoursePost);
    path.add(courseId);
    path.add(task.topic);
    path.add(task.post);

    return <><Container fluid style={{marginTop: "8px"}}>
        <Row>
            <Col>
                <div className="chatPost" style={{maxWidth: "620px", margin: "16px auto"}}>
                    <p className="fkenyan xlarge">{task.taskName}</p>
                    <PostMessage msg={task.message} path={path} open={false} sep={false}/>
                </div>
            </Col>
        </Row>
        </Container>
        <TaskParticipants task={task} postId={task.post} packageId={packageId} groupId={groupId}
            markTaskAsSent={markTaskAsSent} options={options}/>
    </>
}

export default Task