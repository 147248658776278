import Modal from 'react-bootstrap/Modal';
import { useContext, useState, useEffect, lazy, Suspense } from 'react';
import { AppContext } from '../AppContext/AppContext';
import { getAPIurl } from './APICall';
import { BsCloudDownload } from 'react-icons/bs';
import { getFileTypeByName } from './MimeIcon';
import { getAPICall } from './APICall';
import BeatLoader from 'react-spinners/BeatLoader';
import RingLoader from 'react-spinners/RingLoader';
import { BiErrorCircle } from 'react-icons/bi';
import "react-h5-audio-player/lib/styles.css";

const AudioPlayer = lazy(() => import('react-h5-audio-player'));

function DocPreview({name, setPreview, path}) {
    const { userId, token } = useContext(AppContext);
    const [fitToWindow, setFitToWindow] = useState(true);
    const [element, setElement] = useState(null);
    const [loading, setLoading] = useState(true);
    const [downloading, setDownloading] = useState(false);

    function downloadFile(url, name) {
        setDownloading(true);
        fetch(url)
            .then(response => {
                response.blob().then(blob => {
                    let bloburl = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = bloburl;
                    a.download = name;
                    a.click();
                    setDownloading(false);
                });
            });
    }
    
    const changeBodyStyle = () => {
        setFitToWindow(!fitToWindow);
    }

    const sty = {backgroundColor: "black", color: "burlywood"};
    const bodySty = fitToWindow ? {backgroundColor: "black", display: "flex", justifyContent: "center"} :
        {backgroundColor: "black", textAlign: "center"};
    const url = getAPIurl('media/img', {name: encodeURIComponent(name), path: encodeURIComponent(path.jsonPath),
        userId: userId, token: encodeURIComponent(token)});
    const fileKind = getFileTypeByName(name);
    
    const setErrorElement = () => {
        setElement(<div style={{textAlign: "center", color: "lightslategray"}}><p><BiErrorCircle size={46}/></p>
            <p>No se puede mostrar una vista previa del fichero</p></div>);
        setLoading(false);
    }

    useEffect(() => {
        const onOfficeURLsuccess = (data) => {
            setElement(<iframe title="preview" id="office" width="100%" height="100%" frameBorder="0"
                src={"https://view.officeapps.live.com/op/embed.aspx?src=" + encodeURIComponent(data["url"])}
                allowFullScreen="allowfullscreen"/>);
            setLoading(false);
        }
        const onAudioURLsuccess = (data) => {
            const Loading = () => <BeatLoader size={24} color="#0d6efd" />;
            setElement(<Suspense fallback={<Loading />}>
                <AudioPlayer style={{borderRadius: "1rem", width: "420px", maxWith: "100%", height: "200px"}} 
                autoPlay src={data["url"]} showSkipControls={false} showJumpControls={false}
                header={'Reproduciendo: ' + name}/>
                </Suspense>);
            setLoading(false);
        }
        const onVideoURLsuccess = (data) => {
            setElement(<video width="100%" height="auto" controls style={{maxHeight: "100%"}} autoPlay>
                <source src={data["url"]} type={"video/" + name.split(".").pop()}/></video>);
            setLoading(false);
        }
        const onURLerror = (data) => {
            setErrorElement();
        }
        switch (fileKind.kind) {
            case 1: { // Image
                    const cursorSty = fitToWindow ? "zoom-in" : "zoom-out";
                    setElement(<img alt={name} src={url} onClick={changeBodyStyle} style={{cursor: cursorSty}}/>);
                    setLoading(false);
                }
                break;
            case 2:
                getAPICall('media/getUrl', {userId: userId, token: encodeURIComponent(token), name: name,
                    path: encodeURIComponent(path.jsonPath)}, onVideoURLsuccess, onURLerror);                
                break;
            case 3: // TXT
                setElement(<iframe title="preview" id="txt" width="100%" height="100%" frameBorder="0"
                    src={url} allowFullScreen="allowfullscreen" style={{backgroundColor: "cornsilk"}}/>);
                setLoading(false);
                break;
            case 4: // PDF
                setElement(<object data={url} type="application/pdf" width="100%" height="100%">
                    <p>No se puede visualizar el PDF en tu navegador.</p>
                    </object>);
                setLoading(false);
                break;
            case 5: // Powerpoint
            case 6: // Word
            case 7: // Excel
                getAPICall('media/getUrl', {userId: userId, token: encodeURIComponent(token), name: name,
                    path: encodeURIComponent(path.jsonPath)}, onOfficeURLsuccess, onURLerror);                
                break;
            case 8: // audio
                getAPICall('media/getUrl', {userId: userId, token: encodeURIComponent(token), name: name,
                    path: encodeURIComponent(path.jsonPath)}, onAudioURLsuccess, onURLerror);
                break;
            default:
                setErrorElement();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fitToWindow]);

    return <Modal show={true} fullscreen={true} onHide={() => setPreview(false)}>
        <Modal.Header closeButton closeVariant="white" style={sty}>
          <Modal.Title>
            <div style={{display: "flex", alignItems: "center"}}>
            {downloading ? <RingLoader color="burlywood" size={32}/> :
                <BsCloudDownload style={{cursor: "pointer"}} onClick={() => {downloadFile(url, name)}}/>}
            <span style={{marginLeft: "8px"}}>{name}</span>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={bodySty}>
            {loading ? <BeatLoader color="white" size={36}/> : element}
        </Modal.Body>
    </Modal>
}

export default DocPreview