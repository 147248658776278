import { useEffect, useState } from "react";
import { getAPICall } from "../../Components/APICall";
import Translate from "../../AppContext/Translate";
import BeatLoader from "react-spinners/BeatLoader";
import HorizontalScroll from "../../Components/HorizontalScroll";
import FollowedUser from "./FollowedUser";

function Following({userId, token}) {
    const [users, setUsers] = useState(null);
    
    useEffect(() => {
        getAPICall('user/followedUsers', {userId: userId, token: encodeURIComponent(token)}, (data) => {
            if (data) setUsers(data);
        });
    }, [userId, token]);

    return <div style={{padding: "0px 12px"}}>
        <div style={{backgroundColor: "#d9f5ed", borderRadius: "4px"}} className="pad12">
            <p className="fkenyan xlarge">
                <img alt="Збережені профілі" src="/icons/follow.png" height="30px" className="right6"/>
                <Translate>Збережені профілі</Translate>
            </p>
            {users === null ? <div className="bot16 top18">
                <BeatLoader/> 
            </div> : (users.length === 0 ? <p className="bot16 top8">
                <Translate>Ви ще не зберегли жодного профілю</Translate>.
            </p> : <div style={{paddingTop: "16px"}}>
                <HorizontalScroll itemSize="260">
                {users.map((u, i) => {
                    return <FollowedUser key={i} name={u.name} user={u.followedUser}
                        userId={userId} token={token}/>
                })}
                </HorizontalScroll>
            </div>)}
        </div>
    </div>
}

export default Following