import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../AppContext/AppContext";
import { getAPICall } from "../../Components/APICall";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import CabSubtitle from "../CabSubtitle";
import CabPaymentRequest from "./CabPaymentRequest";

function AdminPayments() {
    const { userId, token } = useContext(AppContext);
    const [paymentReq, setPaymentReq] = useState([]);

    useEffect(() => {
        getAPICall('payment/requests', {userId: userId, token: encodeURIComponent(token)}, (data) => {
            if (data && data.length > 0) {
                setPaymentReq(data);
            }
        });
    }, [userId, token]);

    return paymentReq.length > 0 ? <div>
    <CabSubtitle title="Платежі" icon={<FaMoneyBillTransfer/>}/>
    {paymentReq.map((req, i) => {
        return <CabPaymentRequest key={i} payment={req}/>
    })}
    </div> : null
}

export default AdminPayments