import { useState, useContext, useEffect, lazy, Suspense } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Translate, { translate, getLanguagesData } from '../../AppContext/Translate';
import { PiPresentationChartDuotone } from "react-icons/pi";
import { HiPhotograph } from 'react-icons/hi';
import Button from "react-bootstrap/Button";
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import { AppContext } from '../../AppContext/AppContext';
import { MediaPath } from '../../Components/MediaPath';
import WriteHelper from '../../Components/WriteHelper';
import RTEditor from '../../Components/RTEditor';
import { BiUpArrow, BiDownArrow, BiTrash } from 'react-icons/bi';
import DropdownImageSelect from '../../Components/DropdownImageSelect';
import { HiOutlineLightBulb } from "react-icons/hi";
import rtEditorUploader from '../../Components/RTEditorUploader';
import { postAPICall } from '../../Components/APICall';
import UploadProgress from '../../Components/UploadProgress';

const UploadPicture = lazy(() => import('../../Components/UploadPicture'));

function CabEditCourseInfo({get, set, courseData, categories}) {
    const { userId, token, dicLang } = useContext(AppContext);
    const [picNumber, setPicNumber] = useState(1);
    const [showChangePicture, setShowChangePicture] = useState(false);
    const [description, setDescription] = useState({text: '', files: []});
    const [goal, setGoal] = useState({text: '', files: []});
    const [audience, setAudience] = useState({text: '', files: []});
    const [requirements, setRequirements] = useState({text: '', files: []});
    const [ideas, setIdeas] = useState([]);
    const [sloganImage, setSloganImage] = useState(-1);
    const [slogan, setSlogan] = useState('');
    const [diplomaTitles, setDiplomaTitles] = useState({});
    const [changes, setChanges] = useState(false);
    const [progress, setProgress] = useState(null);

    useEffect(() => {
        async function setFields(id, desc) {
            if (desc) {
                var path = new MediaPath(MediaPath.kind_openMediaCourse);
                path.add(id);
                path.addStr('desc');
                setDescription(await WriteHelper.parseMessage(desc, path, userId, token));
            }
        }
        setFields(courseData.id, courseData.description);
    }, [userId, token, courseData.id, courseData.description]);

    useEffect(() => {
        async function setFields(id, desc) {
            if (desc) {
                var path = new MediaPath(MediaPath.kind_openMediaCourse);
                path.add(id);
                path.addStr('goal');
                setGoal(await WriteHelper.parseMessage(desc, path, userId, token));
            }
        }
        setFields(courseData.id, courseData.goals);
    }, [userId, token, courseData.id, courseData.goals]);

    useEffect(() => {
        async function setFields(id, desc) {
            if (desc) {
                var path = new MediaPath(MediaPath.kind_openMediaCourse);
                path.add(id);
                path.addStr('aud');
                setAudience(await WriteHelper.parseMessage(desc, path, userId, token));
            }
        }
        setFields(courseData.id, courseData.audience);
    }, [userId, token, courseData.id, courseData.audience]);

    useEffect(() => {
        async function setFields(id, desc) {
            if (desc) {
                var path = new MediaPath(MediaPath.kind_openMediaCourse);
                path.add(id);
                path.addStr('req');
                setRequirements(await WriteHelper.parseMessage(desc, path, userId, token));
            }
        }
        setFields(courseData.id, courseData.requirements);
    }, [userId, token, courseData.id, courseData.requirements]);

    useEffect(() => {
        var videas = [];
        var tokens = courseData.ideas.split('|');
        for (var i = 0; i < tokens.length - 1; i += 2) {
            videas.push({icon: tokens[i], idea: tokens[i+1]});
        }
        setIdeas(videas);
    }, [userId, token, courseData.id, courseData.ideas]);

    useEffect(() => {
        const tokens = courseData.mission.split('|');
        if (tokens.length < 2) {
            setSloganImage(-1);
            setSlogan('');
        } else {
            setSloganImage(parseInt(tokens[0]));
            setSlogan(tokens[1]);
        }
    }, [userId, token, courseData.id, courseData.mission]);

    useEffect(() => {
        var newTitles = {};
        const titles = courseData.diplomaTitles.split("|");
        for (var i = 0; i < titles.length; i++) {
            if (titles[i].length > 3)
                newTitles[titles[i].substring(0, 2)] = titles[i].substring(3);
        }
        setDiplomaTitles(newTitles);
    }, [userId, token, courseData.id, courseData.diplomaTitles]);

    const getCategoryName = (id) => {
        if (categories) {
            for (var i = 0; i < categories.length; i++) {
                if (categories[i].link === id) {
                    return <>
                        <img alt="" src={'/categories/cat' + id + '.png'} width="24" height="24" />
                        {' ' + categories[i].title}</>;
                }
            }
        }
        return '';
    }

    const updateIdeaIcon = (idIcon, idIdea) => {
        var newIcon = idIcon >= 0 && idIcon < ideasImages.length ? idIcon + 1 : -1;  
        const newIdeas = ideas.map((idea, i) => {
            if (i === idIdea) return {icon: newIcon, idea: idea.idea};
            else return idea;
        });
        setIdeas(newIdeas);
        set(null, null, null);
        setChanges(true);
    }

    const updateIdea = (event, idIdea) => {
        const newIdeas = ideas.map((idea, i) => {
            if (i === idIdea) return {icon: idea.icon, idea: event.target.value};
            else return idea;
        });
        setIdeas(newIdeas);
        set(null, null, null);
        setChanges(true);
    }

    const moveUpIdea = (idIdea) => {
        const newIdeas = ideas.map((idea, i) => {
            if (i === idIdea - 1) return ideas[idIdea];
            else if (i === idIdea) return ideas[idIdea - 1];
            else return idea;
        });
        setIdeas(newIdeas);
        set(null, null, null);
        setChanges(true);
    }

    const addIdea = () => {
        setIdeas([...ideas, {icon: -1, idea: ''}]);
        set(null, null, null);
        setChanges(true);
    }

    const deleteIdea = (i) => {
        var copy = [...ideas];
        copy.splice(i, 1);
        setIdeas(copy);
        set(null, null, null);
        setChanges(true);
    }

    const updateSloganImage = (id) => {
        if (id >= 0 && id < bkgImages.length) setSloganImage(bkgImages[id].id);
        else setSloganImage(-1);
        set(null, null, null);
        setChanges(true);
    }

    const updateBulletImage = (id) => {
        if (id >= 0 && id < bulletImages.length) set(courseData.id, 'content', bulletImages[id].id);
        else set(courseData.id, 'content', -1);
        setChanges(true);
    }

    const getDiplomaTitle = (language) => {
        if (language in diplomaTitles) return diplomaTitles[language];
        return "";
    }

    const updateDiplomaTitle = (title, language) => {
        const newTitles = {...diplomaTitles};
        newTitles[language] = title;
        setDiplomaTitles(newTitles);
        set(null, null, null);
        setChanges(true);
    }

    const numIdeas = 28;
    var ideasImages = [];
    for (var i = 1; i <= numIdeas; i++) ideasImages.push({id: i, name: "/icons/course" + i + ".png"});
    const numBackgrounds = 20;
    var bkgImages = [];
    for (i = 1; i <= numBackgrounds; i++) bkgImages.push({id: i, name: "/bkg/coursebkg" + i + ".png"});
    const numBullets = 17;
    var bulletImages = [];
    for (i = 1; i <= numBullets; i++) bulletImages.push({id: i, name: "/bkg/bullet" + i + ".png"});
    
    const saveError = () => {
        setProgress(null);
        alert(translate("Помилка", dicLang) + ": " + translate("не вдалося зберегти зміни", dicLang));
    }

    const saveRequirements = (course) => {
        var path = new MediaPath(MediaPath.kind_openMediaCourse);
        path.add(course);
        path.addStr('req');
        rtEditorUploader([requirements], [path.jsonPath], setProgress, userId, token,
            [{apifnc: 'course/requirements', params: {userId: userId, token: token, course: course}}],
            (data) => {
                setProgress(null);
                setChanges(false);
                set(null, null, null, true, false);
                alert(translate("Зміни успішно збережено", dicLang));
            }, saveError);
    }

    const saveAudience = (course) => {
        var path = new MediaPath(MediaPath.kind_openMediaCourse);
        path.add(course);
        path.addStr('aud');
        rtEditorUploader([audience], [path.jsonPath], setProgress, userId, token,
            [{apifnc: 'course/audience', params: {userId: userId, token: token, course: course}}],
            (data) => {
                set(course, 'audience', WriteHelper.encodeWithFiles(audience));
                saveRequirements(course);
            }, saveError);
    }

    const saveGoal = (course) => {
        var path = new MediaPath(MediaPath.kind_openMediaCourse);
        path.add(course);
        path.addStr('goal');
        rtEditorUploader([goal], [path.jsonPath], setProgress, userId, token,
            [{apifnc: 'course/goal', params: {userId: userId, token: token, course: course}}],
            (data) => {
                set(course, 'goals', WriteHelper.encodeWithFiles(goal));
                saveAudience(course);
            }, saveError);
    }

    const saveDescription = (course) => {
        var path = new MediaPath(MediaPath.kind_openMediaCourse);
        path.add(course);
        path.addStr('desc');
        rtEditorUploader([description], [path.jsonPath], setProgress, userId, token,
            [{apifnc: 'course/description', params: {userId: userId, token: token, course: course}}],
            (data) => {
                set(course, 'description', WriteHelper.encodeWithFiles(description));
                saveGoal(course);
            }, saveError);
    }

    const save = () => {
        var textIdeas = '';
        for (var i = 0; i < ideas.length; i++) {
            if ('idea' in ideas[i] && ideas[i].idea) {
                if (i > 0) textIdeas += '|';
                textIdeas += ideas[i].icon + '|' + WriteHelper.filterSimpleText(ideas[i].idea);
            }
        }
        var titles = '';
        ['en', 'ua'].forEach((language) => { 
            if (titles.length > 0) titles += '|';
            titles += language + '-' + WriteHelper.filterSimpleText(getDiplomaTitle(language)); });
        setProgress({file: translate('Дані основного курсу', dicLang), percent: 0, action: 'upd'});
        postAPICall('course/info', {userId: userId, token: token, course: courseData.id,
            title: WriteHelper.filterSimpleText(courseData.title), lang: courseData.lang,
            category: courseData.category, ideas: textIdeas, content: courseData.content,
            mission: sloganImage.toString() + '|' + WriteHelper.filterSimpleText(slogan),
            diplomaTitles: titles, shortDescription: WriteHelper.filterSimpleText(courseData.shortDescription)},
            (data) => {
                if (data && "error" in data && data.error === "none") saveDescription(courseData.id);
                else saveError();
            }, saveError);
    }

    return <Accordion.Item eventKey="1">
        <Accordion.Header>
            <PiPresentationChartDuotone size="24" style={{margin: "1px 8px 0px 0px"}}/>
            <span style={{fontSize: "larger", fontWeight: "600"}}><Translate>Інформація про курс</Translate></span>
        </Accordion.Header>
        <Accordion.Body>
            <Form.Label><Translate>Назва курсу</Translate></Form.Label>
            <Form.Control value={get('title', '')} type="text" maxLength={96} 
                onChange={(e) => { set(courseData.id, 'title', e.target.value); setChanges(true); }}/>
            <div style={{textAlign: "center"}}>
                <figure>
                    <img alt="" src={"/courses/cover" + get('id') + ".jpg?n=" + picNumber} width="348px" 
                        style={{borderStyle: "ridge", maxWidth: "100%"}}
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = "/courses/nocover.jpg";
                        }} />
                </figure>
                <Button variant="secondary" size="sm" onClick={()=>{ setShowChangePicture(true); }}>
                    <HiPhotograph size="18" style={{marginRight: "4px", marginTop: "-2px"}}/>
                    <Translate>Змінити покриття курсу</Translate>
                </Button>
                <Suspense>
                    <UploadPicture show={showChangePicture} handleClose={()=>{ setShowChangePicture(false); }}
                        width={348} height={196} title="Фото на обкладинці" apiFnc="course/photo"
                        forceUpdate={() => {
                            setPicNumber(current => current + 1);
                        }} params={{course: get('id')}}/>
                </Suspense>
            </div>
            <hr/>
            <div style={{display: "flex", alignItems: "baseline", marginTop: "16px"}}>
                <span style={{marginRight: "8px"}}><Translate>Мова</Translate></span>
                <Dropdown>
                    <Dropdown.Toggle variant="outline-dark" id="dropdown-basic" style={{maxWidth: "100%"}}>
                        {get('lang', 'ua')}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {getLanguagesData().map((m, i) => { 
                            return <Dropdown.Item key={i} onClick={() => { 
                                set(courseData.id, 'lang', m.id);
                                setChanges(true); }}>{m.name}</Dropdown.Item>})}
                    </Dropdown.Menu>
                </Dropdown>
                <img alt="" src={"/lang/" + get('lang', 'ua') + ".png"} width={24} style={{marginLeft: "8px"}}/>
            </div>
            <div style={{display: "flex", alignItems: "baseline", marginTop: "6px", marginBottom: "8px"}}>
                <Form.Label style={{marginRight: "8px"}}><Translate>Категорія</Translate></Form.Label>
                <Dropdown onSelect={(eventkey, event) => { 
                    set(courseData.id, 'category', parseInt(event.target.id));
                    setChanges(true); }}>
                    <Dropdown.Toggle variant="outline-dark" id="dropdown-category">
                        {getCategoryName(courseData.category)}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {categories && categories.map((cat, i) => {
                        return <Dropdown.Item key={i} id={cat.link}>{cat.title}</Dropdown.Item>
                        })}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <hr/>
            <Form.Label><Translate>Опис</Translate></Form.Label>
            <RTEditor item={description} onChange={(data) => { setDescription(data); set(null, null, null); setChanges(true); }}/>
            <Form.Label className='top8'><Translate>Короткий опис</Translate></Form.Label>
            <Form.Control value={courseData.shortDescription ? courseData.shortDescription : ""}
                type="text" as="textarea" maxLength={255} id="inputSdesc" rows="3"
                onChange={(e) => { 
                set(courseData.id, 'shortDescription', e.target.value);
                setChanges(true); }} />
            <Form.Label><Translate>Мета курсу</Translate></Form.Label>
            <RTEditor item={goal} onChange={(data) => { setGoal(data); set(null, null, null); setChanges(true); }}/>
            <Form.Label className='top8'><Translate>Цільова аудиторія (для кого)</Translate></Form.Label>
            <RTEditor item={audience} onChange={(data) => { setAudience(data); set(null, null, null); setChanges(true); }}/>
            <Form.Label className='top8'><Translate>Вимоги</Translate></Form.Label>
            <RTEditor item={requirements} onChange={(data) => { setRequirements(data); set(null, null, null); setChanges(true); }}/>
            <hr/>
            <Form.Label><Translate>Ключові ідеї</Translate></Form.Label>
            <div style={{marginBottom: "12px"}}>
                {ideas.map((idea, i) => {
                    return <div key={i}>
                        <DropdownImageSelect images={ideasImages} onChange={(id) => { updateIdeaIcon(id, i); }}/>
                        <div style={{display: "flex", alignItems: "flex-start", marginTop: "4px"}}>
                            <img alt='' src={idea.icon > 0 ? ideasImages[idea.icon-1].name : "/courses/nocover.jpg"}
                                style={{height: "40px", marginRight: "16px"}}/>
                            <Form.Control value={idea.idea ? idea.idea : ''} type="text" id={"inputIdea" + i}
                                onChange={(e) => { updateIdea(e, i); }} />
                            <Button variant="light" size="sm" disabled={i === 0} style={{marginTop: "2px"}}
                                onClick={() => {moveUpIdea(i);}}><BiUpArrow/></Button>
                            <Button variant="light" size="sm" disabled={i === ideas.length - 1} style={{marginTop: "2px"}}
                                onClick={() => {moveUpIdea(i+1);}}><BiDownArrow/></Button>
                            <Button variant="light" size="sm" style={{marginTop: "2px"}} onClick={() => { deleteIdea(i); }}><BiTrash/></Button>
                        </div>
                    </div>
                })} 
                <Button variant="outline-primary" size="sm" onClick={addIdea}>
                    <HiOutlineLightBulb size="18" style={{margin: "-4px 6px 0px 0px"}}/>
                    <Translate>Додати</Translate></Button>
            </div>
            <hr/>
            <Form.Label><Translate>Слоган (головна ідея)</Translate></Form.Label>
            <div style={{display: "flex", alignItems: "center", marginBottom: "8px"}}>
                <DropdownImageSelect images={bkgImages} onChange={updateSloganImage}/>
                <img alt='' src={sloganImage > 0 ? bkgImages[sloganImage-1].name : "/courses/nocover.jpg"}
                    style={{width: "96px", marginLeft: "16px"}}/>
            </div>
            <Form.Control value={slogan} type="text" id="inputSlogan" onChange={(e) => { 
                setSlogan(e.target.value);
                set(null, null, null);
                setChanges(true); }} />
            <hr/>
            <Form.Label><Translate>Зображення для елементів індексу</Translate></Form.Label>                
            <div style={{display: "flex", alignItems: "center", marginBottom: "8px"}}>
                <DropdownImageSelect images={bulletImages} onChange={updateBulletImage}/>
                <img alt='' src={courseData.content > 0 ? bulletImages[courseData.content - 1].name :
                    "/courses/nocover.jpg"} style={{width: "64px", marginLeft: "16px"}}/>
            </div>
            <hr/>
            <Form.Label><Translate>Назва курсу для диплома</Translate></Form.Label>
            {['en', 'ua'].map((language, i) => {
                return <div key={i} style={{display: "flex", alignItems: "center", marginBottom: "4px"}}>
                    <img src={"/lang/" + language + ".png"} alt={language} style={{height: "24px", marginRight: "8px"}}/>
                    <Form.Control value={getDiplomaTitle(language)} type="text" id={"inputDiplom" + i}
                        style={{margin: "0px"}} onChange={(e) => { updateDiplomaTitle(e.target.value, language); }}/>
                </div>
            })}
            <div style={{textAlign: "center", marginTop: "16px"}}>
                <Button disabled={!changes} onClick={save}><Translate>Зберегти зміни</Translate></Button>
            </div>
            <UploadProgress progress={progress}/>
        </Accordion.Body>
    </Accordion.Item>
}

export default CabEditCourseInfo