import { useEffect, useContext, useState } from "react"
import { AppContext } from "../../AppContext/AppContext";
import { Navigate, useParams } from "react-router-dom";
import CabTitle from "../CabTitle";
import Menu from "../../Menu/Menu";
import Footer from "../../Components/Footer";
import { getAPICall, postAPICall } from "../../Components/APICall";
import BeatLoader from "react-spinners/BeatLoader";
import { translate } from "../../AppContext/Translate";
import CabEditCourse from "./CabEditCourse";
import { confirm } from "../../Components/ConfirmFunction";
import CabHomeButton from "../CabHomeButton";
import CabCourseSelection from "./CabCourseSelection";
import TeacherTerms from "../../Terms/TeacherTerms";
import Banner from "../../Components/Banner";

function CabCourses() {
    var courseParam = useParams()["id"];
    const { userId, token, lang, dicLang } = useContext(AppContext);
    const [school, setSchool] = useState(null);
    const [categories, setCategories] = useState([]);
    const [expanded, setExpanded] = useState(true);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [coursesData, setCoursesData] = useState(null);
    const [changes, setChanges] = useState(false);

    useEffect(() => {
        getAPICall('categories/list', {lang: lang}, (data) => {
            if (data && data.length > 0) setCategories(data);
        });
    }, [userId, token, lang]);

    const newCourseError = (data) => {
        alert(translate("Помилка", dicLang) + ": " + translate("не вдалося зберегти зміни", dicLang));
    }

    const newCourseCreated = (data) => {
        if (data && 'course' in data) {
            const courseId = data.course;
            setCoursesData(prevState => ({
                ...prevState,
                [courseId]: {id: courseId, lang: 'ua', description: '', goals: '', audience: '', requirements: '', 
                    ideas: '', mission: '', content: -1, diplomaTitles: ''}
              }));
            selectCourseConfirmed(courseId);
        } else newCourseError(data);
    }

    const selectCourseConfirmed = async (id) => {
        setChanges(false);
        if (id < 0) {   // New course
            if (await confirm({confirmation: translate('Ви впевнені, що хочете створити новий курс?', dicLang)})) {
                postAPICall('course/new', {userId: userId, token: token, school: school.id}, 
                    newCourseCreated, newCourseError);
            }    
        } else {        // Edit course
            setExpanded(false);
            setSelectedCourse(id);
        }
    }

    const selectCourse = async (id) => {
        if (selectCourse === null) selectCourseConfirmed(id);
        else {
            if (selectCourse.id !== id) {
                if (changes) {
                    if (await confirm({confirmation: translate('Є незбережені зміни', dicLang) + '.' +
                        translate('Ви впевнені, що бажаєте продовжити?', dicLang)})) {
                        selectCourseConfirmed(id);
                    }
                } else selectCourseConfirmed(id);
            }
        }
    }

    const set = (id, field, value, isAChange=true, changeValue=true) => {
        if (field) {
            setCoursesData(prevState => ({
                ...prevState,
                [id]: {
                ...prevState[id],
                [field]: value
                }
            }));
        }
        if (isAChange) setChanges(changeValue);
    }

    return userId < 0 ? <Navigate to="/" replace={true} /> :
    <div className='mainContainer' style={{backgroundColor: "white"}}>
        <Menu/>
        <Banner srcImg='/bkg/banners/courses.jpg' down={false}
            title={<img alt="" src="/icons/courses.png" width="148px" style={{maxWidth: "30vw"}}/>}/>
        <TeacherTerms/>
        <div style={{padding: "10px"}}>
            {school && <CabTitle title="Курси вашої школи"/>}
            {coursesData === null && <BeatLoader/>}
            <CabCourseSelection coursesData={coursesData} setCoursesData={setCoursesData} 
                allowNewCourses={school !== null}
                setSchool={setSchool}
                title={school ? "Виберіть курс для редагування або створіть новий" : "Виберіть курс для редагування"}
                expanded={expanded} setExpanded={setExpanded} selectCourse={selectCourse} courseParam={courseParam}/>
            {selectedCourse !== null && 
                <CabEditCourse courseData={coursesData[selectedCourse]} categories={categories} 
                    set={set}/>}
            <CabHomeButton/>
        </div>
        <Footer/>
    </div>
}

export default CabCourses