import Button from "react-bootstrap/Button"
import Footer from "../Components/Footer"
import Menu from "../Menu/Menu"
import { FaFilePdf } from "react-icons/fa6";
import { useWindowSize } from "../AppContext/WindowSize";
import { useEffect, useState } from "react";
import { PiArrowUpBold } from "react-icons/pi";
import { Link } from "react-router-dom";

function Cookies() {
    const [width,] = useWindowSize();
    const [showUp, setShowUp] = useState(false);

    const handleDownload = () => {
        const pdfUrl = '/docs/cookies.pdf';
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = 'cookies.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    useEffect(() => {
        const handleScroll = () => {
          if (window.scrollY > 200) {
            setShowUp(true);
          } else {
            setShowUp(false);
          }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return <div className='mainContainer'>
        <Menu/>
        <div className="terms-div">
            {width > 960 && <div className="terms-circle" style={{left: "calc(50% + 360px + 48px)",
                top: "84px"}} onClick={handleDownload}>
                <FaFilePdf size="24" style={{transform: "translateX(2px)"}}/>
            </div>}
            {width > 960 && <div className={showUp ? "terms-circle fadeIn2" : "terms-circle fadeOut2"}
                style={{left: "calc(50% - 360px - 120px)", bottom: "84px"}} onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                }}><PiArrowUpBold size="24"/>
            </div>}
            <h1 className="center">MAGISTRIKA</h1>
            <p className="center bold large">ПОЛІТИКА ВИКОРИСТАННЯ ФАЙЛІВ COOKIE</p>
            <div className="center top12 bot16 fkenyan">
                <Button variant="light" style={{color: "#595e69"}} onClick={handleDownload}>
                    <FaFilePdf style={{marginRight: "6px"}}/>PDF версія
                </Button>
            </div>
            <p className="pterms"><strong>Останнє оновлення: </strong>1 вересня 2024 р.</p>
            <p className="pterms">У Magistrika ми зобов'язуємося захищати конфіденційність наших користувачів і гарантувати, що будь-які зібрані персональні дані обробляються безпечно та відповідно до чинного законодавства. Ця Політика використання файлів cookie пояснює, як ми використовуємо файли cookie та подібні технології на нашій платформі.</p>
            <ol>
                <li><strong>Що таке файли cookie?</strong></li>
            </ol>
            <p className="pterms">Файли cookie &ndash; це невеликі текстові файли, які зберігаються на вашому пристрої (комп'ютері, планшеті або мобільному телефоні), коли ви відвідуєте веб-сайт. Файли cookie дозволяють веб-сайту запам'ятовувати ваші дії та налаштування (такі як логін, мова, розмір шрифту та інші параметри відображення) протягом певного періоду часу, тому вам не потрібно змінювати їх налаштування щоразу, коли ви повертаєтеся на сайт або переходите зі сторінки на сторінку.</p>
            <ol start="2">
                <li><strong>Використання технологій зберігання в Magistrika</strong></li>
            </ol>
            <p className="pterms">Замість файлів cookie Magistrika використовує localStorage API, технологію, яка дозволяє зберігати інформацію локально у вашому браузері. Ця інформація включає ваші особисті уподобання та інші налаштування, які покращують ваш досвід роботи на платформі.</p>
            <ol start="3">
                <li><strong>Що таке API localStorage?</strong></li>
            </ol>
            <p className="pterms"><code>localStorage</code> &ndash; це технологія веб-сховища, яка дозволяє зберігати дані у вашому браузері. На відміну від файлів cookie:</p>
            <ul>
                <li><strong>Не має терміну придатності за замовчуванням.</strong> Дані, що зберігаються в localStorage, залишаються у вашому браузері до тих пір, поки ви не видалите їх вручну або додаток не видалить їх.</li>
                <li><strong>Не надсилається на сервер.</strong> Інформація, що зберігається в localStorage , не надсилається автоматично на сервер з кожним HTTP-запитом, що підвищує ефективність та конфіденційність.</li>
            </ul>
            <ol start="4">
                <li><strong>Як ми використовуємо localStorage в Magistrika?</strong></li>
            </ol>
            <p className="pterms">У Magistrika ми використовуємо localStorage для:</p>
            <ul>
                <li><strong>Збережіть свої налаштування користувача,</strong> такі як вибір мови та інші користувацькі налаштування.</li>
                <li><strong>Залишайтеся в системі:</strong> Таким чином, вам не доведеться знову входити в систему щоразу, коли ви відвідуєте нашу платформу.</li>
            </ul>
            <p className="pterms">Ця інформація зберігається локально на вашому пристрої та не передається третім особам.</p>
            <ol start="5">
                <li><strong> Контроль локального сховища</strong></li>
            </ol>
            <p className="pterms">Ви маєте повний контроль над даними, що зберігаються в localStorage. Ви можете видалити їх у будь-який момент із налаштувань браузера. Однак, будь ласка, зверніть увагу, що видалення цих даних може призвести до втрати ваших збережених налаштувань, і вам може знадобитися встановити їх знову при наступному відвідуванні Magistrika.</p>
            <ol start="6">
                <li><strong>Сторонні файли cookie</strong></li>
            </ol>
            <p className="pterms">Magistrika не використовує сторонні файли cookie. Усі дані, які ми збираємо за допомогою файлів cookie, керуються всередині компанії та не передаються третім сторонам для маркетингових або рекламних цілей. Для зручності Magistrika дозволяє авторизуватися за допомогою облікового запису Google або Facebook. У цих випадках ці дві компанії можуть зберігати файли cookie, необхідні для надання цієї послуги. Для отримання додаткової інформації, будь ласка, зверніться до політики <a href="https://policies.google.com/technologies/cookies">Google</a> та <a href="https://www.facebook.com/privacy/policies/cookies">Facebook</a> щодо файлів cookie.</p>
            <ol start="7">
                <li><strong>Зміни в цій Політиці використання файлів cookie</strong></li>
            </ol>
            <p className="pterms">Magistrika може оновлювати цю Політику використання файлів cookie в будь-який час. Про будь-які зміни буде повідомлено через платформу, а оновлена версія буде доступна на нашому сайті з датою останнього оновлення.</p>
            <ol start="8">
                <li><strong>Контакти</strong></li>
            </ol>
            <p className="pterms">Якщо у вас виникли запитання чи сумніви щодо використання файлів cookie на нашій платформі, будь ласка, зв'яжіться з нами через support@magistrika.com або зверніться до нашої <Link to="/privacy">Політики конфіденційності</Link> для отримання додаткової інформації.</p>
            <hr/>
            <p className="pterms">Ця політика розроблена для забезпечення прозорості того, як і чому ми використовуємо файли cookie та подібні технології в Magistrika. Продовжуючи використовувати нашу платформу, ви погоджуєтеся на використання файлів cookie, як описано в цій Політиці.</p>
        </div>
        <Footer/>
    </div>
}

export default Cookies