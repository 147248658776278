import { useEffect, useState } from "react";
import { getAPICall } from "../Components/APICall";
import Translate from "../AppContext/Translate";
import BeatLoader from "react-spinners/BeatLoader";
import Item from "../Shop/Item";
import MagButton from "../Components/MagButton";
import Shop from "../Shop/Shop";
import HorizontalScroll from "../Components/HorizontalScroll";

function SectionShop() {
    const [topItems, setTopItems] = useState([]);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        getAPICall('shop/topItems', {limit: 6}, (data) => {
            if (data) setTopItems(data);
        });
    }, []);

    return <div style={{backgroundColor: "#d9f5ed", paddingTop: "48px", paddingBottom: "12px"}}>
        <img alt="" src="/bkg/decor/dec9.png" style={{position: "absolute", width: "6%", maxWidth: "64px",
            minWidth: "34px", top: "-20px", right: "0px"}}/>
        <h2 className="left24 huge"><Translate>магазин</Translate></h2>
        <p className="left24 fkenyan xlarge"><Translate>креативний шопінг</Translate></p>
        <div style={{padding: "28px"}}>
            {topItems.length === 0 ? <BeatLoader/> : 
            <HorizontalScroll itemSize={272}>
                {topItems.map((data, i) => {
                    return <Item key={i} data={data}/>
                })}
            </HorizontalScroll>}
            <MagButton text="більше товарів" icon={"/bkg/" + (open ? "up.png" : "down.png")}
                clickEff={{customFnc: () => { setOpen(!open); }}}/>
            {open && <>
                <div style={{height: "16px"}}/>
                <Shop showMenu={false} ignore={topItems} bkgColor="#d9f5ed"/>
            </>}
        </div>
    </div>
}

export default SectionShop