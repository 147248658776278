import { getAPICall } from "../../Components/APICall"
import { useEffect, useState, useContext } from "react"
import ActivityDate from "./ActivityDate";
import Translate from "../../AppContext/Translate";
import UserPhotoIcon from "../../Components/UserPhotoIcon";
import SyncLoader from "react-spinners/SyncLoader";
import { Link } from "react-router-dom";
import { AppContext } from "../../AppContext/AppContext";
import { chooseRowByLang } from "../../AppContext/Translate";
import Project from "../../Course/Projects/Project";

function ProjectReactionActivity({date, task, kind, user, userName, onPreview}) {
    var { lang } = useContext(AppContext);
    const [project, setProject] = useState(null);
    const [courseName, setCourseName] = useState(null);

    useEffect(() => {
        const onGetCourseName = (data) => {
            const row = chooseRowByLang(data, lang);
            if (row !== null)
                setCourseName(row.title);
        }
        const onProjectInfo = (data) => {
            if (data && data.length > 0) {
                setProject(data[0]);
                getAPICall('courses/name', {courseId: data[0].course}, onGetCourseName);
            }
        }
        getAPICall('tasks/projectinfo', {id: task}, onProjectInfo);
    }, [task, lang]);

    return <div>
        <div style={{display: "flex"}} className="bot8">
            <UserPhotoIcon user={user} userName={userName}/>
            <div style={{marginLeft: "8px"}}><p style={{margin: "0px", marginBottom: "6px"}}>{userName}
                <span style={{fontWeight: "600", marginLeft: "6px"}}><Translate>сподобався проект</Translate></span>
                {project ?  <span style={{marginLeft: "6px"}}>
                    <Link to={"/profile/" + project.user} className="userLink">{project.name}</Link></span> :
                    <span style={{marginLeft: "6px"}}><SyncLoader size="8px"/></span>}
                </p>
                <p style={{fontSize: "small", margin: "0px"}}><ActivityDate date={date}/></p>
            </div>
        </div>
        {project && <div style={{textAlign: "center"}}>
            {courseName && <Link to={"/course/" + project.course}>
                <p className="userLink" style={{fontWeight: "600", fontSize: "large"}}>{courseName}</p>
            </Link>}
            <div style={{display: "flex", justifyContent: "center"}}>
                <Project data={project} onPreview={onPreview} bkg="white"/>
            </div>
        </div>
        }
    </div>
}

export default ProjectReactionActivity