import { useState, useEffect, useContext } from "react";
import { getAPICall } from "../../Components/APICall";
import { AppContext } from "../../AppContext/AppContext";
import OrderState from "./OrderState";
import { Link } from "react-router-dom";

function OrderLine({line}) {
    const { userId, token } = useContext(AppContext);
    const [desc, setDesc] = useState(null);
    const kind = 'package' in line ? 'course' : 'item';
    const pic = kind === 'course' ? "/courses/cover" + line.course + '.jpg' : "/items/item" + line.item + ".jpg";
    const picError = kind === 'course' ? "/courses/nocover.jpg" : "/items/noitem.jpg";
    const addr = kind === 'course' ? "/load/course/" + line.course : "/load/product/" + line.item;


    useEffect(() => {
        if (kind === 'course') {
            getAPICall('courses/name', {courseId: line.course}, (data) => {
                if (data && data.length > 0) {
                    const row = data[0];
                    setDesc(<><span style={{fontWeight: "600", marginRight: "6px"}}>{row.title}</span>({line.title})</>);
                }
            });
        } else {
            setDesc(<span style={{fontWeight: "600"}}>{line.name}</span>);
        }
    }, [kind, line, userId, token]);

    return <div style={{marginBottom: "14px", display: "flex"}}>
        <div className="orderItemFrame">
            <Link to={addr}>
                <img alt="" src={pic} width="100%" onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = picError; }} />
            </Link>
        </div>
        <div>
            {desc}
            {kind === 'item' && line.digital === 0 && <OrderState state={line.status}/>}
            <p style={{marginBottom: "0px", fontFamily: "Mak", color: "cadetblue"}}>{line.price} грн</p>
        </div>
    </div>
}

export default OrderLine
