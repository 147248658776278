export const insertEmoji = (toAdd, text, start) => {
    const length = text.length;
    var i = 0;
    var counter = 0;
    while (i < length && counter < start) {
        if (text[i] === '<') {
            i = text.indexOf('>', i) + 1;
            if (i === 0) break;
        } else {
            counter++;
            i++;
        }
    }
    if (counter === start) return text.substring(0, i) + toAdd + text.substring(i);
    else return text;
} 

export const insertLink = (link, text, start, end) => {
    const length = text.length;
    var i = 0;
    var counter = 0;
    while (i < length && counter < start) {
        if (text[i] === '<') {
            i = text.indexOf('>', i) + 1;
            if (i === 0) break;
        } else {
            counter++;
            i++;
        }
    }
    if (counter === start) {
        if (start === end) {
            return text.substring(0, i) + '<a href="' + link + '">' + link + '</a>' + text.substring(i);
        } else {
            var j = i;
            while (j < length && counter < end) {
                if (text[j] === '<') break;
                else {
                    counter++;
                    j++;
                }
            }
            if (j === i) return text.substring(0, i) + '<a href="' + link + '">' + link + '</a>' + text.substring(i);
            else return text.substring(0, i) + '<a href="' + link + '">' + text.substring(i, j) + '</a>' + text.substring(j);
        }
    } else return text;
}
