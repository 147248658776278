const initialColors = ["lightgray", "blue", "blueviolet", "brown", "burlywood", "cadetblue",
    "chocolate", "coral", "cornflowerblue", "crimson", "darkblue", "darkcyan", "darkgoldenrod",
    "darkgreen", "darkkhaki", "darkmagenta", "darkolivegreen", "darkorange", "darkorchid",
    "darkred", "darksalmon", "darkseagreen", "darkslateblue", "dimgray", "dodgerblue",
    "firebrick", "indianred", "lightcoral", "mediumaquamarine", "mediumpurple", "mediumslateblue",
    "mediumturquoise", "mediumvioletred", "olive", "olivedrab", "orangered", "orchid",
    "palevioletred", "peru", "plum", "purple", "rosybrown", "royalblue", "saddlebrown", "salmon",
    "sandybrown", "slateblue", "slategray", "steelblue", "tan", "teal", "yellowgreen"];

export function randomColor(id) {
    return initialColors[id % initialColors.length];
}