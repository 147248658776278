import UserPhotoIcon from "../../Components/UserPhotoIcon"
import Translate from "../../AppContext/Translate"
import ActivityDate from "./ActivityDate"
import { getAPICall } from "../../Components/APICall";
import { useEffect, useState, useContext } from "react";
import { AppContext } from "../../AppContext/AppContext";
import { chooseRowByLang } from "../../AppContext/Translate";
import { Link } from "react-router-dom";

function DiplomaActivity({date, course, user, userName}) {
    var { lang } = useContext(AppContext);
    const [courseName, setCourseName] = useState(null);

    useEffect(() => {
        const onGetCourseName = (data) => {
            const row = chooseRowByLang(data, lang);
            if (row !== null)
                setCourseName(row.title);
        }
        getAPICall('courses/name', {courseId: course}, onGetCourseName);
    }, [course, lang]);

    return <div>
        <div style={{display: "flex"}}>
            <UserPhotoIcon user={user} userName={userName}/>
            <div style={{marginLeft: "8px"}}><p style={{margin: "0px", marginBottom: "6px"}}>{userName}
                <span style={{fontWeight: "600", marginLeft: "6px"}}><Translate>отримав/-ла диплом</Translate>:</span></p>
                <p style={{fontSize: "small", margin: "0px"}}><ActivityDate date={date}/></p>
            </div>
        </div>
        <div style={{textAlign: "center"}}>
            {courseName && <Link to={"/course/" + course}>
                <p className="userLink" style={{fontWeight: "600", fontSize: "large"}}>{courseName}</p>
            </Link>}
            <img width="150px" src="/icons/diplom.png" alt="Diploma"/>
        </div>
    </div>
}

export default DiplomaActivity