import Card from 'react-bootstrap/Card';
import CardHeader from 'react-bootstrap/CardHeader';
import CardBody from 'react-bootstrap/CardBody';
import UserPhotoIcon from '../../Components/UserPhotoIcon';
import Form from 'react-bootstrap/Form';
import Translate, { translate } from '../../AppContext/Translate';
import { useState, useContext } from 'react';
import Button from "react-bootstrap/Button";
import { GrSend } from "react-icons/gr";
import validator from 'validator'
import { AppContext } from '../../AppContext/AppContext';
import { postAPICall, postAPIUploadProgress } from '../../Components/APICall';
import ChooseFileButton from '../../Components/ChooseFileButton';
import UploadProgress from '../../Components/UploadProgress';
import StudentFileForRequest from './StudentFileForRequest';

function CabStudentSharedRequest({request, removeRequest}) {
    const { userId, token, dicLang } = useContext(AppContext);
    const [link, setLink] = useState(null);
    const [progress, setProgress] = useState(null);

    const saveError = (msg=null) => {
        alert(translate("Помилка", dicLang) + ": " + translate("не вдалося зберегти зміни", dicLang));
    }
    
    const sendLink = () => {
        if (link && validator.isURL(link)) {
            postAPICall('cabinet/sendSharedOptionLink', {userId: userId, token: token,
                course: request[0].course, code: request[0].code, link: link, pkgId: request[0].package}, (data) => {
                    if (data && 'error' in data && data.error === 'none') removeRequest();
                    else saveError();
                }, saveError);
        } else alert(translate("Помилка", dicLang) + ": " + translate("посилання недійсне", dicLang));
    }

    const fileChange = (evnt) => {
        if (!evnt) setLink(null);
        else {
            const file = evnt.target.files[0];
            if (!file) { return; }
            const size = file.size / 1048576; 
            if (size > 10) {
                alert(translate("Помилка", dicLang) + ": " + translate("файли розміром понад 10 Мб не можна завантажувати", dicLang));
                return;
            }
            setLink({name: file.name, size: size, file: file});
        }
    }

    const sendFile = () => {
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
            const { result } = e.target;
            if (result) {
                postAPIUploadProgress('cabinet/sendSharedOptionFile', {name: link.name, buffer: result},
                    {userId: userId, token: token, course: request[0].course, code: request[0].code,
                    link: link.name, pkgId: request[0].package}, (data) => {
                        if (data && 'error' in data && data.error === 'none') removeRequest();
                        else saveError();
                    },
                    () => { setProgress(null); saveError(); },
                    (percent) => { setProgress({file: link.name, percent: percent, action: 'upl'});
                });
            } else {
                saveError();
            }
        }
        fileReader.readAsDataURL(link.file);
    }

    return !request || request.length < 1 ? null : <Card style={{margin: "8px", maxWidth: "420px"}}>
        <CardHeader>
            <p style={{fontWeight: "600", margin: "0px"}}>{request[0].courseTitle}</p>
            <p style={{color: "cadetblue", margin: "0px"}}>{request[0].pkgTitle}</p>
            <p style={{margin: "0px", fontStyle: "italic"}}>{request[0].optionName}</p>
        </CardHeader>
        <CardBody>
            <div style={{display: "flex", flexWrap: "wrap", fontSize: "small"}}>
            {request.map((row, i) => {
                return <div key={i} style={{display: "flex", alignItems: "center", margin: "0px 12px 4px 0px"}}>
                    <UserPhotoIcon user={row.user} userName={row.name} big={false}/>
                    <span>{row.name}</span>
                </div>
            })}
            </div>
            {request[0].kind === 0 ? <>
                {request[0].kind === 2 && <StudentFileForRequest request={request[0]}/>}
                <Form.Label style={{fontWeight: "600"}}><Translate>Посилання</Translate></Form.Label>
                <div style={{display: "flex", alignItems: "flex-start", justifyContent: "space-between"}}>
                    <Form.Control value={link === null ? '' : link} type="text" maxLength={1000} placeholder="https://..." 
                        onChange={(e) => { setLink(e.target.value); }}/>
                    <Button style={{marginLeft: "8px"}} onClick={sendLink}>
                        <GrSend style={{marginTop: "-4px"}} />
                    </Button>
                </div>
            </> : <>
                {request[0].kind === 2 && <StudentFileForRequest request={request[0]}/>}
                <Form.Label style={{fontWeight: "600"}}><Translate>Файл</Translate></Form.Label>
                <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                    <ChooseFileButton ext={null} fileSrc={link} fileChange={(e) => { fileChange(e); }} />
                    {link && <Button style={{marginLeft: "8px"}} onClick={sendFile}><GrSend style={{marginTop: "-4px"}} /></Button>}
                </div>
                <UploadProgress progress={progress}/>
            </>}
        </CardBody>
    </Card>
}

export default CabStudentSharedRequest