import { Link } from "react-router-dom"
import Translate from "../AppContext/Translate"

function HomeButton({center=true}) {
    const button = <Link to="/">
        <button style={{padding: "4px 12px", backgroundColor: "#f76429", color: "white",
            cursor: "pointer", textAlign: "center", border: "none", borderRadius: "4px"}}
            className="large">
            <img alt="" src="/icons/magistrika.png" height="20px" className="right6" style={{marginTop: "-3px"}}/>
            <Translate>на головну</Translate>
        </button>
    </Link>
    return center ? <div style={{display: "flex", justifyContent: "center"}}>{button}</div> : button 
}

export default HomeButton