import { Link } from "react-router-dom";
import Translate, { formatDate } from "../../AppContext/Translate";
import InfoButton from "../../Components/InfoButton";
import { Box, encodeOrderNumber } from "../../User/Orders/Order";
import OrderState from "../../User/Orders/OrderState";
import OrderSubtotal from "./OrderSubtotal";

function ItemOrder({data, platformPercentage, role}) {
    return <>
    <div style={{display: "flex", flexWrap: "wrap", justifyContent: "flex-start", alignItems: "center"}}>
        <div style={{display: "flex", alignItems: "center"}}>
            <img alt="" src={"/items/item" + data.itemId + ".jpg"} width="128px" className="right6"
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = "/items/noitem.jpg";
                }}/>
            <Box title={<Translate>Продукт</Translate>} value={data.itemName}/>
        </div>
        <Box title={<Translate>Дата</Translate>} value={formatDate(data.orderDate)}/>
        <Box title={<Translate>Замовлення</Translate>} value={<div style={{display: "flex"}}>
            {encodeOrderNumber(data.id)}
            {data.comment && data.comment.length > 0 && <InfoButton text={data.comment} translateText={false}/>}
        </div>}/>
        <Box title={<Translate>Замовник</Translate>} value={<Link to={"/profile/" + data.client}>{data.clientName}</Link>}/>
        {data.digital === 0 && <Box title={<Translate>Статус</Translate>} 
            value={<OrderState state={data.status} editable={{id: data.id, item: data.itemId, client: data.client}} />}/>}
    </div>
    {data.digital === 0 && ((data.phone && data.phone.length > 0) || (data.address && data.address.length > 0)
        || (data.email && data.email.length > 0)) && <Box title={<Translate>Адреса</Translate>} value={<div>
        {data.address && data.address.length > 0 && <p>{data.address}</p>}
        {data.phone && data.phone.length > 0 && <p>{data.phone}</p>}
        {data.email && data.email.length > 0 && <Link to={"mailto:" + data.email}>{data.email}</Link>}
        </div>}/>}
    <OrderSubtotal data={data} platformPercentage={platformPercentage} role={role}/>
    <hr/>
</>
}

/*
{
    "phone": "",
    "email": "anyasamba1@gmail.com",
    "carrier": 0,
    "address": "",
    "amount": 1,
    "status": 0,
}
 */
export default ItemOrder