import Menu from "../Menu/Menu"
import { useEffect, useContext, useState } from "react"
import { AppContext } from "../AppContext/AppContext";
import { Navigate } from "react-router-dom";
import { getAPICall } from "../Components/APICall";
import Translate from "../AppContext/Translate";
import CourseList from "../Lists/CourseList";
import UserListCourses from "./UserListCourses";
import Footer from "../Components/Footer";
import HomeButton from "../Components/HomeButton";
import Banner from "../Components/Banner";
import CabHeader from "../Cabinet/CabHeader";
import MyProfileButton from "../Components/MyProfileButton";

function UserCourses() {
    const { userId, userName, token, lang } = useContext(AppContext);
    const [courses, setCourses] = useState([]);
    const [approvals, setApprovals] = useState([]);

    useEffect(() => {
        const plansInfoSuccess = (data) => {
            if (data && data.length > 0) {
                setCourses(data);
            }
        }

        const approvalSuccess = (data) => {
            if (data && data.length > 0) {
                setApprovals(data);
            }
        }

        if (userId !== -1) {
            getAPICall('user/purchasedPlans', {userId: userId, token: encodeURIComponent(token),
                lang: encodeURIComponent(lang)}, plansInfoSuccess);
            getAPICall('project/approval', {userId: userId, token: encodeURIComponent(token)}, approvalSuccess);
        }
    }, [userId, token, lang]);

    return userId >= 0 ? 
    <div className='mainContainer' style={{backgroundColor: "#fef5de"}}>
        <Menu />
        <Banner srcImg="/bkg/banners/courses.jpg" bkgColor="#fef5de" down={false}
            title={<h1 style={{color: "white"}}><Translate>Мої курси</Translate></h1>}/>
        <CabHeader user={userId} userName={userName}/>
        <div className="userProfileContent">
            {courses.length === 0 ? <>
                <p style={{fontWeight: "600", fontStyle: "italic", marginTop: "16px"}}><Translate>Ви ще не купили жодного курсу</Translate>.</p>
                <p><Translate>Додайте курси в кошик і почніть навчання з найкращими професіоналами</Translate>.</p>
                <div style={{marginLeft: "-12px", marginRight: "-24px"}}>
                    <CourseList title="Курси, які можуть вас зацікавити"/>
                </div>
            </> :
            <UserListCourses courses={courses} approvals={approvals}/>
            }
        </div>
        <div style={{display: "flex", justifyContent: "space-evenly", flexWrap: "wrap",
            backgroundColor: "white", margin: "8px", borderRadius: "4px"}} className="pad12">
            <div style={{margin: "4px"}}><MyProfileButton/></div>
            <div style={{margin: "4px"}}><HomeButton/></div>
        </div>
        <Footer/>
    </div> :
    <Navigate to="/" replace={true} />
}

export default UserCourses