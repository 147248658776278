import { useState, useContext } from "react";
import { AppContext } from "../../AppContext/AppContext";
import { useGoogleLogin } from '@react-oauth/google';
import MenuLoginNotLogged from "./MenuLoginNotLogged";
import MenuLoginGetGoogleProfile from "./MenuLoginGetGoogleProfile";
import { getAPICall, postAPICall } from "../../Components/APICall";
import LoginMessage from './LoginMessage';
import MenuLoggedUser from './MenuLoggedUser';
import MenuCreateAccount from './MenuCreateAccount';
import isEmail from 'validator/es/lib/isEmail';
import isStrongPassword from 'validator/es/lib/isStrongPassword';

function MenuLogin({size}) {
    // Modes
    const mode_not_logged = 0;
    const mode_logged = 1;
    const mode_create_account = 2;
    const mode_success_message = 3;
    const mode_error_message = 4;
    const mode_wrong_password = 5;
    const mode_get_google_profile = 6;
    
    // State
    var {userName, userId, login, lang, dicLang} = useContext(AppContext);
    const [show, setShow] = useState(false);
    const [formMode, setFormMode] = useState(userName === null ? mode_not_logged : mode_logged);
    const [passwordInput, setPasswordInput] = useState("");
    const [emailInput, setEmailInput] = useState("");
    const [formError, setFormError] = useState(0);  // 0: no error, 1: email, 2: password
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({title: "", msg: ""});

    // Hide/show modal form
    const handleShow = () => {
        setShow(true);
    }
    const handleClose = () => {
        setShow(false);
        setFormMode(userName === null ? mode_not_logged : mode_logged);
    }

    // Login events
    const fBLoginSuccess = (email, id, name, pic) => {
        postAPICall("user/login", {lang: lang, email: email, name: name, password: id, mode: "facebook", picture: pic},
            loginSuccess, loginError);
    }
    const loginError = () => {
        setMessage({title: "Не вдалося підтвердити ваш обліковий запис", msg: "Спробуйте пізніше або напишіть до нашої служби підтримки"});
        setFormMode(mode_error_message);
    }
    const googleLogin = useGoogleLogin({
        onSuccess: tokenResponse => googleLoginSuccess(tokenResponse.access_token),
        onError: tokenResponse => loginError()
    });
    const googleLoginSuccess = (token) => {
        setFormMode(mode_get_google_profile);
        getAPICall("https://www.googleapis.com/oauth2/v2/userinfo", {access_token: token},
            googleGetDataSuccess, loginError);
    }
    const googleGetDataSuccess = (data) => {
        const pic = data["picture"] || "";
        const id = data["id"];
        const email = data["email"];
        const name = data["name"];
        postAPICall("user/login", {lang: lang, email: email, name: name, password: id, mode: "google", picture: pic},
            loginSuccess, loginError);
    }
    const userLogin = (evnt) => {
        postAPICall("user/login", {lang: lang, email: emailInput, password: passwordInput, mode: "email"},
            loginSuccess, loginError);
    }
    const loginSuccess = (data) => {
        const error = data['error'];
        switch (error) {
            case 'invalid_password_or_mode': 
                setFormMode(mode_wrong_password); 
                break;
            case 'unknown_email':
                setMessage({title: "Не вдалося підтвердити ваш обліковий запис", msg: "Немає зареєстрованого користувача за вказаною електронною адресою"});
                setFormMode(mode_error_message);
                break;
            case 'none':
                userName = data['name'];
                login(data['id'], userName, data['token']);
                handleClose();
                break;
            default: 
                loginError();
        }
    }

    // Form events
    const formSubmit = (evnt) => {
        evnt.preventDefault();
        switch (formMode) {
            case mode_not_logged:
            case mode_wrong_password:
                userLogin(evnt);
                break;
            case mode_create_account:
                createAccount(evnt);
                break;
            default:
        }
    }
    const handleEmailChange = (evnt) => {
        setEmailInput(evnt.target.value);
    };
    const handlePasswordChange = (evnt) => {
        setPasswordInput(evnt.target.value);
    }
    const createAccountClick = () => {
        setFormMode(mode_create_account);
    }
    const loginClick = () => {
        setFormMode(mode_not_logged);
    }
    const createAccount = (evnt) => {
        var error = 0;
        if (!isEmail(emailInput)) error += 1;
        if (!isStrongPassword(passwordInput, {minLength: 8, minLowercase: 1, minUppercase: 1, minSymbols: 0, minNumbers: 0})) error += 2;
        if (error !== 0) {
            setFormError(error);
        } else {
            setLoading(true);
            postAPICall('user/create', {email: emailInput, password: passwordInput, lang: lang},
                createAccountSuccess, createAccountError);
        }
    }
    const createAccountSuccess = (data) => {
        setLoading(false);
        const error = data['error'];
        if (error !== 'none') {
            if (error === 'existing_email') {
                setMessage({title: "Не вдалося створити обліковий запис", msg: "З цією електронною адресою вже пов’язаний обліковий запис. Якщо ви забули свій пароль, натисніть |тут|",
                    link: "/user/recover"});
                setFormMode(mode_error_message);
            } else {
                createAccountError(data);
            }
        } else {
            userName = data['name'];
            login(data['id'], userName, data['token']);
            setMessage({title: "Обліковий запис успішно створено", msg: "Незабаром ви отримаєте електронний лист для підтвердження свого облікового запису"});
            setFormMode(mode_success_message);
        }
    }
    const createAccountError = (error) => {
        setLoading(false);
        setMessage({title: "Не вдалося створити обліковий запис", msg: "Спробуйте пізніше або напишіть до нашої служби підтримки"});
        setFormMode(mode_error_message);
    }

    switch (formMode) {
        case mode_logged: 
            return <MenuLoggedUser size={size} userName={userName} userId={userId} />
        case mode_create_account:
            return <MenuCreateAccount handleShow={handleShow} size={size} userName={userName}
                handleClose={handleClose} show={show} fBLoginSuccess={fBLoginSuccess} fBLoginError={loginError}
                googleLogin={googleLogin} formSubmit={formSubmit} handleEmailChange={handleEmailChange} emailInput={emailInput}
                formError={formError} handlePasswordChange={handlePasswordChange} passwordInput={passwordInput}
                loading={loading} loginClick={loginClick} />
        case mode_error_message:
            return <LoginMessage error={true} content={message} onClickEvent={handleShow} show={show} onClose={handleClose}
                size={size} userName={userName} dicLang={dicLang} /> 
        case mode_success_message:
            return <LoginMessage error={false} content={message} onClickEvent={handleShow} show={show} onClose={handleClose}
                size={size} userName={userName} dicLang={dicLang} /> 
        case mode_get_google_profile:
            return <MenuLoginGetGoogleProfile handleShow={handleShow} size={size} userName={userName} />
        default:
            return <MenuLoginNotLogged handleShow={handleShow} handleClose={handleClose} show={show}
                size={size} userName={userName} error={formMode === mode_wrong_password}
                fBLoginSuccess={fBLoginSuccess} fBLoginError={loginError} googleLogin={googleLogin}
                formSubmit={formSubmit}  handleEmailChange={handleEmailChange} emailInput={emailInput}
                handlePasswordChange={handlePasswordChange} passwordInput={passwordInput}
                createAccountClick={createAccountClick} />
    }
}

export default MenuLogin