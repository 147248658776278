import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../AppContext/AppContext";
import { getAPICall } from "../../Components/APICall";
import Accordion from 'react-bootstrap/Accordion';
import { RiGroup2Fill } from "react-icons/ri";
import Translate from "../../AppContext/Translate";
import CabStudentSharedRequest from "./CabStudentSharedRequest";

function CabStudentsSharedOptions() {
    const { userId, token } = useContext(AppContext);
    const [requests, setRequests] = useState({});
    const [sent, setSent] = useState(new Set());

    const getCode = (row) => {
        return row.code + row.course;
    }

    useEffect(() => {
        getAPICall('cabinet/requestedOptions', {userId: userId, token: encodeURIComponent(token), kind: 0}, (data) => {
            if (data) {
                var req = {};
                for (var i = 0; i < data.length; i++) {
                    const row = data[i];
                    const code = getCode(row);
                    if (code in req) req[code].push(row);
                    else req[code] = [row];
                }
                setRequests(req);
            }
        });
    }, [userId, token]);

    const removeRequest = (index) => {
        const newSet = new Set(sent);
        newSet.add(index);
        setSent(newSet);
    }

    const requestCodes = Object.keys(requests);
    return requestCodes.length > 0 && requestCodes.length > sent.size ?
    <Accordion>
        <Accordion.Item eventKey="2">
            <Accordion.Header>
                <RiGroup2Fill size="24" style={{margin: "1px 8px 0px 0px"}}/>
                <span style={{fontSize: "larger", fontWeight: "600"}}><Translate>Групові запити</Translate></span>
            </Accordion.Header>
            <Accordion.Body>
                <div style={{display: "flex", flexWrap: "wrap", alignItems: "flex-start"}}>
                {requestCodes.map((req, i) => { 
                    return sent.has(i) ? null : <CabStudentSharedRequest key={i} request={requests[req]}
                        removeRequest={() => { removeRequest(i) }}/>
                })}
                </div>
            </Accordion.Body>
        </Accordion.Item>
    </Accordion> : null
}

export default CabStudentsSharedOptions