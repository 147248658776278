import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../AppContext/AppContext";
import { getAPICall } from "../../Components/APICall";
import BeatLoader from "react-spinners/BeatLoader";
import { MdOutlineRateReview } from 'react-icons/md';
import UserPhotoIcon from "../../Components/UserPhotoIcon";
import PostMessage from "../../Components/PostMessage";
import { MediaPath } from "../../Components/MediaPath";
import Translate, { formatDateTime } from "../../AppContext/Translate";

function Review({data, user, post, packageId}) {
    var path = new MediaPath(MediaPath.kind_privateTaskReview);
    path.add(user);
    path.add(packageId);
    path.add(post);
    path.add(data.teacher);
    return <Row>
        <Col className="taskReviewDiv">
            <div style={{width: "48px"}}>
                <UserPhotoIcon user={data.teacher} userName={data.name}/>
            </div>
            <div className="taskReview">
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div style={{fontWeight: "600"}}>{data.name}</div>
                    <div className="chatUserName">{formatDateTime(data.reviewDate)}</div>
                </div>
                <hr style={{marginTop: "8px"}}/>
                <PostMessage msg={data.comment} path={path} open={false} sep={false} textStyle="smallTextPost"/>
            </div>
        </Col>
    </Row>
}

function TaskReview({user, post, packageId, group}) {
    const { userId, token } = useContext(AppContext);
    const [reviews, setReviews] = useState(null);

    useEffect(() => {
        const getReviewsSuccess = (data) => {
            if (data && data.length > 0 && !('error' in data)) {
                setReviews(data);
            } else getReviewsError(data);
        }
        const getReviewsError = (data) => {
            setReviews([]);
        }
        getAPICall('tasks/reviews', {userId: userId, token: encodeURIComponent(token), user: user,
            post: post, packageId: packageId, groupId: group}, getReviewsSuccess, getReviewsError);
    }, [group, packageId, post, user, userId, token]);

    return !reviews ?
        <Row>
            <Col className="chatPost">
                <BeatLoader color="blueviolet" />
            </Col>
        </Row> : (reviews.length === 0 ?
        <Row>
            <Col style={{margin: "24px 24px 0px 24px", padding: "6px"}}>
                <p className="smallTextPost">
                    <MdOutlineRateReview size={24} style={{marginRight: "6px"}}/>
                    <i><Translate>Це завдання ще не пройшло перевірку</Translate>.</i>
                </p>
            </Col>
        </Row> :
        reviews.map((data, index) => (
            <Review key={index} data={data} user={user} post={post} packageId={packageId}/>
        ))
    )
}

export default TaskReview