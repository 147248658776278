import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';

function ModalAlert({type, title, msg, buttonCaption, show, handleClose, centered=true}) {
    const btnStyle = type.substring(6);
    return (
        <Modal className={type} show={show} onHide={handleClose} backdrop="static" keyboard={false} size="sm" centered={centered}>
            <Container>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { typeof msg === 'string' ? <p>{msg}</p> : <>{msg}</> }
            </Modal.Body>
            <Modal.Footer>
                <Button variant={btnStyle} onClick={() => {handleClose()}}>{buttonCaption}</Button>
            </Modal.Footer>
            </Container>
        </Modal>);
}

export default ModalAlert