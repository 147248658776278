import { useContext, useState } from "react";
import { getAPICall } from "../Components/APICall";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Translate, { getLanguagesData } from "../AppContext/Translate";
import { AppContext } from "../AppContext/AppContext";

function CoursesFilter({show, onFilter}) {
    const { lang } = useContext(AppContext);
    const [schools, setSchools] = useState(null);
    const [categories, setCategories] = useState(null);

    const onDropdownSchoolClick = (evnt) => {
        if (schools === null) {
            getAPICall('school/list', {}, (data) => {setSchools(data);});
        }
    }

    const onDropdownCatClick = (evnt) => {
        if (categories === null) {
            getAPICall('categories/list', {lang: lang}, (data) => { setCategories(data); });    
        }
    }

    return <Form className='shopFilter' style={{display: show ? "block" : "none"}}>
        <Row>
            <Col sm={4}>
                <Form.Label><Translate>Фільтрувати за школою</Translate></Form.Label>
                <Form.Select onClick={onDropdownSchoolClick} onChange={e => { onFilter(e.target.value, null, null);}}>
                    <option value="0">---</option>
                    {schools &&
                        schools.map((item, index) => {
                            return <option key={index} value={item.id}>{item.title}</option>
                        })
                    }
                </Form.Select>
            </Col>
            <Col sm={4}>
                <Form.Label><Translate>Фільтрувати за категорією</Translate></Form.Label>
                <Form.Select onClick={onDropdownCatClick} onChange={e => { onFilter(null, e.target.value, null);}}>
                    <option value="0">---</option>
                    {categories &&
                        categories.map((item, index) => {
                            return <option key={index} value={item.link}>{item.title}</option>
                        })
                    }
                </Form.Select>
            </Col>
            <Col sm={4}>
                <Form.Label><Translate>Фільтрувати за мовою</Translate></Form.Label>
                <Form.Select onChange={e => { onFilter(null, null, e.target.value);}}>
                    <option value="">---</option>
                    {getLanguagesData().map((m, i) => { 
                        return <option key={i} value={m.id}>{m.name}</option>})}
                </Form.Select>
            </Col>
        </Row>
    </Form>
}

export default CoursesFilter