import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../AppContext/AppContext';
import WriteHelper from './WriteHelper';
import DocPreview from './DocPreview';

function PostMessage({msg, path, open = true, sep=true, textStyle="textPost"}) {
    const { userId, token } = useContext(AppContext);
    const [elements, setElements] = useState([]);
    const [preview, setPreview] = useState(null);
    
    const onPreview = (data) => {
        setPreview(data);
    }

    const cn = open ? "noCommentDivPost" : "commentDivPost";

    useEffect(() => {
        if (msg) {
            WriteHelper.parseElements(msg, path, userId, token, textStyle, onPreview).then((value) => {
                setElements(value);
            });
        }
    }, [msg, path, userId, token, textStyle]);

    return <>{preview && <DocPreview kind={preview["kind"]} name={preview["name"]} setPreview={setPreview} path={path}/>}
        <div className={cn}>
        {elements}
        {sep && <hr key={elements.length} style={{marginTop: "48px"}}/>}
        </div>
    </>
}

export default PostMessage