import Translate from "../../AppContext/Translate";
import ActivityDate from "./ActivityDate";
import UserPhotoIcon from "../../Components/UserPhotoIcon";
import ActivityCourseInfo from "./ActivityCourseInfo";

function PurchaseActivity({date, course, user, userName}) {
    return <div>
        <div style={{display: "flex"}} className="bot8">
            <UserPhotoIcon user={user} userName={userName}/>
            <div style={{marginLeft: "8px"}}><p style={{margin: "0px", marginBottom: "6px"}}>{userName}
                    <span style={{fontWeight: "600", marginLeft: "6px"}}><Translate>записався/-лася на курс</Translate>:</span>
                </p>
                <p style={{fontSize: "small"}}><ActivityDate date={date}/></p>
            </div>
        </div>
        <ActivityCourseInfo course={course}/>
    </div>
}

export default PurchaseActivity