import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../AppContext/AppContext";
import Translate, { translate } from "../../AppContext/Translate";
import { getAPICall } from "../../Components/APICall";
import SyncLoader from "react-spinners/SyncLoader";
import InfiniteScroll from 'react-infinite-scroll-component';
import Message from "./Message";
import SendMessage from "./SendMessage";

function MessagesEnd() {
    return <p className='notificationText' style={{textAlign: 'center', marginTop: "24px", fontStyle: "italic"}}>
        <Translate>Повідомлень більше немає</Translate>
    </p>
}

function MessagesChat({user}) {
    var {userId, token, dicLang} = useContext(AppContext);
    const [allowMsgs, setAllowMsgs] = useState(false);
    const [msgs, setMsgs] = useState({items: [], hasMore: true, offset: 0});
    const limit = 16;

    const getMessagesError = (data) => {
        alert(translate("Помилка", dicLang) + ": " + translate("повідомлення не вдалося отримати", dicLang));
    }

    const getMessagesSuccess = (data) => {
        if (data) {
            setMsgs({
                items: msgs.items.concat(data),
                hasMore: data.length >= limit,
                offset: msgs.offset + data.length
            });
        } else getMessagesError();
    }

    const onSent = (msg) => {
        getAPICall('user/messages', {userId: userId, token: encodeURIComponent(token), user: user,
            offset: 0, limit: limit}, (data) => {
            if (data) {
                setMsgs({
                    items: data,
                    hasMore: data.length >= limit,
                    offset: data.length
                });
            } else getMessagesError();
        }, getMessagesError);
    }

    useEffect(() => {
        getAPICall('user/messages', {userId: userId, token: encodeURIComponent(token), user: user,
            offset: 0, limit: limit}, getMessagesSuccess, getMessagesError);
        getAPICall('user/allowMsgs', {user: user}, (data) => {
            if (data && 'allow' in data) setAllowMsgs(data.allow === 1);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, token]);

    return <>
    <div id="scrollableDiv" style={{paddingTop: "18px", height: "calc(100vh - 650px)",  overflow: 'auto',
        display: 'flex', flexDirection: 'column-reverse', minHeight: "320px"}} className="hideScroll">
        <InfiniteScroll 
            inverse={true}
            dataLength={msgs.items.length}
            next={() => {
                getAPICall('user/messages', {userId: userId, token: encodeURIComponent(token), user: user,
                    offset: msgs.offset, limit: limit}, getMessagesSuccess, getMessagesError)
            }}
            scrollableTarget="scrollableDiv"
            className="imessage"
            hasMore={msgs.hasMore}
            loader={<p><SyncLoader size={18} color="#73694c"/></p>}
            endMessage={<MessagesEnd/>}>
            {msgs.items.map((data, index) => 
                <Message key={index} data={data}/> )}
        </InfiniteScroll>
    </div>
    {allowMsgs && <div className="pad12">
        <SendMessage fromUser={userId} toUser={user} token={token} onSent={onSent}/>
    </div>}
    </>
}

export default MessagesChat