import Translate from "../../AppContext/Translate";
import Alert from 'react-bootstrap/Alert';

function SchoolMessageAppPendant({school}) {
    const rejectDate = school[0].rejectDate;
    const comment = school[0].adminComment;
    return <><p style={{color: "darkred", fontStyle: "italic"}}><Translate>
        {rejectDate ? "Ваш запит на створення школи було відхилено адміністратором" : 
        "Ваша школа все ще очікує затвердження від адміністраторів"}
        </Translate>.</p>
        {comment && rejectDate && <Alert key="warning" variant="warning">{comment}</Alert>}
        </>
}

export default SchoolMessageAppPendant