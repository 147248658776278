import UserPhotoIcon from "../../Components/UserPhotoIcon"

function OrderTeam({expanded, benefit, teachers}) {
    return <div className={expanded ? "fadeIn" : "fadeOut"}>
        {teachers && teachers.map((teacher, i) => {
            const sum = Math.round((benefit * teacher.percentage / 100) * 100) / 100;
            return <div key={i} className="top4" style={{display: "flex", alignItems: "center"}}>
                <UserPhotoIcon user={teacher.owner} userName={teacher.name}/>
                <span className="italic right6" style={{marginLeft: "6px"}}>{teacher.name}</span>
                <span className="right6">({teacher.percentage}%)</span>
                <span>{sum} грн</span>
            </div>
        })}
    </div>
}

export default OrderTeam