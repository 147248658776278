import { useContext } from "react";
import { AppContext } from "../../AppContext/AppContext";
import Translate, { translate } from "../../AppContext/Translate";
import { AiOutlineNodeExpand, AiOutlineNodeCollapse } from 'react-icons/ai';

function ChatTitle({topic, scheduled, expand, setExpanded}) {
    const { dicLang } = useContext(AppContext);
    var title;
    if (topic) {
        title = topic.show === 'day' ? translate("День", dicLang) + " " + topic.day : topic.title; 
    } else {
        title = scheduled ? translate("Виберіть день у календарі", dicLang) : 
            translate("Виберіть тему курсу", dicLang);
    }
    return (
    <div className="topCourseMenu" style={{display: "flex", justifyContent: "space-between"}}>
        <div style={{marginLeft: "12px"}}>{title}</div>
        <div style={{display: "flex"}}>
            { expand ?
                <button className="chatExpandButton" onClick={() => setExpanded(true)}><AiOutlineNodeExpand/> <Translate>Розгорнути</Translate></button> :
                <button className="chatExpandButton" onClick={() => setExpanded(false)}><AiOutlineNodeCollapse/> <Translate>Згорнути</Translate></button>
            }
        </div>
    </div>);
}

export default ChatTitle