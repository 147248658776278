import { FcDiploma1 } from 'react-icons/fc';
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';
import Translate from "../AppContext/Translate";
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function DiplomaButton({available, plan}) {
    const [show, setShow] = useState(false);
    const target = useRef(null);
    let navigate = useNavigate(); 
    
    const downloadDiploma = () => {
        navigate('/certificate/' + plan);
    }

    return <>
        <Button size="sm" variant="outline-primary" ref={target} onClick={() => {
            if (!available) setShow(!show);
            else downloadDiploma();
        }}>
            <FcDiploma1 color="green" size="22" style={{marginTop: "-2px", marginRight: "2px"}}/>
            <Translate>Диплом</Translate>
        </Button>
        <Overlay target={target.current} show={show} placement="bottom">
        {(props) => (
          <Tooltip id="diplomaTooltip" {...props}>
            <Translate>Ваш диплом ще не доступний</Translate>.<br/>
            <Translate>Ваш проект має затвердити викладач курсу</Translate>.
          </Tooltip>
        )}
        </Overlay>
    </>
}

export default DiplomaButton