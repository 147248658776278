import RegisterActivity from "./Activity/RegisterActivity"
import PurchaseActivity from "./Activity/PurchaseActivity"
import DiplomaActivity from "./Activity/DiplomaActivity"
import ProjectReactionActivity from "./Activity/ProjectReactionActivity"
import CourseCreationActivity from "./Activity/CourseCreationActivity"

function UserActivity({data, user, userName, onPreview}) {
    return <div className="userActivityCard">
        {data.event === 1 ? <RegisterActivity date={data.date} user={user} userName={userName}/> : (
         data.event === 2 ? <PurchaseActivity date={data.date} course={data.f1} user={user} userName={userName} /> : (
         data.event === 3 ? <DiplomaActivity date={data.date} course={data.f1} user={user} userName={userName} /> : (
         data.event === 4 ? <ProjectReactionActivity date={data.date} task={data.f1} kind={data.f2} user={user}
            userName={userName} onPreview={onPreview}/> : 
        <CourseCreationActivity date={data.date} course={data.f1} user={user} userName={userName}/>
        )))}
    </div>
}

export default UserActivity