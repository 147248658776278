function pathToLink(path) {
    if (!path.course) return '';
    var link = path.course.toString();
    if ('group' in path) link += '-g' + path.group;
    if (path.topic) {
        link += '-bc-d' + path.topic;
        if (path.material) {
            link += '-p' + path.material;
            if (path.comments) {
                for (var i = 0; i < path.comments.length; i++) {
                    link += '-c' + path.comments[i];        
                }
            }
        }
    }
    return link;
}

export default pathToLink;