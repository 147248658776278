import ReactStars from "react-rating-stars-component";
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Translate from '../AppContext/Translate';

function Valoration({score, numOpinions, size=15}) {
    const stars = numOpinions === 0 ? 0 : score / (2 * numOpinions);
    return <OverlayTrigger delay={{ show: 250, hide: 400 }} placement="top" 
            overlay={<Tooltip>{numOpinions}{' '}<Translate>відгуки</Translate></Tooltip>}>
            <div><ReactStars count={5} value={stars} edit={false} isHalf={true} size={size}/></div>
    </OverlayTrigger>
}

export default Valoration