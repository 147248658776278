import { useState, useContext } from 'react';
import Translate, { translate } from '../../AppContext/Translate';
import { AppContext } from '../../AppContext/AppContext';
import { FaRegEyeSlash, FaRegEye } from 'react-icons/fa';

function PasswordForm({onChangeEvent, error, value, isLogin}) {
    const [passwordType, setPasswordType] = useState("password");
    var {dicLang} = useContext(AppContext);
    
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }

    const pasStyle = error ? {borderColor: 'red', marginBottom: '2px'} : {};
    const child = error ? <p style={{color: 'red', fontSize: 'small'}}>
        <Translate>Пароль має містити не менше 8 символів і мати одну велику та одну малу літери</Translate>.</p> : <></>;
    const text = translate("Пароль", dicLang);
 
    return <>
        <input type={passwordType} className="form-control" placeholder={text} onChange={onChangeEvent}
            style={pasStyle} value={value} name="password" autoComplete={isLogin ? "current-password" : "new-password"} />
        <div onClick={togglePassword} className="div-icon-eye">
            { passwordType === "password" ? <FaRegEye/> : <FaRegEyeSlash/> }
        </div>
        {child}
        </>
}

export default PasswordForm