import Navbar from 'react-bootstrap/Navbar';
import { useContext, useState, useEffect } from "react";
import { AppContext } from "../AppContext/AppContext";
import { getAPICall } from "../Components/APICall";
import { translate } from "../AppContext/Translate";
import SearchBar from '../Components/SearchBar';

function MenuSearch(props) {
    const { lang, dicLang } = useContext(AppContext);
    const [courses, setCourses] = useState([]);
    const searchCourses = translate("Назва курсу", dicLang) + "...";
    
    const updateCourses = (data) => {
        if (data)
            setCourses(data);
    }
    
    useEffect(() => {
        getAPICall('courses/list', {lang: lang, active: 1, order: 'r'}, updateCourses);
    }, [lang]);
    
    return (
        <Navbar.Text>
            <SearchBar placeholder={searchCourses} data={courses} size={props.size} />
        </Navbar.Text>
    );
}

export default MenuSearch