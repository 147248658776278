import Translate from "../../AppContext/Translate"
import MenuButton from "./MenuButton"

function TeacherMenu({own, width, isStudent, userId}) {
    return <div className="pad12 center" style={{width: width}}>
        <img alt="учень" src="/icons/teacher.png" height="48px"/>
        <p className="fmak large bold top4"><Translate>{own ? "Викладаю" : "Викладає"}</Translate></p>
        <div style={{backgroundColor: "#fef5de", textAlign: "left", borderRadius: "4px", display: "flex",
            alignItems: "flex-start", flexDirection: "column"}} className="fkenyan top12">
            {!isStudent && <MenuButton icon="/icons/story.png" text="Історія" link={"/profile/activity/" + userId}/>}
            <MenuButton icon="/icons/lesson.png" text="Курси" link={"/profile/teaches/" + userId}/>
            <MenuButton icon="/icons/shop2.png" text="Товари" link={"/profile/products/" + userId}/>
            {own && <MenuButton icon="/icons/myschool.png" text="Школа" link="/cabinet/school"/>}
            {own && <MenuButton icon="/icons/myreport.png" text="Звіт" link="/cabinet/report"/>}
        </div>
    </div>
}

export default TeacherMenu