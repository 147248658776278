import Card from 'react-bootstrap/Card';
import Translate from '../../AppContext/Translate';

function ModalityCard({title, icon, onSelect}) {
    return <Card style={{ width: '18rem', backgroundColor: "#212529", color: "floralwhite", 
        boxShadow: "2px 2px 4px #2125295c", margin: "12px", cursor: "pointer"}} onClick={onSelect}>
        <Card.Img variant="top" src={"/icons/" + icon + ".png"} alt={icon}
            style={{width: "128px", margin: "8px auto auto"}} />
        <Card.Body style={{paddingTop: "0px", paddingBottom: "6px", textAlign: "center"}}>
            <Card.Title style={{color: "#aea78d"}}><Translate>{title}</Translate></Card.Title>
        </Card.Body>
    </Card>
}

export default ModalityCard