import { useContext } from "react";
import { AppContext } from "../../AppContext/AppContext";
import Translate, { translate } from "../../AppContext/Translate";

function EmailForm({onChangeEvent, error, value}) {
    var {dicLang} = useContext(AppContext);
    const emailStyle = error ? {borderColor: 'red', marginBottom: '2px'} : {};
    const child = error ? <p style={{color: 'red', fontSize: 'small'}}>
        <Translate>Здається, ви вказали неправильну електронну адресу</Translate>. <Translate>Будь ласка, перевірте дані ще раз та повторіть спробу</Translate>.</p> : <></>;
    const text = translate("Електронна адреса", dicLang);
    return <div>
        <input type="email" className="form-control" placeholder={text} onChange={onChangeEvent}
            style={emailStyle} value={value} name="email" autoComplete="email"/>
        {child}    
        </div>
}

export default EmailForm