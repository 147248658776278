import { BiSolidRightArrow } from "react-icons/bi";
import Translate from "../AppContext/Translate";
import InfoButton from "../Components/InfoButton";

function PlanItem({text, desc=null, translateText=false, translateDesc=false}) {
    return <div className="planItem">
        <BiSolidRightArrow style={{margin: "-4px 4px 0px 0px"}}/>
        {translateText ? <Translate>{text}</Translate> : text}
        {desc && <InfoButton text={desc} translateText={translateDesc}/>}
    </div>
}

export default PlanItem