import Menu from "../Menu/Menu"
import { useEffect, useContext, useState } from "react"
import { AppContext } from "../AppContext/AppContext";
import { Navigate } from "react-router-dom";
import { getAPICall } from "../Components/APICall";
import Translate from "../AppContext/Translate";
import Container from "react-bootstrap/Container";
import Footer from "../Components/Footer";
import Shop from "../Shop/Shop";
import UserProduct from "./UserProduct";
import HomeButton from "../Components/HomeButton";
import Banner from "../Components/Banner";
import MyProfileButton from "../Components/MyProfileButton";

function UserProducts() {
    const { userId, token, lang } = useContext(AppContext);
    const [items, setItems] = useState([]);

    useEffect(() => {
        const purchasedItemsOk = (data) => {
            if (data && data.length > 0) {
                setItems(data);
            }
        }
        if (userId !== -1) {
            getAPICall('user/purchasedUniqueItems', {userId: userId, token: encodeURIComponent(token)},
                purchasedItemsOk);
        }
    }, [userId, token, lang]);

    return  userId >= 0 ? <div className='mainContainer' style={{backgroundColor: "#fef5de"}}>
        <Menu />
        <Banner srcImg='/bkg/banners/products.jpg' bkgColor="#fef5de" down={false}
            title={<h1 className="huge"><Translate>ТОВАРИ</Translate></h1>}/>
        <Container className="userProfileForm">
            <h1><Translate>Мої товари</Translate></h1>
            {items.length === 0 ? <>
                <p style={{fontWeight: "600", fontStyle: "italic", marginTop: "16px"}}>
                    <Translate>Ви ще не купили жодного продукту</Translate>.</p>
                <hr/>
                <p><Translate>Продукти, які можуть вас зацікавити</Translate>:</p>
                <Shop showMenu={false} bkgColor="white"/>
            </> :
            <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-around", alignItems: "center"}}>
                {
                items.map((item, i) => {
                    return <UserProduct key={i} item={item.reference}/>
                })
                }
            </div>
            }
        </Container>
        <div style={{display: "flex", justifyContent: "space-evenly", flexWrap: "wrap",
            backgroundColor: "white", margin: "8px", borderRadius: "4px"}} className="pad12">
            <div style={{margin: "4px"}}><MyProfileButton/></div>
            <div style={{margin: "4px"}}><HomeButton/></div>
        </div>
        <Footer/>
    </div>:
    <Navigate to="/" replace={true} />;
}

export default UserProducts