import { Link } from "react-router-dom";
import Translate from "../AppContext/Translate"
import { forwardRef } from "react";

const MagButton = forwardRef(({text, icon=null, bkgColor="#f82424", color="white", translate=true, margin="8px",
        size="x-large", addStyle={}, clickEff={link: null, scrollRef: null, customFnc: null}}, ref) => {
    const handleClick = (event) => {
        if (clickEff.customFnc) {
            clickEff.customFnc();
        } else if (clickEff.scrollRef) {
            clickEff.scrollRef.current.scrollIntoView();
        }
    };
    const button = (
        <div
          ref={ref}
          className="buttonShadow fkenyan"
          style={{ ...addStyle, backgroundColor: bkgColor, color: color, margin: margin, fontSize: size }}
          onClick={clickEff ? handleClick : undefined}
        >
          {translate ? <Translate>{text}</Translate> : text}
          {icon && <img alt="" src={icon} width="24" style={{ marginLeft: "8px" }} />}
        </div>
      );
    
      return clickEff && clickEff.link ? (
        <Link to={clickEff.link}>
          {button}
        </Link>
      ) : (
        button
      );
});

export default MagButton