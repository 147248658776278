import Translate from "../AppContext/Translate"
import CourseCard from "../Lists/CourseCard"

function SchoolCourses({courses, school, schoolName}) {
    return <>
    <hr/>
    <h2><Translate>Курси</Translate></h2>
    <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-around"}}>
    { courses &&
        courses.map((course, k) => {
            const data = {...course};
            data['schoolId'] = school;
            data['school'] = schoolName;
            return <CourseCard key={k} course={data} top={false}/>
        })
    }
    </div>
    </>
}

export default SchoolCourses