import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import LeftLoginImage from './LeftLoginImage';
import Translate from '../../AppContext/Translate';
import MenuUserIcon from "./MenuUserIcon";
import ModalBrandHeader from '../../Components/ModalBrandHeader';
import FacebookButton from './FacebookButton';
import GoogleButton from './GoogleButton';
import EmailForm from './EmailForm';
import PasswordForm from './PasswordForm';
import BeatLoader from 'react-spinners/BeatLoader';

function LoginButtonCreateAccount({loading}) {
    return <Button variant="primary" type="submit">
        { loading ? <BeatLoader size={24} color="white" /> : <Translate>Створити акаунт</Translate> }
        </Button>
}

function MenuCreateAccount({handleShow, size, userName, handleClose, show, fBLoginSuccess, fBLoginError,
    googleLogin, formSubmit, handleEmailChange, emailInput, formError, handlePasswordChange, passwordInput,
    loading, loginClick}) {
    return <>
        <MenuUserIcon handleShow={handleShow} size={size} userName={userName} />
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="xl" centered>
            <Container>
                <Row>
                    { (size === "big") && <LeftLoginImage /> }
                    <Col style={{paddingLeft: '0px', paddingRight: '0px'}}>
                        <ModalBrandHeader />
                        <Modal.Body>
                            <p><Translate>Приєднуйтеся до нашої платформи та отримайте доступ до ексклюзивних курсів для письменників та художників</Translate>.</p>
                            <Row style={{alignItems: 'center', textAlign: 'center'}}>
                                <Col>
                                    <FacebookButton createAc={true} loginSuccess={fBLoginSuccess} loginFail={fBLoginError}/>
                                </Col>
                                <Col>
                                    <GoogleButton createAc={true} googleLogin={googleLogin} />      
                                </Col>
                            </Row>
                        </Modal.Body>
                        <div style={{backgroundColor: '#ebe7d6'}}>
                            <Modal.Body>
                                <p><Translate>або зареєструйтесь за допомогою електронної адреси</Translate></p>
                                <form className="Auth-form" onSubmit={formSubmit}>
                                    <EmailForm onChangeEvent={handleEmailChange} error={(formError & 1) === 1} value={emailInput} />
                                    <div className="input-group-btn" style={{position: "relative"}}>
                                        <PasswordForm onChangeEvent={handlePasswordChange}
                                            error={(formError & 2) === 2} 
                                            value={passwordInput} isLogin={false}/>
                                    </div>
                                    <div style={{textAlign: 'right'}}>
                                        <LoginButtonCreateAccount loading={loading} />
                                    </div>       
                                </form>
                            </Modal.Body>
                        </div>
                        <Modal.Footer style={{justifyContent: 'center'}}>
                            <p><Translate>Ви вже маєте акаунт?</Translate>
                                <Button variant="muted" style={{color: '#f03800', fontWeight: 'bold', transform: 'translateY(-2px)',
                                        padding: '4px', marginLeft: '4px'}} onClick={loginClick}><Translate>Увійти</Translate></Button>
                            </p>
                        </Modal.Footer>
                    </Col>
                </Row>
            </Container>
        </Modal>
    </>
}

export default MenuCreateAccount