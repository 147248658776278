import React from 'react';
import PropTypes from 'prop-types';
import { confirmable } from 'react-confirm';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Translate from '../AppContext/Translate';

const ConfirmDialog = ({show, proceed, confirmation, options}) => (
  <Modal onHide={() => proceed(false)} show={show}>
    <Modal.Header closeButton>
      <p>{confirmation}</p>
    </Modal.Header>
    <Modal.Footer>
      <Button variant="secondary" onClick={() => proceed(true)}><Translate>Прийняти</Translate></Button>
      <Button variant="primary" onClick={() => proceed(false)}><Translate>Скасувати</Translate></Button>
    </Modal.Footer>
  </Modal>
)

ConfirmDialog.propTypes = {
  show: PropTypes.bool,            // from confirmable. indicates if the dialog is shown or not.
  proceed: PropTypes.func,         // from confirmable. call to close the dialog with promise resolved.
  confirmation: PropTypes.string,  // arguments of your confirm function
  options: PropTypes.object        // arguments of your confirm function
}

export default confirmable(ConfirmDialog);