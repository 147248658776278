import { useWindowSize } from "../AppContext/WindowSize";
import Translate from "../AppContext/Translate";
import MagButton from "../Components/MagButton";
import { useContext, useRef, useState } from "react";
import { AppContext } from "../AppContext/AppContext";
import Popover from 'react-bootstrap/Popover';
import Overlay from "react-bootstrap/Overlay";
import HorizontalScroll from "../Components/HorizontalScroll";

function SectionOpinions({setVideo}) {
    var {userId} = useContext(AppContext);
    const [show, setShow] = useState(false);
    const [width,] = useWindowSize();
    const target = useRef(null);

    const opinions = ['Світлана Лущ', 'Катерина Косенко', 'Марина Муджирі', 'Маріанна Фролова'];

    const popover = (
        <Popover>
            <Popover.Header>
                <p className="fkenyan"><Translate>Ви вже з нами! Тепер обирайте курси, що подобаються та починайте навчання</Translate>.</p>
            </Popover.Header>
            <Popover.Body>
                <p className="fkenyan"><Translate>Або, якщо ви креативний професіонал і у вас є готові авторські курси, відкрийте власну школу на платформі (Осрбистий кабінет &#x27F6; Школа)</Translate></p>
            </Popover.Body>
        </Popover>
    );

    return <div style={{backgroundColor: "white", paddingTop: "48px", paddingBottom: "12px"}}>
        <img alt="" src="/bkg/decor/dec4.png" style={{position: "absolute", width: "6%", maxWidth: "64px",
                minWidth: "34px", top: "20px", left: "20px", filter: "hue-rotate(180deg)"}}/>
        <img alt="" src="/bkg/decor/dec8.png" style={{position: "absolute", width: "3%", maxWidth: "40px",
                minWidth: "16px", top: "16px", left: "170px"}}/>
        <img alt="" src="/bkg/decor/dec10.png" style={{position: "absolute", width: "24%", maxWidth: "120px",
                bottom: "4px", left: "-20px"}}/>
        <div style={{textAlign: "right", paddingRight: "42px", marginBottom: "24px"}}>
            <h2 className="xxlarge">ІСТОРІЇ НАШИХ СТУДЕНТІВ</h2>
            <p className="fkenyan xlarge">дізнайтесь про результати з перших вуст</p>
        </div>
        <div style={{padding: "28px"}}>
            <HorizontalScroll itemSize={216}>
                {opinions.map((name, i) => {
                    return <div key={i} className="preventSelect">
                        <img key={i} alt={name} src={"/bkg/opinions/opinion" + (i + 1) + ".jpg"} 
                            className="opinionImage" onClick={() => { 
                                setVideo({id: "/bkg/opinions/opinion" + (i+1) + ".mp4",
                                    title: <><Translate>Думка</Translate>: <span style={{color: "wheat"}}>{name}</span></>}); 
                            }}/>
                    </div>
                })}
            </HorizontalScroll>
        </div>
        <div className="pad48" style={{display: "flex", justifyContent: "flex-end"}}>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <p className="fkenyan"><Translate>Долучайся до тих, хто змінює світ</Translate></p>
                <div ref={target}>
                    <MagButton text="стати частиною спільноти" icon="/bkg/user.png" 
                        clickEff={{customFnc: () => {
                            if (userId < 0) {
                                const menuCreateAccountButton = document.getElementById('menuCreateAccountButton');
                                if (menuCreateAccountButton) menuCreateAccountButton.click();
                            } else {
                                setShow(!show);
                            }
                    }}}/>
                </div>
                <Overlay target={target.current} show={show} placement={width > 768 ? "left" : "top"}
                    rootClose onHide={() => { setShow(!show); }}>
                    {popover}
                </Overlay>
            </div>
        </div>
    </div>
}

export default SectionOpinions