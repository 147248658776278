import { getAPICall } from "../../Components/APICall"

export function checkEditPermission(userId, token, course, setAllowed, lowLevel) {
    if (course && parseInt(course) > 0 && userId > 0) {
        getAPICall('course/canEdit', {userId: userId, token: encodeURIComponent(token), course: course,
            lowLevel: lowLevel ? 1 : 0},
            (data) => {
                if (data && 'allowed' in data) {
                    setAllowed(data.allowed === 1);
                } else setAllowed(false);
            },
            (data) => {
                setAllowed(false);
            });
    } else setAllowed(false);
}