import { Link } from "react-router-dom"
import Translate, { formatDate } from "../../AppContext/Translate"
import { Box, encodeOrderNumber } from "../../User/Orders/Order"
import InfoButton from "../../Components/InfoButton"
import OrderSubtotal from "./OrderSubtotal"

function CourseOrder({data, platformPercentage, role}) {
    return <>
        <div style={{display: "flex", flexWrap: "wrap", justifyContent: "flex-start", alignItems: "center"}}>
            <div style={{display: "flex", alignItems: "center"}}>
                <img alt="" src={"/courses/cover" + data.course + ".jpg"} width="128px" className="right6"/>
                <Box title={<Translate>Курс</Translate>} value={data.courseName}/>
            </div>
            <Box title={<Translate>Дата</Translate>} value={formatDate(data.orderDate)}/>
            <Box title={<Translate>Замовлення</Translate>} value={<div style={{display: "flex"}}>
                {encodeOrderNumber(data.id)}
                {data.comment && data.comment.length > 0 && <InfoButton text={data.comment} translateText={false}/>}
            </div>}/>
            <Box title={<Translate>Замовник</Translate>} value={<Link to={"/profile/" + data.client}>{data.clientName}</Link>}/>
            <Box title={<Translate>Пакет</Translate>} value={data.pkgName}/>
        </div>
        <OrderSubtotal data={data} platformPercentage={platformPercentage} role={role}/>
        <hr/>
    </>
}

export default CourseOrder