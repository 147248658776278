import { useState } from 'react';
import { TbDotsCircleHorizontal } from 'react-icons/tb';
import { BiEdit } from 'react-icons/bi';
import { MdOutlineDeleteForever } from 'react-icons/md';
import { confirm } from '../../Components/ConfirmFunction';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import WriteComment from './WriteComment';
import { postAPICall } from '../../Components/APICall';
import PacmanLoader from 'react-spinners/PacmanLoader';
import ModalAlert from '../../Components/ModalAlert';
import { useContext } from 'react';
import { AppContext } from '../../AppContext/AppContext';
import { translate } from '../../AppContext/Translate';

function CommentMenu({commentId, msg, groupId, courseId, parentUser, parentId, color="black"}) {
    var {userId, token, dicLang} = useContext(AppContext);
    const [edit, setEdit] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [error, setError] = useState(false);

    const closePopup = () => {
        setEdit(false);
        document.body.click();
    }

    const onDeleteSuccess = (data) => {
        if ("error" in data && data["error"] !== "none") onDeleteError(data);
        else {
            setDeleting(false);
        }
    }

    const onDeleteError = (data) => {
        setDeleting(false);
        setError(true);
    }

    const deleteComment = async () => {
        if (await confirm({confirmation: translate('Ви впевнені, що хочете видалити свій коментар?', dicLang)})) {
            setDeleting(true);
            postAPICall('comments/delete', {userId: userId, token: token, commentId: commentId, groupId: groupId,
                courseId: courseId}, onDeleteSuccess, onDeleteError);
        }
    };

    const menuComment = (
        <Popover>
          <Popover.Body>
            <div className="menuCommentItem" onClick={()=>{ setEdit(true);}}><BiEdit/> {translate('Редагувати коментар', dicLang)}</div>
            <div className="menuCommentItem" onClick={()=>{ deleteComment(); }}><MdOutlineDeleteForever/> {translate('Видалити коментар', dicLang)}</div>
          </Popover.Body>
        </Popover>
    );

    return edit ?
        <WriteComment show={true} setShow={closePopup} groupId={groupId} commentId={commentId} 
            parentUser={parentUser} parentId={parentId} edit={true} msg={msg} courseId={courseId}/>
        :
        (deleting ? <PacmanLoader size={16} color="darkgreen"/>
        :
        <><OverlayTrigger rootClose trigger="click" placement="left" overlay={menuComment}>
            <button className="menuCommentButton"><TbDotsCircleHorizontal color={color} size={18}/></button>
        </OverlayTrigger>
        <ModalAlert type="modal-danger" title="Помилка" msg="ваш коментар не вдалося оновити" 
                buttonCaption="Закрити" show={error} handleClose={() => {setError(false);}}/>
        </>);
}

export default CommentMenu