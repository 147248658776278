function ImageBackground({children, fix = false, image = "library"}) {
    const ext = "jpg";
    const url = "url('/bkg/" + image + "." + ext + "')";
    const st = {width: "100vw", minHeight: "100vh", backgroundImage: url, backgroundSize: "cover"};
    if (fix) {
        st["zIndex"] = "100";
        st["left"] = "0px";
        st["top"] = "0px";
        st["position"] = "fixed";
        return <div style={st}>
            <div style={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%",
                minHeight: "100vh", backgroundColor: "#000000a6"}}>
            {children}
            </div>
        </div> 
    }
    return <div style={st}>
        {children}
    </div>
}

export default ImageBackground