import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Translate from '../../AppContext/Translate';
import { AppContext } from "../../AppContext/AppContext";
import { useContext } from "react";
import { GoDotFill } from 'react-icons/go';

function WSConected({color}) {
    return <GoDotFill color={color}/>
}

function MenuUserIcon({handleShow, size, userName, dropDown=false, children}) {
    var { wsState } = useContext(AppContext);

    var content = (size !== "big" || userName != null) 
        ? <div className='menuDivIcon'><img alt="" src="/bkg/user.png" height="26"/></div>
        : <div className='menuDivText'><Translate>Увійти</Translate></div>;

    return dropDown ? <div>
        <Navbar.Text style={{position: "absolute", top: "-14px", left: "8px"}}><WSConected color={wsState === 1 ? "green" : "red"}/></Navbar.Text>
        <NavDropdown className="menuDropDown" size="lg" title={<img alt="" src="/bkg/user.png" height="26"/>}
            align="end" id="basic-nav-dropdown" style={{marginLeft: "6px"}}>
            {children}
        </NavDropdown>
    </div>
    : <Navbar.Text id="menuCreateAccountButton" onClick={handleShow}>
        {content}
    </Navbar.Text>
}

export default MenuUserIcon