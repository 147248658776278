import { useState, useEffect } from 'react';
import { BsBookmarkPlus, BsFillCheckSquareFill, BsPatchQuestionFill } from 'react-icons/bs';
import { FaRegEye } from 'react-icons/fa';
import { MdWatchLater, MdFavorite } from 'react-icons/md';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { postAPICall } from './APICall';
import { useContext } from 'react';
import { AppContext } from '../AppContext/AppContext';
import Translate from '../AppContext/Translate';

function BookmarkState({context, kind, data, course, refreshData}) {
    const { userId, token } = useContext(AppContext);
    const [element, setElement] = useState(null);

    useEffect(() => {
        const code = kind === null ? -1 : kind;
        var icon;
        switch (code) {
        case 1: icon = <FaRegEye color="royalblue" size="20"/>; break;
        case 2: icon = <MdWatchLater color="brown" size="21"/>; break;
        case 3: icon = <BsPatchQuestionFill color="crimson" size="18"/>; break;
        case 4: icon = <MdFavorite color="blueviolet" size="22"/>; break;
        case 5: icon = <BsFillCheckSquareFill color="green" size="18"/>; break;
        default: icon = <BsBookmarkPlus color="lightgray" size="18"/>;
        }
        switch (context) {
        case "day":
            setElement(<div className='bookmarkDayPane'>{icon}</div>);
            break;
        case "topic":
        case "post":
            setElement(<div className='bookmarkPostPane'>{icon}</div>);
            break;
        default:
            setElement(null);
        }        
    }, [kind, context]);

    const changeState = (newState) => {
        document.body.click();
        var params = {userId: userId, token: token, state: newState, number: data, course: course};
        switch (context) {
            case "day":   params["kind"] = 1; break;
            case "post":  params["kind"] = 2; break;
            case "topic": params["kind"] = 0; break;
            default:      params = null;
        }
        if (params) {
            postAPICall('bookmarks/add', params, () => { refreshData(); });
        }
    }

    const popover = (
        <Popover style={{maxWidth: "none"}}>
          <Popover.Body>
            <div style={{display: "flex", width: "382px", justifyContent: "space-between", alignItems: "center"}}>
                <div className="bookmarkOption" onClick={() => changeState(1)}>
                    <p style={{margin: "11px 0px 6px 0px"}}><FaRegEye color="royalblue" size="24"/></p>
                    <p><Translate>Переглянуто</Translate></p>
                </div>
                <div className="bookmarkOption" onClick={() => changeState(2)}>
                    <p style={{margin: "11px 0px 6px 0px"}}><MdWatchLater color="brown" size="24"/></p>
                    <p><Translate>В очікуванні</Translate></p>
                </div>
                <div className="bookmarkOption" onClick={() => changeState(3)}>
                    <p style={{margin: "11px 0px 6px 0px"}}><BsPatchQuestionFill color="crimson" size="22"/></p>
                    <p><Translate>Сумніви</Translate></p>
                </div>
                <div className="bookmarkOption" onClick={() => changeState(4)}>
                    <p style={{margin: "11px 0px 6px 0px"}}><MdFavorite color="blueviolet" size="24"/></p>
                    <p><Translate>Улюблений</Translate></p>
                </div>
                <div className="bookmarkOption" onClick={() => changeState(5)}>
                    <p style={{margin: "11px 0px 6px 0px"}}><BsFillCheckSquareFill color="green" size="18"/></p>
                    <p><Translate>Готово</Translate></p>
                </div>
            </div>
          </Popover.Body>
        </Popover>
    );

    return element ?
        <OverlayTrigger rootClose trigger="click" placement="bottom" overlay={popover}>
            {element}
        </OverlayTrigger>
        : <></>
}

export default BookmarkState