import { Box } from "../../User/Orders/Order";
import Translate from "../../AppContext/Translate";
import { useContext } from "react";
import { AppContext } from "../../AppContext/AppContext";

function ReportSubtotal({total, text="Всього"}) {
    return <div style={{display: "flex", justifyContent: "flex-end", marginTop: "8px"}}>
        <Box title={<Translate>{text}</Translate>} value={total + " грн"} align="right"/>
    </div>
}

export default ReportSubtotal