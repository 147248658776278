import { useRef, useState, useEffect } from "react";
import Split from "react-split";
import { useWindowSize } from "../../AppContext/WindowSize";
import Chat from "../Chat/Chat";
import Translate from "../../AppContext/Translate";
import { getAPICall } from "../../Components/APICall";
import BeatLoader from "react-spinners/BeatLoader";
import CalendarCountdown from "../Calendar/CalendarCountdown";
import Calendar from "../Calendar/Calendar";

export function CalendarMenu() {
    return <div className="topCourseMenu">
        <Translate>Календар</Translate>
    </div>
}

function ScheduledCourseContent({course, student, params, setParams}) {
    const [width,] = useWindowSize();
    const [topic, setTopic] = useState(null);
    const [groupData, setGroupData] = useState(null);
    const chatRef = useRef(null);
    const twoColumns = width >= 920;

    useEffect(() => {
        getAPICall('group/date', {groupId: student.group}, (info) => {
            if (info && info.length > 0) {
                var g = info[0];
                const startDate = new Date(g.startDate).getTime();
                const currentDate = new Date(g.today).getTime();
                g['difference'] = currentDate - startDate;
                setGroupData(g);
            }
        });
    }, [student.group]);

    const onEndCountdown = () => {
        getAPICall('group/info', {groupId: student.group}, (info) => {
            if (info && info.length > 0) {
                var g = info[0];
                const startDate = new Date(g.startDate).getTime();
                const currentDate = new Date(g.today).getTime();
                g['difference'] = currentDate - startDate;
                setGroupData(g);
            }
        });
    }

    const changeTopic = (topic) => {
        setTopic(topic);
        chatRef.current.scrollIntoView();
    }

    return groupData || !student.student ? <div style={{marginBottom: "16px", borderBottom: "6px solid #eeeeee"}}>
    {twoColumns ? <Split className="split" minSize={275} gutterSize={6} sizes={[50, 50]}>
        <div style={{paddingRight: "16px"}}>
            <CalendarMenu />
            {groupData && groupData.difference < 0 &&
                <CalendarCountdown groupData={groupData} onEndCountdown={onEndCountdown} /> }
            <Calendar course={course.id} numDays={course.numDays} groupData={groupData} pageParams={params}
                setTopicInfo={changeTopic} params={params}/>
        </div>
        <Chat course={course} topic={topic} style={{marginLeft: "12px"}} chatRef={chatRef}
            student={student} pageParams={params} setParams={setParams} />
    </Split> : <div>
        <CalendarMenu />
        {groupData && groupData.difference < 0 &&
            <CalendarCountdown groupData={groupData} onEndCountdown={onEndCountdown} /> }
        <Calendar course={course.id} numDays={course.numDays} groupData={groupData} pageParams={params}
            setTopicInfo={changeTopic} params={params}/>
        <Chat course={course} topic={topic} style={{marginTop: "16px"}} chatRef={chatRef}
            student={student} pageParams={params} setParams={setParams}/>
    </div>}
    </div> : <div><BeatLoader size={24} color="#0d6efd" /></div>
}

export default ScheduledCourseContent