import { useParams } from "react-router-dom";
import { Navigate } from "react-router-dom";
import Menu from "../Menu/Menu";
import UserCover from "./UserCover";
import { useState, useEffect } from "react";
import { getAPICall } from "../Components/APICall";
import Translate from "../AppContext/Translate";
import Project from "../Course/Projects/Project";
import DocPreview from "../Components/DocPreview";
import Footer from "../Components/Footer";
import MyProfileButton from "../Components/MyProfileButton";
import HomeButton from "../Components/HomeButton";

function PublicProfileProjects() {
    const user = useParams()["id"];
    const [projects, setProjects] = useState([]);
    const [userName, setUserName] = useState("");
    const [preview, setPreview] = useState(null);

    const onPreview = (data) => {
        setPreview(data);
    }

    useEffect(() => {
        const projectsLoaded = (data) => {
            if (data) setProjects(data);
        }
        const getNameSuccess = (data) => {
            if (data.length === 1) setUserName(data[0].name);
        }
        if (user && user >= 0) {
            getAPICall('user/name', {user: user}, getNameSuccess);
            getAPICall('user/projects', {user: user}, projectsLoaded);
        }
    }, [user]);

    return !user || user < 0 ? <Navigate to="/" replace={true}/> : <>
     {preview && <DocPreview kind={preview["kind"]} name={preview["name"]} setPreview={setPreview} path={preview["path"]}/>}
     <div className='mainContainer' style={{backgroundColor: "#fef5de"}}>
        <Menu />
        <UserCover user={user} allowChange={false}/>
        <h1 style={{textAlign: "right"}} className="pad12"><Translate>Проекти</Translate></h1>
        <div className="userProfileContent" style={{marginBottom: "32px"}}>
            <div style={{height: "16px"}}/>
            {projects.length === 0 ?
            <p className="large top8">{userName} <Translate>ще не опублікував/-ла жодного проекту</Translate>.</p> :
            <div>
                <p className="large top8"><Translate>Проекти</Translate> {userName}:</p>
                <div style={{backgroundColor: "black", height: "2px", width: "80%", margin: "4px 0px 16px 0px"}}/>
                <div style={{display: "flex", flexWrap: "wrap"}} className="top18">
                    {projects.map((data, index) => <Project key={index} data={data} onPreview={onPreview}/>)}
                </div>
            </div>
            }
        </div>
        <div style={{display: "flex", justifyContent: "space-evenly", flexWrap: "wrap",
            backgroundColor: "white", margin: "8px", borderRadius: "4px"}} className="pad12">
            <div style={{margin: "4px"}}><MyProfileButton/></div>
            <div style={{margin: "4px"}}><HomeButton/></div>
        </div>
        <Footer/>
    </div>
    </>
}

export default PublicProfileProjects