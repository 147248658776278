import { useWindowSize } from "../AppContext/WindowSize";

function Banner({srcImg, title, bkgColor="white", down=true}) {
    const [width,] = useWindowSize();

    return <div>
        <div style={{color: "white"}}>
            <img alt="" src={srcImg} style={{width: "100%", maxHeight: "200px", objectFit: "cover", objectPosition: "top"}}/>
            {(width >= 460 || !down) && <div style={{position: "absolute", zIndex: 100, left: "12px", top: "12px", maxWidth: "50%"}}>
                {title}
            </div>}
        </div>
        {(width < 460 && down) && <div className="pad12" style={{color: "black", backgroundColor: bkgColor}}>
            {title}
        </div>}
    </div>
}

export default Banner