import { useParams } from "react-router-dom";
import { Navigate } from "react-router-dom";
import Menu from "../Menu/Menu";
import UserCover from "./UserCover";
import { useEffect, useState } from "react";
import { getAPICall } from "../Components/APICall";
import Translate from "../AppContext/Translate";
import Footer from "../Components/Footer";
import CourseCard from "./Activity/ActivityCourseInfo";
import MyProfileButton from "../Components/MyProfileButton";
import HomeButton from "../Components/HomeButton";

function PublicProfileCourses() {
    const user = useParams()["id"];
    const [purchased, setPurchased] = useState([]);
    const [userName, setUserName] = useState("");

    useEffect(() => {
        const coursesLoaded = (data) => {
            if (data) {
                setPurchased(data);
            }
        }
        const getNameSuccess = (data) => {
            if (data.length === 1) setUserName(data[0].name);
        }
        if (user && user >= 0) {
            getAPICall('user/name', {user: user}, getNameSuccess);
            getAPICall('user/courses', {user: user}, coursesLoaded);
        }
    }, [user]);

    return !user || user < 0 ? <Navigate to="/" replace={true}/> : 
    <div className='mainContainer' style={{backgroundColor: "#fef5de"}}>
        <Menu />
        <UserCover user={user} allowChange={false}/>
        <h1 style={{textAlign: "right"}} className="pad12"><Translate>Курси</Translate></h1>
        <div className="userProfileContent" style={{marginBottom: "32px"}}>
            <div style={{height: "16px"}}/>
            {purchased.length === 0 ?
            <p className="large top8">{userName} <Translate>ще не бере участі в жодному курсі</Translate>.</p>
            : purchased.length > 0 && <>
                <p className="large">
                    <img alt="" src="/icons/course.png" height="28px" className="right6"/>
                    {userName} <Translate>бере участь у наступних курсах</Translate>:</p>
                <div style={{backgroundColor: "black", height: "2px", width: "80%", margin: "4px 0px 16px 0px"}}/>
                <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-around"}}>
                    {purchased.map((course, i) => {
                        return <CourseCard key={i} course={course.course}/>
                    })}
                </div>
            </>
            }
        </div>
        <div style={{display: "flex", justifyContent: "space-evenly", flexWrap: "wrap",
            backgroundColor: "white", margin: "8px", borderRadius: "4px"}} className="pad12">
            <div style={{margin: "4px"}}><MyProfileButton/></div>
            <div style={{margin: "4px"}}><HomeButton/></div>
        </div>
        <Footer/>
    </div>
}

export default PublicProfileCourses