import { useState, useContext, useEffect } from 'react';
import Translate, { translate } from '../../AppContext/Translate'
import { getAPICall, postAPICall } from '../../Components/APICall';
import { AppContext } from '../../AppContext/AppContext';
import UserPhotoIcon from '../../Components/UserPhotoIcon';
import SchoolCover from '../../School/SchoolCover';
import { MediaPath } from '../../Components/MediaPath';
import PostMessage from '../../Components/PostMessage';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { confirm } from '../../Components/ConfirmFunction';
import WriteHelper from '../../Components/WriteHelper';
import { useNavigate } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';

function CabSchoolAproval({school}) {
    var { dicLang, userId, token } = useContext(AppContext);
    const [schoolData, setSchoolData] = useState(null);
    const [comment, setComment] = useState(school.adminComment === null ? "" : school.adminComment);
    let navigate = useNavigate(); 

    useEffect(() => {
        getAPICall('school/data', {school: school.id}, (data) => {
            if (data && data.length > 0) {
                setSchoolData(data[0]);
            }
        });
    }, [school]);

    var path = new MediaPath(MediaPath.kind_publicSchool);
    path.add(school.id);
    path.addStr('desc');

    const commentChange = (event) => {
        setComment(event.target.value);
    }

    const approveSchool = async () => {
        if (await confirm({confirmation: translate('Ви впевнені, що хочете затвердити школу?', dicLang)})) {
            postAPICall('admin/approveSchool', {school: school.id, userId: userId, token: token, schoolOwner: school.adminUser, 
                comment: WriteHelper.filterSimpleText(comment)},
                (data) => {
                    navigate('/load/cabinet/admin');
                },
                (data) => {
                    alert(translate("Помилка", dicLang) + ": " + translate("не вдалося зберегти зміни", dicLang));
                });
        }
    }

    const rejectSchool = async () => {
        if (await confirm({confirmation: translate('Ви впевнені, що хочете відхилити школу?', dicLang)})) {
            postAPICall('admin/rejectSchool', {school: school.id, userId: userId, token: token, schoolOwner: school.adminUser,
                comment: WriteHelper.filterSimpleText(comment)},
                (data) => {
                    navigate('/load/cabinet/admin');
                },
                (data) => {
                    alert(translate("Помилка", dicLang) + ": " + translate("не вдалося зберегти зміни", dicLang));
                });            
        }
    }

    return <Accordion style={{marginTop: "8px", marginBottom: "8px"}}>
        <Accordion.Item eventKey="0">
            <Accordion.Header>
                <div>
                    <h4><Translate>Школа очікує схвалення</Translate></h4>
                    <div style={{display: "flex", alignItems: "flex-start", justifyContent: "left", marginTop: "14px"}}>     
                        <img className="medSchoolLogo" style={{marginRight: "16px", marginBottom: "0px"}} 
                            alt="Logo" src={'/schools/logo' + school.id + '.png'}/>     
                        <h1 style={{marginTop: "16px"}}>{school.title}</h1>
                    </div>
                </div>
            </Accordion.Header>
            <Accordion.Body>
                <div className='cabSchoolCard' style={{maxWidth: "600px", margin: "auto"}}>
                    <div style={{overflow: "hidden"}}>
                        <SchoolCover school={school.id} allowChange={false}/>
                    </div>
                    <div style={{padding: "16px"}}>
                        {schoolData && schoolData.description && schoolData.description.length > 0 && 
                            <PostMessage msg={schoolData.description} path={path} open={false} sep={false}
                            textStyle="textPost"/>
                        }
                    </div>
                </div>
                <div style={{display: "flex", justifyContent: "space-around", marginTop: "16px"}}>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <UserPhotoIcon user={school.adminUser} userName={school.name} big={true}/>
                        <p style={{fontWeight: "600"}}>{school.name}</p>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-evenly"}}>
                        <Button variant="primary" onClick={approveSchool}><Translate>Прийняти</Translate></Button>
                        <Button variant="secondary" onClick={rejectSchool}><Translate>Скасувати</Translate></Button>
                    </div>
                </div>
                <Form>
                    <Form.Group className="mb-3" controlId={"tas" + school.id}>
                        <Form.Label><Translate>Додайте коментар</Translate>:</Form.Label>
                        <Form.Control as="textarea" rows={3} defaultValue={comment} maxLength="1000" onChange={commentChange} />
                    </Form.Group>
                </Form>
            </Accordion.Body>
        </Accordion.Item>
    </Accordion>
}

export default CabSchoolAproval