import { useContext, useState, useEffect } from "react";
import { AppContext } from "../../AppContext/AppContext";
import { Navigate } from "react-router-dom";
import Menu from "../../Menu/Menu";
import Footer from "../../Components/Footer";
import { getAPICall } from "../../Components/APICall";
import Translate from "../../AppContext/Translate";
import SchoolForm from "./SchoolForm";
import CabTitle from "../CabTitle";
import CabHomeButton from "../CabHomeButton";
import Banner from "../../Components/Banner";

function CabSchool() {
    const { userId, token } = useContext(AppContext);
    const [school, setSchool] = useState(null);

    useEffect(() => {
        const getSchoolOk = (data) => {
            if (data && data.length > 0) {
                setSchool(data);
            }
        }
        getAPICall('cabinet/myschool', {userId: userId, token: encodeURIComponent(token)}, getSchoolOk);
    }, [userId, token]);

    return userId < 0 ? <Navigate to="/" replace={true} /> :
    <div className='mainContainer' style={{backgroundColor: "white"}}>
        <Menu/>
        <Banner srcImg='/bkg/banners/school.jpg' down={false}
            title={<img alt="" src="/icons/school.png" width="148px" style={{maxWidth: "30vw"}}/>}/>
        <div style={{padding: "10px"}}>
            {school === null && <>
            <p style={{fontWeight: "600"}}><Translate>Ви ще не створили свою школу</Translate>.</p>
            <p><Translate>Якщо ви плануєте створити власні курси, вам потрібно спочатку створити школу</Translate>.</p>
            <hr/>
            </>}
            <CabTitle title="Школа"/>
            <div style={{overflow: "hidden"}}>
                <SchoolForm school={school} setSchool={setSchool}/>
            </div>
        </div>
        <CabHomeButton/>
        <Footer/>
    </div>
}

export default CabSchool