import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Translate, { formatDate } from "../../AppContext/Translate";
import { getAPICall } from "../../Components/APICall";
import { AppContext } from "../../AppContext/AppContext";

const reactionIcons = ["/icons/like.png", "/icons/love.png", "/icons/interesting.png", "/icons/amazed.png"];

function PostReactions({reactions, numComments, changeExpanded, postId, student, onComment=false, textColor="black"}) {
    const { userId, token } = useContext(AppContext);
    const [show, setShow] = useState(false);
    const [allReactions, setAllReactions] = useState(null); 
    const size = onComment ? "22px" : "26px";
    const textSty = onComment ? {cursor: "pointer", fontSize: "smaller", paddingTop: "2px"} : {cursor: "pointer"};

    const getReactionsSuccess = (data) => {
        setAllReactions(data);
        setShow(true);
    }
    const showAllReactions = () => {
        getAPICall('reactions/postReactions', {userId: userId, token: encodeURIComponent(token),
            postId: postId, onComment: onComment ? 1 : 0, groupId: student.group}, getReactionsSuccess);
    }

    return <>
        <Modal show={show} onHide={() => setShow(false)} centered>
            <Modal.Header closeButton><Modal.Title><Translate>Реакції</Translate></Modal.Title></Modal.Header>
            <Modal.Body style={{overflow: "hidden", display: "inline-block", verticalAlign: "top",
                backgroundColor: "whitesmoke", borderRadius: "8px"}}>
                <div style={{overflowY: "scroll", maxHeight: "75vh", backgroundColor: "white", padding: "8px"}}>
                {allReactions && allReactions.map((reaction, index) => (
                    <div key={index} style={{margin: "8px 4px 8px 4px", display: "flex", justifyContent: "space-between"}}>
                        <div>
                            <img src={reactionIcons[reaction["kind"] - 1]} alt="" width={size} height={size}
                                style={{marginRight: "8px"}}/>
                            <Link style={{color: "inherit"}} to={"/profile/" + reaction["user"]}>{reaction["name"]}</Link>
                        </div>
                        <div className="chatUserName">
                            {formatDate(reaction["reactionDate"])}
                        </div>
                    </div>
                ))}
                </div>
            </Modal.Body>
        </Modal>
        <div style={{display: "flex", justifyContent: "space-between", color: textColor}}>
            <div>
                {reactions.map((reaction, index) => (
                <div key={index} className="singleReaction" onClick={showAllReactions}>
                   <img src={reactionIcons[reaction["kind"] - 1]} alt="" width={size} height={size}/>
                   {reaction["numUsers"]}
                </div>
            ))}
            </div>
            {numComments > 0 &&
            <div style={textSty} onClick={changeExpanded}>
                {numComments}{' '}<Translate>{numComments === 1 ? "коментар" : "коментарі"}</Translate>
            </div>
            }
        </div>
    </>
}

export default PostReactions