import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';

function MenuBrand({bar = true, link="/", invert=false}) {
    const sty = invert ? {marginLeft: "10px", filter: "invert(1)"} : {marginLeft: "10px"}; 
    const img = <img alt="" src="/bkg/brandw.png" width="164" className="d-inline-block align-top" style={sty}/>;
    const lnkst = {color: "inherit"};
    if (link) {
        return bar ? 
        <Navbar.Brand style={{color: "#c6ba89"}}><Link style={lnkst} to={link}>{img}</Link></Navbar.Brand> :
        <Link style={lnkst} to={link}>{img}</Link>;
    } else {
        return bar ? 
            <Navbar.Brand style={{color: "#c6ba89"}}>{img}</Navbar.Brand> :
            <>{img}</>;
    }
}

export default MenuBrand