import Modal from 'react-bootstrap/Modal';

function VideoPreview({video, setVideo, title}) {
    return video && <Modal show={video !== null} fullscreen={true} onHide={() => setVideo(null)}
        dialogClassName="videoOpinionDialog" contentClassName="videoOpinionContent">
        <Modal.Header closeButton closeVariant="white">
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{overflowY: "hidden"}}>
            <video width="100%" height="100%" controls autoPlay>
                <source src={video} type="video/mp4"/>
            </video>
        </Modal.Body>
    </Modal>
}

export default VideoPreview