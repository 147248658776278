import { useState, lazy, Suspense } from "react";
import { getAPIurl } from "../Components/APICall";
import Button from "react-bootstrap/Button";
import { HiPhotograph } from 'react-icons/hi';
import Translate from '../AppContext/Translate';

const UploadPicture = lazy(() => import('../Components/UploadPicture'));

function SchoolCover({school, allowChange = false}) {
    const [foundImg, setFoundImg] = useState(true);
    const [showChangeCover, setShowChangeCover] = useState(false);
    const [coverUrl, setCoverUrl] = useState(getAPIurl('school/cover', {school: school}));
    const [number, setNumber] = useState(1);

    const forceUpdate = () => {
        setNumber(current => current + 1);
        setCoverUrl(getAPIurl('school/cover', {school: school}) + '?n=' + number);
        setFoundImg(true);
    };
    
    return <div className="userCoverDiv">
        {foundImg ?
        <img className="userCoverImg" src={coverUrl} alt="" onError={()=>setFoundImg(false)} /> : 
        <svg className="userCoverImg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2799 699" width="100%" 
            height="100%" preserveAspectRatio="none">
            <rect width="100%" height="100%" fill="#696969" />
        </svg>}
        {allowChange && <>
         <Button variant="secondary" size="sm" className="userButtonCoverChange" onClick={()=>{setShowChangeCover(true);}}>
            <HiPhotograph size="18" style={{marginRight: "4px", marginTop: "-2px"}}/>
            <Translate>Змінити обкладинку</Translate>
        </Button>
        <Suspense>
            <UploadPicture show={showChangeCover} handleClose={()=>{setShowChangeCover(false);}} width={2800} height={400} 
                title="Фото на обкладинці" apiFnc="school/cover" forceUpdate={forceUpdate} params={{school: school}}/>
        </Suspense>
        </>
        }
    </div>
}

export default SchoolCover