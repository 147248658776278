import { getAPIurl } from "../../Components/APICall";
import { useState } from "react";
import Form from 'react-bootstrap/Form';
import Translate, {translate} from "../../AppContext/Translate";
import { BiTrash } from "react-icons/bi";
import Button from "react-bootstrap/Button";
import NumericInput from "../../Components/NumericInput";

function TeamMember({member, index, setMain, setRole, setPercentage, deleteMember, dicLang, isDirector,
    setChanged}) {
    const [foundImg, setFoundImg] = useState(true);
    const url = getAPIurl('media/userPhoto', {user: member.teacher});

    return <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-around",
        borderBottom: "solid thin #cfe2ff", marginBottom: "8px", paddingBottom: "8px", position: "relative"}}>
        <div style={{display: "flex", alignItems: "center", flexDirection: "row"}}>
            <Button disabled={!isDirector} variant="link" style={{position: "absolute", right: "0px", top: "0px"}} 
                onClick={() => { deleteMember(index); }}><BiTrash/></Button>
            <img alt={member.name} src={foundImg ? url : "/icons/nouser.png"} className="teacherImg" 
                style={{marginRight: "16px"}} onError={()=>setFoundImg(false)}/>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%", position: "relative"}}>
                <p className="teacherName">{member.name}</p>
                <Form.Check checked={member.main === 1} type="switch" disabled={!isDirector}
                    id={"switch" + member.teacher} label={translate("Головний", dicLang)}
                    onChange={() => { setMain(index, member.main === 1 ? 0: 1); }}/>
            </div>
        </div>
        <div style={{paddingTop: "8px"}}>
            <p className="percentage top4" style={{marginBottom: "4px"}}><Translate>Відсоток гонорару курсу</Translate></p>
            <NumericInput value={member.percentage} maxWidth={120} step={1} max={100} precision={0} isDisabled={!isDirector}
                onChange={(value) => { setPercentage(index, value); setChanged(true); }} />
            <Form.Label style={{marginBottom: "0px"}}><Translate>Роль</Translate></Form.Label>
            <Form.Control value={member.role} disabled={!isDirector} type="text" id={"inputRole" + member.teacher}
                onChange={(e) => { setRole(index, e.target.value); setChanged(true); }}/>
        </div>
    </div>
}

export default TeamMember
