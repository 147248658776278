import { API_URL } from "../serverData";
import axios from 'axios';

export function getAPIurl(apiFnc, params) {
    var url = apiFnc.startsWith('http') ? apiFnc : API_URL + apiFnc; 
    if (params.size !== 0) {
        var sep = '?';
        for (const [key, value] of Object.entries(params)) {
            url += sep + key + '=' + value;
            sep = '&';
        };
    }
    return url;
}

export function getAPICall(apiFnc, params, setFnc, errorFnc = null) {
    var url = apiFnc.startsWith('http') ? apiFnc : API_URL + apiFnc; 
    if (params.size !== 0) {
        var sep = '?';
        for (const [key, value] of Object.entries(params)) {
            url += sep + key + '=' + value;
            sep = '&';
        };
    }
    axios.get(url, {headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
    },}).then(response => {
        setFnc(response.data);
    }, error => {
        if (errorFnc !== null) errorFnc(error);
    });
}

export function postAPICall(apiFnc, params, setFnc, errorFnc = null) {
    var url = apiFnc.startsWith('http') ? apiFnc : API_URL + apiFnc; 
    axios.post(url, params, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
    }).then(response => {
        setFnc(response.data);
    }, error => {
        if (errorFnc !== null) errorFnc(error);
    });
}

export function postAPIUpload(apiFnc, content, params, setFnc, errorFnc = null) {
    var url = apiFnc.startsWith('http') ? apiFnc : API_URL + apiFnc;
    let formData = new FormData();
    if (content) formData.append('file', new File([content.buffer], content.name, {type: content.buffer.type}));
    formData.append('params', JSON.stringify(params));
    axios.post(url, formData, {
        headers: {
            'Content-Type': `multipart/form-data;boundary=${formData._boundary}`,
        },
    }).then(response => {
        setFnc(response.data);
    }, error => {
        if (errorFnc !== null) errorFnc(error);
    });
}

export function postAPIUploadProgress(apiFnc, content, params, setFnc, errorFnc = null, progressFnc = null) {
    var url = apiFnc.startsWith('http') ? apiFnc : API_URL + apiFnc;
    let formData = new FormData();
    if (content) {
        formData.append('file', new File([content.buffer], content.name, {type: content.buffer.type}));
    }
    var contentSize = content ? content.buffer.length : 1;
    const config = {
        headers: {
            'Content-Type': `multipart/form-data;boundary=${formData._boundary}`,
        },
        onUploadProgress: progressEvent => {
            var percent = Math.round(100 * (progressEvent.loaded / contentSize));
            if (percent > 100) percent = 100;
            if (progressFnc) progressFnc(percent);
        }
    }
    formData.append('params', JSON.stringify(params));
    axios.post(url, formData, config).then(response => {
        if (progressFnc) progressFnc(100);
        setFnc(response.data);
    }, error => {
        if (errorFnc !== null) errorFnc(error);
    });
}

export function downloadFileFromURL(url, filename, setFnc = null, errorFnc = null) {
    fetch(url)
    .then(response => {
        response.blob().then(blob => {
            let bloburl = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = bloburl;
            a.download = filename;
            a.click();
            if (setFnc !== null) setFnc();
        });
    }).catch(error => {
        if (errorFnc !== null) errorFnc(error);
    });
}
