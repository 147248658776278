import { useContext, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { AppContext } from "../../AppContext/AppContext";

function Logout() {
    const {logout} = useContext(AppContext);
    useEffect(() => {
        logout();
    }, [logout]);
    return <Navigate to="/" replace={true} />
}

export default Logout