import { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { getAPICall } from "../../Components/APICall";
import Menu from "../../Menu/Menu";
import CourseMenu from "../CourseMenu";
import Footer from "../../Components/Footer";
import ShowProjects from "./ShowProjects";
import Translate from "../../AppContext/Translate";

function CourseProjects() {
    var courseId = useParams()["id"];
    const [allowed, setAllowed] = useState(null);
    const [courseName, setCourseName] = useState(null);
        
    useEffect(() => {
        if (!courseId || courseId < 0) setAllowed(false);
        else {
            getAPICall('courses/name', {courseId: courseId}, (data) => {
                if (data && data.length > 0) setCourseName(data[0].title);
                else setAllowed(false);
            });
        }
    }, [courseId]);

    
    return allowed !== null && allowed === false ? <Navigate to="/" replace={true} /> :
    <div className='mainContainer' style={{backgroundColor: "white"}}>
        <Menu/>
        <CourseMenu courseId={courseId}/>
        <div className="pad16">
            <h1><Translate>Курсові проекти</Translate></h1>
            {courseName && <h2>{courseName}</h2>}
        </div>
        {courseName && <ShowProjects courseId={courseId} courseName={courseName}/>}
        <Footer/>
    </div>
}

export default CourseProjects