import { useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import { HiPhotograph } from 'react-icons/hi';
import Translate from "../AppContext/Translate";
import { postAPIUpload } from "../Components/APICall";
import RingLoader from "react-spinners/RingLoader";

function CabUploadLocalFile({path, defaultImage, uploadApi, params, updatable, title, description, dimensions}) {
    const [foundImg, setFoundImg] = useState(true);
    const [number, setNumber] = useState(1);
    const [logoUrl, setLogoUrl] = useState(path + '?n=0');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const inputFile = useRef(null);

    const scale = Math.round(Math.min(dimensions.width, dimensions.height) / 92);
    const iconWidth = Math.round(dimensions.width / scale);
    const iconHeight = Math.round(dimensions.height / scale);

    const onSendSuccess = () => {
        setNumber(current => current + 1);
        setLogoUrl(path + '?n=' + number);
        setFoundImg(true);
        setLoading(false);
    };

    const onButtonClick = () => {
        inputFile.current.click();
    };

    const onSendError = () => {
        setError('Не вдалося оновити зображення');
        setLoading(false);
    }

    const handleFileUpload = e => {
        setError(null);
        const file = e.target.files[0];
        if (file) {
            if (file.type === 'image/png') {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const image = new Image();
                    image.src = e.target.result;
                    image.onload = () => {
                        if (image.width !== dimensions.width || image.height !== dimensions.height)
                        setError('Розміри зображення неправильні');
                    else {
                        setLoading(true);
                        postAPIUpload(uploadApi, {buffer: e.target.result}, params, onSendSuccess, onSendError);
                    }
                    };
                };
                reader.readAsDataURL(file);
            } else setError('Файл не є дійсним зображенням PNG');
        }
    };

    return <div>
        <p style={{fontWeight: "600", margin: "0px"}}><Translate>{title}</Translate></p>
        <p style={{fontSize: "smaller"}}><Translate>{description}</Translate></p>
        <input style={{ display: "none" }} accept=".png" ref={inputFile} onChange={handleFileUpload} type="file"/>
        {error && <p style={{color: "red"}}><Translate>{error}</Translate></p>}
        <div style={{display: "flex", alignItems: "flex-start", justifyContent: "space-between", position: "relative"}}>
            {loading ? <RingLoader/> :
                <img className="medSchoolLogo" alt="Logo" src={foundImg ? logoUrl : defaultImage}
                onError={()=>setFoundImg(false)} style={{width: iconWidth, height: iconHeight}}/>}
            {foundImg && !updatable && !loading ?
            <p style={{maxWidth: "248px", fontStyle: "italic", fontSize: "smaller", color: "cadetblue", marginLeft: "16px"}}>
            <Translate>Це зображення не можна оновити. Якщо вам це потрібно, зверніться до нашої служби підтримки</Translate>.</p>
            :
            <Button disabled={loading} variant="secondary" size="sm" className="userButtonCoverChange"
                onClick={onButtonClick}>
                <HiPhotograph size="18" style={{marginRight: "4px", marginTop: "-2px"}}/>
                <Translate>Оновити</Translate>
            </Button>
            }
        </div>
    </div>
}

export default CabUploadLocalFile