import Form from 'react-bootstrap/Form';
import Translate, { translate } from '../AppContext/Translate';
import { AppContext } from '../AppContext/AppContext';
import { useContext, useState, useEffect } from 'react';
import { getAPICall, getAPIurl, postAPICall, downloadFileFromURL } from '../Components/APICall';
import BeatLoader from 'react-spinners/BeatLoader';
import Button from 'react-bootstrap/Button';

function CertificateLanguage({packageId, diploma}) {
    const { userId, token, dicLang } = useContext(AppContext);
    const [certLang, setCertLang] = useState('ua');
    const [studentName, setStudentName] = useState(null);
    const [newStudentName, setNewStudentName] = useState('');
    const [downloading, setDownloading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const nameSuccess = (data) => {
            if (!data || data.length !== 1) setStudentName('');
            else setStudentName(data[0].name);
        }
        getAPICall('diploma/studentName', {userId: userId, lang: certLang},
            nameSuccess);
    }, [userId, certLang]);

    const updateError = () => {
        setDownloading(false);
        setError(<><Translate>Помилка</Translate>, <Translate>не вдалося зберегти зміни</Translate>.</>);
    };

    const continueDownload = () => {
        const url = getAPIurl('diploma/download', {userId: userId, token: encodeURIComponent(token),
            lang: encodeURIComponent(certLang), packageId: packageId, diploma: diploma ? 1 : 0});
        downloadFileFromURL(url, diploma ? 'diploma.pdf' : 'certificate.pdf',
            () => {setDownloading(false);}, () => {setDownloading(false);});
    };

    const download = () => {
        const name = studentName && studentName.length > 0 ? studentName : newStudentName.trim();
        if (name.length < 3 || name.indexOf(' ') < 0 || name.indexOf('"') >= 0) 
            setError(<><Translate>Помилка</Translate>, <Translate>необхідно вказати ваше ім'я та прізвище</Translate>.</>);
        else {
            setError(null);
            setDownloading(true);
            if (studentName === null || studentName.length <= 0) {
                postAPICall('diploma/studentName', {userId: userId, token: token, lang: certLang, name: name},
                    continueDownload, updateError);
            } else {
                continueDownload();
            }
        }
    }
    
    return <Form>
    <p><Translate>Виберіть мову сертифіката</Translate>:</p>
    <Form.Check defaultChecked type='radio' id='radio-ua' label={translate('Українська', dicLang)} 
        name="langGroup" onChange={() => { setCertLang('ua'); }}/>
    <Form.Check type='radio' id='radio-en' label={translate('Англійська', dicLang)} name="langGroup"
        onChange={() => { setCertLang('en'); }} style={{marginBottom: "24px"}}/>
    {studentName === null ? <BeatLoader/> : <>
        {studentName === '' ? <>
            <p style={{fontWeight: "600", margin: "0px"}}><Translate>Напишіть ім'я та прізвище, які мають бути на ваших дипломах (для диплома на українській мові впишіть дані кирилицею; для диплома на англійській мові – латиницею)</Translate>:</p>
            <p><span style={{fontStyle: "italic", color: "crimson"}}><Translate>Увага</Translate>: </span>
            <Translate>це ім'я не можна буде змінити, оскільки воно повинно бути однаковим в усіх ваших дипломах</Translate>.</p>
            <Form.Control value={newStudentName} size="sm" type="text" placeholder={translate('Nombre completo', dicLang)}  
                maxLength="40" onChange={e => { setNewStudentName(e.target.value); }} />
            { error !== null && <p style={{fontSize: "smaller", color: "crimson"}}>{error}</p> }
        </> : <p><span style={{fontWeight: "600"}}><Translate>Ім'я</Translate>:</span> {studentName}</p>
        }
        <div style={{marginTop: "24px", marginBottom: "8px", textAlign: "center"}}>
            { downloading ? <Button><BeatLoader color="white"/></Button> :
                <Button onClick={download}><Translate>Завантажити</Translate></Button>
            }
        </div>
    </>
    }
    </Form>
}

export default CertificateLanguage