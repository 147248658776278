import { useState, useContext, useEffect } from "react";
import Menu from "../Menu/Menu"
import { getAPICall } from "../Components/APICall";
import { AppContext } from "../AppContext/AppContext";
import Translate, { translate } from "../AppContext/Translate";
import Footer from "../Components/Footer";
import UserPhotoIcon from "../Components/UserPhotoIcon";
import HomeButton from "../Components/HomeButton";
import Banner from "../Components/Banner";
import MyProfileButton from "../Components/MyProfileButton";
import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from 'react-bootstrap/Button';
import UserList from "./Messages/UserList";
import MessagesChat from "./Messages/MessagesChat";

function UserMessage() {
    var {userId, token, dicLang} = useContext(AppContext);
    const [users, setUsers] = useState(null);
    const [show, setShow] = useState(true);
    const [selected, setSelected] = useState(null);

    const getMessagesError = (data) => {
        alert(translate("Помилка", dicLang) + ": " + translate("повідомлення не вдалося отримати", dicLang));
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        getAPICall('user/msgUsers', {userId: userId, token: encodeURIComponent(token)},
            (data) => {
                if (data) setUsers(data);
                else getMessagesError();
            }, getMessagesError);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, token]);

    const buttonOpen = <Button variant="link" className="d-lg-none top8 bot8"
        style={{backgroundColor: "#fac032", textDecoration: "none", color: "black"}} onClick={handleShow}>
        <img alt="" src="/icons/contact.png" height="20px" className="right6"/>
        <Translate>Показати</Translate>
    </Button>

    return <div className='mainContainer' style={{backgroundColor: "#fef5de"}}>
        <Menu/>
        <Banner srcImg='/bkg/banners/messages.jpg'
            title={<h1><Translate>Мої повідомлення</Translate></h1>}/>
            <div style={{display: "flex"}}>
                <Offcanvas show={show} onHide={handleClose} responsive="lg">
                    <Offcanvas.Header closeButton closeVariant="white" style={{backgroundColor: "#212529"}}>
                        <Offcanvas.Title><img alt="" src="/bkg/brandw.png" width="164"/></Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body style={{height: "100%"}}>
                        <div style={{backgroundColor: "white", width: "320px"}} className="pad12">
                            <UserList users={users} setSelected={(data) => {
                                handleClose();
                                setSelected(data);
                            }}/>
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
            <div style={{width: "100%", flex: 1}}>
                {selected ? <>
                    <div style={{display: "flex", backgroundColor: "white", margin: "8px", borderRadius: "4px",
                        justifyContent: "space-between"}} className="pad12">
                        <div style={{display: "flex", alignItems: "center"}}>
                            <UserPhotoIcon user={selected.user} userName={selected.name} big={true}/>
                            <p className="large fkenyan" style={{marginLeft: "6px"}}>{selected.name}</p>
                        </div>
                        <div className="center d-lg-none">
                            <p><Translate>Оберіть бесіду</Translate></p>
                            {buttonOpen}
                        </div>
                    </div>
                    <MessagesChat user={selected.user}/>  
                </> : <div className="center pad32">
                    <p><Translate>Оберіть бесіду</Translate></p>
                    {buttonOpen}
                </div>}
                <div style={{display: "flex", justifyContent: "space-evenly", flexWrap: "wrap",
                    backgroundColor: "white", margin: "8px", borderRadius: "4px"}} className="pad12">
                    <div style={{margin: "4px"}}><MyProfileButton/></div>
                    <div style={{margin: "4px"}}><HomeButton/></div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
}

export default UserMessage