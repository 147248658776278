import { Link } from "react-router-dom"
import Translate from "../../AppContext/Translate"

function EditButton() {
    return <Link to="/user/profile">
        <button style={{backgroundColor: "#12c191", color: "white", border: "none", padding: "3px 10px"}}>
            <img alt="edit" src="/icons/edit.png" width="22px" className="right6"/><Translate>Редагувати профіль</Translate>
        </button>
    </Link>
}

export default EditButton