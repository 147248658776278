import { useContext } from "react";
import { AppContext } from "../../AppContext/AppContext";
import { useNavigate } from "react-router-dom";
import { confirm } from "../../Components/ConfirmFunction";
import { postAPICall } from "../../Components/APICall";
import Translate, { translate } from "../../AppContext/Translate";
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import { Box } from "../../User/Orders/Order";
import UserPhotoIcon from "../../Components/UserPhotoIcon";

function CabPaymentRequest({payment}) {
    const { dicLang, userId, token } = useContext(AppContext);
    let navigate = useNavigate(); 

    const pay = async () => {
        if (await confirm({confirmation: translate('Ви впевнені, що платіж здійснено?', dicLang)})) {
            postAPICall('payment/approve', {userId: userId, token: token, user: payment.user, 
                month: payment.month, year: payment.year},
                (data) => {
                    navigate('/load/cabinet/admin');
                },
                (data) => {
                    alert(translate("Помилка", dicLang) + ": " + translate("не вдалося зберегти зміни", dicLang));
                });
        }
    }

    const months = translate("Січень Лютий Березень Квітень Травень Червень Липень Серпень Вересень Жовтень Листопад Грудень", dicLang).split(' ');

    return <Accordion style={{marginTop: "8px", marginBottom: "8px"}}>
        <Accordion.Item eventKey="0">
            <Accordion.Header>
                <h4><Translate>Запит на оплату</Translate></h4>
            </Accordion.Header>
            <Accordion.Body>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div style={{display: "flex", flexWrap: "wrap"}}>
                        <Box title={<Translate>Бенефіціар</Translate>}
                            value={<div style={{display: "flex", alignItems: "center"}}>
                                <UserPhotoIcon user={payment.user} userName={payment.holder}/>
                                <span style={{marginLeft: "6px"}}>{payment.holder}</span>
                            </div>}/>
                        <Box title={<Translate>Дата</Translate>} value={<div style={{marginTop: "4px"}}>
                            {months[payment.month - 1] + '/' + payment.year}</div>}/>
                        <Box title={<Translate>IBAN</Translate>} value={<div style={{marginTop: "4px"}}>
                            {payment.iban}</div>}/>
                    </div>
                    <Box title={<Translate>Сума</Translate>} value={<div style={{marginTop: "4px"}}>
                        {payment.amount + " грн"}</div>} align='right'/>
                </div>
                <div style={{textAlign: "center", marginTop: "16px"}}>
                    <Button variant="primary" onClick={pay}><Translate>Позначити як оплачено</Translate></Button>
                </div>
            </Accordion.Body>
        </Accordion.Item>
    </Accordion>
}

export default CabPaymentRequest