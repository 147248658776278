import OpaqueBackground from "../../Components/OpaqueBackground";
import MenuUserIcon from "./MenuUserIcon";
import BeatLoader from  "react-spinners/BeatLoader";

function MenuLoginGetGoogleProfile({handleShow, size, userName}) {
    return <>
        <MenuUserIcon handleShow={handleShow} size={size} userName={userName} />
        <OpaqueBackground>
            <BeatLoader size={24} color="white" />            
        </OpaqueBackground>
    </>
}

export default MenuLoginGetGoogleProfile