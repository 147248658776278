import Card from 'react-bootstrap/Card';
import CardHeader from 'react-bootstrap/CardHeader';
import UserPhotoIcon from '../../Components/UserPhotoIcon';
import CardBody from 'react-bootstrap/CardBody';
import dateFormat from '../../Components/DateFormat';
import Form from 'react-bootstrap/Form';
import Translate, { translate } from '../../AppContext/Translate';
import { useState, useContext } from 'react';
import Button from "react-bootstrap/Button";
import { GrSend } from "react-icons/gr";
import ChooseFileButton from '../../Components/ChooseFileButton';
import validator from 'validator'
import { postAPICall, postAPIUploadProgress } from '../../Components/APICall';
import { AppContext } from '../../AppContext/AppContext';
import UploadProgress from '../../Components/UploadProgress';
import StudentFileForRequest from './StudentFileForRequest';

function CabStudentIndRequest({request, removeRequest}) {
    const { userId, token, dicLang } = useContext(AppContext);
    const [link, setLink] = useState(request.kind === 0 ? '' : null);
    const [progress, setProgress] = useState(null);

    const fileChange = (evnt) => {
        if (!evnt) setLink(null);
        else {
            const file = evnt.target.files[0];
            if (!file) { return; }
            const size = file.size / 1048576; 
            if (size > 10) {
                alert(translate("Помилка", dicLang) + ": " + translate("файли розміром понад 10 Мб не можна завантажувати", dicLang));
                return;
            }
            setLink({name: file.name, size: size, file: file});
        }
    }

    const saveError = (msg=null) => {
        alert(translate("Помилка", dicLang) + ": " + translate("не вдалося зберегти зміни", dicLang));
    }

    const sendLink = () => {
        if (validator.isURL(link)) {
            postAPICall('cabinet/sendIndOptionLink', {userId: userId, token: token, student: request.user,
                course: request.course, code: request.code, link: link, pkgId: request.package}, (data) => {
                    if (data && 'error' in data && data.error === 'none') removeRequest();
                    else saveError();
                }, saveError);
        } else alert(translate("Помилка", dicLang) + ": " + translate("посилання недійсне", dicLang));
    }

    const sendFile = () => {
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
            const { result } = e.target;
            if (result) {
                postAPIUploadProgress('cabinet/sendIndOptionFile', {name: link.name, buffer: result},
                    {userId: userId, token: token, student: request.user, course: request.course, code: request.code,
                    link: link.name, pkgId: request.package}, (data) => {
                        if (data && 'error' in data && data.error === 'none') removeRequest();
                        else saveError();
                    },
                    () => { setProgress(null); saveError(); },
                    (percent) => { setProgress({file: link.name, percent: percent, action: 'upl'});
                });
            } else {
                saveError();
            }
        }
        fileReader.readAsDataURL(link.file);
    }

    return <Card style={{margin: "8px", maxWidth: "384px"}}>
        <CardHeader style={{display: "flex", justifyContent: "space-between"}}>
            <div style={{display: "flex", alignItems: "center"}}>
                <UserPhotoIcon user={request.user} userName={request.name} big={false}/>
                <span>{request.name}</span>
            </div>
            <div style={{fontSize: "small", marginLeft: "8px"}}>{dateFormat(request.requestDate)}</div>
        </CardHeader>
        <CardBody>
            <p style={{margin: "0px", fontSize: "smaller", fontWeight: "600"}}>{request.pkgTitle}</p>
            <p style={{margin: "0px", fontSize: "smaller", color: "cadetblue"}}>{request.pkgTitle}</p>
            <p style={{margin: "0px", fontSize: "smaller", fontStyle: "italic"}}>{request.optionName}</p>
            {request.kind === 0 ? <>
                {request.request === 2 && <StudentFileForRequest request={request}/>}
                <Form.Label style={{fontWeight: "600"}}><Translate>Посилання</Translate></Form.Label>
                <div style={{display: "flex", alignItems: "flex-start", justifyContent: "space-between"}}>
                    <Form.Control value={link} type="text" maxLength={1000} placeholder="https://..." 
                        onChange={(e) => { setLink(e.target.value); }}/>
                    <Button style={{marginLeft: "8px"}} onClick={sendLink}>
                        <GrSend style={{marginTop: "-4px"}} />
                    </Button>
                </div>
            </> : <>
                {request.request === 2 && <StudentFileForRequest request={request}/>}
                <Form.Label style={{fontWeight: "600"}}><Translate>Файл</Translate></Form.Label>
                <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                    <ChooseFileButton ext={null} fileSrc={link} fileChange={(e) => { fileChange(e); }} />
                    {link && <Button style={{marginLeft: "8px"}} onClick={sendFile}><GrSend style={{marginTop: "-4px"}} /></Button>}
                </div>
                <UploadProgress progress={progress}/>
            </>}
        </CardBody>
    </Card>
}

export default CabStudentIndRequest