import { useEffect, useContext, useState } from "react";
import { AppContext } from "../../AppContext/AppContext";
import { AttentionSeeker } from "react-awesome-reveal";
import DocPreview from "../../Components/DocPreview";
import { MediaPath } from "../../Components/MediaPath";
import { Fade } from "react-awesome-reveal";
import UserPhotoIcon from "../../Components/UserPhotoIcon";
import { Link } from "react-router-dom";
import CommentMenu from "./CommentMenu";
import WriteHelper from "../../Components/WriteHelper";
import PostReactions from "./PostReactions";
import { getAPICall } from "../../Components/APICall";
import PostReactionOrComment from "./PostReactionOrComment";
import { formatDate } from "../../AppContext/Translate";

const max_depth = 3;

const getPath = (user, group, id) => {
    var path = new MediaPath(MediaPath.kind_privateCommentPost);
    path.add(user);
    path.add(group);
    path.add(id);
    return path;
}

function CommentBox(props) {
    return <div key={props.index} className="chatCommentPane" style={{marginLeft: props.marginLeft}}>
        <div className="commentCorner"/>
        {!props.last[props.last.length - 1] && <div className="commentLine"/>}
        {props.parentLines}
        <Fade>
        {props.children}
        </Fade>
    </div>
}

function ChatComment({index, comment, student, courseId, postPath, last, allExpanded, highlightComment, parentId,
    parentUser}) {
    const { userId, token, lastNotification } = useContext(AppContext);
    const [elements, setElements] = useState(null);
    const [preview, setPreview] = useState(null);
    const [comments, setComments] = useState([]);
    const [expanded, setExpanded] = useState(false);
    const [reactions, setReactions] = useState([]);
    const [initialized, setInitialized] = useState(false);

    const highlight = highlightComment && highlightComment.length > 0 &&
        highlightComment[highlightComment.length - 1] === comment.id;
    var path = new MediaPath(MediaPath.kind_privateCommentPost);
    path.add(comment.user);
    path.add(student.group);
    path.add(comment.id);
    
    const bounce = (ref) => {
        if (ref && highlight) {
            ref.scrollIntoView();
        }
    }

    const marginLeft = (20 * postPath.length) + "px";
    var parentLines = [];
    var leftStart = 2;
    if (last.length > 2) leftStart -= 20 * (last.length - 2);
    for (var j = 0; j < last.length - 1; j++) {
        if (!last[j]) {
            parentLines.push(<div key={j} className="commentParentLine" style={{left: leftStart + "px"}}/>);
        }
        leftStart += 20;
    }

    var pathForChild = [...postPath];
    pathForChild.push(comment.id);
    const ownComment = userId === comment.user; 
    const bkgColor = ownComment ? "darkseagreen" : "beige";

    const addToLast = (l) => {
        var lastToChild = [...last];
        lastToChild.push(l);
        return lastToChild;
    }

    const changeExpanded = () => {
        setExpanded(!expanded);
    }

    const reachedMaxDepth = postPath.length >= max_depth;

    const validNotification = (n, event, commentId, groupId) => {
        if (event === "reaction") {
            return n !== null && n.msgId === commentId && n.parentPost >= 0 &&
                n.event === event && n.group === groupId;
        } else {
            return n !== null && n.parentPost === commentId && n.parentPost >= 0 &&
                n.event === event && n.group === groupId;
        }
    }

    useEffect(() => {
        const parseMessage = async () => {
            const path = getPath(comment.user, student.group, comment.id);
            const e = await WriteHelper.parseElements(comment.message, path, userId, token,
                "medTextPost", (data) => { setPreview(data); });
            setElements(e);
        }
        parseMessage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [comment]);

    useEffect(() => {
        setExpanded(allExpanded);
    }, [allExpanded]);

    useEffect(() => {
        const reactionsSuccess = (data) => {
            //console.log("Reactions: " + JSON.stringify(data));
            if (data.length === 0 || (data.length > 0 && !("error" in data[0])))
                setReactions(data);
        }
        const commentsSuccess = (data) => {
            if (data.length === 0 || (data.length > 0 && !("error" in data[0]))) {
                //console.log("Comments updated: " + JSON.stringify(data));
                setComments(data);
            }
        }
        if (!reachedMaxDepth && student.group) {
            if (!initialized || validNotification(lastNotification, "reaction", comment.id, student.group)) {
                getAPICall('reactions/list', {userId: userId, token: encodeURIComponent(token), 
                    postId: comment.id, onComment: 1, groupId: student.group}, reactionsSuccess);
            }
            if (!initialized || validNotification(lastNotification, "comment", comment.id, student.group)) {
                getAPICall('comments/list', {userId: userId, token: encodeURIComponent(token), 
                    postId: comment.id, onComment: 1, groupId: student.group}, commentsSuccess);
            }
        }
        if (!initialized) setInitialized(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [comment, userId, token, lastNotification]);

    const content = <div className="chatCommentMessage" style={{backgroundColor: bkgColor}}>
        <div style={{display: "flex", overflowX: "clip"}}>
            <div className="chatPhotoPane">
                <UserPhotoIcon user={comment["user"]} userName={comment["name"]}/>
            </div>
            <div style={{width: "100%"}}>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <Link to={"/profile/" + comment.user}><p className="chatUserName">{comment.name}</p></Link>
                    <p className="chatUserName" style={{textAlign: "right"}}>
                        {formatDate(comment["postDate"])}
                        {ownComment && <>{' '}<CommentMenu commentId={comment.id} msg={comment.message} 
                        groupId={student.group} parentUser={parentUser} courseId={courseId}
                        parentId={parentId}/></>}
                    </p>
                </div>
                {elements !== null && elements}
            </div>
        </div>
        {reactions.length > 0 || comments.length > 0 ? 
            <><hr style={{marginTop: "4px", marginBottom: "2px"}}/>
            <PostReactions reactions={reactions} numComments={comments.length} changeExpanded={changeExpanded}
                postId={comment.id} onComment={true} student={student}/>
            <hr style={{marginTop: "0px", marginBottom: "4px"}}/>
            </> : (student.plan === 1 ? <hr style={{marginTop: "8px", marginBottom: "4px"}}/> : null)
        }
        <PostReactionOrComment student={student} post={comment.id} userId={userId} token={token}
        parentUser={comment.user} setExpanded={setExpanded} showCommentButton={!reachedMaxDepth}
        courseId={courseId} parentPost={parentId}/>
    </div>

    return <div ref={ref => { bounce(ref); }}>
    {preview && <DocPreview kind={preview["kind"]} name={preview["name"]} setPreview={setPreview} path={path}/>} 
    {highlight ? <CommentBox marginLeft={marginLeft} index={index} last={last}>
        <AttentionSeeker effect="bounce">
            {content}
        </AttentionSeeker>
    </CommentBox> :
    <CommentBox marginLeft={marginLeft} index={index} last={last}>
        {content}
    </CommentBox>
    }
    {expanded && comments.map((childComment, index) => (
        <ChatComment key={index} index={index} comment={childComment} postPath={pathForChild} student={student}
        last={addToLast(index === comments.length - 1)} allExpanded={allExpanded}
        highlightComment={highlightComment} courseId={courseId} parentId={comment.id}
        parentUser={comment.user} />
    ))}
    </div>
}

export default ChatComment