import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../AppContext/AppContext";
import { getAPICall } from "../../Components/APICall";
import CabSubtitle from "../CabSubtitle";
import CabCourseApproval from "./CabCourseApproval";
import { PiChalkboardTeacherFill } from "react-icons/pi";

function AdminCourses() {
    const { userId, token } = useContext(AppContext);
    const [courses, setCourses] = useState([]);

    useEffect(() => {
        getAPICall('course/pendant', {userId: userId, token: encodeURIComponent(token)}, (data) => {
            if (data) {
                setCourses(data);
            }
        });
    }, [userId, token]);

    return courses.length > 0 ? <div>
    <CabSubtitle title="Курси" icon={<PiChalkboardTeacherFill/>}/>
    {courses.map((course, i) => {
        return <CabCourseApproval key={i} course={course}/>
    })}
    </div> : null
}

export default AdminCourses