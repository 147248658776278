import UserPhotoIcon from "../../Components/UserPhotoIcon"
import Translate from "../../AppContext/Translate"
import ActivityDate from "./ActivityDate"
import ActivityCourseInfo from "./ActivityCourseInfo";

function CourseCreationActivity({date, course, user, userName}) {
    return <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
        <p style={{margin: "12px 0px 0px 0px"}}><UserPhotoIcon user={user} userName={userName}/></p>
        <p style={{margin: "0px"}}><ActivityDate date={date}/></p>
        <p style={{margin: "0px"}}>{userName}
        <span style={{fontWeight: "600", marginLeft: "6px"}}><Translate>створив/-ла новий курс</Translate>:</span></p>
        <hr style={{width: "100%"}}/>
        <ActivityCourseInfo course={course}/>
    </div>
}

export default CourseCreationActivity