import Button from "react-bootstrap/Button";
import { AiOutlineNotification } from 'react-icons/ai';
import { MdRemoveCircle, MdOutlineCancel } from 'react-icons/md';
import { useRef } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Translate from "../AppContext/Translate";
import MimeIcon from "./MimeIcon";

function ChooseFileButton({ext, fileSrc, fileChange, cancel=false, align="center"}) {
    const hiddenFileInput = useRef(null);
    var accept = null;
    var desc = null;
    var v = [];
    if (ext) {
        v = ext.split("|");
        if (v.length > 0) {
            accept = '.' + v[0];
            desc = v[0];
            for (var i = 1; i < v.length; i++) {
                accept += ',.' + v[i];
                desc += ', ' + v[i];
            }
        }
    }

    const handleClick = event => {
        hiddenFileInput.current.click();
    };

    return <Row>
        <Col style={{display: "flex", flexDirection: "column", alignItems: align}}>
            {fileSrc && 'error' in fileSrc &&
            <p className="textPost" style={{color: "brown"}}><AiOutlineNotification size={24} style={{marginBottom: "6px",
                marginRight: "6px"}}/>{fileSrc.error}.</p>
            }
            {(!fileSrc || 'error' in fileSrc) && <>
            <Button onClick={handleClick}><Translate>Завантажити файл</Translate></Button>
            {cancel && <button className='ChatToolButton' onClick={() => fileChange(null)}>
                <MdOutlineCancel color="maroon" size={20}/>
            </button>}
            <form>
                {accept ?
                    <input type="file" ref={hiddenFileInput} accept={accept} multiple={false} 
                        onChange={fileChange} style={{display: 'none'}}/> :
                    <input type="file" ref={hiddenFileInput} multiple={false} onChange={fileChange}
                        style={{display: 'none'}}/> }
            </form>
            {accept && <p className="smallTextPost">
            <Translate>Допустимі формати</Translate>: <i>{desc}</i> 
            </p>}
            </>
            }
            {fileSrc && !('error' in fileSrc) && <div className="chatPaneUpload">
            <div className="chatImageUpload">
                <MimeIcon name={fileSrc['name']} size={fileSrc['size']}/>
            </div>
            <button className='ChatToolButton' onClick={() => fileChange(null)}>
                <MdRemoveCircle color="maroon" size={20}/>
            </button>
            </div>}
        </Col>
    </Row>
}

export default ChooseFileButton