import { useState } from "react";
import Translate from "../AppContext/Translate";
import { Link } from "react-router-dom";

function Footer() {
    const [openMap, setOpenMap] = useState(false);
    const yellow = {color: "#fac032"};
    return <div className="footer">
        <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-evenly"}}>
            <div className="pad12">
                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <p className="large" style={yellow}>Карта сайту</p>
                    <img alt="" src={"/bkg/" + (openMap ? "upy.png" : "downy.png")} width="24"
                        className="imgButton" onClick={() => { setOpenMap(!openMap); }}/>
                </div>
                {openMap && <div className="top8">
                    <p className="whiteTextHover top4"><Translate>Школи</Translate></p>
                    <p className="whiteTextHover top4"><Translate>Категорії</Translate></p>
                    <p className="whiteTextHover top4"><Translate>Курси</Translate></p>
                    <Link to="/projects"><p className="whiteTextHover top4"><Translate>Проєкти</Translate></p></Link>
                    <p className="whiteTextHover top4"><Translate>Викладачі</Translate></p>
                    <p className="whiteTextHover top4"><Translate>Магазин</Translate></p>
                    <p className="whiteTextHover top4"><Translate>Послуги Платформи</Translate></p>
                </div>}
            </div>
            <div className="pad12" style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <p className="large" style={yellow}>Розмістити свій курс</p>
                <img alt="" src="/bkg/plus.png" width="24" className="imgButton top4"/>
            </div>
            <div className="pad12" style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <p className="large" style={yellow}>Підтримка</p>
                <a className="whiteTextHover" href="mailto:support@magistrika.com">support@magistrika.com</a>
            </div>
            <div className="pad12" style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <p className="large" style={yellow}>Підписуйтесь</p>
                <div className="top4">
                    <img alt="Facebook" src="/bkg/facebook.png" height="24" className="imgButton"/>
                    <a href="www.instagram.com/magistrika"><img alt="Instagram" src="/bkg/instagram.png" height="24" className="left24 imgButton"/></a>
                    <img alt="Telegram" src="/bkg/telegram.png" height="24" className="left24 imgButton"/>
                </div>
            </div>
        </div>
        <div style={{textAlign: "right"}} className={openMap ? "bot8" : "bot8 top24"}>
            <p><Link className="whiteTextHover" to="/terms"><Translate>Публічний договір</Translate></Link> / <Link className="whiteTextHover" to="/privacy"><Translate>Політика конфіденційності</Translate></Link> / <Link className="whiteTextHover" to="/cookies"><Translate>Політика cookies</Translate></Link></p>
        </div>
    </div>
}

export default Footer