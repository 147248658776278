function dateFormat(date) {
    const moment = date instanceof Date ? date : new Date(date);
    return new Intl.DateTimeFormat('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(moment);
}

export function endDateFormat(date, numDays) {
    return dateFormat(date.getTime() + 86400000 * (numDays - 1));
}

export function endDate(date, numDays) {
    return new Date(date.getTime() + 86400000 * (numDays - 1));
}

export function msToDays(ms) {
    return ms / 86400000;
}

export default dateFormat