import { AttentionSeeker } from "react-awesome-reveal"

function ShakeOnError(props) {
    return props.error ?
        <AttentionSeeker effect="shake">
            {props.children}
        </AttentionSeeker>
        : <>
            {props.children}
        </>
}

export default ShakeOnError