import { useState, useContext, useEffect } from "react";
import { FiHeart } from "react-icons/fi";
import { MimeIconNoText } from "../../Components/MimeIcon";
import { getAPICall } from "../../Components/APICall";
import { MediaPath } from "../../Components/MediaPath";
import { AppContext } from "../../AppContext/AppContext";
import { postAPICall } from "../../Components/APICall";
import UserPhotoIcon from "../../Components/UserPhotoIcon";
import { Link } from "react-router-dom";

function Project({data, onPreview, small=false}) {
    const { userId, token } = useContext(AppContext);
    const [likes, setLikes] = useState(data.likes);
    const [thn, setThn] = useState(null);
    const [course, setCourse] = useState(null);

    const likeSuccess = (data) => {
        if (data && "likes" in data) {
            setLikes(data.likes);
        }
    }

    const likeClick = (projectId, user, group, e) => {
        e.stopPropagation(); 
        postAPICall('tasks/likeproject', {userId: userId, token: token, projectId: projectId, user: user, groupId: group},
            likeSuccess);
    }

    useEffect(() => {
        getAPICall('media/projectThumb', {projectId: data.id, userId: userId, token: encodeURIComponent(token)},
            (data) => {
            if (data && 'url' in data) setThn(data.url);                
        });
        getAPICall('package/course', {packageId: data.package}, (data) => {
            if (data) setCourse(data);
        })
    }, [data.id, data.package, userId, token]);

    var path = new MediaPath(MediaPath.kind_privateTaskDelivery);
    path.add(data.user);
    path.add(data.package);
    path.add(data.post);

    return <div className="projectFrame">
            <div className={small ? "projectDivSmall" : "projectDiv"}>
            {thn ? <img alt="" src={thn} style={{width: "100%", cursor: "pointer"}}
                onClick={() => {onPreview({kind: 'f', name: data.file, path: path})}}
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    setThn(null);
                }}/> : 
                <div className="square" style={{cursor: "pointer"}}
                    onClick={() => {onPreview({kind: 'f', name: data.file, path: path})}}>
                    <div className="square-content">
                        <MimeIconNoText name={data.file} width="128px"/>
                    </div>
                </div>}
            <div style={{display: "flex", justifyContent: "center", marginTop: "-48px", marginRight: "8px", zIndex: 100}}>
                <UserPhotoIcon user={data.user} userName={data.name} big={true}/>
            </div>
            <div style={{display: "flex", marginTop: "-4px"}}>
                <div style={{textAlign: "left", width: course ? "50%" : "100%"}}>
                    <p className="large">{data.name}</p>
                    <p className="fkenyan top4">{data.file}</p>
                    {likes > 0 && <p className="top4" style={{color: "lightslategray", cursor: userId >= 0 ? "pointer" : "auto"}}
                        onClick={(e) => { likeClick(data.id, data.user, data.classgroup, e); }}>
                        <FiHeart style={{marginTop: "-3px"}}/>{likes}
                    </p>}
                </div>
                {course &&
                <div style={{width: "50%", textAlign: "center", color: "gray"}}>
                    <Link to={"/course/" + course.course}><p className="small blackTextHover italic">{course.courseTitle}</p></Link>
                    <Link className="schoolLink" to={"/school/" + course.id}><p className="small fkenyan">{course.title}</p></Link>
                </div>}
            </div>
        </div>
    </div>
}

export default Project