import { useEffect, useContext } from "react";
import { AppContext } from "../../AppContext/AppContext";
import { getAPICall } from "../../Components/APICall";
import Translate from "../../AppContext/Translate";
import ExpandButton from "../../Components/ExpandButton";
import CabCourseCard from "./CabCourseCard";

function CabCourseSelection({coursesData, setCoursesData, expanded, setExpanded, selectCourse,
    title="Виберіть курс", allowNewCourses=false, setSchool=null, courseParam=null}) {
    const { userId, token } = useContext(AppContext);

    useEffect(() => {
        setCoursesData(allowNewCourses ? {'-1': {id: -1, lang: 'ua', description: '', goals: '', 
            audience: '', requirements: '', ideas: '', mission: '', content: -1, diplomaTitles: ''}} : {});
        const getCoursesData = (ids) => {
            for (var i = 0; i < ids.length; i++) {
                const id = ids[i];
                getAPICall('course/data', {course: id}, (data) => {
                    if (data && data.length > 0) {
                        setCoursesData(prevState => ({
                            ...prevState,
                            [id]: data[0]
                          }));
                        if (courseParam && id.toString() === courseParam) {
                            selectCourse(id);
                        }
                    }
                });
            }
        }
        getAPICall('school/teacherCourses', {userId: userId, token: encodeURIComponent(token), active: -1}, (data) => {
            if (data) {
                const coursesAsTeacher = data;
                getAPICall('cabinet/myschool', {userId: userId, token: encodeURIComponent(token)}, (data) => {
                    if (data && data.length > 0) {
                        const row = data[0];
                        if (row.approvalDate !== null) {
                            if (setSchool) setSchool({id: row.id, title: row.title});
                            getAPICall('school/courses', {school: row.id, active: -1}, (data) => {
                                if (data) {
                                    const combinedCourses = [...data, ...coursesAsTeacher];
                                    const uniqueIdsSet = new Set(combinedCourses.map(obj => obj.id));
                                    const uniqueIdsArray = Array.from(uniqueIdsSet);
                                    getCoursesData(uniqueIdsArray);
                                }
                            });
                        }
                    } else {
                        const uniqueIdsSet = new Set(coursesAsTeacher.map(obj => obj.id));
                        const uniqueIdsArray = Array.from(uniqueIdsSet);
                        getCoursesData(uniqueIdsArray);
                    }
                });
            }
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, token]);

    return coursesData === null ? null : <>
    <div style={{display: "flex", justifyContent: "space-between", backgroundColor: "whitesmoke", padding: "4px"}}>
        <span><Translate>{title}</Translate>:</span>
        <ExpandButton expanded={expanded} setExpanded={setExpanded}/>
    </div>
    <div className={expanded ? "fadeIn" : "fadeOut"} style={{display: "flex", flexWrap: "wrap", alignItems: "flex-start"}}>
        {Object.entries(coursesData).map(([id, data]) => (
            id === '-1' ? null : <CabCourseCard key={id} courseData={data} selectCourse={selectCourse}/>
        ))}
        {'-1' in coursesData &&
        <CabCourseCard key={-1} courseId={-1} courseData={coursesData['-1']} selectCourse={selectCourse}/>}
    </div>
    </>
}

export default CabCourseSelection