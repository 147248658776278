import { useEffect, useState, useContext } from "react"
import { getAPICall, postAPICall } from "../../Components/APICall";
import { AppContext } from "../../AppContext/AppContext";
import { endDate, endDateFormat } from "../../Components/DateFormat";
import Translate, { translate } from "../../AppContext/Translate";
import { useNavigate } from "react-router-dom";
import StudentGroup from "./StudentGroup";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import DatePicker from "react-datepicker";
import Button from "react-bootstrap/Button";
import "react-datepicker/dist/react-datepicker.css";

function CabDynamicPlan({courseId}) {
    var { userId, token, dicLang } = useContext(AppContext);
    const [today, setToday] = useState(null);
    const [numDays, setNumDays] = useState(-1);
    const [active, setActive] = useState(false);
    const [groups, setGroups] = useState({active: null, coming: [], historical: []});
    const [startDate, setStartDate] = useState(new Date());
    let navigate = useNavigate(); 

    const updateGroups = (data, currentDay, numDays) => {
        var active = null;
        var coming = [];
        var historical = [];
        data.forEach((group) => {
            const groupStartDate = new Date(group.startDate);
            const groupEndDate = endDate(groupStartDate, numDays);
            const groupData = {id: group.id, startDate: groupStartDate, endDate: groupEndDate};
            const dif = (groupEndDate - currentDay) / 86400000;
            if (dif < 0) historical.push(groupData);
            else if (dif < numDays) active = groupData;
            else coming.push(groupData);
        });
        setGroups({active: active, coming: coming, historical: historical});
    }

    const refreshGroups = (courseId) => {
        getAPICall('check/today', {}, (data) => {
            if (data && 'today' in data) {
                const currentDay = new Date(data.today);
                currentDay.setHours(0,0,0,0);
                setToday(currentDay);
                getAPICall('course/info', {course: courseId}, (data) => {
                    if (data && data.length > 0) {
                        const days = data[0].numDays;
                        setNumDays(days);
                        setActive(data[0].active > 0);
                        getAPICall('course/groups', {course: courseId}, (data) => {
                            if (data) updateGroups(data, currentDay, days);
                        });
                    }
                });
            }
        });
    }

    useEffect(() => {
        refreshGroups(courseId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [courseId]);

    const onAccess = (id) => {
        navigate('/students/' + id);
    }

    const studentsError = () => {
        alert(translate("Помилка", dicLang) + ": " + translate("не можна видаляти групи зі студентами", dicLang));
    }

    const onDelete = (id) => {
        postAPICall('students/deleteGroup', {userId: userId, token: token, course: courseId, groupId: id},
            (data) => {
                if (data && 'error' in data && data.error === 'none') {
                    refreshGroups(courseId);
                }
                else studentsError();
            }, studentsError);
    }

    const groupOverlaps = (startDate, finishDate, group) => {
        return (startDate >= group.startDate && startDate <= group.endDate) ||
            (finishDate >= group.startDate && finishDate <= group.endDate);
    }

    const saveError = () => {
        alert(translate("Помилка", dicLang) + ": " + translate("не вдалося зберегти зміни", dicLang));
    }

    const newGroup = () => {
        const finishDate = endDate(startDate, numDays);
        var error = startDate < today;
        if (groups.active && !error) error = groupOverlaps(startDate, finishDate, groups.active);  
        for (var i = 0; i < groups.coming.length && !error; i++) {
            error = groupOverlaps(startDate, finishDate, groups.coming[i]);
        }
        if (error) alert(translate("Помилка", dicLang) + ": " + translate("дата початку має бути пізнішою за поточну та не повинна збігатися з датою початку інших груп цього курсу", dicLang));
        else {
            postAPICall('students/newGroup', {userId: userId, token: token, course: courseId, startDate: startDate},
                (data) => {
                    if (data && 'group' in data) {
                        refreshGroups(courseId);
                    }
                    else saveError();
                }, saveError);
        }
    }

    return <div>
        <h3><Translate>Групи курсу</Translate>:</h3>
        {groups.active && <>
            <hr/>
            <p className="boldPost"><Translate>Активна група</Translate>:</p>
            <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-evenly"}}>
                <StudentGroup data={groups.active} onAccess={onAccess}/>
            </div>
        </>}
        {groups.coming.length > 0 && <>
            <hr/>
            <p className="boldPost"><Translate>Групи за розкладом</Translate>:</p>
            <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-evenly"}}>
            {groups.coming.map((group, i) => {
                return <StudentGroup key={i} data={group} coming={true} color="aliceblue" onDelete={onDelete}/>
            })}
            </div>
        </>}
        {active && <>
            <hr/>
            <div>
                <div style={{display: "flex", alignItems: "stretch"}}>
                    <p style={{marginRight: "8px"}}><Translate>Дата початку</Translate></p>
                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} dateFormat="dd/MM/yyyy" />
                </div>
                {numDays > 0 && <p className="top8"><Translate>Дата закінчення</Translate>: {endDateFormat(startDate, numDays)}</p>}
                <Button variant="outline-primary" onClick={newGroup} className="top12">
                    <AiOutlineUsergroupAdd style={{marginRight: "4px"}}/>
                    <Translate>Створити нову групу</Translate></Button>
            </div>
        </>}
        {groups.historical.length > 0 && <>
            <hr/>
            <p className="boldPost"><Translate>Завершені групи</Translate>:</p>
            <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-evenly"}}>
            {groups.historical.map((group, i) => {
                return <StudentGroup key={i} data={group} color="gainsboro" onAccess={onAccess}/>
            })}
            </div>
        </>}
        {!groups.active && groups.historical.length === 0 && groups.coming.length === 0 &&
        <hr/>}
    </div>
}

export default CabDynamicPlan