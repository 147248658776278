import { useState, useEffect } from "react";

function CountUp({delay, end}) {
    const [value, setValue] = useState(0);

    const increase = (v) => {
        if (v < end) {
            setValue(v + 1);
            setTimeout(()=>{
                increase(v + 1);
            }, 250)
        }
    }
    
    useEffect(() => {
        setTimeout(()=>{
            increase(value);
        }, delay * 1000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <span>{value}</span>
}

export default CountUp