import { IoLocationOutline } from "react-icons/io5"
import { Link } from "react-router-dom"

function City({city}) {
    return (city && city !== '') ?
        <p className="top4"><IoLocationOutline size="18" style={{marginTop: "-4px"}}/>
        <Link style={{color: "inherit"}} to={"/city/" + encodeURIComponent(city)}>{city}</Link></p>
        : null
}

export default City