import Form from 'react-bootstrap/Form';

function NumericInput({value, onChange, step=0.1, min=0, max=999999, precision=2, maxWidth=320, isDisabled=false}) {
    const round = (n, precision) => {
        if (n === '') return '';
        const num = parseFloat(n)
        if (isNaN(num)) return min;
        const s = num.toString()
        var decimalValue = s.indexOf(".");
        if (decimalValue < 0 || (s.length - decimalValue) <= precision + 1) return num;
        return num.toFixed(precision);
    }

    return <Form.Control disabled={isDisabled} type="number" step={step} min={min} max={max} style={{maxWidth: maxWidth + "px"}}
        value={value} onChange={(e) => { if (!isDisabled) onChange(round(e.target.value, precision))}}/> 
}

export default NumericInput