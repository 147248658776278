import { getAPIurl } from "./APICall";
import AttFile from "./AttFile";
import { MimeIconSmall } from "./MimeIcon";
import axios from 'axios';
import Video from "./VideoPlayer";

// b: bold, c: italic, u: underline, l: list, *: item, e: emoticon, a: link, i: image, f: file, v: video

const symbol_replace = {"|": "｜", "'": "’", "<b>": "|b", "</b>": "||b",
    "<i>": "|c", "</i>": "||c", "<u>": "|u", "</u>": "||u",
    "<ul>": "|l", "</ul>": "||l", "<li>": "|*", "</li>": "||*", "<a>": "|a", "</a>": "||a",
    "<div>": "|n", "</div>": "", "<br>": "|n", "<br/>": "|n", "<br />": "|n"};

const symbol_replace_inv = {"|l|n": "|l", "||b": "</b>", "|b": "<b>", "||c": "</i>", "|c": "<i>",
    "||u": "</u>", "|u": "<u>", "||l": "</ul>", "|l": "<ul>", "||*": "</li>", "|*": "<li>",
    "|n": "<br/>"};

class WriteHelper {

    static filterSimpleText = (text) => {
        if (text == null) return "";
        return text.replace(/\|/g, '｜').replace(/'/g, '’');
    }

    static async parseMessage(text, path, userId = null, token = null) {
        const sym = Object.keys(symbol_replace_inv);
        var res = text;
        for (var i = 0; i < sym.length; i++) {
            res = res.replaceAll(sym[i], symbol_replace_inv[sym[i]]);
        }
        var files = [];
        var pos = res.indexOf('|');
        while (pos >= 0) {
            const code = res[pos + 1];
            var posEnd = res.indexOf('|', pos + 2);
            if (posEnd < pos) break;
            if (code === 'i' || code === 'f' || code === 'v') { // Image/file/video (name, size, buffer, ext)
                const name = res.substring(pos + 2, posEnd);
                if (code === 'i' || code === 'f') {
                    var params = {name: encodeURIComponent(name), path: encodeURIComponent(path.jsonPath)};
                    if (userId) params["userId"] = userId;
                    if (token) params["token"] = encodeURIComponent(token);
                    const url = getAPIurl('media/img', params);
                    files.push(new AttFile(code, name, {url: url}));
                } else {
                    const videoId = parseInt(name);
                    var url = getAPIurl('media/videoType', {name: videoId});
                    let kind;
                    try {
                        kind = await axios.get(url, {headers: {Accept: "application/json",
                            "Content-Type": "application/json;charset=UTF-8"}});
                        kind = kind.data["kind"];
                    } catch (e) { 
                        kind = 'video/mp4'; 
                    }
                    params = {name_i:  videoId};
                    if (userId) params["userId_i"] = userId;
                    if (token) params["token_s"] = encodeURIComponent(token);
                    url = getAPIurl('media/hls', params) + '&kind=' + kind;
                    files.push(new AttFile(code, name, {url: url}));
                }
                res = res.substring(0, pos) + res.substring(posEnd + 1); 
            } else if (code === 'e') {
                res = res.substring(0, pos) + '<img alt="" width="18px" height="18px" src="https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/'
                    + res.substring(pos + 2, posEnd) + '.png"/>' + res.substring(posEnd + 1);
            } else if (code === 'a') {
                var posLast = res.indexOf('||a', posEnd + 1);
                if (posLast < posEnd) break;
                res = res.substring(0, pos) + '<a href="' + res.substring(pos + 2, posEnd) +
                    '">' + res.substring(posEnd + 1, posLast) + '</a>' + res.substring(posLast + 3); 
            } else {
                //console.log(code);
                break;
            }
            pos = res.indexOf('|');
        }
        return {text: res, files: files};
    }

    static encode(text) {
        const sym = Object.keys(symbol_replace);
        var res = text;
        for (var i = 0; i < sym.length; i++) {
            res = res.replaceAll(sym[i], symbol_replace[sym[i]]);
        }
        var pos = res.indexOf('<img '), pos2;
        while (pos >= 0) {
            pos2 = res.indexOf('>', pos);
            var img = res.substring(pos, pos2 + 1);
            var png = img.lastIndexOf('.png');
            var bar = img.lastIndexOf('/', png);
            res = res.substring(0, pos) + '|e' + img.substring(bar + 1, png) + '|' +
                res.substring(pos2 + 1);
            pos = res.indexOf('<img ');
        }
        pos = res.indexOf('<a ');
        while (pos >= 0) {
            pos2 = res.indexOf('href', pos) + 6;
            var pos3 = res.indexOf('"', pos2 + 1);
            var pos4 = res.indexOf('>', pos3 + 1);
            var link = res.substring(pos2, pos3);
            res = res.substring(0, pos) + '|a' + link + '|' + res.substring(pos4 + 1);
            pos = res.indexOf('<a ');
        }
        return res;
    }

    static addAttachedFiles(text, files, rename=null) {
        for (var i = 0; i < files.length; i++) {
            const f = files[i];
            if (!f.deleted) {
                const name = rename ? rename(f) : f.name;
                text += '|' + f.kind + name + '|';
            }
        }
        return text;
    }

    static encodeWithFiles(item) {
        var text = WriteHelper.encode(WriteHelper.filterSimpleText(item.text));
        return WriteHelper.addAttachedFiles(text, item.files, (file) => {
            if (file.isVideo() && "id" in file && file.id >= 0) return file.id;
            return file.name;
        });
    }

    static async parseImages(text, path, userId, token, images, onPreview) {
        var res = text;
        var pos = res.indexOf('|i');
        while (pos >= 0) {
            var pos2 = res.indexOf('|', pos + 2);
            var params = {name: res.substring(pos + 2, pos2), path: encodeURIComponent(path.jsonPath)};
            if (userId) params["userId"] = userId;
            if (token) params["token"] = encodeURIComponent(token);
            var url = getAPIurl('media/img', params);
            (function(currentParams, currentUrl) {
                images.push(<div key={"i" + images.length} className="postimgdiv" style={{cursor: onPreview ? 'pointer' : 'auto'}} >
                    <img className="postimg" alt="" src={currentUrl} onClick={() => { if (onPreview) onPreview({kind: 'i', name: currentParams.name}); }}/>
                    </div>);
            })(params, url);
            res = res.substring(0, pos) + res.substring(pos2 + 1);
            pos = res.indexOf('|i');
        }
        return res;
    }

    static async parseFiles(text, files, onPreview) {
        var res = text;
        var pos = res.indexOf('|f');
        while (pos >= 0) {
            var pos2 = res.indexOf('|', pos + 2);
            files.push(<div key={"f" + files.length} className="chatCommentImgDiv" style={{margin: "4px"}}>
                <MimeIconSmall name={res.substring(pos + 2, pos2)} onPreview={onPreview}/>
            </div>);
            res = res.substring(0, pos) + res.substring(pos2 + 1);
            pos = res.indexOf('|f');
        }
        return res;
    }

    static async parseVideos(text, path, userId, token, videos) {
        var res = text;
        var pos = res.indexOf('|v');
        while (pos >= 0) {
            var pos2 = res.indexOf('|', pos + 2);
            const videoId = parseInt(res.substring(pos + 2, pos2));
            var url = getAPIurl('media/videoType', {name: videoId});
            let kind;
            try {
                kind = await axios.get(url, {headers: {Accept: "application/json",
                    "Content-Type": "application/json;charset=UTF-8"}});
                kind = kind.data["kind"];
            } catch (e) { 
                kind = 'video/mp4'; 
            }
            var params = {name_i:  videoId};
            if (userId) params["userId_i"] = userId;
            if (token) params["token_s"] = encodeURIComponent(token);
            url = getAPIurl('media/hls', params) + '&kind=' + kind;            
            videos.push(<div key={"v" + videos.length} className="postvideodiv">
                <Video name={videoId} url={url}/>
            </div>);
            res = res.substring(0, pos) + res.substring(pos2 + 1);
            pos = res.indexOf('|v');
        }
        return res;
    }

    static getRecPart(par, st, elements, context, asLine) {
        const children = [];
        var i = 1;
        var start = 0;
        var pos, pos2;
        while (i < par.length) {
            if (par[i] === 'e' && par[i-1] === '|') {
                pos = par.indexOf('|', i);
                if (pos > i) {
                    if (i > start) children.push(<span className={st} key={children.length}>{par.substring(start, i-1)}</span>);
                    children.push(<img key={children.length} alt="" width="18px" height="18px" 
                        src={'https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/' + 
                        par.substring(i + 1, pos) + '.png'}/>);
                    i = pos + 1;
                    start = i;
                } else i++;
            } else if (par[i] === 'a' && par[i-1] === '|') {
                pos = par.indexOf('|', i);
                pos2 = par.indexOf('|', pos + 1);
                if (pos > i && pos2 > pos) {
                    if (i > start) children.push(<span className={st} key={children.length}>{par.substring(start, i-1)}</span>);
                    children.push(<a key={children.length} href={par.substring(i + 1, pos)} target="_blank" rel="noreferrer">{par.substring(pos+1, pos2)}</a>);
                    i = pos2 + 3;
                    start = i;
                } else i++;
            } else i++;
        }
        if (start < par.length - 1) children.push(<span className={st} key={children.length}>{par.substring(start)}</span>);
        if (asLine) {
            if (context === 't') elements.push(<p className={st} key={elements.length}>{children}</p>);
            else elements.push(<li className={st} key={elements.length}>{children}</li>);
        } else elements.push(<span className={st} key={elements.length}>{children}</span>);
    }

    static getPart(par, style, elements, textStyle, context, asLine) {
        if (par === "") par = "\u00A0";
        var st = textStyle;
        if (style.includes('b')) st += " bold";
        if (style.includes('c')) st += " italic";
        if (style.includes('u')) st += " underlinePost";
        if (par.indexOf('|e') >= 0 || par.indexOf('|a') >= 0) WriteHelper.getRecPart(par, st, elements, context, asLine);
        else if (asLine) {
            if (context === 't') elements.push(<p className={st} key={elements.length}>{par}</p>);
            else elements.push(<li className={st} key={elements.length}>{par}</li>);
        } else elements.push(<span className={st} key={elements.length}>{par}</span>);
    }

    static processInfo(info, elements, textStyle) {
        var i = 0;
        var context = ''; 
        var children = [];
        while (i < info.length) {
            var line = info[i];
            var text = line[1];
            if (line[0] !== context) {
                if (children.length > 0) {
                    if (context === 't') elements.push(<p className={textStyle} key={elements.length}>{[...children]}</p>);
                    else elements.push(<ul key={elements.length}>{[...children]}</ul>);
                    children = [];
                }
                context = line[0];
            }
            if (text.trim() === "") {
                i++;
                continue;
            }
            var ret = text.indexOf('|n');
            if (ret >= 0) {
                if (children.length === 0) {
                    WriteHelper.getPart(text.substring(0, ret), line[2], elements, textStyle, context, true);
                } else {
                    WriteHelper.getPart(text.substring(0, ret), line[2], children, textStyle, context, false);
                    if (context === 't') elements.push(<p className={textStyle} key={elements.length}>{[...children]}</p>);
                    else elements.push(<li className={textStyle} key={elements.length}>{[...children]}</li>);
                    children = [];
                }
                info[i][1] = text.substring(ret + 2);
            } else {
                WriteHelper.getPart(text, line[2], children, textStyle, context, false);
                i++;
            }  
        }
        if (children.length > 0) {
            if (context === 't') elements.push(<p className={textStyle} key={elements.length}>{[...children]}</p>);
            else elements.push(<li className={textStyle} key={elements.length}>{[...children]}</li>);
        }
    }

    static processText(text, elements, textStyle) {
        var info = [];
        var attr = '';
        var context = 't';
        var start = 0;
        text = text.replace(/\|\|\*/g, '|n').replace(/\|\*/g, '');
        var next = text.indexOf('|');
        while (next >= 0) {
            var c = next < text.length - 1 ? text[next + 1] : '';
            if (['a', 'e'].includes(c)) {
                if (c === 'a') next = text.indexOf('||a', next) + 2;
                else next = text.indexOf('|', next + 1);
            }
            if (['b', 'c', 'u', 'l', '|'].includes(c)) {
                if (next > start) {
                    info.push([context, text.substring(start, next).replace(/\n/g, '|n'), attr]);
                }
                if (c === 'b' || c === 'c' || c === 'u') {
                    if (!attr.includes(c)) attr += c;
                    start = next + 2;
                    next = text.indexOf('|', start);
                } else if (c === '|') {
                    c = next < text.length - 2 ? text[next + 2] : '';
                    if (c === 'l') context = 't';
                    else attr = attr.replace(new RegExp(c, 'g'), '');
                    start = next + 3;
                    next = text.indexOf('|', start);
                } else if (c === 'l') {
                    context = 'l';
                    start = next + 2;
                    next = text.indexOf('|', start);
                }
            } else {
                next = text.indexOf('|', next + 1);
            }
        }
        if (start < text.length - 1) {
            info.push([context, text.substring(start).replace(/\n/g, '|n'), attr]);
        }
        for (var i = 1; i < info.length; i++) {
            while (info[i][1].startsWith('|n')) {
                info[i - 1][1] += '|n';
                info[i][1] = info[i][1].substring(2);
            }
        }
        WriteHelper.processInfo(info, elements, textStyle);
    }

    static async parseElements(text, path, userId = null, token = null, textStyle="textPost", onPreview=null) {
        var elements = [];
        var images = [];
        var videos = [];
        var files = [];
        var res = await WriteHelper.parseImages(text, path, userId, token, images, onPreview);
        res = await WriteHelper.parseVideos(res, path, userId, token, videos);
        res = await WriteHelper.parseFiles(res, files, onPreview);
        var i;
        for (i = 0; i < videos.length; i++) elements.push(videos[i]);
        WriteHelper.processText(res, elements, textStyle);
        if (files.length > 0 && images.length > 0) {
            elements.push(<div key={elements.length} style={{display: "flex", flexWrap: "wrap", justifyContent: "center"}}>
                {images}
                <div style={{padding: "4px", display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "center"}}>
                    {files}
                </div>
            </div>)
        } else if (images.length > 0) {
            elements.push(<div key={elements.length} style={{display: "flex", flexWrap: "wrap", justifyContent: "center"}}>
                {images}
            </div>)
        } else if (files.length > 0) {
            elements.push(<div key={elements.length} style={{padding: "4px", display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "center"}}>
                {files}
            </div>)
        }
        return elements;
    }
}

export default WriteHelper