import { useParams } from "react-router-dom";
import Courses from "./Courses";
import Menu from "../Menu/Menu";
import { useContext, useEffect, useState } from "react";
import { getAPICall } from "../Components/APICall";
import { AppContext } from "../AppContext/AppContext";
import Footer from "../Components/Footer";
import CourseList from "../Lists/CourseList";
import HomeButton from "../Components/HomeButton";
import Translate from "../AppContext/Translate";
import Banner from "../Components/Banner";

function Category() {
    const { lang } = useContext(AppContext);
    const [title, setTitle] = useState('');
    const cat = useParams()["id"];

    useEffect(() => {
        if (cat) {
            getAPICall('category/info', {cat: cat, lang: lang}, (data) => {
                if (data && 'title' in data) setTitle(data.title);
            });
        }
    }, [cat, lang]);

    return cat ? <div className='mainContainer'>
        <Menu/>
        <Banner srcImg='/bkg/banners/courses.jpg' down={false}
            title={<img alt="" src={"/categories/cat" + cat + ".png"} width="148"/>}/>
        <div style={{backgroundColor: "white", paddingTop: "48px"}} className="center">
            <h1 style={{fontSize: "min(3.5em, 10vw)", margin: "0px"}}>{title}</h1>
        </div>
        <div style={{padding: "24px 24px 8px 24px", backgroundColor: "white"}}>
            <h1><Translate>Курси в категорії</Translate></h1>
            <CourseList filter={{show: false, school: 0, cat: cat, lang: ''}}/>
            <HomeButton/>
        </div>
        <Footer/>
    </div> : <Courses />
}

export default Category