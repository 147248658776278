import { useParams } from "react-router-dom"
import { Navigate } from "react-router-dom";
import Menu from "../Menu/Menu";
import UserCover from "./UserCover";
import { getAPICall } from "../Components/APICall";
import { useEffect, useState, useContext, useRef } from "react";
import BeatLoader from "react-spinners/BeatLoader";
import Footer from "../Components/Footer";
import { AppContext } from "../AppContext/AppContext";
import { useWindowSize } from "../AppContext/WindowSize";
import HomeButton from "../Components/HomeButton";
import ProfileSmall from "./Profile/ProfileSmall";
import Following from "./Profile/Following";
import ProfileBig from "./Profile/ProfileBig";

function PublicProfile() {
    const user = useParams()["id"];
    const { userId, token } = useContext(AppContext);
    const [profile, setProfile] = useState(null);
    const [isTeacher, setIsTeacher] = useState(false);
    const [isStudent, setIsStudent] = useState(false);
    const [unreadMessages, setUnreadMessages] = useState(0);
    const followRef = useRef(null);
    const [windowWidth,] = useWindowSize();
    
    const own = parseInt(user) === userId;

    useEffect(() => {
        const profileSuccess = (data) => {
            if (data && data.length === 1) {
                setProfile(data[0]);
            }
        }

        if (user && user >= 0) {
            getAPICall('user/profile', {user: user}, profileSuccess);
            getAPICall('user/isTeacher', {userId: user}, (data) => {
                if (data && 'teacher' in data) setIsTeacher(data.teacher === 1);
            });
            getAPICall('user/isStudent', {userId: user}, (data) => {
                if (data && 'student' in data) setIsStudent(data.student === 1);
            });
            getAPICall('user/unreadMessages', {user: user, userId: userId, token: encodeURIComponent(token)}, (data) => {
                if (data && 'num' in data) setUnreadMessages(data.num);
            });
        }
    }, [user, userId, token, own]);

    return !user || user < 0 ? <Navigate to="/" replace={true}/> : 
    <div className='mainContainer' style={{backgroundColor: "#fef5de"}}>
        <Menu />
        <div style={{width: "100%", maxWidth: "1024px", margin: "auto"}}>
            <UserCover user={user} allowChange={own}/>
            {profile !== null ? (windowWidth < 920 ?
                <ProfileSmall user={user} profile={profile} own={own} isTeacher={isTeacher} isStudent={isStudent}
                    unreadMessages={unreadMessages} followRef={followRef} userId={userId} token={token}/> :
                <ProfileBig user={user} profile={profile} own={own} isTeacher={isTeacher} isStudent={isStudent}
                    unreadMessages={unreadMessages} followRef={followRef} userId={userId} token={token}/>) : 
                    <p style={{marginTop: "84px"}}><BeatLoader color="gray" size={36}/></p>}
        </div>
        {own && <div ref={followRef}>
            <Following userId={userId} token={token}/>
            <div className="center top12 bot16">
                <HomeButton/>
            </div>
        </div>}
        <Footer/>
    </div>
}

export default PublicProfile