import Translate from "../../AppContext/Translate"
import Footer from "../../Components/Footer"
import HomeButton from "../../Components/HomeButton"
import Menu from "../../Menu/Menu"
import ShowProjects from "./ShowProjects"

function Projects() {
    return <div className='mainContainer' style={{backgroundColor: "white"}}>
        <Menu/>
        <div style={{width: "100%", height: "148px", backgroundImage: "url('/bkg/banners/board.jpg')",
            backgroundSize: "240px"}}>
            <img alt="" src="/bkg/banners/project.png" style={{height: "148px", position: "absolute",
                right: "0px", top: "0px"}}/>
            <h1 className="xxxlarge left24" style={{color: "black", paddingTop: "54px"}}><Translate>Проєкти</Translate></h1>
        </div>
        <ShowProjects courseId={0}/>
        <HomeButton/>
        <Footer/>
    </div>
}

export default Projects