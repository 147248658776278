import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import MenuUserIcon from "./MenuUserIcon";
import ShakeOnError from '../../Components/ShakeOnError';
import { AiOutlineNotification } from 'react-icons/ai';
import Translate from '../../AppContext/Translate';
import LeftLoginImage from './LeftLoginImage';
import ModalBrandHeader from '../../Components/ModalBrandHeader';
import FacebookButton from './FacebookButton';
import GoogleButton from './GoogleButton';
import EmailForm from './EmailForm';
import PasswordForm from './PasswordForm';

function LoginInvalidPassword({error}) {
    return error ? 
    <div style={{backgroundColor: "#ffcfcf", borderTopLeftRadius: "var(--bs-modal-border-radius)",
        borderTopRightRadius: "var(--bs-modal-border-radius)", textAlign: "center", padding: "12px"}}>
        <AiOutlineNotification size={28}/>
        <p style={{margin: "0px", fontWeight: "bold"}}>
            <Translate>Error</Translate>: <Translate>електронна адреса або пароль неправильні</Translate>.
        </p>
    </div> :
    <></>
}

function MenuLoginNotLogged({handleShow, handleClose, show, size, userName, error, fBLoginSuccess, fBLoginError,
    googleLogin, formSubmit, handleEmailChange, emailInput, handlePasswordChange, passwordInput, createAccountClick}) {
    return <>
        <MenuUserIcon handleShow={handleShow} size={size} userName={userName} />
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="xl" centered>
            <ShakeOnError error={error}>
                <LoginInvalidPassword error={error} />
                <Container>
                    <Row>
                        { (size === "big") && <LeftLoginImage /> }
                        <Col style={{paddingLeft: '0px', paddingRight: '0px'}}>
                            <ModalBrandHeader invert={true} />
                            <Modal.Body>
                                <Row style={{alignItems: 'center', textAlign: 'center'}}>
                                    <Col>
                                        <FacebookButton createAc={false} loginSuccess={fBLoginSuccess} loginFail={fBLoginError}/>
                                    </Col>
                                    <Col>
                                        <GoogleButton createAc={false} googleLogin={googleLogin} />      
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <div style={{backgroundColor: 'whitesmoke'}}>
                                <Modal.Body>
                                    <p className='bot8'><Translate>або введіть свою електронну адресу</Translate></p>
                                    <form className="Auth-form" onSubmit={formSubmit}>
                                        <EmailForm onChangeEvent={handleEmailChange} error={false} value={emailInput} />
                                        <div className="input-group-btn" style={{position: "relative"}}>
                                            <PasswordForm onChangeEvent={handlePasswordChange} error={false} 
                                                value={passwordInput} isLogin={true}/>
                                            <Link to="/user/recover">
                                                <p className="simplelink" style={{margin: '0px', fontSize: 'small'}}>
                                                    <Translate>Ви забули пароль?</Translate></p>
                                            </Link>
                                        </div>
                                        <div style={{textAlign: 'right'}}>
                                            <Button variant="primary" type="submit"><Translate>Увійти</Translate></Button>
                                        </div>
                                    </form>
                                </Modal.Body>
                            </div>
                            <Modal.Footer style={{justifyContent: 'center'}}>
                                <p><Translate>У вас немає облікового запису?</Translate>
                                    <Button variant="muted" style={{color: 'crimson', fontWeight: 'bold', transform: 'translateY(-2px)',
                                        padding: '4px', marginLeft: '4px'}} onClick={createAccountClick} >
                                        <Translate>Створити акаунт</Translate></Button>
                                </p>
                            </Modal.Footer>
                        </Col>
                    </Row>
                </Container>
            </ShakeOnError>
        </Modal>
    </>
}

export default MenuLoginNotLogged