import { useState, createContext, useEffect, useRef } from 'react';
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { getAPICall, postAPICall } from '../Components/APICall';
import { WS_CLIENT_URL } from '../serverData';

export const AppContext = createContext("");

function loadDictionary(lang) {
    const context = require.context('./languages', false, /.json$/);
    var dic = {};
    context.keys().forEach(file => {
        const fileName = file.replace('./', '');
        if (fileName === lang + ".json") {
        const resource = require("./languages/" + fileName);
        dic = JSON.parse(JSON.stringify(resource));
        }
    });
    return dic;
};

function compatibleCartElements(e1, e2) {
  if (e1.kind !== e2.kind) return true;
  switch (e1.kind) {
  case 'plan': return e1.packageId !== e2.packageId;
  case 'item': return e1.itemId !== e2.itemId;
  default: 
    return false;
  }
}

function AppContextProvider(props) {
  const WS_STATE_DISCONNECTED = 0;
  const WS_STATE_CONNECTED = 1;
  const WS_STATE_CONNECTING = 2;

  const [wsClient, _setWsClient] = useState(null);
  const [wsState, _setWsState] = useState(WS_STATE_DISCONNECTED);
  const wsClientRef = useRef(wsClient);
  const wsStateRef = useRef(wsState);
  const setWsState = data => {
    wsStateRef.current = data;
    _setWsState(data);
  };
  const setWsClient = data => {
    wsClientRef.current = data;
    _setWsClient(data);
  };

  const [userId, setUserId] = useState(() => {
    const id = localStorage.getItem('userId');
    if (id) return JSON.parse(id);
    return -1;
  });

  const [notifications, setNotifications] = useState(0);
  const [lastNotification, setLastNotification] = useState(null);

  const [userName, setUserName] = useState(() => {
    const user = localStorage.getItem('userName');
    if (user && userId !== -1) {
      return JSON.parse(user);
    }
    return null;
  });

  const [token, setToken] = useState(() => {
    const t = localStorage.getItem('token');
    if (t) return JSON.parse(t);
    return null;
  });

  const [lang, setLang] = useState(() => {
    const savedLang = localStorage.getItem("language");
    const initialValue = JSON.parse(savedLang);
    return initialValue || "ua";
  });

  const [dicLang, setDicLang] = useState(() => {
    return loadDictionary(lang);
  });

  const [cart, setCart] = useState(() => {
    const content = localStorage.getItem('cart');
    if (content) return JSON.parse(content);
    return [];
  });

  const addToCart = (element, amount=1) => {
    var found = false;
    for (var i = 0; i < cart.length && !found; i++) {
      if (!compatibleCartElements(cart[i], element)) found = true;
    }
    if (!found) {
      element["amount"] = amount;
      const newCart = [
        ...cart,
        element
      ]
      setCart(newCart);
      localStorage.setItem("cart", JSON.stringify(newCart));
      return true;
    } else {
      return false;
    }
  }

  const removeFromCart = (index) => {
    var newCart = index >= 0 ?
      cart.filter((_, i) => i !== index) :
      [];
    setCart(newCart);
    localStorage.setItem("cart", JSON.stringify(newCart));
  }

  const updateCartAmount = (index, amount) => {
    const newCart = cart.map((line, i) => {
      if (i === index) {
        return {...line, amount: amount};
      } else {
        return line;
      }
    });
    setCart(newCart);
    localStorage.setItem("cart", JSON.stringify(newCart));
  }

  const notifSuccess = (data) => {
    if ("count" in data)
      setNotifications(data["count"]);
  }
  
  const updateNotificationsCount = () => {
    getAPICall('notifications/countUnread', {token: encodeURIComponent(token), userId: userId}, notifSuccess);
  }

  useEffect(() => {
    const tokenOk = (data) => {
      if (!("error" in data) || data["error"] !== "none")
        tokenError(data);
    }
    const tokenError = (data) => {
      logout();
    }
    if (userId > 0 && token) {
      postAPICall('validateToken', {token: token, userId: userId}, tokenOk, tokenError);
      updateNotificationsCount();
      reconnect();
    } else setNotifications(0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const startWebsocket = (id) => {
    setWsState(WS_STATE_CONNECTING);
    var wsc = new W3CWebSocket(WS_CLIENT_URL + '/id=' + id);
    wsc.onopen = () => {
      setWsClient(wsc);
      setWsState(WS_STATE_CONNECTED);
    };
    wsc.onmessage = (message) => {
      const dataFromServer = JSON.parse(message.data);
      //console.log(dataFromServer);
      if ("countNotification" in dataFromServer) {
        if (dataFromServer["countNotification"] === 1)
          setNotifications(notifications => notifications + 1);
      }
      setLastNotification(dataFromServer);
    }
    wsc.onerror = function() {
      setWsClient(null);
      setWsState(WS_STATE_DISCONNECTED);
    }
    wsc.onclose = function (event) {
      setWsClient(null);
      setWsState(WS_STATE_DISCONNECTED);
    }
  }

  const closeConnection = () => {
    setWsState(WS_STATE_DISCONNECTED);
    if (wsClientRef.current !== null) {
      wsClientRef.current.close();
      setWsClient(null);
    }
  }

  const reconnect = () => {
    if (wsStateRef.current === WS_STATE_DISCONNECTED && userId >= 0) {
      startWebsocket(userId);
    }
  }

  const changeLanguage = (newLang) => {
      setLang(newLang);
      localStorage.setItem("language", JSON.stringify(newLang));
      const newDic = loadDictionary(newLang);
      setDicLang(newDic);
  };

  const login = (id, name, token) => {
    setUserId(id);
    setUserName(name);
    setToken(token);
    localStorage.setItem("userId", JSON.stringify(id));
    localStorage.setItem("userName", JSON.stringify(name));
    localStorage.setItem("token", JSON.stringify(token));
  };

  const logout = () => {
    setUserId(-1);
    setUserName(null);
    setToken(null);
    localStorage.removeItem("userId");
    localStorage.removeItem("userName");
    localStorage.removeItem("token");
    closeConnection();
  };

  const onFocus = () => {
    reconnect();
  };

  useEffect(() => {
    window.addEventListener("focus", onFocus);
    return () => {
      window.removeEventListener("focus", onFocus);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initValues = {lang: lang, dicLang: dicLang, changeLanguage: changeLanguage,
      userId: userId, userName: userName, token: token, login: login, logout: logout, 
      cart: cart, notifications: notifications, lastNotification: lastNotification,
      wsState: wsState, updateNotificationsCount: updateNotificationsCount,
      addToCart: addToCart, removeFromCart: removeFromCart, updateCartAmount: updateCartAmount};

  return (
    <AppContext.Provider value={initValues}>
      {props.children}
    </AppContext.Provider>
  );
}

export default AppContextProvider;