import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../AppContext/AppContext";
import { getAPICall } from "../../Components/APICall";
import CabSubtitle from "../CabSubtitle";
import { FaSchool } from "react-icons/fa6";
import CabSchoolAproval from "./CabSchoolAproval";

function AdminSchools() {
    const { userId, token } = useContext(AppContext);
    const [schools, setSchools] = useState([]);

    useEffect(() => {
        getAPICall('school/pendant', {userId: userId, token: encodeURIComponent(token)}, (data) => {
            if (data) {
                setSchools(data);
            }
        });
    }, [userId, token]);

    return schools.length > 0 ? <div>
        <CabSubtitle title="Школи" icon={<FaSchool/>}/>
        {schools.map((school, i) => {
            return <CabSchoolAproval key={i} school={school}/>
        })}
    </div> : null
}

export default AdminSchools