import Translate from "../../AppContext/Translate";

function GoogleButton({createAc, googleLogin}) {
    return (
        <button className="google" onClick={googleLogin}>
            <img alt="" src="/icons/google.png" width="24" height="24" className="d-inline-block" />
            {' '}<Translate>{ createAc ? "Зареєструйтесь через Google" : "Зайдіть за допомогою Google" }</Translate>
        </button>);
}

export default GoogleButton