import { useState } from "react";
import { postAPICall } from "../../Components/APICall";
import Translate from "../../AppContext/Translate";
import HomeButton from "../../Components/HomeButton";
import Form from 'react-bootstrap/Form';
import WriteHelper from "../../Components/WriteHelper";
import BeatLoader from "react-spinners/BeatLoader";

function SendMessage({fromUser, toUser, token}) {
    const [sent, setSent] = useState('No');
    const [msg, setMsg] = useState('');

    const messageSent = (data) => {
        if (data && 'error' in data && data['error'] === 'none') {
            setSent("Yes");
        }
    }

    const sendMessage = () => {
        setSent('Sending');
        postAPICall('message/send', {userId: fromUser, token: token, toUser: toUser, text: msg},
            messageSent);
    }

    const disabled = !msg || msg.length < 1;

    return <div style={{margin: "16px 12px 0px 12px", paddingBottom: "32px"}}>
        <p className="fkenyan large">
            <img alt="" src="/icons/dialog.png" height="30px" style={{marginRight: "8px", transform: "translateY(-4px)"}}/>
            <Translate>Написати повідомлення</Translate>:
        </p>
        {sent === 'Yes' ? <>
            <div style={{backgroundColor: "white", borderRadius: "4px"}} className="pad12 bot8">
                <p className="bold" style={{color: "#12c191"}}>
                    <Translate>Повідомлення успішно надіслано</Translate>
                </p>
            </div>
            <div className="center">
                <HomeButton/> 
            </div>
        </>: (sent === 'No' ? <>
            <Form.Control as="textarea" className="sendMessageBox" value={msg} rows={3} maxLength="4000" onChange={(event) => { 
                setMsg(WriteHelper.filterSimpleText(event.target.value));
            }} style={{margin: "4px 0px 8px 0px"}}/>
            <div style={{display: "flex"}}>
                <button disabled={disabled} style={{padding: "4px 12px", backgroundColor: "rgb(18, 193, 145)",
                    color: "white", cursor: disabled ? "auto" : "pointer", textAlign: "center", border: "none",
                    borderRadius: "4px", filter: disabled ? "opacity(0.5)" : "none"}}
                    className="large right18" onClick={() => { sendMessage(); }}>
                    <Translate>Надіслати</Translate>
                </button>
                <HomeButton/>
            </div>
        </>: <div style={{backgroundColor: "white", borderRadius: "4px"}} className="pad12">
            <BeatLoader color="#12c191" size="10px"/>
        </div>)}
    </div>
}

export default SendMessage