import { useState } from "react"
import ExpandButton from "../../Components/ExpandButton"
import UserPhotoIcon from "../../Components/UserPhotoIcon"
import TaskReview from "./TaskReview";

function StudentInfo({data, courseId}) {
    const [expanded, setExpanded] = useState(false);

    return <>
        <div className="studentRow" onClick={() => { setExpanded(!expanded); }}>
            <UserPhotoIcon user={data.client} userName={data.name} big={false}/>
            <span style={{marginLeft: "4px"}}>{data.name}</span>
            <div style={{position: "absolute", right: "0px", top: "2px"}}>
                <ExpandButton expanded={expanded} setExpanded={setExpanded}/>
            </div>
        </div>
        {expanded && <TaskReview student={data.client} pkgId={data.package} courseId={courseId}/>}
    </>
}

export default StudentInfo