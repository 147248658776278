import MagButton from "../Components/MagButton";
import Translate from "../AppContext/Translate";

function SectionTitle({setVideo}) {
    return <div style={{backgroundColor: "#12c191"}}>
        <div className="fkenyan" style={{paddingTop: "48px", paddingLeft: "8%"}}>
            <p className="fkenyan"><Translate>Міжнародна онлайн-платформа</Translate></p>
            <p className="fkenyan"><Translate>для розвитку у творчих сферах</Translate></p>
            <img alt="Magistrika" src="/bkg/magistrika.png" style={{width: "80%",
                maxWidth: "600px", marginTop: "28px", position: "relative", zIndex: 50}}/>
            <div style={{display: "flex", marginTop: "24px", paddingBottom: "128px", flexWrap: "wrap",
                zIndex: 50, width: "90%"}}>
                <MagButton text="дивитись всі курси" icon="/bkg/down.png" margin="4px 32px 4px 0px"
                    clickEff={{link: "/courses"}}/>
                <MagButton text="мисли по-іншому" icon="/bkg/play.png" bkgColor="white" color="black"
                    margin="4px 0px 4px 0px" clickEff={{customFnc: () => {
                        setVideo({id: "/videos/presentation.mp4", title: <>
                            <img alt="" src="/bkg/brandw.png" width="164" style={{marginRight: "10px"}}/>
                            <span style={{color: "wheat"}}><Translate>Мисли по-іншому</Translate></span>
                        </>});
                }}}/>
            </div>
        </div>
        <img alt="" src="/bkg/decor/dec2.png" style={{position: "absolute", width: "200px", maxWidth: "20%", bottom: "0px"}}/>
        <img alt="" src="/bkg/decor/dec1.png" style={{position: "absolute", width: "200px", maxWidth: "20%", zIndex: 100}}/>
        <img alt="" src="/bkg/decor/dec3.png" style={{position: "absolute", width: "25%", maxWidth: "320px",
            top: "20px", right: "0px"}}/>
    </div>
}

export default SectionTitle