import { useState, useEffect } from "react";
import Translate, { translate } from "../../AppContext/Translate";
import NumericInput from "../../Components/NumericInput";
import RTEditor from "../../Components/RTEditor";
import WriteHelper from "../../Components/WriteHelper";
import Form from 'react-bootstrap/Form';
import { MediaPath } from "../../Components/MediaPath";
import Button from "react-bootstrap/Button";
import { MdOutlineAddBox } from "react-icons/md";
import { GiSave } from "react-icons/gi";
import { BiUpArrow, BiDownArrow, BiTrash } from 'react-icons/bi';
import UploadProgress from "../../Components/UploadProgress";
import rtEditorUploader from "../../Components/RTEditorUploader";
import { postAPICall } from "../../Components/APICall";
import { confirm } from "../../Components/ConfirmFunction";
import InfoButton from "../../Components/InfoButton";

function Material({material, numDays, userId, token, dicLang, numPosts, unsavedMaterials, 
    updateMaterial, updateNumbers}) {
    const kind = material.project === 1 ? 'project' : (material.taskName !== null ? 'task' : 'post');
    const bkgColor = kind==='post' ? "aliceblue" : (kind === 'task' ? "darkseagreen": "#cfe2ff");
    const [newExtension, setNewExtension] = useState('');
    const [progress, setProgress] = useState(null);
    const [withFiles, setWithFiles] = useState(false);
    const [saved, setSaved] = useState(true);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        var wf = false;
        if (material.message.files && material.message.files.length > 0) {
            for (var i = 0; i < material.message.files.length; i++) {
                const f = material.message.files[i];
                if (!('deleted' in f) || !f.deleted) {
                    wf = true;
                    break;
                }
            }
        }
        setWithFiles(wf);
    }, [material]);

    const addExtension = (ext) => {
        const newExt = ext.toLowerCase();
        var aux = [];
        for (var i = 0; i < material.extensions.length; i++)
            if (material.extensions[i].length > 0 && material.extensions[i] !== newExt)
                aux.push(material.extensions[i]);
        aux.push(newExt);
        setNewExtension('');
        updateMaterial(material.postOrder, 'extensions', aux);
    }

    const delExtension = (ext) => {
        const index = material.extensions.indexOf(ext);
        if (index >= 0) {
            var copy = [...material.extensions];
            copy.splice(index, 1);
            updateMaterial(material.postOrder, 'extensions', copy);
        }
    }

    const getPath = (course, topic, post) => {
        var p = new MediaPath(MediaPath.kind_privateCoursePost);
        p.add(course);
        p.add(topic);
        p.add(post);
        return p;
    }

    const saveError = () => {
        setSaving(false);
        alert(translate("Помилка", dicLang) + ": " + translate("не вдалося зберегти зміни", dicLang));
    }

    const update = (postId) => {
        const params = {userId: userId, token: token, post: postId, postOrder: material.postOrder, day: material.day,
            kind: kind, course: material.course, taskName: material.taskName === null ? '' : 
            WriteHelper.filterSimpleText(material.taskName), extensions: material.extensions === null ? '' :
            material.extensions.join('|')};
        const path = getPath(material.course, material.topic, postId);
        rtEditorUploader([material.message], [path.jsonPath], setProgress, userId, token,
            [{apifnc: 'materials/save', params: params}],
            () => {
                updateMaterial(material.postOrder, 'post', postId);
                setSaved(true);
                setSaving(false);
            }, saveError, (data) => {
                if (data && 'error' in data && data.error === 'day')
                alert(translate("Помилка", dicLang) + ": " + translate("не можна викладати кілька тем в один день", dicLang));
            });
    }

    const save = () => {
        setSaving(true);
        if (material.post <= 0) {
            postAPICall('materials/new', {userId: userId, token: token, course: material.course,
                topic: material.topic, project: material.project}, (data) => {
                    if (data && 'post' in data) {
                        update(data.post);
                    }
                }, saveError);
        } else update(material.post);
    }

    const deletePost = async () => {
        if (withFiles) {
            alert('Спочатку видаліть всі файли з публікації');
        } else {
            if (material.post > 0) {
                if (await confirm({confirmation: translate('¿Ви впевнені, що хочете видалити цей матеріал?', dicLang)})) {
                    postAPICall('materials/delete', {userId: userId, token: token, course: material.course, post: material.post,
                        postOrder: material.postOrder, topic: material.topic}, (data) => {
                            if (data && 'error' in data && data.error === 'none') {
                                updateNumbers(material.postOrder, -1);
                            }
                        }, saveError);
                }
            } else updateNumbers(material.postOrder, -1);
        }
    }

    return <div className="chatPost" style={{marginBottom: "24px", backgroundColor: bkgColor}}>
        <div style={{display: "flex", alignItems: "baseline"}}>
            <Translate>День</Translate><span style={{width: "8px"}}/>
            <NumericInput value={material.day} onChange={(n) => { 
                updateMaterial(material.postOrder, 'day', n); 
                setSaved(false);
                }} step={1} min={0} max={numDays} precision={0}/>
            <InfoButton text="Якщо цей курс статичний (передбачає, що студент отримує доступ до усіх матеріалів одночасно), у графі День вказуйте значення 0."/>
        </div>
        {kind !== 'post' && <div>
            <Form.Control value={material.taskName} type="text" id={new Date().getTime()}
                placeholder={translate("Назва завдання/проекту", dicLang)} 
                onChange={(e) => { updateMaterial(material.postOrder, 'taskName', e.target.value); }}/>
            <Form.Label><Translate>Дозволені розширення файлів</Translate>:</Form.Label>
            <div style={{display: "flex", alignItems: "flex-start"}}>
                {material.extensions.map((ext, i) => {
                    return ext === '' ? null :
                        <div key={i} className="divExtension" onClick={() => { delExtension(ext); }}>{ext}</div>
                })}
                <Form.Control value={newExtension} type="text" maxLength={10} style={{maxWidth: "84px"}}
                    onChange={(e) => { setNewExtension(e.target.value); }}/>
                <Button variant="link" disabled={newExtension.length===0} onClick={() => { addExtension(newExtension); }}>
                    <MdOutlineAddBox size="20"/></Button>
            </div>
        </div>}
        <RTEditor item={material.message} onChange={(v) => {
            updateMaterial(material.postOrder, 'message', v); 
            setSaved(false);
            }} bkgColor={bkgColor} flagChanged={() => {
                setSaved(false);
            }}/>
        <div style={{textAlign: "center"}}>
            <Button variant="outline-dark" style={{margin: "2px"}} onClick={save}
                disabled={saved || saving}><GiSave size="20"/></Button>
            {!unsavedMaterials && <>
                <Button variant="outline-dark" style={{margin: "2px"}} disabled={material.postOrder===1 || saving}
                    onClick={() => { updateNumbers(material.postOrder, material.postOrder - 1); }}>
                    <BiUpArrow size="20"/></Button>
                <Button variant="outline-dark" style={{margin: "2px"}} disabled={material.postOrder>=numPosts || saving}
                    onClick={() => { updateNumbers(material.postOrder, material.postOrder + 1); }}>
                    <BiDownArrow size="20"/></Button>
            </>}
            <Button variant="outline-dark" style={{margin: "2px"}} disabled={saving}
                onClick={deletePost}><BiTrash size="20"/></Button>
        </div>
        <UploadProgress progress={progress}/>
    </div>
}

export default Material