import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { translate } from "../../AppContext/Translate";
import { useState } from 'react';

function StudentsFilter({show, setFilter, dicLang}) {
    const [currentFilter, setCurrentFilter] = useState(0);

    const set = (value) => {
        setCurrentFilter(value);
        setFilter(value);
    }

    return <Form className='studentsFilter' style={{display: show ? "block" : "none"}}>
        <Row>
            <Col sm={4}>
                <Form.Check checked={currentFilter === 0} type="radio" id="f0" label={translate("Всі учні", dicLang)}
                    onChange={() => { set(0); }}/>
            </Col>
            <Col sm={4}>
                <Form.Check checked={currentFilter === 1} type="radio" id="f1" label={translate("З поставленими завданнями", dicLang)}
                    onChange={() => { set(1); }}/>
            </Col>
            <Col sm={4}>
                <Form.Check checked={currentFilter === 2} type="radio" id="f2" label={translate("З уточненими завданнями", dicLang)}
                    onChange={() => { set(2); }}/>
            </Col>
        </Row>
    </Form>
}

export default StudentsFilter