import { useState, useContext, useEffect } from "react";
import { AppContext } from "../../AppContext/AppContext";
import { getAPICall } from "../../Components/APICall";
import OrderLine from "./OrderLine";

function OrderDetail({order}) {
    const { userId, token } = useContext(AppContext);
    const [lines, setLines] = useState([]);
    
    useEffect(() => {
        getAPICall('orders/detail', {userId: userId, token: encodeURIComponent(token), orderId: order.id}, (data) => {
            if (data) {
                setLines(data);
            } 
        });
    }, [userId, token, order.id]);

    return <>
        {lines.map((line, i) => {
            return <OrderLine key={i} line={line}/>
        })}
    </>
}

export default OrderDetail