import RingLoader from "react-spinners/RingLoader";
import ProgressBar from 'react-bootstrap/ProgressBar';
import Translate from "../AppContext/Translate";
import Modal from 'react-bootstrap/Modal';

function UploadProgress({progress}) {
    return progress && <Modal show={true} centered>
        <Modal.Header>
            {progress.action === 'del' ? 
                <Translate>Видаляється</Translate> :
                progress.action === 'upl' ? 
                    <Translate>Завантажуються</Translate> :
                    <Translate>Оновлюється</Translate>}: {progress.file}
        </Modal.Header>
        <Modal.Body style={{display: "flex"}}>
            <div style={{marginRight: "8px"}}>
                <RingLoader color="cornflowerblue"/>
            </div>
            <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                <p style={{margin: "0px"}}>{progress.action === 'del' ? 
                    <Translate>Видаляється</Translate> :
                    progress.action === 'upl' ? 
                        <Translate>Завантажуються</Translate> :
                        <Translate>Оновлюється</Translate>}: {progress.file}</p>
                <ProgressBar animated now={progress.percent} /> 
            </div>
        </Modal.Body>
    </Modal>
}

export default UploadProgress