import { AttentionSeeker } from 'react-awesome-reveal';
import Translate from '../../AppContext/Translate';
import { HiOutlineInformationCircle } from 'react-icons/hi';
import { GiNightSleep } from 'react-icons/gi';
import BookmarkState from '../../Components/BookmarkState';
import LinesEllipsis from 'react-lines-ellipsis';

function CalendarDay({day, course, index, difference, setShowDayModal, setTopic, refreshData, highlightDay}) {
    var kind, color;
    if (!(day in index)) { kind = 'rest'; color = 'whitesmoke'; }
    else if (day < difference) { kind = 'available'; color = '#ffedbe'; }
    else if (day === difference) { kind = 'current'; color = 'darksalmon'; }
    else { kind = 'non_available'; color = 'gainsboro'; }
    const shadow = "drop-shadow(2px 3px 2px " + color + ")";

    const title = (day in index) && ('title' in index[day]) ? index[day].title : '';

    const elements = <div key={index} className="calendarDayPane" style={{filter: shadow}}>
        <div className="calendarTopDay" style={{backgroundColor: color}}>
            <Translate>День</Translate>
            {kind !== 'rest' &&
            <button onClick={() => {setShowDayModal(day);}} className="calendarDayInfo">
                <HiOutlineInformationCircle size={20} />
            </button>}
            {kind !== 'non_available' && kind !== 'rest' && 
            <BookmarkState context="day" kind={index[day].state} data={day} course={course} 
                refreshData={refreshData}/>}
        </div>
        { kind === 'non_available' ?
        <div className="calendarDay" style={{borderColor: color, color: color}} onClick={() => setShowDayModal(day)}>
            {day}
        </div> : (kind === 'rest' ? <div className="calendarDay" style={{borderColor: color, color: color, cursor: "default"}}>
            {day}
        </div> :
        <div className="calendarDay" style={{borderColor: color, color: color}} onClick={()=>setTopic(day)}>
            {day}
        </div>) }
        { kind === 'non_available' ?
        <div className="calendarCaption" style={{borderColor: color, color: color}} onClick={() => setShowDayModal(day)}>
            <LinesEllipsis text={title} maxLine='2' ellipsis='...' trimRight basedOn='letters' />    
        </div> : (kind === 'rest' ? <div className="calendarCaption" style={{borderColor: color,
            justifyContent: "center", cursor: "default"}}>
            <GiNightSleep size="22" color="gainsboro"/></div> :
        <div className="calendarCaption" style={{borderColor: color}} onClick={()=>setTopic(day)}>
            <LinesEllipsis text={title} maxLine='2' ellipsis='...' trimRight basedOn='letters' />    
        </div>)
        }
    </div>
    
    return highlightDay ?
        <AttentionSeeker effect="bounce">
            {elements}
        </AttentionSeeker>
        : <>{elements}</>
}

export default CalendarDay