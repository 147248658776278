import { useEffect, useContext, useState } from "react";
import { AppContext } from "../../AppContext/AppContext";
import { getAPICall } from "../../Components/APICall";
import { msToDays } from "../../Components/DateFormat";
import CalendarDay from "./CalendarDay";
import DayInfoModal from "./DayInfoModal";

function Calendar({course, numDays, groupData, pageParams, setTopicInfo, params}) {
    var { userId, token } = useContext(AppContext);
    var [index, setIndex] = useState({});
    const [showDayModal, setShowDayModal] = useState(-1);
    
    const setTopic = (day) => {
        if (day in index && 'topic' in index[day] && 'title' in index[day]) {
            setTopicInfo({topic: index[day].topic, title: index[day].title, day: day, show: 'day'});
        }
    }

    useEffect(() => {
        const contentInfoSuccess = (data) => {
            var info = {};
            for (var i = 0; i < data.length; i++) {
                const row = data[i];
                info[row.day] = {topic: row.topicId, state: row.state, date: row.markDate};
            }
            getAPICall('units/course', {course: course}, (data) => {
                if (data && data.length > 0) {
                    var units = {}
                    for (var i = 0; i < data.length; i++)
                        units[data[i].id] = {number: data[i].number, title: data[i].title, description: data[i].description};
                    for (i = 1; i <= numDays; i++) {
                        if (i in info) {
                            const topic = info[i].topic;
                            if (topic in units) {
                                info[i]['number'] = units[topic].number;
                                info[i]['title'] = units[topic].title;
                                info[i]['description'] = units[topic].description;
                            } else {
                                var j = i - 1;
                                while (j >= 1 && (!(j in info))) j--;
                                if (j >= 1) {
                                    info[i]['number'] = info[j].number;
                                    info[i]['title'] = info[j].title;
                                    info[i]['description'] = info[j].description;
                                }
                            }
                        }
                    }
                    setIndex(info);
                }
            });
        }
        const contentInfoError = (data) => {
        }
        getAPICall('courses/scheduledContent', {courseId: course, userId: userId, token: encodeURIComponent(token)},
            contentInfoSuccess, contentInfoError);
    }, [course, userId, token, numDays]);

    useEffect(() => {
        if (params && params.topicId > 0 && params.topicId in index) {
            const day = params.topicId;
            setTopicInfo({topic: index[day].topic, title: index[day].title, day: day, show: 'day'});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [index]);
    
    const refreshData = () => {
        getAPICall('courses/scheduledContent', {courseId: course, userId: userId, token: encodeURIComponent(token)},
            (data) => {
                var newIndex = {...index};
                for (var i = 0; data && i < data.length; i++) {
                    const row = data[i];
                    if (row.day in newIndex) {
                        newIndex[row.day].state = row.state;
                        newIndex[row.day].date = row.markDate;
                    }
                }
                setIndex(newIndex);
            });
    }

    const highlightDay = pageParams.topicId !== -1 && pageParams.postId === -1 ? pageParams.topicId : -1;

    var days2render = [];
    const difference = groupData ? Math.ceil(msToDays(groupData.difference)) : Number.MAX_SAFE_INTEGER;

    for (var day = 1; day <= numDays; day++) {
        days2render.push(<CalendarDay key={day} day={day} course={course} index={index} difference={difference}
            setShowDayModal={setShowDayModal} setTopic={setTopic} refreshData={refreshData}
            highlightDay={highlightDay === day}/>);
    }

    return <div className="calendarPane">
        {(showDayModal > 0) && <DayInfoModal index={index} showDayModal={showDayModal} 
            startDate={groupData ? groupData.startDate : null} difference={difference} 
            setShowDay={setShowDayModal} setTopic={setTopic}/>}
        {days2render}
    </div>
}

export default Calendar