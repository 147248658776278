import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Translate from '../../AppContext/Translate';
import { AiOutlineNodeExpand, AiOutlineNodeCollapse } from 'react-icons/ai';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { randomColor } from '../../Components/RandomColor';
import { FaChalkboardTeacher } from 'react-icons/fa';
import { MdCancelScheduleSend } from 'react-icons/md';
import { RiMailSendFill } from 'react-icons/ri';
import { Fade } from "react-awesome-reveal";

const task_state_done = 0;
const task_state_ready = 1;
const task_state_closed = 2;

function taskStatus(task, day) {
    return task["day"] > day ? task_state_closed :
        (task["sentFiles"] > 0 ? task_state_done : task_state_ready);
}

function LinkToTask(props) {
    return props.kind === task_state_closed ?
        <>{props.children}</> :
        <div onClick={() => { props.selectTask(props.data); }} style={{cursor: "pointer"}}>
            {props.children}
        </div>
}

function TaskPostIt({task, kind, day, selectTask}) {
    return taskStatus(task, day) === kind ?
        <div className="taskPostIt" style={{backgroundColor: randomColor(task["post"]),
            transform: "rotate(" + Math.floor(Math.random() * 30 - 15) + "deg)"}}>
            <div className="taskPin"><img alt="" src="/icons/pin.png" width="32px" height="25px"/></div>
            <LinkToTask data={task} kind={kind} selectTask={selectTask}>
                <div style={{padding: "6px"}}>
                    <div className="taskName">{task["taskName"]}</div>
                    <div className="taskReviewed">
                        {task.sentFiles === 0 ?
                        <MdCancelScheduleSend size={24} style={{marginRight: "6px"}} /> :
                        (task.numReviews === 0 ?
                        <RiMailSendFill size={24} style={{marginRight: "6px"}} />:
                        <FaChalkboardTeacher size={24} style={{marginRight: "6px"}} />)
                        }
                        {task.sentFiles === 0 ?
                        <Translate>Не виконано</Translate> :
                        (task.numReviews === 0 ?
                        <Translate>Надіслано</Translate> :
                        <Translate>Є коментар від тренера</Translate>)
                        }
                    </div>
                </div>
            </LinkToTask>
        </div> 
        : <></>
}

function TaskCard({kind, tasks, day, selectTask}) {
    const titles = ["Зроблено", "Готово", "В очікуванні"];
    const bkgColor = ["darkseagreen", "lightblue", "lightgray"];
    const tip = ["Список завдань, які ви вже виконали", 
                 "Доступні завдання, які ви вже можете виконувати",
                 "Завдання, які ще не доступні"];
    return <div className="taskCard">
        <OverlayTrigger rootClose trigger="click" placement="top" overlay={<Tooltip><Translate>{tip[kind]}</Translate></Tooltip>}>
        <div className='taskCardHeader' style={{backgroundColor: bkgColor[kind], cursor: "pointer"}}>
            <Translate>{titles[kind]}</Translate></div>
        </OverlayTrigger>
        {tasks.map((task, index) => (
            <TaskPostIt key={index} task={task} kind={kind} day={day} selectTask={selectTask}/>
        ))}
        <div style={{height: "32px", overflow: "hidden"}}/>
    </div>
}

function TasksList({expanded, setExpanded, tasks, dayNumber, selectTask}) {
    return <Container fluid>
        <Row>
            <Col className="topCourseMenu" style={{display: "flex", justifyContent: "space-between", borderRadius: "8px"}}>
                <div style={{marginLeft: "12px"}}><Translate>Завдання курсу</Translate></div>
                <div style={{display: "flex"}}>
                    { expanded ?
                        <button className="chatExpandButton" onClick={() => setExpanded(false)}>
                            <AiOutlineNodeExpand/> <Translate>Згорнути</Translate></button> :
                        <button className="chatExpandButton" onClick={() => setExpanded(true)}>
                            <AiOutlineNodeCollapse/> <Translate>Розгорнути</Translate></button>
                    }
                </div>
            </Col>
        </Row>
        {expanded &&
        <Fade>
        <Row style={{marginBottom: "24px"}}>
        <Col sm><TaskCard kind={task_state_done} tasks={tasks} day={dayNumber} selectTask={selectTask}/></Col>
        <Col sm><TaskCard kind={task_state_ready} tasks={tasks} day={dayNumber} selectTask={selectTask}/></Col>
        <Col sm><TaskCard kind={task_state_closed} tasks={tasks} day={dayNumber} selectTask={selectTask}/></Col>
        </Row>
        </Fade>
        }
  </Container>
}

export default TasksList