import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Translate from '../AppContext/Translate';
import MagButton from '../Components/MagButton';
import { useState } from 'react';
import { useWindowSize } from '../AppContext/WindowSize';

function SectionFormat({coursesRef}) {
    const [activeButton, setActiveButton] = useState(0);
    const [width,] = useWindowSize();

    const buttons = [{icon: "student", title: "Як влаштовано навчання", content: <>
        <p className="fkenyan xlarge bold"><Translate>цікава та зрозуміла теорія</Translate></p>
        <p className="top8"><Translate>Ми ретельно готуємо матеріали для курсу та дбаємо про їхню якість. При цьому ми переконані, що уроки мають викладатись простими словами, бути зрозумілими та неодмінно цікавими.</Translate></p>
        <p><Translate>Щоб ви не нудилися, поки навчаєшся.</Translate></p>
        <p className="top8"><Translate>Більшість з курсів має як теоретичні модулі так і практичні завдання з перевіркою від рецензентів.</Translate></p>
        <p className="top8"><Translate>Утім, як саме навчатися, обирати лише тобі!</Translate></p>
    </>},
    {icon: "comment", title: "Зворотній зв'язок", content: <>
        <p className="fkenyan xlarge bold"><Translate>завжди на зв'язку, завжди підтримаємо</Translate></p>
        <p className="top8"><Translate>Виконуйте практичні завдання та отримуйте зворотній зв'язок від тренерів та спільноти.</Translate></p>
        <p><Translate>Значна частина курсів має пакети, де ви можете долучитися до чату з підтримкою тренерів та спілкуванням з одногрупниками.</Translate></p>
        <p className="top8"><Translate>Відеоуроки, групові завдання та ігри допоможуть закріпити знання на практиці.</Translate></p>
        <p><Translate>А наша команда викладачів перевірить їх та надасть зворотний зв'язок.</Translate></p>
    </>},
    {icon: "user2", title: "особистий кабінет", content: <>
        <p className="fkenyan xlarge bold"><Translate>усе навчання в одному місці</Translate></p>
        <p className="top8"><Translate>Підключайтесь до особистого кабінету та передивляйтеся заняття, спілкуйтеся з викладачами, виконуйте домашні завдання - ми подбали, щоб ваше навчання було комфортним.</Translate></p>
    </>},
    {icon: "present", title: "винагороди", content: <>
        <p className="fkenyan xlarge bold"><Translate>індивідуальні винагороди</Translate></p>
        <p className="top8"><Translate>Ми цінуємо вашу присутність у нашій спільноті.</Translate></p>
        <p><Translate>Саме тому даруємо винагороди у вигляді балів за кожен придбаний курс або продукт, виконане домашнє завдання або проєкт, а також за відгуки.</Translate></p>
        <p><Translate>За допомогою індивідуальної мапи навчання, яку ви знайдете у себе в особистому кабінеті, ви зможете побачити, скільки кроків вже пройдено та які нагороди ви отримали.</Translate></p>
        <p className="top8"><Translate>Нам подобається робити подарунки!</Translate></p>
    </>},
    {icon: "diplom", title: "диплом", content: <>
        <p className="fkenyan xlarge bold"><Translate>в кінці навчання ви отримаєте диплом</Translate></p>
        <p className="top8"><Translate>Успішно виконавши всі завдання курсу, ви матимете наприкінці навчання ДИПЛОМ, де вказуватиметься, що ви успішно виконали фінальний проект і пройшли програму курсу.</Translate></p>
        <p className="top8"><Translate>Деякі курси або пакети курсів передбачають самостійну роботу з матеріалами і не включають перевірку фінальної роботи викладачем.</Translate></p>
        <p><Translate>В такому разі ви отримаєте СЕРТИФІКАТ учасника курсу.</Translate></p>
        <p className="top8"><Translate>Тож, якщо ви надаєте перевагу самостійному опануванню тем, без виконання завдань, такі курси/пакети дозволять вам навчатися у комфортному для себе форматі та передивлятися матеріали у зручний момент.</Translate></p>
        <p><Translate>Диплом/сертифікат ви отримаєте українською і англійською мовами.</Translate></p>
        <p><Translate>Свої досягнення ви зможете з гордістю додати до свого резюме.</Translate></p>
    </>}];

    return <div style={{backgroundColor: "white", paddingTop: "48px"}}>
        <img alt="" src="/bkg/decor/dec5.png" style={{position: "absolute", width: "160px", maxWidth: "20%",
            bottom: "calc(100% - 64px)", zIndex: 100, right: "0px"}}/>
        <img alt="" src="/bkg/decor/dec6.png" style={{position: "absolute", width: "32px",
            bottom: "calc(100% - 64px)", zIndex: 100, left: "4%"}}/>
        <div className="centeredSection" style={{backgroundColor: "#fef5de"}}>
            <Row>
                <Col lg={6} style={{paddingLeft: "32px", paddingTop: "64px"}}>
                    <h1><Translate>ФОРМАТ НАВЧАННЯ</Translate></h1>
                    <p className="fkenyan bold large"><Translate>Як саме навчатися, обирати лише тобі!</Translate></p>
                    <MagButton text="обрати курс" icon="/bkg/down.png" margin="24px 0px 0px 0px"
                        clickEff={{scrollRef: coursesRef}}/>
                </Col>
                <Col lg={6} className="center">
                    <img alt="" src={"/bkg/" + buttons[activeButton].icon + ".png"} width="84" style={{marginTop: "32px"}}/>
                    <div className="round pad16 top24" style={{backgroundColor: "white"}}>
                        {buttons[activeButton].content}
                    </div>
                </Col>
            </Row>
            <div className="sround" style={{backgroundColor: "#fac032", marginTop: "32px", display: "flex",
                flexWrap: "wrap", justifyContent: "space-between", marginBottom: "32px"}}>
                {buttons.map((button, i) => {
                    const active = i === activeButton;
                    return <div key={i} style={{backgroundColor: active ? "#12c191" : "#fac032", display: "flex",
                        alignItems: "center", cursor: "pointer"}} className="pad12 sround" onClick={() => {
                            setActiveButton(i);
                        }}>
                        <img alt="" src={"/bkg/" + button.icon + ".png"} width="32"
                            style={{filter: active ? "invert(1)" : "none", marginRight: "6px"}}/>
                        <p className="fkenyan" style={{color: active ? "white" : "black"}}>{button.title}</p>
                        {active && width > 908 && <img alt="" src="/bkg/decor/dec7.png"
                            style={{position: "absolute", left: "-2px", bottom: "-26px", width: "100%", zIndex: 100}}/>}
                    </div>
                })}
            </div>
        </div>
    </div>
}

export default SectionFormat