import UserPlan from "./UserPlan";

function UserListCourses({courses, approvals}) {
    const imgExt = ".jpg"; 

    return <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-around", alignItems: "center"}}
        className="top24 bot24">
        {
        courses.map((course, k) => {
            return <UserPlan key={k} course={course} ext={imgExt} approvals={approvals}/>
        })
        }
    </div>
}

export default UserListCourses