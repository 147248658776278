import { Link } from 'react-router-dom';
import Translate from '../AppContext/Translate';
import MagButton from '../Components/MagButton';

function Item({data}) {
    const addr = "/product/" + data.id;
    const pic = "/items/item" + data.id + ".jpg";
    return <div className="pad16">
        <div className='mainCard' style={{backgroundColor: "white"}}>
            <div className="shineHover">
                <Link to={addr}>
                    <figure style={{overflow: "hidden"}}><img alt="" src={pic} width="100%" /></figure>
                </Link>
                <div className='divKindProduct'><Translate>{data.digital === 0 ? "Фізичний продукт" : "Цифровий продукт"}</Translate></div>
            </div>
            <div style={{padding: "0px 12px 0px 12px"}}>
                <Link to={addr} className="blackTextHover"><p className="fkenyan bold large">{data.name}</p></Link>
                <p className="fmak top8 small">
                    <Link className="schoolLink" to={"/school/" + data.school}>{data.title}</Link>
                </p>
                <div className="top12" style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                    <p className="small bot8 italic">{data.price} грн.</p>
                    <MagButton text="детальніше" bkgColor="white" color="#f94646" margin="0px" size="smaller"/>
                </div>
            </div>
            <MagButton text="купити" margin="16px auto 8px auto" bkgColor="#12c191" icon="/bkg/cart.png"
                size="large"/>
        </div>
    </div>
}

export default Item