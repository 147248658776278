import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import { getAPICall } from '../Components/APICall';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Translate from '../AppContext/Translate';

function ShopFilter({show, setSchool, setItemType}) {
    const [schools, setSchools] = useState(null);
    
    const onDropdownClick = (evnt) => {
        if (schools === null) {
            getAPICall('school/list', {}, (data) => {setSchools(data);});
        }
    }

    return <Form className='shopFilter' style={{display: show ? "block" : "none"}}>
        <Row>
            <Col sm={6}>
                <Form.Label><Translate>Фільтрувати за школою</Translate></Form.Label>
                <Form.Select onClick={onDropdownClick} onChange={e => { setSchool(e.target.value);}}>
                    <option value="0">---</option>
                    {schools &&
                        schools.map((item, index) => {
                            return <option key={index} value={item.id}>{item.title}</option>
                        })
                    }
                </Form.Select>
            </Col>
            <Col sm={6}>
                <Form.Label><Translate>Фільтрувати за типом товару</Translate></Form.Label>
                <Form.Select onChange={e => { setItemType(e.target.value);}}>
                    <option value="0"><Translate>Все</Translate></option>
                    <option value="1"><Translate>Цифрові</Translate></option>
                    <option value="2"><Translate>Фізичні</Translate></option>
                </Form.Select>
            </Col>
        </Row>
    </Form>
}

export default ShopFilter