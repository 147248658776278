import { useSearchParams } from "react-router-dom"
import { useEffect, useState, useContext } from "react";
import { getAPICall } from "../Components/APICall";
import Menu from "../Menu/Menu";
import Footer from "../Components/Footer";
import Translate from "../AppContext/Translate";
import { chooseRowByLang } from "../AppContext/Translate";
import { AppContext } from "../AppContext/AppContext";
import PostMessage from "../Components/PostMessage";
import { MediaPath } from "../Components/MediaPath";
import HomeButton from "../Components/HomeButton";

function DiplomaValidate() {
    const [params] = useSearchParams();
    var code = params.get("code");
    if (!code) code = '';
    else code = code.replaceAll(' ', '+');
    const { lang } = useContext(AppContext);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);    
    const [courseInfo, setCourseInfo] = useState(null);

    useEffect(() => {
        const courseInfoSuccess = (data) => {
            var row = chooseRowByLang(data, lang);
            if (row !== null) {
                var path = new MediaPath(MediaPath.kind_openMediaCourse);
                path.add(row.course);
                row['path'] = path;
                setCourseInfo(row);
            }
        }
        const validateError = () => {
            setError(true);
        }
        const validateOk = (data) => {
            if (!data || 'error' in data) validateError();
            else {
                setData(data);
                getAPICall('courses/info', {courseId: data.course}, courseInfoSuccess);
            }
        }
        getAPICall('diploma/validate', {code: encodeURIComponent(code)},
            validateOk, validateError);
    }, [code, lang]);

    return <div className='mainContainer'>
        <Menu />
        <div className="userProfileForm" style={{margin: "16px"}}>
            <h1><Translate>{data && data.diploma === 0 ? "Сертифікат" : "Диплом"}</Translate></h1>
            {error ? <>
                <h4 style={{marginTop: "16px", color: "crimson"}}><Translate>Помилка</Translate>:</h4>
                <p><Translate>Диплом не підтвердили</Translate>.</p>
                </>
            : <>
            <hr/>
            {data && <>
            <h2>{data.courseName}</h2>
            <h4 style={{marginTop: "24px"}}><Translate>Нагороджується</Translate>: <span style={{color: "cornflowerblue"}}>{data.userName}</span></h4>
            </>}
            {courseInfo && <>
                <hr style={{marginTop: "36px"}}/>
                <PostMessage key="desc" msg={courseInfo["description"]} path={courseInfo.path} />
                <PostMessage key="goal" msg={courseInfo["goals"]} path={courseInfo.path} sep={false} />
            </>}
            </>
            }
        </div>
        <HomeButton/>
        <Footer/>
    </div>
}

export default DiplomaValidate