import { TiMinus, TiPlus } from "react-icons/ti";
import { useState } from "react"; 

function NumericSpinner({amount, min=1, max=30, onChange=null, width=32}) {
    const [quantity, setQuantity] = useState(amount < min ? min : (amount > max ? max : amount));

    const decrease = () => {
        setQuantity(quantity => Math.max(quantity - 1, min));
        if (onChange) onChange(Math.max(quantity - 1, min));
    }

    const increase = () => {
        setQuantity(quantity => Math.min(quantity + 1, max));
        if (onChange) onChange(Math.min(quantity + 1, max));
    }

    return <div style={{display: "flex", justifyContent: "center"}}>
        <div style={{backgroundColor: "cadetblue", width: "28px", height: "28px", borderTopLeftRadius: "50%",
            borderBottomLeftRadius: "50%", cursor: "pointer", paddingLeft: "8px"}} onClick={decrease}><TiMinus size="16px"/></div>
        <div className="preventSelect" style={{backgroundColor: "white", textAlign: "center", width: width + "px", 
            border: "cadetblue solid thin", fontFamily: "Mak", fontSize: "16px"}}>{quantity}</div>
        <div style={{backgroundColor: "cadetblue", width: "28px", height: "28px", borderTopRightRadius: "50%",
            borderBottomRightRadius: "50%", cursor: "pointer", paddingLeft: "4px"}} onClick={increase}><TiPlus size="16px"/></div>
    </div>
}

export default NumericSpinner