import { formatDate } from "../../AppContext/Translate";
import { PiChecksBold } from "react-icons/pi";

function Message({data}) {
    const sent = data.sent === 1;
    return <div className={sent ? "from-me" : "from-them"}>
        <p style={{padding: "6px 0px"}}>{data.message}</p>
        <p className="small" style={{textAlign: "right"}}>{formatDate(data.msgDate)}
            {sent && data.readDate && <PiChecksBold style={{marginLeft: "6px", color: "cornflowerblue"}} size="18px"/>}
        </p>
    </div>
}

export default Message
