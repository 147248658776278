import { useEffect, useState, useContext } from "react"
import { getAPICall, postAPICall } from "../../Components/APICall";
import Translate, { translate } from "../../AppContext/Translate";
import Button from "react-bootstrap/Button";
import { AppContext } from "../../AppContext/AppContext";
import Alert from 'react-bootstrap/Alert';
import { timeAgo } from "../../Menu/MenuNotifications";

function ActivationState({data}) {
    const { userId, token, dicLang } = useContext(AppContext);
    const [info, setInfo] = useState(null);
    const [errorMsg, setErrorMsg] = useState(null);

    const activationError = (msg) => {
        alert(translate("Помилка", dicLang) + ": " + translate("не вдалося зберегти зміни", dicLang));
        if (msg) setErrorMsg(msg);
    }

    const activateCourse = () => {
        postAPICall('course/activationRequest', {userId: userId, token: token, course: data.id},
            (d) => {
                if (d && 'error' in d) {
                    if (d.error === "none") {
                        getAPICall('course/activationRequest', {course: data.id}, (d) => {
                            if (d && d.length > 0) setInfo(d[0]);
                        });
                    }
                    else activationError(d.error);
                } else activationError(null);
            }, () => { activationError(null); });
    }

    useEffect(() => {
        getAPICall('course/activationRequest', {course: data.id}, (d) => {
            if (d && d.length > 0) setInfo(d[0]);
            else setInfo(null);
        });
    }, [data.id]);

    return <>
        {!('active' in data) || data.active === 0 ? (
            !info ? <div style={{textAlign: "center", marginTop: "16px"}}>
                <Button onClick={activateCourse}><Translate>Запит на активацію курсу</Translate></Button>
            </div> : <>
            {info.adminUser === null ? <p><Translate>Запит на активацію надіслано</Translate>.</p> : <>
            <p style={{color: "darkred", fontStyle: "italic"}}>
                <Translate>Адміністратор відхилив ваш курс</Translate>
                {info && info.rejectDate && <span> ({timeAgo(info.rejectDate, info.now, dicLang)})</span>
            }.</p>
            {info.adminComment && <Alert key="warning" variant="warning">{info.adminComment}</Alert>}
            <div style={{textAlign: "center"}}>
                <Button onClick={activateCourse}><Translate>Solicitar activación del curso</Translate></Button>
            </div></>}
            </>
        ) : <p style={{fontSize: "smaller"}}><Translate>Адміністратор активував ваш курс</Translate>{
            info && info.approvalDate && <span> ({timeAgo(info.approvalDate, info.now, dicLang)})</span>
        }.</p>}
        {errorMsg && <Alert key="warning" variant="warning" style={{marginTop: "16px"}}>
                <Translate>{errorMsg}</Translate>.</Alert>}
    </>
}

export default ActivationState