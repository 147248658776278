import MenuUserIcon from "./MenuUserIcon";
import { translate, TranslateWithLink } from "../../AppContext/Translate";
import ModalAlert from "../../Components/ModalAlert";

function LoginShowError({error, content, onClickEvent, show, onClose, size, userName, dicLang}) {
    const title = translate(content["title"], dicLang);
    const message = "link" in content ? <TranslateWithLink link={content["link"]}>{content["msg"]}</TranslateWithLink> :
        translate(content["msg"], dicLang);
    const type = error ? "modal-danger" : "modal-success";
    const close = translate("Закрити", dicLang);
    return (<>
        <MenuUserIcon handleShow={onClickEvent} size={size} userName={userName} />
        <ModalAlert type={type} title={title} msg={message}
            buttonCaption={close} show={show} handleClose={onClose} />
    </>);
}

export default LoginShowError