import { useParams } from "react-router-dom"
import Menu from "../Menu/Menu";
import Footer from "../Components/Footer";
import Translate from "../AppContext/Translate";
import { useState, useEffect } from "react";
import { getAPICall } from "../Components/APICall";
import InfiniteScroll from "react-infinite-scroll-component";
import SyncLoader from "react-spinners/SyncLoader";
import UserPhotoIcon from "../Components/UserPhotoIcon";
import { Link } from "react-router-dom";

function UsersEnd() {
    return <p><Translate>Користувачів більше немає</Translate></p>
}

function CitySearch() {
    const city = useParams()['id'];
    const addr = "https://www.google.com/maps/embed/v1/place?q=" + encodeURIComponent(city) +
        "&key=AIzaSyAeymMoP373RK5SJvSFP4fnWWcRxojyP2M";
    const [state, setState] = useState({items: [], hasMore: true, offset: 0});
    const limit = 25;
    
    const getUsersSuccess = (data) => {
        if (data) {
            setState({
                items: state.items.concat(data),
                hasMore: data.length >= limit,
                offset: state.offset + data.length
            });
        } else getUsersError(data);
    }

    const getUsersError = (data) => {
        setState({items: state.items, hasMore: false, offset: state.offset});
    }

    const fetchMoreData = () => {
        getAPICall('user/fromCity', {city: encodeURIComponent(city), offset: state.offset, limit: limit},
            getUsersSuccess, getUsersError);
    }

    useEffect(() => {
        fetchMoreData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <div className='mainContainer' style={{backgroundColor: "white"}}>
        <Menu />
        <h1><Translate></Translate></h1>
        <iframe title="map" width="100%" height="360" style={{frameborder: "0", border: "0", marginTop: "-8px"}} src={addr}/>
        <div style={{padding: "8px 32px 8px 32px"}}>
            <h3><Translate>Творчі люди в</Translate> {city}</h3>
            <div id="notifScrollableDiv">
            <InfiniteScroll
                dataLength={state.items.length}
                next={fetchMoreData}
                hasMore={state.hasMore}
                loader={<p><SyncLoader size={18} color="#73694c"/></p>}
                scrollableTarget="notifScrollableDiv"
                endMessage={<UsersEnd/>}>
                {state.items.map((data, index) => 
                    <div key={index} style={{paddingLeft: "8px", display: "flex", alignItems: "center", 
                        margin: "16px 0px 16px 0px"}}>
                        <UserPhotoIcon user={data.id} userName={data.name} big={true}/>
                        <Link to={"/profile/" + data.id}>
                            <p className="userLink" style={{margin: "0px 0px 0px 8px", fontSize: "large"}}>{data.name}</p>
                        </Link>
                    </div> )}
            </InfiniteScroll>
            </div>
        </div>
        <Footer/>
    </div>
}

export default CitySearch