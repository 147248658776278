import Button from 'react-bootstrap/Button';
import Translate from '../AppContext/Translate';
import { TbHomeUp } from "react-icons/tb";
import { Link } from "react-router-dom";

function CabHomeButton() {
    return <div style={{textAlign: "center", margin: "16px 0px 24px 0px"}}>
        <Link to="/cabinet/menu">
            <Button variant="outline-dark" size="sm">
                <Translate>Повернутися до кабінету</Translate>
                <TbHomeUp size="16" style={{marginLeft: "8px", marginTop: "-2px"}}/>
            </Button>
        </Link>
    </div>
}

export default CabHomeButton