import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ReactStars from 'react-rating-stars-component';
import { useEffect, useContext, useState } from 'react';
import { AppContext } from '../AppContext/AppContext';
import { getAPICall, postAPICall } from '../Components/APICall';
import Translate, { translate } from '../AppContext/Translate';
import WriteHelper from '../Components/WriteHelper';
import SimpleComment from '../Components/SimpleComment';

function RateCourse({title, courseId, show, setShow}) {
    const { userId, token, dicLang } = useContext(AppContext);
    const [opinion, setOpinion] = useState([]);
    const [html, setHtml] = useState("");
    const [score, setScore] = useState(0);

    const opinionSent = (data) => {
      if ('error' in data && data.error === 'none') {
        setShow(false);
      } else {
        opinionError();
      }
    }

    const opinionError = () => {
      alert(translate('Помилка', dicLang) + ': ' + 
      translate('не вдалося зберегти зміни', dicLang));
    }

    const send = () => {
      const sc = Math.round(score * 2);
      const msg = WriteHelper.encode(html);
      postAPICall('user/opinion', {userId: userId, course: courseId, token: token, score: sc, msg: msg},
        opinionSent, opinionError);
    }

    const ratingChanged = (newRating) => {
      setScore(newRating);
    }

    useEffect(() => {
        const onOpinionSuccess = async (data) => {
            if (data && data.length > 0) {
                const op = data[0]
                setOpinion(op);
                setScore(op.score / 2.0);
                var h = '';
                if (op.message) {
                  h = await WriteHelper.parseMessage(op.message, null);
                  h = h['text'];
                }
                setHtml(h);
            }
        }
        if (show) {
            getAPICall('user/opinion', {user: userId, course: courseId}, onOpinionSuccess);
        }
    }, [userId, courseId, show]);
    
    return <Modal show={show} onHide={() => { setShow(false); }} backdrop="static" keyboard={false}>
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        {opinion.length === 0 ?
        <><p style={{fontWeight: "600"}}><Translate>Залиште відгук про цей курс</Translate>.</p>
        <p style={{fontStyle: "italic"}}><Translate>Ваша думка важлива для нас, допоможіть нам вдосконалитися</Translate>.</p></>
        : <><p style={{fontWeight: "600"}}><Translate>Дякуємо за оцінку цього курсу!</Translate></p>
        <p style={{fontStyle: "italic"}}><Translate>Якщо ваша думка змінилася, ви можете оновити її нижче</Translate>:</p></>
        }
        <ReactStars key={score} count={5} value={score} edit={true} isHalf={true} size={32} onChange={ratingChanged}/>
        <p><Translate>Коментар</Translate> (<Translate>не обов'язково</Translate>):</p>
        <SimpleComment html={html} setHtml={setHtml}/>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={() => { setShow(false); }}>
        <Translate>Скасувати</Translate>
      </Button>
      <Button variant="primary" onClick={() => { send(); }}>
        <Translate>Надіслати</Translate></Button>
    </Modal.Footer>
  </Modal>
}

export default RateCourse