import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../AppContext/AppContext";
import { getAPICall, postAPICall } from "../../Components/APICall";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Translate from "../../AppContext/Translate";
import Form from 'react-bootstrap/Form';
import BeatLoader from "react-spinners/BeatLoader";
import Delivery from "./Delivery";
import SyncLoader from "react-spinners/SyncLoader";

function TaskDelivery({user, postId, privacy, setPrivacy, groupId, packageId, task, markTaskAsSent, options}) {
    const { userId, token } = useContext(AppContext);
    const [updating, setUpdating] = useState(false);
    const [delivery, setDelivery] = useState(null);

    useEffect(() => {
        const onDeliverySuccess = (data) => {
            if (data.length > 0 && !("error" in data[0])) {
                setDelivery(data);
            } else onDeliveryError(data); 
        }

        const onDeliveryError = (data) => {
            setDelivery([]);
        }

        getAPICall('tasks/delivery', {userId: userId, token: encodeURIComponent(token),
            user: user.user, post: postId, groupId: groupId ? groupId : -1, pkgId: packageId},
            onDeliverySuccess, onDeliveryError);
    }, [groupId, postId, user, userId, token, task, packageId]);

    const onSetPrivacy = () => {
        setUpdating(false);
    }

    const changePrivacy = (checked) => {
        setUpdating(true);
        setPrivacy(!checked);
        postAPICall('tasks/setPrivacy', {userId: userId, token: token, postId: postId, value: checked ? 0 : 1},
            onSetPrivacy, onSetPrivacy);
    }

    const onUpdateSuccess = (data) => {
        if (data.length > 0 && !("error" in data[0])) {
            setDelivery(data);
        } 
    }

    const updateDelivery = () => {
        getAPICall('tasks/delivery', {userId: userId, token: encodeURIComponent(token),
            user: user.user, post: postId, groupId: groupId ? groupId : -1, pkgId: packageId}, onUpdateSuccess);
    }

    const canSend = (task.project === 0 && options.includes('Y')) || (task.project === 1 && options.includes('Z'));

    return <>
    {user.user === userId && canSend &&
        <Row>
            <Col style={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
                <p style={{margin: "0px 6px 2px 0px"}}><Translate>
                    {task.project === 1 ? "Опублікувати мій проект" : 
                    "Поділитися моїми роботами з іншими студентами"}</Translate></p>
                {updating ? <SyncLoader size="9px" color="blueviolet"/> : 
                    <Form.Check type="switch" checked={!privacy} onChange={() => { changePrivacy(privacy); }}/>}
            </Col>
        </Row>
    }
    {delivery ? <Delivery delivery={delivery} user={user.user} task={task} markTaskAsSent={markTaskAsSent}
        userName={user.name} group={groupId} packageId={packageId} updateDelivery={updateDelivery} canSend={canSend}/>
    : <Row>
        <Col style={{textAlign: "center"}}>
            <BeatLoader size={24} color="blueviolet"/>
        </Col>
    </Row>
    }
    </>
}

export default TaskDelivery