import { useEffect, useState } from "react";
import Valoration from "../Lists/Valoration";
import SyncLoader from "react-spinners/SyncLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import { getAPICall } from "../Components/APICall";
import Translate from "../AppContext/Translate";
import UserOpinion from "./UserOpinion";

function CourseOpinions({courseId, title="Bідгуки", score=null, numOpinions=null}) {
    const [state, setState] = useState({items: [], hasMore: true, offset: 0});
    const limit = 48;

    const opinionsError = (data) => {
        setState({items: state.items, hasMore: false, offset: state.offset});
    }

    const opinionsSuccess = (data, reset) => {
        if (data && data.length > 0) {
            if (reset)
                setState({
                    items: data,
                    hasMore: data.length >= limit,
                    offset: data.length
                });
            else
                setState({
                    items: state.items.concat(data),
                    hasMore: data.length >= limit,
                    offset: state.offset + data.length
                });
        } else opinionsError(data);
    }

    const fetchMoreData = (reset=false) => {
        getAPICall('course/opinions', {courseId: courseId, offset: reset ? 0 : state.offset, limit: limit},
            (data) => { opinionsSuccess(data, reset); }, opinionsError);
    }

    useEffect(() => {
        fetchMoreData(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [courseId]);

    return <>
        {state.items.length > 0 && <>
            {title && <h1 style={{fontWeight: "600"}}><Translate>Bідгуки</Translate></h1>}
            {score && numOpinions && <Valoration score={score} numOpinions={numOpinions} size={18}/>}
        </>}
        <div id="opScrollableDiv">
            <InfiniteScroll
                dataLength={state.items.length}
                next={fetchMoreData}
                hasMore={state.hasMore}
                loader={<p><SyncLoader size={18} color="#73694c"/></p>}
                scrollableTarget="opScrollableDiv"
                endMessage={<></>}>
                {state.items.map((data, index) =>
                    <UserOpinion key={index} user={data.user} userName={data.name} score={data.score}
                        message={data.message} date={data.firstUpdate}/>)}
            </InfiniteScroll>
        </div>
    </>
}

export default CourseOpinions