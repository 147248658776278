import Modal from 'react-bootstrap/Modal';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from 'react-bootstrap/Button';
import Container from "react-bootstrap/Container";
import { useState, useContext } from "react";
import { AppContext } from "../../AppContext/AppContext";
import Translate from "../../AppContext/Translate";
import { translate } from "../../AppContext/Translate";
import isEmail from 'validator/es/lib/isEmail';
import BeatLoader from  'react-spinners/BeatLoader';
import ModalAlert from "../../Components/ModalAlert";
import ImageBackground from "../../Components/ImageBackground";
import { useWindowSize } from "../../AppContext/WindowSize";
import { Navigate } from "react-router-dom";
import ShakeOnError from '../../Components/ShakeOnError';
import { AiOutlineNotification } from 'react-icons/ai';
import LeftLoginImage from './LeftLoginImage';
import ModalBrandHeader from '../../Components/ModalBrandHeader';
import { getAPICall } from '../../Components/APICall';

function RecoverInvalidEmail({error}) {
    return error ? 
    <div style={{backgroundColor: "#ffcfcf", borderTopLeftRadius: "var(--bs-modal-border-radius)",
        borderTopRightRadius: "var(--bs-modal-border-radius)", textAlign: "center", padding: "12px"}}>
        <AiOutlineNotification size={28}/>
        <p style={{margin: "0px", fontWeight: "bold"}}><Translate>Помилка</Translate>: <Translate>за вказаною електронною адресю немає зареєстрованого користувача</Translate>.</p>
    </div> :
    <></>
}

function RecoverPassword() {
    const mode_form = 0;
    const mode_invalid_email = 1;
    const mode_email_not_registered = 2;
    const mode_close = 3;
    const mode_success = 4;
    const mode_error = 5;

    var {lang, dicLang} = useContext(AppContext);
    const [state, setState] = useState(mode_form);
    const [loading, setLoading] = useState(false);
    const [emailInput, setEmailInput] = useState("");
    const [width, ] = useWindowSize();
    const size = width >= 768 ? "big" : (width < 576 ? "tiny" : "small");
    const emailStyle = state === mode_invalid_email ? {borderColor: 'red', marginBottom: '2px'} : {};
    const child = state === mode_invalid_email 
        ? <p style={{color: 'red', fontSize: 'small'}}><Translate>Здається, ви вказали неправильну електронну адресу</Translate>. <Translate>Будь ласка, перевірте дані ще раз та повторіть спробу</Translate>.</p>
        : <></>;

    const handleClose = () => {
        setState(mode_close);
    }
    const onChangeEmail = (evnt) => {
        setEmailInput(evnt.target.value);
    }
    const formSubmit = (evnt) => {        
        evnt.preventDefault();
        if (!isEmail(emailInput)) {
            setState(mode_invalid_email);
        } else {
            setLoading(true);
            getAPICall('user/recoverPass', {email: emailInput, lang: lang}, sendEmailSuccess, sendEmailError);
        }
    }
    const sendEmailSuccess = (data) => {
        setLoading(false);
        const error = data['error'];
        if (error === 'none') {
            setState(mode_success);
        } else if (error === 'invalid_email') {
            setState(mode_email_not_registered);
        } else {
            setState(mode_error);
        }
    }
    const sendEmailError = (data) => {
        setLoading(false);
        setState(mode_error);
    }

    switch (state) {
    case mode_form:
    case mode_invalid_email:
    case mode_email_not_registered:
        return <ImageBackground>
            <Modal show={true} onHide={handleClose} backdrop="static" keyboard={false} size="xl" centered>
                <ShakeOnError error={state === mode_email_not_registered}>
                    <RecoverInvalidEmail error={state === mode_email_not_registered} />
                        <Container>
                            <Row>
                                {(size === "big") && <LeftLoginImage/>}
                                <Col style={{paddingLeft: '0px', paddingRight: '0px'}}>
                                    <ModalBrandHeader/>
                                    <div style={{backgroundColor: '#ebe7d6'}}>
                                        <Modal.Body>
                                            <h1><Translate>Відновити пароль</Translate></h1>
                                            <p><Translate>Ми надішлемо вам електронний лист із посиланням, щоб ви могли змінити свій пароль</Translate>.</p>
                                        </Modal.Body>
                                        <Modal.Body>
                                            <form className="Auth-form" onSubmit={formSubmit}>
                                                <div>
                                                    <input type="email" className="form-control" placeholder={translate("Електронна адреса", dicLang)} 
                                                        style={emailStyle} onChange={onChangeEmail} value={emailInput} />
                                                    {child}
                                                </div>
                                                <div style={{display: "flex", justifyContent: "flex-end", gap: "12px"}}>
                                                    <Button variant="primary" type="submit">
                                                        { loading ? <BeatLoader size={24} color="white" /> : translate("Відновити пароль", dicLang) }
                                                    </Button>
                                                    <Button variant="secondary" type="button" onClick={handleClose}><Translate>Скасувати</Translate></Button>
                                                </div>
                                            </form>
                                        </Modal.Body>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                </ShakeOnError>
            </Modal>
        </ImageBackground>
    case mode_close:
        return <Navigate to="/" replace={true} />
    case mode_success:
        return <ImageBackground>
            <ModalAlert type="modal-success" title={translate("Електронний лист для відновлення надіслано", dicLang)} 
                    msg={translate("Невдовзі ви отримаєте електронний лист для скидання пароля", dicLang) + "."}
                    buttonCaption={translate("Прийняти", dicLang)} show={true} handleClose={handleClose} />
        </ImageBackground>
    default:
        return <ImageBackground>
            <ModalAlert type="modal-danger" title={translate("Помилка надсилання електронного листа", dicLang)} 
                msg={translate("Спробуйте пізніше або напишіть до нашої служби підтримки", dicLang) + "."}
                buttonCaption={translate("Закрити", dicLang)} show={true} handleClose={handleClose} />
        </ImageBackground>            
    }
}

export default RecoverPassword