import Translate from "../../AppContext/Translate"
import { LiaDropbox } from "react-icons/lia";
import { TbTruckDelivery } from "react-icons/tb";
import { FaFlagCheckered } from "react-icons/fa";
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { postAPICall } from "../../Components/APICall";
import { AppContext } from "../../AppContext/AppContext";
import { useContext, useState } from "react";

function OrderState({state, editable=null}) {
    const { userId, token } = useContext(AppContext);
    const [orderState, setOrderState] = useState(state);

    const changeState = (newState) => {
        document.body.click();
        postAPICall('orders/changeStatus', {userId: userId, token: token, order: editable.id,
            item: editable.item, client: editable.client, status: newState}, (data) => {
            if (data && 'error' in data && data.error === 'none') {
                setOrderState(newState);
            }
        });
    }
    
    const popover = <Popover>
        <Popover.Body>
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <div className="bookmarkOption" style={{padding: "0px 6px 4px 6px"}} onClick={() => changeState(0)}>
                    <p style={{margin: "11px 0px 6px 0px"}}><LiaDropbox color={orderState !== 0 ? "indianred" : "gray"} size="24"/></p>
                    <p><Translate>В обробці</Translate></p>
                </div>
                <div className="bookmarkOption" style={{padding: "0px 6px 4px 6px"}} onClick={() => changeState(1)}>
                    <p style={{margin: "11px 0px 6px 0px"}}><TbTruckDelivery color={orderState !== 1 ? "royalblue" : "gray"} size="24"/></p>
                    <p><Translate>Надіслано</Translate></p>
                </div>
                <div className="bookmarkOption" style={{padding: "0px 6px 4px 6px"}} onClick={() => changeState(2)}>
                    <p style={{margin: "11px 0px 6px 0px"}}><FaFlagCheckered color={orderState !== 2 ? "cornflowerblue" : "gray"} size="24"/></p>
                    <p><Translate>Отримано</Translate></p>
                </div>
            </div>
        </Popover.Body>
    </Popover>

    const elems = <div style={{cursor: editable ? "pointer" : "auto"}}>
        {orderState === 0 && <div style={{color: "indianred", fontStyle: "italic"}}>
            <LiaDropbox size={20} style={{marginRight: "6px", marginTop: "-4px"}}/>
            <Translate>В обробці</Translate>
        </div>}
        {orderState === 1 && <div style={{color: "royalblue", fontStyle: "italic"}}>
            <TbTruckDelivery size={20} style={{marginRight: "6px", marginTop: "-4px"}}/>
            <Translate>Надіслано</Translate>
        </div>}
        {orderState === 2 && <div style={{color: "cornflowerblue", fontStyle: "italic"}}>
            <FaFlagCheckered size={20} style={{marginRight: "6px", marginTop: "-4px"}}/>
            <Translate>Отримано</Translate>
        </div>}
    </div>
    // 	0:preparation, 1:sent, 2:completed	
    return editable ? <OverlayTrigger rootClose trigger="click" placement="bottom" overlay={popover}>
        {elems}
    </OverlayTrigger>
    : elems
}

export default OrderState