import { Navigate, useParams } from "react-router-dom";
import { convert2int } from "../../Course/Course";
import { useContext, useEffect, useState } from "react";
import { checkEditPermission } from "../Courses/CheckEditPermission";
import { AppContext } from "../../AppContext/AppContext";
import Menu from "../../Menu/Menu";
import Footer from "../../Components/Footer";
import { getAPICall } from "../../Components/APICall";
import Translate from "../../AppContext/Translate";
import CabTitle from "../CabTitle";
import CabStaticPlan from "./CabStaticPlan";
import CabDynamicPlan from "./CabDynamicPlan";
import CabHomeButton from "../CabHomeButton";

function parseParameter(pageParams) {
    const splittedParams = pageParams !== undefined && pageParams !== null && pageParams.length >= 1 ?  
        pageParams.split('-') : null;
    var courseId = -1;
    var plan = -1;
    if (splittedParams && splittedParams.length > 0) {
        courseId = convert2int(splittedParams[0]);
        if (splittedParams.length > 1) {
            plan = convert2int(splittedParams[1]);
        }
    }
    return {courseId: courseId, plan: plan};
}

function CabPlan() {
    var pageParams = parseParameter(useParams()["id"]);
    var { userId, token } = useContext(AppContext);
    const [allowed, setAllowed] = useState(null);
    const [courseName, setCourseName] = useState(null);

    useEffect(() => {
        if (pageParams.courseId < 0 || pageParams.plan < 0) setAllowed(false);
        else {
            checkEditPermission(userId, token, pageParams.courseId, setAllowed, true);
            getAPICall('courses/name', {courseId: pageParams.courseId}, (data) => {
                if (data && data.length > 0) setCourseName(data[0].title);
            });
        }
    }, [pageParams.courseId, pageParams.plan, userId, token]);

    return allowed !== null && allowed === false ? <Navigate to="/" replace={true} /> :
    <div className='mainContainer'>
        <Menu/>
        <div style={{padding: "10px", backgroundColor: "white"}}>
            {courseName && <CabTitle title={courseName} translate={false}/>}
            <h3 className="fkenyan"><Translate>{pageParams.plan === 0 ? "Статичний курс" : "Групові заняття з доступом до чату" }</Translate></h3>
            {pageParams.plan === 0 ?
                <CabStaticPlan courseId={pageParams.courseId}/> :
                <CabDynamicPlan courseId={pageParams.courseId}/>}
            <CabHomeButton/>
        </div>
        <Footer/>
    </div>
}

export default CabPlan