import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../AppContext/AppContext";
import { getAPICall } from "../../Components/APICall";
import { translate } from "../../AppContext/Translate";
import TaskParticipant from "./TaskParticipant";
import TaskDelivery from "./TaskDelivery";
import Container from "react-bootstrap/Container";
import HorizontalScroll from "../../Components/HorizontalScroll";

function TaskParticipants({task, groupId, packageId, postId, markTaskAsSent, options}) {
    const { userId, token, dicLang } = useContext(AppContext);
    const [participants, setParticipants] = useState(null);
    const [selectedParticipant, setSelectedParticipant] = useState(0);
    const [privacy, setPrivacy] = useState(false);

    useEffect(() => {
        getAPICall('tasks/privacy', {userId: userId, token: encodeURIComponent(token), postId: postId},
            (data) => {
                if (data.length > 0 && "priv" in data[0]) {
                    setPrivacy(data[0]["priv"] > 0);
                }
            });
        if (groupId && groupId >= 0) {
            getAPICall('tasks/participants', {userId: userId, token: encodeURIComponent(token), 
                classGroup: groupId ? groupId : -1, postId: postId}, (data) => {
                var plist = [{user: userId, name: translate('Мої роботи', dicLang)}];
                for (var i = 0; i < data.length; i++) {
                    if (data[i].user !== userId) {
                        plist.push(data[i]);
                    }
                }
                setParticipants(plist);
            });
        } else setParticipants([{user: userId, name: translate('Мої роботи', dicLang)}]);
    }, [userId, token, groupId, postId, dicLang]);

    return <>
        <hr/>
        {participants && <HorizontalScroll itemSize={196}>
            {participants.map((data, index) => (
                <TaskParticipant key={index} index={index} user={data.user} name={data.name} 
                    selected={selectedParticipant===index} setSelected={setSelectedParticipant}/>
                ))}
        </HorizontalScroll>}
        <hr/>
        <Container fluid>
        {participants && (selectedParticipant >= 0) && (selectedParticipant < participants.length) &&
            <TaskDelivery user={participants[selectedParticipant]} postId={postId} privacy={privacy}
                setPrivacy={setPrivacy} groupId={groupId} task={task} markTaskAsSent={markTaskAsSent}
                packageId={packageId} options={options}/>}
        </Container>
    </>
}

export default TaskParticipants
