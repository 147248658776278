import { useEffect, useState } from "react";
import ClockLoader from "react-spinners/ClockLoader";
import BeatLoader from "react-spinners/BeatLoader";
import Translate, { translate } from "../../AppContext/Translate";
import { useContext } from "react";
import { AppContext } from "../../AppContext/AppContext";

function ClockDigit({value, text}) {
    return <div className="clockDigitPane">
        <p className="clockDigit">{value}</p>
        <p className="clockDigitText">{text}</p>
    </div>
}

function CalendarCountdown({groupData, onEndCountdown}) {
    const startDate = new Date(groupData["startDate"]);
    const start = startDate.toLocaleDateString('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit' });
    const [remaining, setRemaining] = useState(Math.floor(Math.abs(groupData["difference"]) / 1000));
    const { dicLang } = useContext(AppContext);

    useEffect(() => {
        setRemaining(Math.floor(Math.abs(groupData["difference"]) / 1000));
        const intervalId = setInterval(() => {
            setRemaining(remaining => remaining - 1);
        }, 1000);
        return () => clearInterval(intervalId);
      }, [groupData]);
      if (remaining <= 0) {
        if (remaining === -2) {
            setRemaining(0);
            onEndCountdown();
        }
        return <div style={{textAlign: "center"}}>
            <BeatLoader size={24} color="#0d6efd" />
        </div>
      } else {
        const seconds = remaining % 60;
        const minutes = Math.floor(remaining / 60) % 60;
        const hours = Math.floor(remaining / 3600) % 24;
        const days = Math.floor(remaining / 86400);
        return <div style={{marginBottom: "10px"}}>
            <p style={{display: "inline-flex", marginTop: "8px"}}>
                <span style={{minWidth: "28px"}}><ClockLoader color="black" size={28} speedMultiplier={0.2}/></span>
                <span style={{marginLeft: "8px"}}><Translate>Este curso comenzará el día</Translate>{' '}{start}</span>
            </p>
            <p><Translate>Comenzamos en</Translate>:</p>
            <div style={{display: "flex", justifyContent: "center"}}>
                <ClockDigit value={days} text={translate("días", dicLang)}/>
                <ClockDigit value={hours} text={translate("horas", dicLang)}/>
                <ClockDigit value={minutes} text={translate("minutos", dicLang)}/>
                <ClockDigit value={seconds} text={translate("segundos", dicLang)}/>
            </div>
        </div>
      }
}

export default CalendarCountdown