import { useEffect, useState, useContext } from "react"
import { getAPICall } from "../../Components/APICall";
import { AppContext } from "../../AppContext/AppContext";
import Accordion from 'react-bootstrap/Accordion';
import { BsPersonFillUp } from "react-icons/bs";
import Translate from "../../AppContext/Translate";
import CabStudentIndRequest from "./CabStudentIndRequest";

function CabStudentsIndOptions() {
    const { userId, token } = useContext(AppContext);
    const [requests, setRequests] = useState([]);
    const [sent, setSent] = useState(new Set());

    useEffect(() => {
        getAPICall('cabinet/requestedOptions', {userId: userId, token: encodeURIComponent(token), kind: 1}, (data) => {
            if (data) setRequests(data);
        });
    }, [userId, token]);

    const removeRequest = (index) => {
        const newSet = new Set(sent);
        newSet.add(index);
        setSent(newSet);
    }

    return requests && requests.length > 0 && requests.length > sent.size ?
    <Accordion>
        <Accordion.Item eventKey="1">
            <Accordion.Header>
                <BsPersonFillUp size="24" style={{margin: "1px 8px 0px 0px"}}/>
                <span style={{fontSize: "larger", fontWeight: "600"}}><Translate>Індивідуальні запити</Translate></span>
            </Accordion.Header>
            <Accordion.Body>
                <div style={{display: "flex", flexWrap: "wrap", alignItems: "flex-start"}}>
                {requests.map((req, i) => { 
                    return sent.has(i) ? null : <CabStudentIndRequest key={i} request={req}
                        removeRequest={() => { removeRequest(i) }}/>
                })}
                </div>
            </Accordion.Body>
        </Accordion.Item>
    </Accordion>
    : null
}

export default CabStudentsIndOptions