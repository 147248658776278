import { useContext, useState, useEffect } from "react";
import { AppContext } from "../../AppContext/AppContext";
import ChatTitle from "./ChatTitle";
import ChatPost from "./ChatPost";
import { getAPICall } from "../../Components/APICall";

function Chat({course, topic, style={}, chatRef, student, pageParams, setParams}) {
    const { userId, token } = useContext(AppContext);
    var sty = Object.assign({}, style);
    sty["backgroundImage"] = "url('/bkg/chat.jpg')";
    const [posts, setPosts] = useState([]);
    const [allExpanded, setAllEpanded] = useState(false);
    
    useEffect(() => {
        if ((pageParams.courseId === course.id) && (topic && pageParams.topicId === topic.day) &&
            (pageParams.postId !== -1) && (pageParams.commentId.length > 0))
            setAllEpanded(true);
    }, [topic, pageParams.courseId, course.id, pageParams.topicId, pageParams.postId, pageParams.commentId]);
    
    const refreshData = () => {                
        if (topic !== null) {
            if (student.plan === 1) {
                getAPICall('materials/day', {day: topic.day, userId: userId, token: encodeURIComponent(token),
                    group: student.group, course: course.id}, (data) => {
                    if (!data || data.length < 1) setPosts([]);
                    else {
                        setPosts(data);
                    }
                });
            } else {
                getAPICall('materials/topic', {topic: topic.topic, userId: userId, token: encodeURIComponent(token),
                    course: course.id}, (data) => {
                    if (!data || data.length < 1) setPosts([]);
                    else {
                        setPosts(data);
                    }
                });
            }
        }
    }

    useEffect(() => {
        refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [topic, userId, token, student, course]);

    return <div ref={chatRef} className="chatPane" style={sty}>
        <ChatTitle topic={topic} scheduled={student.plan === 1} expand={!allExpanded} setExpanded={setAllEpanded}/>
        {posts.map((post, index) => (
            <ChatPost key={index} post={post} course={course.id} topic={topic} student={student} 
                allExpanded={allExpanded} refreshData={refreshData} pageParams={pageParams} setParams={setParams}/>
        ))}
        <div style={{height: "24px"}}/>
    </div>
}

export default Chat