import Button from "react-bootstrap/Button"
import Footer from "../Components/Footer"
import Menu from "../Menu/Menu"
import { FaFilePdf } from "react-icons/fa6";
import { useWindowSize } from "../AppContext/WindowSize";
import { useEffect, useState } from "react";
import { PiArrowUpBold } from "react-icons/pi";

function Contract() {
    const [width,] = useWindowSize();
    const [showUp, setShowUp] = useState(false);

    const handleDownload = () => {
        const pdfUrl = '/docs/terms.pdf';
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = 'terms.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    useEffect(() => {
        const handleScroll = () => {
          if (window.scrollY > 200) {
            setShowUp(true);
          } else {
            setShowUp(false);
          }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return <div className='mainContainer'>
        <Menu/>
        <div className="terms-div">
            {width > 960 && <div className="terms-circle" style={{left: "calc(50% + 360px + 48px)",
                top: "84px"}} onClick={handleDownload}>
                <FaFilePdf size="24" style={{transform: "translateX(2px)"}}/>
            </div>}
            {width > 960 && <div className={showUp ? "terms-circle fadeIn2" : "terms-circle fadeOut2"}
                style={{left: "calc(50% - 360px - 120px)", bottom: "84px"}} onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                }}><PiArrowUpBold size="24"/>
            </div>}
            <h1 className="center">MAGISTRIKA</h1>
            <p className="center bold large">ПУБЛІЧНИЙ ДОГОВІР</p>
            <p className="center">про надання послуг на освітній платформі Magistrika.com</p>
            <div className="center top12 bot16 fkenyan">
                <Button variant="light" style={{color: "#595e69"}} onClick={handleDownload}>
                    <FaFilePdf style={{marginRight: "6px"}}/>PDF версія
                </Button>
            </div>
            <ol>
                <li><strong>Загальні положення</strong></li>
                <ol>
                    <li>Цей договір є публічним договором (далі &ndash; "Договір"), відповідно до статей 633, 634, 641, 642 Цивільного кодексу України, а також інших нормативно-правових актів України, що регулюють діяльність у сфері надання послуг.</li>
                    <li>Цей Договір укладається між фізичною особою-підприємцем Трофименко Ганною Сергіївною (далі &ndash; "Виконавець"), яка діє на підставі Свідоцтва про державну реєстрацію, і будь-якою фізичною особою, яка прийняла умови цього Договору (далі &ndash; "Користувач").</li>
                    <li>Прийняття (акцепт) цього Договору є підтвердженням факту укладення Договору між Користувачем і Виконавцем та означає повну згоду Користувача з усіма його умовами.</li>
                    <li>Акцепт цього Договору відбувається шляхом реєстрації на освітній платформі Magistrika.com (далі &ndash; "Платформа") та/або здійсненням оплати за послуги.</li>
                </ol>
                <li><strong> Предмет договору</strong></li>
                <ol>
                    <li>Виконавець зобов&rsquo;язується надати освітні послуги, а Користувач зобов&rsquo;язується прийняти та оплатити ці послуги на умовах, викладених у цьому Договорі.</li>
                    <li>Перелік, обсяг та зміст освітніх послуг, які надаються Виконавцем, визначається на Платформі та/або в окремих додатках до цього Договору, що можуть укладатися між сторонами.</li>
                </ol>
                <li><strong> Порядок надання послуг</strong></li>
                <ol>
                    <li>Послуги надаються Виконавцем шляхом доступу до навчальних матеріалів на Платформі після успішної реєстрації та оплати Користувачем відповідного курсу або пакету послуг.</li>
                    <li>Виконавець залишає за собою право змінювати розклад, зміст та вартість послуг, про що Користувач буде повідомлений через Платформу або іншим зручним для Виконавця способом.</li>
                    <li>Magistrika надає Послуги Користувачу через Платформу. Користувач може отримувати Послуги, маючи обліковий запис.</li>
                </ol>
            </ol>
            <p className="pterms">Послуги можуть включати ознайомлення з інформацією, налаштування Облікових записів, публікацію, практичних кейсів, фінальних робіт, відгуків та статей тощо.</p>
            <p className="pterms">Magistrika гарантує, що Послуги будуть якісними та наданими на професійному рівні. Послуги будуть доступні 24 години на добу, 7 днів на тиждень, за винятком випадків технічного обслуговування або системних збоїв.</p>
            <p className="pterms">Час від часу Magistrika може додавати новий функціонал до Платформи та нові Послуги.</p>
            <p className="pterms">Послуги для Користувачів без Облікового запису. Користувач без Облікового запису може:</p>
            <p className="pterms">Відвідувати та ознайомлюватися з інформацією на Платформі без необхідності реєстрації;</p>
            <p className="pterms">Підписуватись на маркетингові розсилки та будь-які інші повідомлення від Magistrika та Платформи;</p>
            <p className="pterms">Звертатися до служби підтримки всіма доступними шляхами;</p>
            <p className="pterms">Використовувати інший доступний функціонал Платформи.</p>
            <p className="pterms">Послуги для Користувача з Обліковим записом. Користувач з базовим Обліковим записом може:</p>
            <p className="pterms">Змінювати налаштування профілю та інтересів;</p>
            <p className="pterms">Публікувати, кейси та статті;</p>
            <p className="pterms">Залишати коментарі та оцінки;</p>
            <p className="pterms">Проходити навчальні курси;</p>
            <p className="pterms">Розміщувати в системі інформацію про себе;</p>
            <p className="pterms">Взаємодіяти з іншими користувачами;</p>
            <p className="pterms">Оплачувати послуги Magistrika;</p>
            <p className="pterms">Використовувати інший функціонал Платформи.</p>
            <p className="pterms">Послуги для Користувача, що викладає на Платформі або є засновником Школи, а саме: створив курси, власну Школу на Платформі. Користувач з Обліковим записом засновника/викладача може:</p>
            <p className="pterms">Налаштувати профіль Школи;</p>
            <p className="pterms">Публікувати курси, кейси від імені Школи;</p>
            <p className="pterms">Створювати власний онлайн магазин з товарами від Школи (що не є курсами);</p>
            <p className="pterms">Отримувати про Школу, курси відгуки від користувачів Magistrika;</p>
            <p className="pterms">Оплачувати послуги Magistrika;</p>
            <p className="pterms">Вести рекламу на сторінку Magistrika з метою привернення уваги користувачів.</p>
            <p className="pterms">Використовувати інший функціонал Платформи.</p>
            <p className="pterms"><strong>Спеціальні послуги.</strong> Magistrika може надавати Користувачу спеціальні послуги, такі як консультації щодо взаємодії з Платформою та його алгоритмами, наповнення Облікового запису контентом, ведення профілю, створення курсів, дизайн сторінки курсу та його програмування, послуги редактора, ілюстратора та відеомейкера тощо. Надання таких послуг може регулюватися окремим договором між Magistrika та Користувачем.</p>
            <p className="pterms">Момент надання Послуг. Послуги та Платформа, що надаються Magistrika, будуть доступні 24 години на добу, 7 днів на тиждень, за винятком випадків технічного обслуговування або системних збоїв. Magistrika докладатиме розумних зусиль для забезпечення безперебійної та безпомилкової роботи Послуг і Платформи.</p>
            <p className="pterms">Якість Послуг. Magistrika гарантує, що всі Послуги та Платформа, що надаються за цим Договором, будуть надаватися на професійному рівні. Magistrika докладатиме розумних зусиль для забезпечення своєчасного та ефективного надання всіх Послуг, і щоб усі результати не мали матеріальних дефектів.</p>
            <p className="pterms">Інші послуги. Час від часу Magistrika можуть додавати нові функціональні можливості, про що буде повідомлено на Платформі. Користувач також може використовувати різні функції, які доступні на Платформі.</p>
            <ol start="4">
                <li><strong>Права та обов&rsquo;язки сторін</strong></li>
                <ol>
                    <li>Виконавець зобов&rsquo;язується:</li>
                    <ul>
                        <li className="terms-ilist">Надати Користувачу доступ до навчальних матеріалів після отримання оплати.</li>
                        <li className="terms-ilist">Забезпечити технічну підтримку Користувача в межах роботи Платформи.</li>
                        <li className="terms-ilist">Надати Сертифікат або Диплом про завершення курсу за результатами навчання, якщо це передбачено умовами курсу.</li>
                        <p className="pterms">Сертифікат відвідувача курсу видається автоматично з усіма матеріалами курсу, у разі, якщо придбаний Користувачем курс/пакет не передбачає перевірки фінальної роботи викладачем.</p>
                        <p className="pterms">У разі, якщо курс/ пакет курсу передбачає перевірку фінальної роботи викладачем, якщо робота виконана згідно строкам курсу і схвалена викладачем, Користувач отримає Диплом проходження курсу, де вказується успішне завершення курсу та виконання фінальної роботи.</p>
                    </ul>
                    <li>Виконавець має право:</li>
                    <ul>
                        <li className="terms-ilist">Вносити зміни в умови надання послуг, вартість та зміст навчальних програм.</li>
                        <li className="terms-ilist">Тимчасово припиняти доступ до Платформи з технічних або інших причин, які не залежать від Виконавця.</li>
                    </ul>
                    <li>Користувач зобов&rsquo;язується:</li>
                    <ul>
                        <li className="terms-ilist">Надати достовірну інформацію при реєстрації на Платформі. При створенні Облікового запису на Платформі Користувач повинен надати правдиву і точну інформацію. Ви не повинні створювати Обліковий запис від імені іншої фізичної або юридичної особи без її згоди. Ви також погоджуєтесь миттєво оновлювати цю інформацію у випадку будь-яких змін.</li>
                        <li className="terms-ilist">Своєчасно оплачувати послуги згідно з умовами Договору.</li>
                        <li className="terms-ilist">Дотримуватися правил користування Платформою, які розміщені на Magistrika.com.</li>
                        <p className="pterms">Користувач, що створює Школу та курси на Magistrika зобов&rsquo;язується не використовувати ці матеріали на інших інформаційних ресурсах та передає Платформі виключне право на демонстрацію, продаж та комерційне використання курсів.</p>
                    </ul>
                    <li>Користувач має право:</li>
                    <ul>
                        <li className="terms-ilist">Отримати доступ до навчальних матеріалів після оплати послуг.</li>
                        <li className="terms-ilist">Отримати інформаційну підтримку щодо користування Платформою.</li>
                    </ul>
                    <li>4.5 Прийняття Договору Користувачем з Обліковим записом. Реєструючи обліковий запис, Користувач гарантує, що він прочитав, зрозумів і згоден з умовами цього Договору, заповнивши відповідний чек бокс під час реєстрації.</li>
                    <li>4.6 Додаткова інформація для реєстрації. Magistrika може запросити додаткову інформацію для підтвердження вашої особи або перевірки вашого бізнесу. Якщо Ви відмовляєтесь надати цю інформацію, Magistrika може заблокувати Ваш Обліковий запис.</li>
                    <li>4.7 Видалення Magistrika Облікового запису. Magistrika має право видалити Обліковий запис Користувача, якщо він не використовується Користувачем протягом 12 календарних місяців.</li>
                    <li>4.8 Створення унікального пароля. Під час реєстрації на Платформі Користувач зобов&rsquo;язаний ввести унікальний пароль. При цьому Користувач несе повну відповідальність за надійність наданого пароля і захист Облікового запису.</li>
                    <li>4.9 Доступ третіх осіб до Облікового запису Користувача. У разі отримання Третьою особою доступу до Облікового запису Користувача, Користувач зобов&rsquo;язаний негайно повідомити про це Magistrika для вжиття відповідних заходів, за умови, що Користувач може підтвердити легітимність приналежності Облікового запису такому конкретному Користувачеві.</li>
                </ol> 
            </ol>
            <ol start="5">
                <li><strong>Вартість та порядок розрахунків</strong></li>
                <ol>
                    <li>Вартість послуг визначається на Платформі та залежить від обраного курсу або пакету послуг.</li>
                    <li>Оплата здійснюється Користувачем за допомогою платіжних систем, доступних на Платформі.</li>
                    <li>Користувач зобов&rsquo;язаний здійснити оплату до моменту надання послуг.</li>
                    <li>Користувач погоджується оплачувати Послуги за вказаною ціною, валютою та способами оплати на Платформі. Ця інформація може бути змінена.</li>
                    <li>Здійснюючи покупку Послуг на Платформі, Ви погоджуєтеся оплатити їх за вказаною ціною та у вказаній валюті на Платформі. Ціна та валюта чітко відображаються на Платформі під час здійснення покупки. Ми залишаємо за собою право змінювати ціну і валюту в будь-який час без попереднього повідомлення Вас, але це не вплине на вартість вже здійснених покупок.</li>
                    <li>Ми приймаємо оплату кредитними та дебетовими картками через платіжний сервіс WayforPay та інші платіжні сервіси, які доступні на Платформі. При оплаті карткою Вам потрібно буде надати необхідні дані картки під час покупки. Проте якщо Ви хочете оплатити банківським переказом, Вам будуть надані необхідні платіжні реквізити під час покупки. Платіжні сервіси можуть стягувати додаткову комісію.</li>
                    <li>Платіжна інформація Користувача зберігається та обробляється з дотриманням всіх необхідних засобів безпеки на стороні платіжного сервісу WayforPay та інших сервісів, які доступні на Платформі.</li>
                    <li>Надаючи платіжну інформацію платіжним сервісам для оплати послуг Magistrika, Користувач гарантує, що всі надані платіжні реквізити є правдивими, точними та повними, а також, що Користувач є авторизованим власником Облікового запису або авторизованим Користувачем використовуваного способу оплати.</li>
                    <li>Ми не несемо відповідальності за будь-які помилкові платежі, що виникли в результаті надання Користувачем неправильної платіжної інформації або використання несанкціонованого способу оплати.</li>
                    <li>На Платформі діє система подарунків та знижок. За кожну Вашу покупку, відгук, виконану фінальну роботу ви отримуватимете бали, які відображатимуться на вашій особистій мапі прогресу. Ви зможете скористатися ними, отримавши або подарунок, або знижку.</li>
                </ol>
            </ol>
            <ol start="6">
                <li><strong>Відповідальність сторін</strong></li>
                <ol>
                    <li>Виконавець не несе відповідальності за повні або часткові збої в роботі Платформи, викликані технічними проблемами, які не залежать від Виконавця.</li>
                    <li>Користувач несе відповідальність за дотримання правил користування Платформою та за достовірність інформації, наданої під час реєстрації.</li>
                </ol>
            </ol>
            <ol start="7">
                <li><strong>Модерація конкенту</strong></li>
            </ol>
            <p className="pterms">Користувачу забороняється публікувати незаконний контент, порушувати авторські права або права інтелектуальної власності, поширювати неправдиву інформацію або ображати інших користувачів на основі індивідуальних особливостей.</p>
            <p className="pterms">На Платформі також заборонено розміщувати контент, який шкодить фізичному, психічному або моральному розвитку дітей. Користувачі можуть повідомляти про такий контент, а Magistrika може застосовувати санкції до порушників.</p>
            <p className="pterms">Magistrika залишає за собою право обмежити або заблокувати Облікові записи тих Користувачів, хто публікує заборонений контент, а Користувачі можуть подати апеляцію до служби підтримки, якщо вони не згодні з рішенням Magistrika.</p>
            <p className="pterms">Тип модерації. Ми постійно модеруємо контент до і після публікації. Контент модерується живими модераторами та автоматичними алгоритмами.</p>
            <p className="pterms">Заборонений контент. Користувачі не повинні публікувати певні типи контенту і робити деякі дії, такі як:</p>
            <p className="pterms">Публікація незаконного контенту, наприклад, контент, що містить насильство, сексуальне насильство, жорстоке поводження з тваринами, продаж, пропаганда вживання наркотичних та психотропних речовин, зброї та інших заборонених товарів і послуг. Користувачі можуть публікувати товари та послуги, які підлягають регулюванню, лише за наявності відповідної ліцензії або дозволу на це;</p>
            <p className="pterms">Публікація контенту, що порушує авторські права або інші права інтелектуальної власності;</p>
            <p className="pterms">Розповсюдження неправдивої інформації, дезінформацію та фейкових новин.</p>
            <p className="pterms">Публікація контенту, який ображає інших Користувачів або групи Користувачів на основі расової приналежності, статі, сексуальної орієнтації, релігії, національності або інших індивідуальних особливостей;</p>
            <p className="pterms">Використання Платформи для шахрайських дій або спроб отримати доступ до Облікових записів інших Користувачів;</p>
            <p className="pterms">Розміщення персональних даних інших Користувачів без їхньої явної згоди;</p>
            <p className="pterms">Пропаганда або заклики до тероризму і терористичних актів, а також інформація, що виправдовує або схвалює такі дії;</p>
            <p className="pterms">Демонстрування фільмів, розповсюдження і демонстрування яких заборонено відповідно до Закону України &laquo;Про кінематографію&raquo;;</p>
            <p className="pterms">Інструкції або поради щодо виготовлення, придбання або використання вибухових, наркотичних або психотропних речовин;</p>
            <p className="pterms">Інформація, яка заперечує або виправдовує злочинний характер комуністичного тоталітарного режиму 1917-1991 років в Україні, злочинний характер націонал &ndash; соціалістичного (нацистського) тоталітарного режиму, створює позитивний образ осіб, які обіймали керівні посади в Комуністичній партії (посади секретаря райкому і вище), вищих органах влади та управління СРСР, Української РСР (УРСР) інших союзних та автономних радянських республік (крім випадків, пов&rsquo;язаних з розвитком української науки і культури), працівників радянських органів державної безпеки, виправдовує діяльність радянських органів державної безпеки, встановлення радянських органів влади на території України або в окремих адміністративно-територіальних одиницях, переслідування учасників боротьби за незалежність України у 20 столітті;</p>
            <p className="pterms">Інформація, що містить символіку комуністичного або націонал-соціалістичного (нацистського) тоталітарного режиму, крім випадків, передбачених Законом України &laquo;Про засудження комуністичного та націонал-соціалістичного (нацистського) тоталітарних режимів в Україні та заборону пропаганди їхньої символіки&raquo;;</p>
            <p className="pterms">Інформація, що принижує чи ображає державну мову;</p>
            <p className="pterms">Інформація, що заперечує або ставить під сумнів існування українського народу (нації) та/або української державності та/або української мови.</p>
            <p className="pterms">Інформація, що пов&rsquo;язана з тематикою азартних ігор, онлайн- чи офлайн-казино. До такої інформації відповідно до цієї угоди відносяться також кейси, новини, статті та матеріали щодо створення дизайну для проєктів, пов&rsquo;язаних з темою азартних ігор, а також матеріали щодо благодійної діяльності чи корпоративно-соціальної відповідальності організацій, належних до індустрії азартних ігор. Вакансії організацій-учасників індустрії азартних ігор також не будуть розміщуватись на платформі.</p>
            <p className="pterms">Заборонений контент для дітей. Користувачі не повинні публікувати певні типи контенту і робити деякі дії, які можуть зашкодити фізичному, розумовому або моральному розвитку дітей, наприклад:</p>
            <p className="pterms">Надмірна увага до насильства, а саме поширення висловлювань або зображень насильства, які не є виправданими або є надмірними в контексті відповідної програми або публікації;</p>
            <p className="pterms">Позитивна оцінка нанесення собі тілесних ушкоджень, заподіяння собі каліцтв або вчинення самогубства, підбурювання до таких дій, надмірна та необґрунтована деталізація способів та обставин суїциду;</p>
            <p className="pterms">Демонстрація жорстокого поводження з тваринами, способів вбивства тварин, демонстрація крупним планом присмертної або жорстоко понівеченої тварини, крім випадків, коли така демонстрація необхідна для популяризації гуманного ставлення до тварин, за умови попередження глядачів про сцени жорстокості;</p>
            <p className="pterms">Позитивна оцінка вандалізму;</p>
            <p className="pterms">Позитивна оцінка злочинного діяння або ідеалізація злочинця, надмірно детальне моделювання злочинних дій та/або демонстрація дій, відтворення яких дітьми може бути небезпечним для їхнього здоров&rsquo;я та життя;</p>
            <p className="pterms">Позитивна оцінка залежності від наркотичних, токсичних, психотропних речовин, тютюну або алкоголю, а також від інших речовин, що вживаються або можуть бути вжиті з метою ведення в оману, заохочення до їх вживання, виробництва, розповсюдження чи придбання, крім творів мистецтва;</p>
            <p className="pterms">Нецензурні вирази, слова, непристойні жести, крім випадків використання у творах мистецтва або відтворення у повідомленнях про новини дня чи поточні події, які мають характер звичайної інформації для преси;</p>
            <p className="pterms">Заклики до участі в азартних іграх, заохочення до участі в азартних іграх, крім випадків, передбачених законами України;</p>
            <p className="pterms">Скарги від Користувача. Будь-який Користувач може поскаржитися на контент іншого Користувача. Якщо Magistrika вважає, що цей Користувач порушує правила модерації контенту, Magistrika може застосувати санкції до такого Користувача.</p>
            <p className="pterms">Санкції. Magistrika залишає за собою право розглядати кожен випадок порушення правил модерації в індивідуальному порядку і застосовувати до порушників відповідні заходи, включаючи обмеження використання Облікового запису або його блокування. Крім того, Magistrika може повідомити відповідні органи державної влади про таке порушення, якщо цього вимагає чинне законодавство.</p>
            <p className="pterms">Оскарження рішення Magistrika. Якщо Ви не згодні з рішенням Magistrika, Ви можете подати скаргу за посиланням до служби підтримки протягом 10 календарних днів з дати прийняття рішення. Якщо служба підтримки не задовольнить Вашу скаргу, рішення залишається в силі і не підлягає оскарженню.</p>
            <ol start="8">
                <li><strong>Інші правила</strong></li>
            </ol>
            <p className="pterms">Користувач повинен використовувати Платформу і будь-які пов&rsquo;язані з ним Послуги законним, етичним і шанобливим чином, дотримуючись усіх застосовних законів і правил.</p>
            <p className="pterms">Користувач має право створити лише один Обліковий запис, і його не можна передавати або використовувати для шахрайських дій або доступу до Облікових записів інших Користувачів.</p>
            <p className="pterms">Magistrika може залучати субпідрядників і партнерів для надання Послуг без додаткової згоди Користувача, поширювати рекламу, проводити конкурси та акції, надсилати повідомлення Користувачу тощо.</p>
            <p className="pterms">Добросовісне використання правил модерації містить відмову від поширення неправдивої інформації, використання модерації у власних цілях, подання неправдивих скарг або розміщення контенту, що порушує правила, на інших платформах, посилаючись на Платформу.</p>
            <p className="pterms">Реклама. Magistrika має право поширювати будь-яку рекламу за допомогою Платформи.</p>
            <p className="pterms">Поведінка. Користувач погоджується використовувати Платформу та будь-які пов&rsquo;язані з ним Послуги лише законним, етичним та шанобливим до інших користувачів способом. Використовуючи Платформу, Ви погоджуєтеся не використовувати його або будь-які пов&rsquo;язані з ним послуги з метою дискримінації, переслідування, наклепу або застосування образливої поведінки, або у інший спосіб, який порушує умови передбачені цим Договором.</p>
            <p className="pterms">Дотримання закону. Користувач повинен дотримуватися всіх законів і правил, які застосовуються до його використання Платформи та Послуг. Він несе відповідальність за те, щоб не порушувати жодних законів під час використання Платформи та Послуг. Magistrika може проводити відповідні перевірки будь-яких користувачів.</p>
            <p className="pterms">Конкурси, розіграші та змагання. Magistrika має право проводити конкурси, розіграші та змагання на умовах, зазначених на Платформі, в соціальних мережах і месенджерах Magistrika, а також його партнерів.</p>
            <p className="pterms">Акції та знижки. Magistrika має право проводити акції та надавати Користувачу знижки на умовах, зазначених на Платформі, у соціальних мережах і месенджерах Magistrika, а також його партнерів.</p>
            <p className="pterms">Повідомлення. Magistrika має право надсилати Користувачу будь-які електронні повідомлення, у тому числі рекламного характеру, якщо:</p>
            <p className="pterms">Користувач надав свою згоду за допомогою спеціальної відмітки;</p>
            <p className="pterms">Користувач підписався на розсилку Magistrika;</p>
            <p className="pterms">У разі коли Користувач вже користувався послугами Magistrika, Ми надсилаємо йому рекламне повідомлення з подальшою можливістю відмовитися від такої розсилки в самому повідомленні;</p>
            <p className="pterms">Magistrika надсилає Користувачу технічне повідомлення.</p>
            <p className="pterms">Дозволено використовувати тільки один Обліковий запис. Ви маєте право створити лише один Обліковий запис на Платформі. Якщо Ви створите більше одного Облікового запису, Magistrika залишає за собою право видалити всі Ваші Облікові записи.</p>
            <p className="pterms">Заборона передачі Облікового запису. Ви погоджуєтеся не передавати свій Обліковий запис на Платформі будь-якій іншій фізичній або юридичній особі, а також не намагатися продавати, обмінювати, здавати в оренду свій Обліковий запис на Платформі без попередньої письмової згоди Magistrika.</p>
            <p className="pterms">Заборона шахрайських дій. Використання Платформи для шахрайських дій або спроб отримання доступу до Облікових записів інших Користувачів заборонено. Забороняється використовувати будь-які методи, включаючи, &mdash; але не обмежуючись ними, &mdash; автоматичні системи для розміщення, коментування або оцінювання контенту з метою обходу правил модерації та створення фіктивної активності в Обліковому записі.</p>
            <p className="pterms">Субпідрядники та сторонні послуги. Magistrika може залучати підрядників, субпідрядників та партнерів для виконання цього Договору без додаткового погодження з Користувачем.</p>
            <p className="pterms">Добросовісне використання правил модерації.</p>
            <p className="pterms">Забороняється створювати або поширювати неправдиву інформацію про модерацію контенту або управління платформою;</p>
            <p className="pterms">Користувачі не повинні використовувати модерацію контенту як засіб вираження особистої ворожнечі, помсти або інших особистих мотивів;</p>
            <p className="pterms">Користувачі не повинні зловживати правом на скаргу, подаючи неправдиві або оманливі скарги на контент, який не порушує умови модерації;</p>
            <p className="pterms">Користувачі не повинні розміщувати або поширювати контент, який порушує правила модерації на Платформі, на інших платформах або в соціальних мережах, посилаючись на Платформу.</p>
            <p className="pterms">Зовнішні посилання на Платформі. Посилання з Платформи на інші вебсайти, застосунки та соціальні мережі надаються лише для ознайомлення. Ми не контролюємо їх й не несемо відповідальності за будь-які матеріали, знайдені на них, або будь-які збитки, яких Ви зазнали, використовуючи інші Сайти, застосунки та соціальні мережі.</p>
            <ol start="9">
                <li><strong>Строк дії договору</strong></li>
                <ol>
                    <li>Цей Договір набуває чинності для конкретного Користувача з дати його укладення, як зазначено вище. Цей Договір діє до його припинення або розірвання будь-якою Стороною. Версія Договору відкрита для приєднання з Дати набрання чинності, зазначеної в самому початку.</li>
                    <p className="pterms top8">Припинення дії Договору за взаємною згодою Сторін. У такому випадку Сторона, яка має намір розірвати/припинити дію Договору, повинна письмово повідомити про це іншу Сторону, надіславши відповідне повідомлення електронною поштою.</p>
                    <li>Виконавець має право в будь-який час змінити умови цього Договору з обов&rsquo;язковим повідомленням Користувача через Платформу.</li>
                    <p className="pterms top8">Договір стає чинним з дати його укладення та діє до моменту припинення або розірвання. Датою укладання договору є дата погодження Користувача з умовами цього Договору.</p>
                    <p className="pterms">Magistrika має право розірвати Договір в односторонньому порядку, якщо Користувач порушує будь-які його умови, а Користувач може розірвати Договір, повідомивши про це службу підтримки Magistrika або припинивши використання Платформи.</p>
                    <p className="pterms">Magistrika може змінити Договір в будь-який час без згоди Користувача, і будь-які зміни будуть позначені зміною дати набрання чинності.</p>
                    <li>Розірвання Договору за рішенням Magistrika. Magistrika має право розірвати цей Договір в односторонньому порядку в разі порушення Користувачем будь-яких умов Договору, з метою запобігання будь-якої шахрайської, незаконної або зловмисної діяльності або якщо це необхідно для запобігання або припинення будь-якої шкоди або збитку нам, іншим Користувачам Платформи або широкій громадськості. Про факт і причини розірвання Договору Magistrika інформує Користувача шляхом надсилання повідомлення на електронну пошту Користувача та/або повідомлення на Платформі. Дія Договору припиняється з дати відправлення повідомлення. При цьому Користувач не має права повторно укладати цей Договір і використовувати Платформу, за винятком доступу до Платформи, звернення до служби підтримки та отримання повідомлень.</li>
                    <li>Розірвання Договору Користувачем. Користувач має право розірвати цей Договір в односторонньому порядку, повідомивши про це службу підтримки Magistrika та/або надіславши повідомлення на електронну адресу Magistrika, яка зазначена на Платформі. Користувач також має право в будь-який час припинити використання Платформи та видалити Обліковий запис. Договір вважається розірваним з дати отримання повідомлення.</li>
                    <li>Зміна умов Договору. Magistrika залишає за собою право змінювати Договір, Платформу і Послуги в будь-який час і без попереднього узгодження з Користувачем. Зміни позначаються зміною дати набрання чинності. Користувач надає свою згоду на внесення змін до Договору, Платформи та Послуг шляхом продовження використання Платформи та Послуг.</li>            
                </ol>                
            </ol>
            <ol start="10">
                <li><strong>Додаткові документи, що регулюють взаємовідносини з </strong><strong>Magistrika</strong></li>
            </ol>
            <p className="pterms">Повідомлення про обробку персональних даних. Описує, як Magistrika обробляє Ваші персональні дані. Ви можете прочитати повну версію Повідомлення про обробку персональних даних.</p>
            <p className="pterms">Повідомлення про використання файлів cookie. Описує, як Magistrika обробляє Ваші персональні дані за допомогою файлів cookie та інших подібних технологій. Ви можете прочитати повну версію Повідомлення про використання файлів cookie.</p>
            <p className="pterms">Невіддільні частини. Повідомлення про обробку персональних даних та Повідомлення про використання файлів cookie є невіддільними частинами Договору.</p>
            <ol start="11">
                <li><strong>Права інтелектуальної власності</strong></li>
                <ol>
                    <li>Magistrika надає Користувачу невиключну ліцензію на використання Платформи та Послуг, але не передає жодних виключних майнових інтелектуальної власності прав на них.</li>
                    <li>Користувач надає Magistrika невиключну ліцензію на використання контенту, створеного ним на Платформі та під час надання Послуг. Magistrika залишає за собою право видаляти будь-який контент, що порушує умови Договору.</li>
                    <p className="pterms top8">Ви можете повідомити Magistrika через спеціальну процедуру про можливе порушення авторських або суміжних прав Третіх осіб на Платформі.</p>
                    <li>Ліцензійна угода з Користувачем:</li>
                    <p className="pterms top8">Ліцензія. Magistrika надає Користувачу персональну, обмежену, відкличну, невиключну, всесвітню ліцензію протягом терміну дії цього Договору на використання Платформи, а саме: відображення, перегляд, відвідування та використання інших функцій Платформи.</p>
                    <li>Виключні майнові права інтелектуальної власності. Усі виключні майнові права інтелектуальної власності на Платформу, дизайн Платформи, програмний код, бази даних, дані, торговельні марки, промислові зразки, патенти на винаходи та корисні моделі належать Magistrika, якщо умовами Договору не зазначено інше.</li>
                    <p className="pterms top8">Відсутність передачі виключних майнових прав інтелектуальної власності. Цей Договір не передбачає відчуження будь-яких виключних майнових прав інтелектуальної власності на користь Користувача.</p>
                    <p className="pterms">Дії, які можуть порушувати виключні майнові права інтелектуальної власності. Користувач зобов'язаний утримуватися від будь-яких дій щодо копіювання, модифікації, декомпіляції та інших дій, які можуть бути порушенням виключних майнових прав інтелектуальної власності Magistrika та будь-якої Третьої особи.</p>
                    <li>11.5 Ліцензія на контент, що був створений користувачами (User-Generated Content):</li>
                    <p className="pterms top8">Ліцензія. Користувач надає Magistrika безстрокову, безповоротну, всесвітню, безоплатну, невиключну, ліцензію з правом субліцензії на передачу, використання, відтворення, розповсюдження, підготовку похідних робіт, відображення та виконання контенту, що був створений користувачами (User-Generated Content) для цілей роботи нашої Платформи, надання Послуг, просування бізнесу тощо.</p>
                    <li>Гарантії Користувача. Користувач заявляє і гарантує, що Він має всі права і повноваження, необхідні для надання вищезазначеної ліцензії, і що весь контент, що був створений користувачами (User-Generated Content) не порушує і не буде порушувати будь-яке право інтелектуальної власності, право на недоторканність приватного життя або публічність, або будь-яке інше право власності будь-якої третьої сторони.</li>
                    <li>Застереження про право на видалення. Ми залишаємо за собою право видаляти будь-який контент, що був створений користувачами (User-Generated Content), тексти або матеріали, надані або завантажені Вами з будь-якої причини, якщо ми вважаємо, що вони порушують умови цього Договору.</li>
                    <li>Припинення порушення авторського права та суміжних прав:</li>
                    <p className="pterms top8">Порушення авторського права та суміжних прав. Користувач та/або його представник можуть повідомити Magistrika про можливе порушення авторського права та суміжних прав на Платформі. Повідомлення може бути надіслано за посиланням або на електронну пошту: support@magistrika.com</p>
                    <li>Повідомлення. Повідомлення від Користувача повинно містити:</li>
                    <p className="pterms top8">Ідентифікацію твору, права на який було порушено, або, якщо в одному повідомленні йдеться про декілька творів, репрезентативний перелік таких творів на цій Платформі.</p>
                    <p className="pterms">Ідентифікацію матеріалу, який порушує права або є предметом діяльності, що порушує права, і який має бути видалений або доступ до якого має бути заблокований, а також інформація, достатня для того, щоб дозволити нам знайти матеріал.</p>
                    <p className="pterms">Інформацію, достатню для того, щоб Ми могли зв'язатися з Вами, наприклад, адреса, номер телефону та, за наявності, адреса електронної пошти, за якою з Вами можна зв'язатися.</p>
                    <p className="pterms">Заяву про те, що Ви добросовісно вважаєте, що використання матеріалу у спосіб, на який Ви скаржитеся, не дозволено власником авторських прав, його агентом або законом.</p>
                    <p className="pterms">Ваше підтвердження, що надана інформація є точною та Ви ознайомлені з наслідками надання неправдивих даних, передбачених у заяві, а також що Ви маєте відповідні повноваження діяти від імені власника виключних прав (якщо заява подається не автором або володільцем виключних майнових прав інтелектуальної власності).</p>
                    <p className="pterms">Magistrika надає Користувачу та/або його представнику відповідь на повідомлення протягом 3 робочих днів з моменту отримання заяви про припинення порушення та діє далі згідно з законодавством.</p>
                </ol>
            </ol>
            <ol start="12">
                <li><strong>Обмеження відповідальності</strong></li>
            </ol>
            <p className="pterms">Magistrika обмежує свою відповідальність перед Користувачем за будь-які завдані йому збитки та не несе відповідальності за будь-які дії Користувачів або завдані ними збитки Третім особам під час користування ними Послугами Magistrika.</p>
            <p className="pterms">Ми також не несемо відповідальності за Послуги, отримані Користувачем від Третіх осіб за допомогою Платформи. Крім того, Magistrika не підтримує будь-які особисті думки, переконання або погляди Користувача, опубліковані ним на Платформі.</p>
            <p className="pterms">Також, у більшості випадків, Magistrika обмежує відповідальність Користувача за порушення Договору розміром платежів Користувача за цим Договором, але не менше, ніж розмір збитків, фактично завданих Користувачем.</p>
            <p className="pterms">Обмеження відповідальності Magistrika. Magistrika обмежує свою відповідальність перед Користувачем за порушення Договору, а також за будь-які збитки (включаючи упущену вигоду Користувача), сумою платежів Користувача за Послуги. Magistrika не несе відповідальності за дії Користувачів і обмежує свою відповідальність перед Третіми особами за збитки (включаючи упущену вигоду), а також шкоду, заподіяну Користувачем цим Третім особам під час отримання Послуг і використання Платформи Magistrika.</p>
            <p className="pterms">Виключення відповідальності Magistrika. Magistrika виключає свою відповідальність за:</p>
            <p className="pterms">Будь-яку втрату прибутку (прямо чи опосередковано);</p>
            <p className="pterms">Будь-яку втрату ділової репутації;</p>
            <p className="pterms">Будь-яку втрату можливостей;</p>
            <p className="pterms">Будь-яку залежність від повноти, точності або існування будь-якої інформації або реклами, або в результаті будь-яких відносин або транзакцій між Вами та будь-якою Третьою стороною, реклама якої з&rsquo;являється на Платформі або у зв'язку з Послугами;</p>
            <p className="pterms">За будь-які зміни, які Ми можемо вносити до Платформи або Послуг, або за будь-які тимчасові перерви в роботі Платформи або Послуг;</p>
            <p className="pterms">Видалення, пошкодження або незбереження будь-яких даних, що зберігаються або передаються Вами під час користування Платформою або Послугами;</p>
            <p className="pterms">За ненадання точної облікової та платіжної інформації;</p>
            <p className="pterms">Неможливість особисто Користувачем забезпечити власну безпеку та конфіденційність даних Облікового запису такого Користувача.</p>
            <p className="pterms">Обмеження відповідальності Користувача. Magistrika обмежує відповідальність Користувача перед Magistrika за порушення Договору, а також відшкодування збитків, розміром платежу Користувача за цим Договором, але не менше, ніж розмір збитків, фактично завданих Користувачем. Дія цього положення не поширюється на відповідальність, збитки та упущену вигоду у випадках захисту прав інтелектуальної власності, порушення засобів забезпечення інформаційної безпеки, зокрема кібербезпеки, а також захисту ділової репутації Magistrika, його працівників, контрагентів та засновників.</p>
            <p className="pterms">Послуги третіх осіб. Magistrika не несе відповідальності за будь-які послуги, які Користувач може отримати від Третіх осіб через Платформу.</p>
            <p className="pterms">Magistrika не несе відповідальності за паролі Користувачів. Користувач самостійно забезпечує збереження інформації про свої паролі та іншої конфіденційної інформації. Magistrika не несе відповідальності за наслідки втрати Користувачем конфіденційної інформації, його логіну та паролів.</p>
            <p className="pterms">Відсутність згоди з Користувачем. Magistrika підтверджує, що не поділяє думки, переконання і погляди Користувачів у зв'язку з публікацією останніх на Платформі контенту Користувача.</p>
            <p className="pterms">Які обмеження поширюються на Наші гарантії</p>
            <p className="pterms">Платформа ліцензується на умовах &laquo;ЯК Є&raquo; та &laquo;ЯК ДОСТУПНА&raquo;. Magistrika не несе відповідальності та не надає жодних гарантій, явних чи неявних, і цим відмовляється від будь-яких неявних гарантій щодо Платформи та Послуг, зокрема:</p>
            <p className="pterms">Доступності в разі проблем з мережею, програмним та апаратним забезпеченням, електропостачанням Користувача та Третіх осіб;</p>
            <p className="pterms">Відповідності назви, зовнішнього вигляду, внутрішньої структури та функцій будь-яким очікуванням Користувача;</p>
            <p className="pterms">Захисту від будь-якої шкоди, заподіяної Користувачеві, пов'язаним з ним особам, їхнім майновим і немайновим правам внаслідок дії та/або бездіяльності будь-яких Третіх осіб.</p>
            <ol start="13">
                <li><strong>Яке право регулює цей Договір</strong></li>
            </ol>
            <p className="pterms">Цей Договір регулюється чинним законодавством України.</p>
            <p className="pterms">Застосовне право. Цей Договір регулюється та тлумачиться відповідно до законодавства України без застосування будь-яких принципів міжнародного приватного права.</p>
            <p className="pterms">Як вирішуються спори</p>
            <p className="pterms">Усі спори або претензії щодо цього Договору вирішуються шляхом переговорів протягом 90 робочих днів з моменту їх виникнення, якщо переговори не принесли результату, вони вирішуються у судовому порядку відповідно до вимог законодавства України.</p>
            <p className="pterms">Переговори. До початку будь-якого судового розгляду Сторони намагатимуться вирішити будь-які суперечки або претензії, що виникають з цього Договору або у зв'язку з ним, шляхом проведення переговорів протягом 90 робочих днів.</p>
            <p className="pterms">Вирішення спорів. Будь-який спір або вимога, що виникають з цього Договору або у зв'язку з ним, її порушенням, припиненням або недійсністю, підлягають виключній юрисдикції судів України та вирішуються відповідно до матеріального та процесуального права України.</p>
            <p className="pterms">Витрати. Сторона, яка виграє справу в будь-якому судовому процесі, що виникає з цього Договору або у зв'язку з нею, має право на відшкодування своїх обґрунтованих судових та адвокатських витрат та витрат на оплату послуг адвокатів.</p>
            <ol start="14">
                <li><strong>Інші умови</strong></li>
                <ol>
                    <li>Усі спори, що виникають з цього Договору або у зв&rsquo;язку з ним, вирішуються шляхом переговорів. У разі недосягнення згоди, спір може бути переданий на розгляд до суду відповідно до чинного законодавства України.</li>
                    <li>Користувач надає свою згоду на обробку персональних даних Виконавцем відповідно до Закону України "Про захист персональних даних".</li>
                    <li>Якщо Magistrika не застосовує будь-яку частину цього Договору, це не означає, що Magistrika звільняє Вас від відповідальності. Magistrika все одно може застосувати такі умови, якщо Magistrika це буде необхідно. Якщо Magistrika вирішить відмовитися від будь-якого з прав зазначених у цьому Договорі, Magistrika у письмовій формі повідомить про це заздалегідь.</li>
                    <li>Цей Договір може бути укладений в одному примірнику, який вважається оригіналом. Розміщення цього Договору на Платформі має таку ж юридичну силу, як і оригінал підписаної Magistrika копії цього Договору.</li>
                    <li>Цей Договір є публічною офертою і є обов&rsquo;язковим до виконання після його прийняття Користувачем.</li>
                </ol>
            </ol>
            <p className="center top24 italic">Дата набрання чинності: 1 вересня 2024 року</p>
        </div>
        <Footer/>
    </div>
}

export default Contract