import { useEffect, useState } from "react";
import Translate from "../../AppContext/Translate"
import { Box } from "../../User/Orders/Order"
import ExpandButton from "../../Components/ExpandButton";
import { getAPICall } from "../../Components/APICall";
import OrderTeam from "./OrderTeam";

function OrderSubtotal({data, platformPercentage, role}) {
    const [expanded, setExpanded] = useState(false);
    const [teachers, setTeachers] = useState(null);
    const isTeacher = role === "teacher";
    const isDirector = role === "director";
    const isAdmin = role === "admin";

    useEffect(() => {
        if (expanded && teachers === null) {
            setTeachers([]);
            if ('itemId' in data) {
                getAPICall('item/team', {item: data.itemId}, (data) => {
                    if (data) {
                        setTeachers(data);
                    }
                });
            } else {
                getAPICall('course/team', {course: data.course}, (data) => {
                    if (data) {
                        var team = [];
                        data.forEach(t => {
                            team.push({owner: t.teacher, percentage: t.percentage, name: t.name});
                        });
                        setTeachers(team);
                    }
                });
            }
        }
    }, [expanded, teachers, data]);

    return <div style={{display: "flex", justifyContent: "space-between"}}> 
        <div className="top4">
            <span className="right6"><Translate>Деталі</Translate></span>
            <ExpandButton expanded={expanded} setExpanded={setExpanded}/>
            <OrderTeam expanded={expanded} benefit={isTeacher ? data.sum : data.benefit} teachers={teachers}/>
        </div>
        <div style={{display: "flex", flexWrap: "wrap", justifyContent: "flex-end", alignItems: "center"}}>
            <Box title={<Translate>Вартість</Translate>} value={data.price + " грн"} align="right"/>
            <Box title={<Translate>Комісія</Translate>} value={data.commission + " грн (" + platformPercentage + "%)"} align="right"/>
            {isTeacher && <Box title={<Translate>Прибуток</Translate>} value={data.sum + " грн"} align="right"/>}
            {isTeacher && <Box title={<Translate>Зароблено</Translate>} value={data.benefit + " грн (" + data.percentage + "%)"} align="right"/>}
            {isDirector && <Box title={<Translate>Прибуток</Translate>} value={data.benefit + " грн"} align="right"/>}
        </div>
    </div>
}

export default OrderSubtotal