import { useContext, useEffect, useState } from "react";
import { getAPICall } from "../../Components/APICall"
import UnitNumber from "../../Components/UnitNumber";
import BookmarkState from "../../Components/BookmarkState";
import { AppContext } from "../../AppContext/AppContext";
import PostMessage from "../../Components/PostMessage";

function CourseTopics({course, setTopic, pageParams}) {
    const { userId, token } = useContext(AppContext);
    const [topics, setTopics] = useState([]);
    const [selectedTopic, setSelectedTopic] = useState(pageParams.topicId);

    const setBookmarks = (data, book) => {
        for (var i = 0; i < data.length; i++) {
            const topic = data[i].id;
            var bookmark = -1;
            for (var j = 0; j < book.length; j++) {
                if (book[j].number === topic) {
                    bookmark = book[j].state;
                    break;
                }
            }
            data[i]['bookmark'] = bookmark;
        }
        setTopics(data);
    }

    useEffect(() => {
        getAPICall('units/course', {course: course}, (data) => {
            if (data) {
                getAPICall('topic/bookmarks', {userId: userId, token: encodeURIComponent(token), courseId: course},
                    (res) => { setBookmarks(data, res); }, 
                    () => { setBookmarks(data, []); });
            }
        });
    }, [course, userId, token]);

    const refreshBookmarks = () => {
        getAPICall('topic/bookmarks', {userId: userId, token: encodeURIComponent(token), courseId: course},
        (res) => { 
            var data = [...topics];
            setBookmarks(data, res);
        });
    }

    return <> 
    {topics && topics.map((t, i) => {
        return <div key={i} style={{padding: "8px"}}>
            <div className="studentRow" onClick={() => {
                setSelectedTopic(t.id);
                setTopic({topic: t.id, title: t.title, day: 0, show: "topic"});
            }}>
                <UnitNumber number={t.number}/>
                <span style={{fontWeight: selectedTopic === t.id ? "600" : "400"}}>{t.title}</span>
                <BookmarkState context="topic" kind={t.bookmark} data={t.id} course={course}
                    refreshData={refreshBookmarks}/>
            </div>
            {t.description && t.description.length > 0 && <PostMessage msg={t.description} open={false} sep={false}
                textStyle="indexTextPost"/>}
        </div>
    })}
    <div style={{height: "16px"}}/>
    </>
}

export default CourseTopics