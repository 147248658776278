import Button from "react-bootstrap/Button"
import Translate from "../../AppContext/Translate"
import { Link } from "react-router-dom"

function MenuButton({icon, text, link=null, fncClick}) {
    return link ? <Link to={link}>
        <Button variant="link" className="blackTextHover top8 bot8">
            <img alt="" src={icon} height="20px" className="right6"/><Translate>{text}</Translate>
        </Button>
    </Link> :
    <Button variant="link" className="blackTextHover top8 bot8" onClick={fncClick}>
        <img alt="" src={icon} height="20px" className="right6"/><Translate>{text}</Translate>
    </Button>
}

export default MenuButton