import { useState, lazy, Suspense } from "react";
import { getAPIurl } from "../Components/APICall";
import Button from "react-bootstrap/Button";
import { HiUser } from 'react-icons/hi';
import Translate from '../AppContext/Translate';
import BeatLoader from "react-spinners/BeatLoader";

const UploadPicture = lazy(() => import('../Components/UploadPicture'));

function UserCover({user, allowChange = false}) {
    const [foundImg, setFoundImg] = useState(true);
    const [foundProfile, setFoundProfile] = useState(true);
    const [showChangeCover, setShowChangeCover] = useState(false);
    const [showChangeProfile, setShowChangeProfile] = useState(false);
    const [updateNumber, setUpdateNumber] = useState(1);
    const [coverUrl, setCoverUrl] = useState(getAPIurl('user/cover', {user: user}) + '?n=0');
    const [profileUrl, setProfileUrl] = useState(getAPIurl('media/userPhoto', {user: user}) + '?n=0');

    const Loading = () => <BeatLoader size={18} color="#0d6efd" />;

    const forceUpdate = () => {
        setUpdateNumber(current => current + 1);
        setCoverUrl(getAPIurl('user/cover', {user: user}) + '?n=' + updateNumber);
        setProfileUrl(getAPIurl('media/userPhoto', {user: user}) + '?n=' + updateNumber);
        setFoundProfile(true);
        setFoundImg(true);
    };
    
    return <div className="userCoverDiv">
        {foundImg ?
        <img className="userCoverImg" src={coverUrl} alt="" onError={()=>setFoundImg(false)} /> : 
        <img className="userCoverImg" src='/bkg/profilecover.jpg' alt=""/>}
        {allowChange && <>
         <Button variant="secondary" size="sm" className="userButtonCoverChange" onClick={()=>{setShowChangeCover(true);}}>
            <img alt="" src="/icons/camera.png" width="20px" style={{margin: "-4px 6px 0px 0px"}}/>
            <Translate>Змінити обкладинку</Translate>
        </Button>
        <Suspense fallback={<Loading />}>
            <UploadPicture show={showChangeCover} handleClose={()=>{setShowChangeCover(false);}} width={1024} height={200} 
                title="Фото на обкладинці" apiFnc="user/cover" forceUpdate={forceUpdate}/>
        </Suspense>
        </>
        }
        <div className="userProfileDiv">
            <div className="userProfileFrame">
                {foundProfile ?
                    <img style={{width: "100%", height: "100%"}} 
                        src={profileUrl} alt="" onError={()=>setFoundProfile(false)} /> :
                    <HiUser size="64"/>
                } 
            </div>
            {allowChange && <>
                <Button variant="secondary" size="sm" className="userButtonCoverChange"
                    style={{border: "none", borderRadius: "50%", padding: "7px 8px 8px 9px", top: "60%", right: "-8px"}}
                    onClick={()=>{setShowChangeProfile(true);}}>
                    <img alt="Змінити фотографію профілю" src="/icons/camera.png" width="20px"/>
                </Button>
                <Suspense fallback={<Loading />}>
                    <UploadPicture show={showChangeProfile} handleClose={()=>{setShowChangeProfile(false);}} 
                        width={552} height={552} 
                        title="Зображення профілю" apiFnc="user/photo" forceUpdate={forceUpdate}/>
                </Suspense>
            </>
            }
        </div>
    </div>
}

export default UserCover