import Split from "react-split";
import { useWindowSize } from "../../AppContext/WindowSize";
import { useRef, useState } from "react";
import Chat from "../Chat/Chat";
import Translate from "../../AppContext/Translate";
import CourseTopics from "./CourseTopics";

function StaticCourseContent({course, student, params, setParams}) {
    const [width,] = useWindowSize();
    const [topic, setTopic] = useState(params.topicId < 0 ? null : params.topicId);
    const chatRef = useRef(null);
    const twoColumns = width >= 920;
    
    const changeTopic = (topic) => {
        setTopic(topic);
        chatRef.current.scrollIntoView();
    }

    return <div style={{marginBottom: "16px", borderBottom: "6px solid #eeeeee"}}>
    {twoColumns ? <Split className="split" minSize={275} gutterSize={6} sizes={[50, 50]}>
        <div style={{paddingRight: "16px"}}>
            <div className="topCourseMenu"><Translate>Зміст</Translate></div>
            <CourseTopics course={course.id} setTopic={changeTopic} pageParams={params}/>
        </div>
        <Chat course={course} topic={topic} style={{marginLeft: "12px"}} chatRef={chatRef}
            student={student} pageParams={params} setParams={setParams} />
    </Split> : <div>
        <div className="topCourseMenu"><Translate>Зміст</Translate></div>
        <CourseTopics course={course.id} setTopic={changeTopic} pageParams={params}/>
        <Chat course={course} topic={topic} style={{marginTop: "16px"}} chatRef={chatRef}
            student={student} pageParams={params} />
    </div>}
    </div>
}

export default StaticCourseContent