import { useState, useEffect } from "react";
import { getAPICall } from "../../Components/APICall";
import CourseCard from "../../Lists/CourseCard";

function ActivityCourseInfo({course}) {
    const [courseInfo, setCourseInfo] = useState(null);

    useEffect(() => {
        const courseInfoSuccess = (data) => {
            if (data && data.length > 0) {
                setCourseInfo(data[0]);
            }
        }
        getAPICall('courses/card', {course: course}, courseInfoSuccess);
    }, [course]);

    return courseInfo ? <CourseCard course={courseInfo} top={false}/> : null
}

export default ActivityCourseInfo