import { useEffect, useState } from "react";
import { getAPICall } from "../Components/APICall";
import Translate from "../AppContext/Translate";

function UserRank({user}) {
    const [rank, setRank] = useState(null);

    useEffect(() => {
        getAPICall('user/rank', {user: user}, (data) => {
            if (data) {
                if (data.length === 0) setRank(0);
                else setRank(data[0].rank);
            }
        });
    }, [user]);

    return <p className="fkenyan">
        <Translate>Рівень</Translate> {rank + 1}
        <img src={"/icons/rank" + rank + ".png"} alt="" width="32px" style={{marginLeft: "6px"}}/>
    </p>
}

export default UserRank