import Translate from "../../AppContext/Translate"

function Contact({profile}) {
    return ((profile.facebook && profile.facebook !== '') || (profile.twitter && profile.twitter !== '') ||
        (profile.instagram && profile.instagram !== '') || (profile.webpage && profile.webpage !== '')) && <div>
        <p className="fmak bold large bot8"><Translate>Контакти</Translate></p>
        <div style={{display: "flex"}}>
            {profile.instagram && profile.instagram !== '' && <a href={profile.instagram} target="_blank" rel="noreferrer">
                <img src="/icons/instagram.png" alt="Instagram" height="30px" className="right18"/></a>}
            {profile.twitter && profile.twitter !== '' && <a href={profile.twitter} target="_blank" rel="noreferrer">
                <img src="/icons/twitter.png" alt="Twitter" height="30px" className="right18"/></a>}
            {profile.facebook && profile.facebook !== '' && <a href={profile.facebook} target="_blank" rel="noreferrer">
                <img src="/icons/facebookbw.png" alt="Facebook" height="30px" className="right18"/></a>}
            {profile.webpage && profile.webpage !== '' && <a href={profile.webpage} target="_blank" rel="noreferrer">
                <img src="/icons/web.png" alt="Webpage"  height="30px"/></a>}
        </div>
    </div>
}

export default Contact