import { useState } from "react";
import { getAPIurl } from "../../Components/APICall";
import Button from "react-bootstrap/Button";
import { BiTrash } from "react-icons/bi";
import NumericInput from "../../Components/NumericInput";
import Translate from "../../AppContext/Translate";

function ProductMember({member, index, setPercentage, deleteMember, setChanged, canChange}) {
    const [foundImg, setFoundImg] = useState(true);
    const url = getAPIurl('media/userPhoto', {user: member.owner});

    return <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-around",
        borderBottom: "solid thin #cfe2ff", marginBottom: "8px", paddingBottom: "8px", position: "relative"}}>
        <div style={{display: "flex", alignItems: "center", flexDirection: "row"}}>
            <Button disabled={!canChange} variant="link" style={{position: "absolute", right: "0px", top: "0px"}} 
                onClick={() => { deleteMember(index); }}><BiTrash/></Button>
            <img alt={member.name} src={foundImg ? url : "/icons/nouser.png"} className="teacherImg" 
                style={{marginRight: "16px"}} onError={()=>setFoundImg(false)}/>
            <p className="teacherName">{member.name}</p>
        </div>
        <div style={{paddingTop: "8px"}}>
            <p className="percentage top4" style={{marginBottom: "4px"}}><Translate>Відсоток гонорару товару</Translate></p>
            <NumericInput value={member.percentage} maxWidth={120} step={1} max={100} precision={0} isDisabled={!canChange}
                onChange={(value) => { setPercentage(index, value); setChanged(true); }} />
        </div>
    </div>
}

export default ProductMember