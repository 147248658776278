import { formatDate } from "../AppContext/Translate"
import PostMessage from "../Components/PostMessage"
import UserPhotoIcon from "../Components/UserPhotoIcon"
import ReactStars from "react-rating-stars-component";

function UserOpinion({user, userName, score, message, date}) {
    return <div style={{margin: "32px"}}>
        <div style={{display: "flex", alignItems: "center"}}>
            <UserPhotoIcon user={user} userName={userName}/>
            <span style={{marginLeft: "8px"}}>{userName}</span>
        </div>
        <p style={{fontSize: "small", color: "gray", marginBottom: "0px"}}>{formatDate(date)}</p>
        <ReactStars count={5} value={score} edit={false} isHalf={true}/>
        <PostMessage msg={message} open={false}/>
    </div>

}

export default UserOpinion