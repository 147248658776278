import { Link } from "react-router-dom"
import Translate from "../AppContext/Translate"

function CategoryCard({category}) {
    return <div>
        <Link to={"/category/" + category.id}>
            <div className="squareFrame">
                <img alt={category.title} src={"/categories/cat" + category.id + ".png"} width="148"/>
            </div>
        </Link>
        <div className="categoryCardTitle">
            {category.title}
        </div>
        <div className="categoryCardFooter">
            {category.numCourses} <Translate>курсів</Translate>
        </div>
    </div>
}

export default CategoryCard