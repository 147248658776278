import { Navigate, useParams } from "react-router-dom";
import { convert2int } from "../../Course/Course";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../AppContext/AppContext";
import { getAPICall } from "../../Components/APICall";
import { checkEditPermission } from "../Courses/CheckEditPermission";
import Menu from "../../Menu/Menu";
import Footer from "../../Components/Footer";
import { RiEqualizerLine } from 'react-icons/ri';
import Translate, { formatDate } from "../../AppContext/Translate";
import StudentsFilter from "../Plan/StudentsFilter";
import StudentInfo from "../Plan/StudentInfo";
import CabTitle from "../CabTitle";
import ChatPost from "../../Course/Chat/ChatPost";

function parseParameter(pageParams) {
    const splittedParams = pageParams !== undefined && pageParams !== null && pageParams.length >= 1 ?  
        pageParams.split('-') : null;
    var group = -1;
    var student = -1;
    if (splittedParams) {
        group = convert2int(splittedParams[0]);
        for (var i = 1; i < splittedParams.length; i++) {
            const param = splittedParams[i];
            const letter = param.charAt(0); 
            if (letter === 's') {           // Student
                student = convert2int(param.substring(1));
            }
        }
    }
    return {group: group, student: student}
}

function ManageGroup() {
    var { userId, token, dicLang } = useContext(AppContext);
    const [pageParams, setParams] = useState(parseParameter(useParams()["id"]));
    const [allowed, setAllowed] = useState(null);
    const [course, setCourse] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [showFilter, setShowFilter] = useState(false);
    const [filter, setFilter] = useState(0);
    const [students, setStudents] = useState([]);
    const [posts, setPosts] = useState(null);

    useEffect(() => {
        if (pageParams.group < 0) setAllowed(false);
        else {
            getAPICall('group/date', {groupId: pageParams.group}, (info) => {
                if (info && info.length > 0) {
                    setStartDate(info[0].startDate);
                }
            });
            getAPICall('group/course', {groupId: pageParams.group}, (data) => {
                if (data && data.length > 0) {
                    setCourse(data[0]);
                    checkEditPermission(userId, token, data[0].course, setAllowed, true);
                } else setAllowed(false);
            }, () => { setAllowed(false); });
        }
    }, [pageParams.group, userId, token]);

    const getStudents = (courseId, groupId, filter, userId, token) => {
        getAPICall('students/groupList', {userId: userId, token: encodeURIComponent(token), course: courseId,
            groupId: groupId, filter: filter}, (data) => { 
                if (data) setStudents(data);
            });
    }

    useEffect(() => {
        if (allowed && pageParams.group >= 0 && course !== null)
            getStudents(course.course, pageParams.group, filter, userId, token);
    }, [pageParams.group, allowed, filter, course, userId, token]);

    const refreshData = () => {
        getAPICall('materials/list', {userId: userId, token: encodeURIComponent(token), course: course.course, topic: -1},
        (data) => {
            if (data) {
                setPosts(data);
            }
        });
    }

    useEffect(() => {
        if (allowed && pageParams.group >= 0 && course !== null) {
            getAPICall('materials/list', {userId: userId, token: encodeURIComponent(token), course: course.course, topic: -1},
            (data) => {
                if (data) {
                    setPosts(data);
                }
            });
        }
    }, [pageParams.group, allowed, course, userId, token]);

    return allowed !== null && allowed === false ? <Navigate to="/" replace={true} /> :
    <div className='mainContainer'>
        <Menu/>
        <div style={{padding: "16px", backgroundColor: "#e8e8e8"}}>
            <CabTitle title="Студенти"/>
            {course && <h4>{course.title}</h4>}
            {startDate && <p><Translate>Дата початку</Translate>: {formatDate(startDate)}</p>}
            <div style={{textAlign: "right", marginTop: "-16px"}}>
                <RiEqualizerLine className="userLink" size="24px" onClick={()=>{setShowFilter(!showFilter)}}/>
            </div>
            <StudentsFilter show={showFilter} setFilter={setFilter} dicLang={dicLang}/>
            {students.map((data, index) => 
                <StudentInfo key={index} data={data} courseId={course.course}/>)}
        </div>
        <div style={{padding: "16px", backgroundColor: "white", borderTop: "solid thin #8f9090"}}>
            {course && <>
                <h4><Translate>Груповий чат</Translate></h4>
                <div style={{display: "flex", justifyContent: "center"}}>
                    <div style={{width: "640px", maxWidth: "100%", backgroundColor: "whitesmoke", paddingBottom: "24px",
                        borderRadius: "8px"}}>
                    {posts && posts.map((post, index) => (
                        <ChatPost key={index} post={post} course={course.course} 
                            topic={{topic: post.topic, title: '', day: post.day, show: 'day'}}
                            student={{student: -1, group: pageParams.group, dynamic: false}} 
                            allExpanded={true} refreshData={refreshData} pageParams={pageParams} setParams={setParams} />
                    ))}
                    </div>
                </div>
            </>}
        </div>
        <Footer/>
    </div>
}

export default ManageGroup