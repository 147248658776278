import { useEffect, useRef, useState } from "react";

const HorizontalScroll = ({ children, itemSize = 200 }) => {
    const scrollContainerRef = useRef(null);
    const [isAtStart, setIsAtStart] = useState(true);
    const [isAtEnd, setIsAtEnd] = useState(false);
    const [fit, setFit] = useState(false);

    useEffect(() => {
      if (scrollContainerRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
        const fit = clientWidth + 10 >= scrollWidth;
        setIsAtStart(scrollLeft === 0);
        setIsAtEnd(fit);
        setFit(fit);
      }
    }, [children, itemSize]);

    const checkScrollPosition = () => {
        if (scrollContainerRef.current) {
          const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
          setIsAtStart(scrollLeft === 0);
          setIsAtEnd(scrollLeft + clientWidth + 10 >= scrollWidth);
        }
    };

    const scrollLeft = () => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollBy({
          left: -itemSize,
          behavior: 'smooth'
        });
        setTimeout(checkScrollPosition, 300);
      }
    };
  
    const scrollRight = () => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollBy({
          left: itemSize,
          behavior: 'smooth'
        });
        setTimeout(checkScrollPosition, 300);
      }
    };
  
    const handleMouseDown = (e) => {
      scrollContainerRef.current.isDown = true;
      scrollContainerRef.current.startX = e.pageX - scrollContainerRef.current.offsetLeft;
    };
  
    const handleMouseLeave = () => {
      scrollContainerRef.current.isDown = false;
    };
  
    const handleMouseUp = () => {
      scrollContainerRef.current.isDown = false;
    };
  
    const handleMouseMove = (e) => {
      if (!scrollContainerRef.current.isDown) return;
      e.preventDefault();
      const x = e.pageX - scrollContainerRef.current.offsetLeft;
      const walk = (x - scrollContainerRef.current.startX) * 2; // Scroll-fast
      scrollContainerRef.current.scrollLeft = scrollContainerRef.current.scrollLeft - walk;
      setTimeout(checkScrollPosition, 300);
    };
  
    return (
      <div className="horizontal-scroll">
        <button className={`scroll-button left ${isAtStart ? 'disabled' : ''}`} onClick={scrollLeft}/>
        <div style={fit ? {width: "100%", justifyContent: "center"} : {}}
          className="scroll-container preventSelect"
          ref={scrollContainerRef}
          onMouseDown={handleMouseDown}
          onMouseLeave={handleMouseLeave}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
        >
          {children}
        </div>
        <button className={`scroll-button right ${isAtEnd ? 'disabled' : ''}`} onClick={scrollRight}/>
      </div>
    );
  };
  
  export default HorizontalScroll