import { translate } from "../AppContext/Translate";
import { useContext, lazy, Suspense, useState, useRef } from "react";
import { AppContext } from "../AppContext/AppContext";
import Popover from 'react-bootstrap/Popover';
import { BsEmojiSmile } from 'react-icons/bs';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import BeatLoader from 'react-spinners/BeatLoader';
import ContentEditor from "./ContentEditor";
import { insertEmoji } from "./htmlInsert";

const EmojiPicker = lazy(() => import('emoji-picker-react'));

function SimpleComment({html, setHtml, maxLength=2048}) {
    var {dicLang} = useContext(AppContext);
    const [cursorPosition, setCursorPosition] = useState(null);
    const editor = useRef(null);

    const Loading = () => <BeatLoader size={24} color="#0d6efd" />;
            
    const onTextChange = (txt) => {
        const s = txt.length > maxLength ? txt.substring(0, maxLength - 1) : txt;
        setHtml(s);
    }

    function onClickEmoji(emojiData) {
        if (cursorPosition) {
            document.body.click();
            editor.current.focus();    
            const newText = insertEmoji('<img alt="" width="18px" height="18px" src="' +
                emojiData.getImageUrl("apple") + '"/>', html, cursorPosition.cursorPosition);
            onTextChange(newText);
        }
    }
    
    const emojiPicker = (
        <Popover id="popover-emoji">
        <Popover.Body>
        <Suspense fallback={<Loading />}>
          <EmojiPicker onEmojiClick={onClickEmoji} height={360}
              previewConfig={{
                 defaultCaption: translate("Виберіть смайлик!", dicLang),
                 defaultEmoji: "1f60a"
              }}
              categories={[
                  {name: translate("Рекомендовані", dicLang), category: 'suggested'},
                  {name: translate("Люди", dicLang), category: 'smileys_people'},
                  {name: translate("Природа", dicLang), category: 'animals_nature'},
                  {name: translate("Харчування", dicLang), category: 'food_drink'},
                  {name: translate("Поїздки", dicLang), category: 'travel_places'},
                  {name: translate("Діяльність", dicLang), category: 'activities'},
                  {name: translate("Об'єкти", dicLang), category: 'objects'},
                  {name: translate("Символи", dicLang), category: 'symbols'},
                  {name: translate("Прапори", dicLang), category: 'flags'}]}
          />
        </Suspense>            
        </Popover.Body>
      </Popover>
    );

    return <div>
        <ContentEditor text={html} onTextChange={onTextChange} sty={{marginBottom: "0px"}}
            editor={editor} setCursorPosition={setCursorPosition}/>
        <OverlayTrigger rootClose trigger="click" placement="right" overlay={emojiPicker}>
            <button className="ChatToolButton"><BsEmojiSmile size={22}/></button>
        </OverlayTrigger>
    </div>
}

export default SimpleComment