import { useState } from "react";
import DocPreview from "../../Components/DocPreview";
import { MediaPath } from "../../Components/MediaPath";
import { MimeIconSmall } from "../../Components/MimeIcon";

function StudentFileForRequest({request}) {
    const [preview, setPreview] = useState(null);

    var path = new MediaPath(MediaPath.kind_privateOptionRequest);
    path.add(request.user);
    path.add(request.package);
    path.addStr(request.code);

    const onPreview = (data) => {
        setPreview(data);
    }

    return request.sentFile === null ? null : <>
        {preview && <DocPreview kind={preview["kind"]} name={preview["name"]} setPreview={setPreview} path={path}/>}
        <MimeIconSmall name={request.sentFile} onPreview={onPreview}/>        
    </>
}

export default StudentFileForRequest