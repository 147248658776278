import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useState, useContext, useEffect, Suspense, lazy, useRef } from "react";
import { BsEmojiSmile } from 'react-icons/bs';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { AppContext } from "../../AppContext/AppContext";
import Translate, { translate } from "../../AppContext/Translate";
import Popover from 'react-bootstrap/Popover';
import Button from "react-bootstrap/Button";
import { FiUpload } from 'react-icons/fi';
import BeatLoader from "react-spinners/BeatLoader";
import ContentEditor from "../../Components/ContentEditor";
import { insertEmoji } from "../../Components/htmlInsert";

const EmojiPicker = lazy(() => import('emoji-picker-react'));

function InlineWriteComment({send, text = ""}) {
    const { dicLang } = useContext(AppContext);
    const [html, setHtml] = useState(text);
    const [cursorPosition, setCursorPosition] = useState(null);
    const editor = useRef(null);

    const Loading = () => <BeatLoader size={24} color="#0d6efd" />;

    useEffect(() => {
        window.scrollBy(0, 260);
    }, [])

    const onTextChange = (txt) => {
        setHtml(txt);
    }

    function onClickEmoji(emojiData) {
        if (cursorPosition) {
            document.body.click();
            editor.current.focus();    
            const newText = insertEmoji('<img alt="" width="18px" height="18px" src="' +
                emojiData.getImageUrl("apple") + '"/>', html, cursorPosition.cursorPosition);
            onTextChange(newText);
        }
    }

    const emojiPopover = (
        <Popover id="popover-emoji">
          <Popover.Body>
            <Suspense fallback={<Loading />}>
            <EmojiPicker onEmojiClick={onClickEmoji} height={360}
                previewConfig={{
                   defaultCaption: translate("Виберіть смайлик!", dicLang),
                   defaultEmoji: "1f60a"
                }}
                categories={[
                  {name: translate("Рекомендовані", dicLang), category: 'suggested'},
                  {name: translate("Люди", dicLang), category: 'smileys_people'},
                  {name: translate("Природа", dicLang), category: 'animals_nature'},
                  {name: translate("Харчування", dicLang), category: 'food_drink'},
                  {name: translate("Поїздки", dicLang), category: 'travel_places'},
                  {name: translate("Діяльність", dicLang), category: 'activities'},
                  {name: translate("Об'єкти", dicLang), category: 'objects'},
                  {name: translate("Символи", dicLang), category: 'symbols'},
                  {name: translate("Прапори", dicLang), category: 'flags'}]}
            />
            </Suspense> 
          </Popover.Body>
        </Popover>
    );

    return <>
    <Row>
        <Col>
        <p style={{margin: "0px"}}><Translate>Додайте коментар</Translate> (<Translate>не обов'язково</Translate>):</p>
        </Col>
    </Row>
    <Row>
        <Col>
            <ContentEditor text={html} onTextChange={onTextChange} editor={editor} setCursorPosition={setCursorPosition}/>
        </Col>
        <Col xs={3} style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between",
            paddingBottom: "16px"}}>
            <OverlayTrigger rootClose trigger="click" placement="top" overlay={emojiPopover}>
                <button className="ChatToolButton"><BsEmojiSmile size={22}/></button>
            </OverlayTrigger>
            <Button style={{display: "flex", alignItems: "center"}} onClick={() => {send(html)}}>
                <Translate>Надіслати</Translate><FiUpload style={{marginLeft: "6px"}}/>
            </Button>
        </Col>
    </Row>
    </>
}

export default InlineWriteComment