import { Link } from "react-router-dom"
import Translate from "../AppContext/Translate"
import { FaAngleLeft } from "react-icons/fa6";
import { useContext } from "react";
import { AppContext } from "../AppContext/AppContext";

function MyProfileButton({center=true}) {
    var {userId} = useContext(AppContext);

    const button = <Link to={"/profile/" + userId}>
        <button style={{padding: "4px 12px", backgroundColor: "#12c191", color: "white",
            cursor: "pointer", textAlign: "center", border: "none", borderRadius: "4px"}}
            className="large">
            <FaAngleLeft size="22px" className="right6"/>
            <Translate>повернутися у мій профіль</Translate>
        </button>
    </Link>
    return center ? <div style={{display: "flex", justifyContent: "center"}}>{button}</div> : button 
}

export default MyProfileButton