import FacebookLogin from '@greatsumini/react-facebook-login';
import Translate from '../../AppContext/Translate';

function FacebookButton({createAc, loginSuccess, loginFail}) {
    return <FacebookLogin
    appId="644542086819304"
    onSuccess={(response) => {
    }}
    onFail={(error) => {
        loginFail();
    }}
    onProfileSuccess={(response) => {
      const email = response.email;
      const id = response.id;
      const name = response.name;
      const pic = response.picture.data["url"];
      loginSuccess(email, id, name, pic);
    }}
    render={({ onClick, logout }) => (
        <button className="facebook" onClick={onClick}>
            <img alt="" src="/icons/facebook.png" width="24" height="24" className="d-inline-block" />
            {' '}<Translate>{ createAc ? "Зареєструйтесь через Facebook" : "Зайдіть через Facebook" }</Translate>
        </button>
    )}
  />
}

export default FacebookButton