import { useEffect, useState, useContext } from 'react';
import Card from 'react-bootstrap/Card';
import CardHeader from 'react-bootstrap/CardHeader';
import CardBody from 'react-bootstrap/CardBody';
import { getAPICall, postAPICall } from '../../Components/APICall';
import UserPhotoIcon from '../../Components/UserPhotoIcon';
import Translate, { formatDate, translate } from '../../AppContext/Translate';
import NumericInput from '../../Components/NumericInput';
import Button from 'react-bootstrap/Button';
import { BsFillInfoCircleFill } from "react-icons/bs";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { AppContext } from '../../AppContext/AppContext';
import { MediaPath } from '../../Components/MediaPath';
import PostMessage from '../../Components/PostMessage';
import { confirm } from "../../Components/ConfirmFunction";

function StudentApproval({student, pkg, teacher, reviewDate, comment, task, removeRequest}) {
    const { userId, token, dicLang } = useContext(AppContext);
    const [userName, setUserName] = useState(null);
    const [teacherName, setTeacherName] = useState(null);
    const [pkgDesc, setPkgDesc] = useState(null);
    const [score, setScore] = useState(0);

    var path = new MediaPath(MediaPath.kind_privateTaskReview);
    path.add(student);
    path.add(pkg);
    path.add(task);
    path.add(teacher);

    useEffect(() => {
        getAPICall('user/name', {user: student}, (data) => {
            if (data.length === 1) setUserName(data[0].name);
        });
    }, [student]);

    useEffect(() => {
        getAPICall('package/data', {packageId: pkg},
            (data) => {
                if (data && data.length > 0) {
                    const row = data[0];
                    setPkgDesc({course: row.course, title: row.title, courseTitle: row.courseTitle});
                }
            });
    }, [pkg]);
    
    useEffect(() => {
        getAPICall('user/name', {user: teacher}, (data) => {
            if (data.length === 1) setTeacherName(data[0].name);
        });
    }, [teacher]);

    const onError = (data) => {
        const msg = 'error' in data ? data.error : "не вдалося зберегти зміни";
        alert(translate("Помилка", dicLang) + ": " + translate(msg, dicLang));
    }

    const approve = async () => {
        if (await confirm({confirmation: translate('Ви впевнені, що хочете схвалити курс студента?', dicLang)})) {
            postAPICall('students/approve', {userId: userId, token: token, packageId: pkg,  
                student: student, score: score},
                (data) => {
                    if (data && 'error' in data && data.error === 'none') removeRequest();
                    else onError(data);
                }, (data) => { onError(data); });
        }
    }

    const popover = (
        <Tooltip>
            <div style={{display: "flex", justifyContent: "space-between"}}>
                {teacherName && <div style={{display: "flex", alignItems: "center"}}>
                    <UserPhotoIcon user={student} userName={teacherName} big={false}/>
                    <span>{teacherName}</span>
                </div>}
                <div style={{marginLeft: "16px", fontSize: "small"}}>{formatDate(reviewDate)}</div>
            </div>
            {comment && <>
                <hr/>
                <PostMessage msg={comment} path={path} open={false} sep={false} textStyle="smallTextPost"/>
            </>}
        </Tooltip>
    );
    
    return userName && pkgDesc ? <Card style={{margin: "8px", maxWidth: "384px"}}>
        <CardHeader style={{display: "flex", justifyContent: "space-between"}}>
            <div style={{display: "flex", alignItems: "center"}}>
                <UserPhotoIcon user={student} userName={userName} big={false}/>
                <span>{userName}</span>
            </div>
        </CardHeader>
        <CardBody>
            <p style={{margin: "0px", color: "cadetblue"}}>{pkgDesc.courseTitle}</p>
            <p style={{margin: "0px", fontSize: "smaller"}}>{pkgDesc.title}</p>
            <div style={{display: "flex", alignItems: "baseline", marginTop: "8px"}}>
                <span style={{marginRight: "6px"}}><Translate>Оцінка</Translate>:</span>
                <NumericInput value={score} step={1} precision={1} min={0} max={10} maxWidth={120}
                    onChange={(e) => { setScore(e); }}/>
            </div>
            <div style={{display: "flex"}}>
                <OverlayTrigger placement="top" delay={{ show: 1000, hide: 400 }} overlay={popover}>
                    <Button size="sm" variant="link" style={{marginRight: "8px"}}>
                        <BsFillInfoCircleFill color="#0d6efd"/></Button>
                </OverlayTrigger>
                <Button size="sm" onClick={approve}><Translate>Затвердити</Translate></Button>
            </div>
        </CardBody>
    </Card> : null
}

export default StudentApproval