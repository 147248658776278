import { useWindowSize } from '../AppContext/WindowSize';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import MenuBrand from './MenuBrand';
import { Link } from 'react-router-dom';
import MenuSearch from './MenuSearch';
import MenuCart from './MenuCart';
import MenuNotifications from './MenuNotifications';
import { GoogleOAuthProvider } from '@react-oauth/google';
import MenuLogin from './Login/MenuLogin';

function Menu() {
    const [width, ] = useWindowSize();
    const size = width >= 768 ? "big" : (width < 576 ? "tiny" : "small");
    
    return <Navbar bg="dark" data-bs-theme="dark" className="py-0" style={{height: '64px', width: "100%"}}>
        <Container fluid style={{padding: "0px"}}>
            <MenuBrand />
            {width >= 434 && <Nav className="me-auto">
                <Navbar.Text as={Link} to='/shop'>
                    <div className='menuDivIcon'>
                        <img src="/bkg/shop2.png" height="26" alt=""/>
                    </div>
                </Navbar.Text>
            </Nav>}
            <Navbar.Toggle />
            <Navbar className="justify-content-end py-0" bg="dark" variant="dark">
                {width >= 434 && <MenuSearch size={size} />}
                <MenuCart />
                <MenuNotifications />
                <GoogleOAuthProvider clientId="69903558674-5f4auee98e7s7so6ri58fmigomvuti3r.apps.googleusercontent.com">
                    <MenuLogin size={size} />
                </GoogleOAuthProvider>
            </Navbar>
        </Container>
    </Navbar>
}

export default Menu