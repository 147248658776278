import Translate from "../AppContext/Translate"
import { getAPIurl } from "../Components/APICall";
import Button from "react-bootstrap/Button";

function SchoolTeachers({teachers, routeChange}) {
    return <>
    <hr/>
    <h2><Translate>Викладачі</Translate></h2>
    <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-around"}}>
    {
        teachers.map((item, i) => {
            const url = getAPIurl('media/userPhoto', {user: item.teacher});
            return <div key={i} className="teachercolumn" style={{backgroundColor: "white"}}>
                <div style={{display: "flex", alignItems: "center", flexDirection: "row"}}>
                    <img alt={item.name} src={url} className="teacherImg" style={{marginRight: "16px"}}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = "/icons/nouser.png";
                    }}/>
                    <div style={{textAlign: "center", width: "100%"}}>
                        <p className="teacherName">{item.name}</p>
                        <p className="teacherRole">{item.role}</p>
                        <Button variant="outline-secondary" onClick={() => {routeChange("/profile/" + item.teacher)}}>
                            <Translate>Дізнатися більше</Translate></Button>
                    </div>
                </div>
            </div>
        })
    }
    </div>
    </>
}

export default SchoolTeachers