import BeatLoader from "react-spinners/BeatLoader";
import Translate from "../../AppContext/Translate";
import { postAPICall } from "../../Components/APICall";
import Form from 'react-bootstrap/Form';
import WriteHelper from "../../Components/WriteHelper";

const { useState } = require("react");

function SendMessage({fromUser, toUser, token, onSent}) {
    const [sending, setSending] = useState(false);
    const [msg, setMsg] = useState('');

    const messageSent = (data) => {
        if (data && 'error' in data && data['error'] === 'none') {
            onSent(msg);
            setSending(false);
            setMsg('');
        }
    }

    const sendMessage = () => {
        setSending(true);
        postAPICall('message/send', {userId: fromUser, token: token, toUser: toUser, text: msg},
            messageSent);
    }

    const disabled = !msg || msg.length < 1;

    return <>
        <p className="fkenyan large">
            <img alt="" src="/icons/dialog.png" height="30px" style={{marginRight: "8px", transform: "translateY(-4px)"}}/>
            <Translate>Нове повідомлення</Translate>:
        </p>
        {sending ? <div style={{backgroundColor: "white", borderRadius: "4px"}} className="pad12">
            <BeatLoader color="#12c191" size="10px"/>
        </div> : <>
            <Form.Control as="textarea" value={msg} rows={3} maxLength="4000" onChange={(event) => { 
                setMsg(WriteHelper.filterSimpleText(event.target.value));
            }} style={{margin: "4px 0px 8px 0px"}}/>
            <button disabled={disabled} style={{padding: "4px 12px", backgroundColor: "rgb(18, 193, 145)",
                color: "white", cursor: disabled ? "auto" : "pointer", textAlign: "center", border: "none",
                borderRadius: "4px", filter: disabled ? "opacity(0.5)" : "none"}}
                className="large right18" onClick={() => { sendMessage(); }}>
                <Translate>Надіслати</Translate>
            </button>
        </>}
    </>
}

export default SendMessage