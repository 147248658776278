import { useEffect, useContext, useState } from "react"
import { getAPICall } from "../../Components/APICall";
import { AppContext } from "../../AppContext/AppContext";
import Accordion from 'react-bootstrap/Accordion';
import { GiDiploma } from "react-icons/gi";
import Translate from "../../AppContext/Translate";
import StudentApproval from "./StudentApproval";

function Approvals() {
    const { userId, token } = useContext(AppContext);
    const [students, setStudents] = useState([]);
    const [sent, setSent] = useState(new Set());

    useEffect(() => {
        getAPICall('diploma/pendant', {userId: userId, token: encodeURIComponent(token)}, (data) => {
            if (data) setStudents(data);
        });
    }, [userId, token]);

    const removeRequest = (index) => {
        const newSet = new Set(sent);
        newSet.add(index);
        setSent(newSet);
    }

    return students.length > 0 && students.length > sent.size  ? <Accordion>
        <Accordion.Item eventKey="4">
            <Accordion.Header>
                <GiDiploma size="24" style={{margin: "1px 8px 0px 0px"}}/>
                <span style={{fontSize: "larger", fontWeight: "600"}}><Translate>Оцінювання</Translate></span>
            </Accordion.Header>
            <Accordion.Body>
                <div style={{display: "flex", flexWrap: "wrap", alignItems: "flex-start"}}>
                {students.map((s, i) => {
                    return <StudentApproval key={i} student={s.client} pkg={s.package} teacher={s.teacher}
                        reviewDate={s.reviewDate} comment={s.comment} task={s.post}
                        removeRequest={() => { removeRequest(i) }}/>
                })}
                </div>
            </Accordion.Body>
        </Accordion.Item>
    </Accordion>: null;
}

export default Approvals