import { useContext, useState, useEffect } from "react";
import { AppContext } from "../AppContext/AppContext";
import { getAPICall } from "../Components/APICall";
import { Navigate } from "react-router-dom";
import Menu from "../Menu/Menu";
import Footer from "../Components/Footer";
import Translate from "../AppContext/Translate";
import CabinetCard from "./CabinetCard";
import HomeButton from "../Components/HomeButton";

function Cabinet() {
    const { userId, token } = useContext(AppContext);
    const [ admin, setAdmin ] = useState(false);
    const [ schoolId, setSchoolId ] = useState(-1); 
    const [ teacher, setTeacher ] = useState(false);

    useEffect(() => {
        if (userId >= 0) {
            getAPICall('cabinet/admin', {userId: userId, token: encodeURIComponent(token)},
                (data) => {
                    if (data && 'admin' in data) {
                        setAdmin(data.admin === 1);
                    }
                });
            getAPICall('cabinet/myschool', {userId: userId, token: encodeURIComponent(token)}, 
                (data) => {
                    if (data && data.length > 0 && data[0].approvalDate !== null) {
                        setSchoolId(data[0].id);
                    }
                });
            getAPICall('user/isTeacher', {userId: userId},
                (data) => {
                if (data && 'teacher' in data) setTeacher(data.teacher === 1);
            });
        }
    }, [userId, token]);

    return userId < 0 ? <Navigate to="/" replace={true} /> :
    <div className='mainContainer' style={{backgroundColor: "white"}}>
        <Menu/>
        <h1 className="pad12"><Translate>Особистий кабінет</Translate></h1>
        <div style={{padding: "24px", display: "flex", flexWrap: "wrap", justifyContent: "center"}}>
            <img alt="" src="/bkg/decor/dec15.png" style={{position: "absolute", zIndex: 100,
                width: "10%", maxWidth: "108px", left: "0px", top: "0px"}}/>
            {admin && <CabinetCard icon='admin' title='Адміністрація' desc='Керуйте платформою, затверджуйте школи та курси' />}
            {(schoolId >= 0 || teacher) && <CabinetCard icon='students' title='Студенти' desc='Керуйте учнями, виправляйте завдання та проекти, видавайте дипломи' />}
            {(schoolId >= 0 || teacher) && <CabinetCard icon='courses' title='Курси' desc='Створюйте та оновлюйте курси та матеріали' />}
            <CabinetCard icon='school' title='Школа' desc='Створіть і керуйте своєю школою' />
            <CabinetCard icon='shop' title='Магазин' desc='Керуйте власним магазином товарів' />
            {(schoolId >= 0 || teacher) && <CabinetCard icon='report' title='Економічний менеджмент' desc='Керуйте своїми доходами та вимагайте платежів' />}
        </div>
        <HomeButton/>
        <div style={{height: "16px"}}/>
        <Footer/>
    </div>
}

export default Cabinet