function PlanPrice({price, prev}) {
    return <div className="planPrice" style={{backgroundImage: "url('/bkg/bkgprice.png')", backgroundSize: "contain",
        backgroundRepeat: "no-repeat", backgroundPosition: "center"}}>
        <p className="planPriceLabel">{price + ' грн'}</p>
        {prev !== null && prev > 0 && <div className="planOldPrice">
            {prev + ' грн'}
        </div>
        }
    </div>    
}

export default PlanPrice