import { HiOutlineEmojiSad } from 'react-icons/hi';
import Form from 'react-bootstrap/Form';
import { Fragment } from 'react';
import Translate from '../AppContext/Translate';
import dateFormat, { endDateFormat } from '../Components/DateFormat';

function StartDates({dates, groupId, setGroupId, pkgId}) {
    return dates.length === 0 ? <div style={{textAlign: "center", color: "darkred"}}>
        <p><HiOutlineEmojiSad size="22"/>{' '}<Translate>Нові групи не плануються</Translate>.<br/>
            <span style={{fontStyle: "italic"}}>
            <Translate>Зверніться до викладачів, якщо ви зацікавлені у відкритті групи найближчим часом</Translate>.</span>
        </p>
    </div> : <>
        <p style={{margin: "-6px 0px 4px 6px", fontStyle: "italic", fontSize: "smaller"}}>
            <Translate>Виберіть дату початку курсу</Translate>:</p>
        <div style={{marginLeft: "6px"}}>
        {dates.map((date, k) => {
            const startDate = new Date(date.startDate);
            const label = dateFormat(startDate) + ' - ' + endDateFormat(startDate, date.numDays);
            return <Fragment key={k}>
                <Form.Check key={2*k} id={"radio" + pkgId + "_" + k}>
                <Form.Check.Input type="radio" checked={groupId === date.id} name={"datesGroup" + pkgId}
                    onChange={() => { setGroupId(date.id); }} />
                <Form.Check.Label>{label}</Form.Check.Label>
                </Form.Check>
                {date.elapsedDays > 0 &&
                <p style={{fontStyle: "italic", margin: "0px 0px 4px 25px", fontSize: "smaller",
                    color: "cadetblue"}}><Translate>Курс вже розпочато, але ви ще маєте час приєднатися</Translate>.</p>}
            </Fragment>
            })
        }
        </div>
    </>
}

export default StartDates