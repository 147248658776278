import Card from 'react-bootstrap/Card';
import Translate from '../../AppContext/Translate';
import { BiBlock, BiCheck } from "react-icons/bi";
import Valoration from '../../Lists/Valoration';
import { RiFileAddFill } from "react-icons/ri";

function CabCourseCard({courseData, selectCourse}) {
    return <Card style={{ width: '18rem', backgroundColor: "#212529", color: "floralwhite", 
        boxShadow: "2px 2px 4px #2125295c", margin: "12px", cursor: "pointer" }}
        onClick={() => { selectCourse(courseData.id); }}>
        {courseData.id >= 0 && <Card.Img variant="top" src={"/courses/cover" + courseData.id + ".jpg"} alt=""
            onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "/courses/nocover.jpg";
        }} />}
        <Card.Body style={{paddingTop: "8px"}}>
            {courseData.id >= 0 ? <>
            <Card.Title style={{color: "#aea78d"}}>
                <img alt={courseData.lang} src={'/lang/' + courseData.lang + '.png'} width='26px' height='17px' />
                <span style={{marginLeft: "8px"}}>{courseData.title}</span>
            </Card.Title>
            <Card.Text style={{borderTop: "solid thin #61583e", marginBottom: "6px"}}/>
            <div style={{fontSize: "small", marginTop: "4px"}}>
                <Translate>Актив</Translate>: {courseData.active ? <BiCheck color="green" size="16px"/> : 
                    <BiBlock color="darkred" size="16px"/>}
            </div>
            {courseData.numOpinions > 0 && <Valoration score={courseData.score} numOpinions={courseData.numOpinions}/> }
            </> : <div style={{textAlign: "center"}}>
                <RiFileAddFill size="28" color="#aea78d"/>
                <p style={{margin: "0px"}}><Translate>Створіть новий курс</Translate></p>
            </div>}
        </Card.Body>
    </Card>
}

export default CabCourseCard