import { useEffect, useContext } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { GiChoice } from "react-icons/gi";
import Translate, { translate } from '../../AppContext/Translate';
import { getAPICall, postAPICall } from '../../Components/APICall';
import Form from 'react-bootstrap/Form';
import { BiTrash } from 'react-icons/bi';
import Button from "react-bootstrap/Button";
import WriteHelper from '../../Components/WriteHelper';
import RTEditor from '../../Components/RTEditor';
import Dropdown from 'react-bootstrap/Dropdown';
import { AppContext } from '../../AppContext/AppContext';
import { confirm } from '../../Components/ConfirmFunction';
import InfoButton from '../../Components/InfoButton';

function CabEditCourseOptions({course, options, setOptions}) {
    var { userId, token, dicLang } = useContext(AppContext);

    useEffect(() => {
        getAPICall('course/options', {course: course}, async (data) => {
            if (data) {
                var opt = {};
                for (var i = 0; i < data.length; i++) {
                    const row = data[i];
                    const desc = await WriteHelper.parseMessage(row.description ? row.description : '', null);
                    row.description = desc;
                    opt[row.code] = row;
                }
                setOptions(opt);
            }
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [course]);

    const deleteError = () => {
        alert(translate("Помилка", dicLang) + ": " + translate("ви не можете видалити параметр, якщо він використовується в пакеті", dicLang));
    }

    const deleteOption = async (code) => {
        if (await confirm({confirmation: translate('Ви впевнені, що хочете видалити цю послугу?', dicLang)})) {
            postAPICall('course/deleteOption', {userId: userId, token: token, course: course, code: code},
                (data) => {
                    if (!data || !('error' in data) || data.error !== 'none') deleteError();
                    else {
                        var copy = {...options};
                        delete copy[code];
                        setOptions(copy);
                    }
                }, deleteError);
        }
    }

    const setChanged = (code, value) => {
        var copy = {...options};
        copy[code]['changed'] = value;
        setOptions(copy);
    }

    const changed = (code) => {
        if ('changed' in options[code]) return options[code].changed;
        else return false; 
    }

    const setOptionField = (code, field, value) => {
        var copy = {...options};
        copy[code][field] = value;
        setOptions(copy);
        setChanged(code, true);
    }

    const getOptionKind = (id) => {
        if (id === 0) return <Translate>Посилання</Translate>
        else return <Translate>Файл</Translate>
    }

    const getRequestKind = (id) => {
        if (id === 0) return <Translate>Немає: керує вчитель</Translate>
        else if (id === 1) return <Translate>Студент матиме кнопку запиту</Translate>
        else return <Translate>Студент повинен буде надіслати файл</Translate>
    }

    const getShareKind = (id) => {
        if (id === 0) return <Translate>Групова</Translate>
        else return <Translate>Індивідуальна</Translate>
    }

    const saveError = (data) => {
        alert(translate("Помилка", dicLang) + ": " + translate("не вдалося зберегти зміни", dicLang));
    }

    const save = (code) => {
        const option = options[code];
        postAPICall('course/option', {userId: userId, token: token, course: course, code: code,
            kind: option.kind, share: option.share, request: option.request, title: option.title,
            description: WriteHelper.encode(option.description.text)}, (data) => {
                if (!data || !('error' in data) || data.error !== 'none') saveError();
                else {
                    setChanged(code, false);
                    alert(translate("Зміни успішно збережено", dicLang));
                }
            }, saveError);
    }

    const addOption = () => {
        const validCodes = 'ABCDEFGHIJKLMNOPQRSTUVWXabcdefghijklmnopqrstuvwxyz0123456789';
        var newCode = null; 
        for (var i = 0; i < validCodes.length; i++) {
            if (!(validCodes[i] in options)) {
                newCode = validCodes[i];
                break;
            }
        }
        if (newCode) {
            var copy = {...options};
            copy[newCode] = {code: newCode, course: course, description: {text: '', files: []},
                kind: 1, request: 1, share: 1, title: '', changed: true};
            setOptions(copy);
        } else alert(translate("Помилка", dicLang) + ": " + translate("ви не можете додати більше послуг", dicLang));
    }

    return <Accordion.Item eventKey="3">
        <Accordion.Header>
            <GiChoice size="24" style={{margin: "1px 8px 0px 0px"}}/>
            <span style={{fontSize: "larger", fontWeight: "600"}}><Translate>Варіанти пакетів</Translate></span>
        </Accordion.Header>
        <Accordion.Body>
        <p className='bot8'><Translate>Сформуйте пропозиції для кожного пакету послуг. Якщо ви пропонуєте лише один пакет, уточніть, які послуги та бонуси в нього входять. Після того, як ви визначите всі можливі варіанти послуг для ваших пакетів, перейдіть до розділу "Пакети" та виберіть зі списку "Послуги для пакетів" ті послуги, які будуть включені в кожен із пакетів.</Translate></p>
        {Object.keys(options).map((code, i) => {
            return <div key={i} style={{position: "relative", borderBottom: "solid thin #cfe2ff", 
                marginBottom: "8px", paddingBottom: "8px"}}>
                <div style={{position: "absolute", right: "0px", top: "-8px"}}>
                    <Button variant="link" onClick={() => { deleteOption(code); }}><BiTrash size={18}/></Button>
                </div>
                <Form.Label style={{fontWeight: "600"}}><Translate>Назва</Translate></Form.Label>
                <Form.Control value={options[code].title} type="text" id={"inputTitle" + code}
                    onChange={(e) => { setOptionField(code, 'title', e.target.value); }}/>
                <Form.Label><Translate>Опис</Translate></Form.Label>
                <RTEditor item={options[code].description} onChange={(desc) => { 
                    setOptionField(code, 'description', desc); }} allowImages={false} 
                    allowVideos={false} allowFiles={false} bkgColor='ghostwhite'/>
                <div style={{display: "flex", alignItems: "baseline", flexWrap: "wrap", marginTop: "8px"}}>
                    <Form.Label style={{marginRight: "8px"}}><Translate>Вид матеріалу</Translate></Form.Label>
                    <Dropdown onSelect={(eventkey, event) => { setOptionField(code, 'kind', parseInt(event.target.id)); }}>
                        <Dropdown.Toggle variant="outline-dark" id="dropdown-category">
                        {getOptionKind(options[code].kind)}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item id={0}>{getOptionKind(0)}</Dropdown.Item>
                            <Dropdown.Item id={1}>{getOptionKind(1)}</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div style={{display: "flex", alignItems: "baseline", marginTop: "6px", flexWrap: "wrap"}}>
                    <Form.Label style={{marginRight: "8px"}}><Translate>Як застосовувати</Translate></Form.Label>
                    <Dropdown onSelect={(eventkey, event) => { setOptionField(code, 'request', parseInt(event.target.id)); }}>
                        <Dropdown.Toggle variant="outline-dark" id="dropdown-category">
                        {getRequestKind(options[code].request)}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item id={0}>{getRequestKind(0)}</Dropdown.Item>
                            <Dropdown.Item id={1}>{getRequestKind(1)}</Dropdown.Item>
                            <Dropdown.Item id={2}>{getRequestKind(2)}</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div style={{display: "flex", alignItems: "baseline", marginTop: "6px", flexWrap: "wrap"}}>
                    <Form.Label style={{marginRight: "8px"}}><Translate>Вид роботи</Translate></Form.Label>
                    <Dropdown onSelect={(eventkey, event) => { setOptionField(code, 'share', parseInt(event.target.id)); }}>
                        <Dropdown.Toggle variant="outline-dark" id="dropdown-category">
                        {getShareKind(options[code].share)}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item id={0}>{getShareKind(0)}</Dropdown.Item>
                            <Dropdown.Item id={1}>{getShareKind(1)}</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <InfoButton text='Оберіть "Індивідуальна", якщо для кожного студента ви надаватимете персоналізовану послугу. Оберіть "Групова", якщо усі студенти отримуватимуть однакову послугу.'/>
                </div>
                <div style={{textAlign: "center", marginTop: "16px", marginBottom: "8px"}}>
                    <Button size="sm" disabled={!changed(code)} variant="outline-primary" onClick={() => { 
                        save(code); }}>
                        <Translate>Зберегти зміни</Translate></Button>
                </div>
            </div>
        })}
        <Button variant="primary" style={{marginTop: "6px"}} onClick={addOption}>
            <Translate>Додайте нову послугу</Translate></Button>
        </Accordion.Body>
    </Accordion.Item>
}

export default CabEditCourseOptions