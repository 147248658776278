import React from "react"
import Translate from "../AppContext/Translate"

function CabSubtitle({title, icon=null, translate=true, color="cadetblue"}) {
    return <h2 style={{marginTop: "8px", color: color}}>
        {icon !== null &&  React.cloneElement(icon, {style: { marginRight: "8px", marginTop: "-8px" }})}
        {translate ? <Translate>{title}</Translate> : title}
    </h2>
}

export default CabSubtitle