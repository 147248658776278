import { useNavigate } from "react-router-dom"
import { MediaPath } from "../Components/MediaPath";
import PostMessage from "../Components/PostMessage";
import { useContext, useEffect, useRef, useState } from "react";
import { getAPICall, getAPIurl } from "../Components/APICall";
import Translate, { translate, translateWithNumber } from "../AppContext/Translate";
import Button from "react-bootstrap/Button";
import Add2Cart from "../AppContext/Add2Cart";
import { AppContext } from "../AppContext/AppContext";
import StartDates from "./StartDates";
import { RiGroup2Fill } from "react-icons/ri";
import PlanItem from "./PlanItem";
import CourseOpinions from "./CourseOpinions";

function Title({mission, ideas}) {
    const mitems = mission ? mission.split("|") : null;
    const mideas = ideas ? ideas.split("|") : null;
    var ideasTxt = [];
    var ideasIcon = [];
    if (mideas) {
        for (var i = 0; i < mideas.length; i += 2) {
            ideasTxt.push(mideas[i + 1]);
            ideasIcon.push(mideas[i]);
        }
    }
    return <>
    <div style={{height: "28px"}}/>
    <div style={{width: "100%", display: "flex", overflowX: "clip", justifyContent: "center", marginBottom: "16px"}}>
        {mission && <div style={{width: "50%", padding: "7%", display: "flex", position: "relative", 
            justifyContent: "center", alignItems: "center", maxWidth: "600px"}}>
            <img src={"/bkg/coursebkg" + mitems[0] + ".png"} alt="" style={{position: "absolute", width: "110%", 
                height: "110%", top: "-8%"}}/>
            <p className="lightTitle" style={{textAlign: "center", maxWidth: "380px", zIndex: "99"}}>{mitems[1]}</p>
        </div>
        }
        {ideas && <div style={{width: "50%", maxWidth: "600px", paddingTop: "16px", paddingLeft: "10%"}}>
            {
            ideasTxt.map((item, i) => {
                return <p key={i} className="darktitle" style={{transform: i === 0 ? "translateX(-32px)" : 
                    (i === 1 ? "translateX(-6px)" : "translateX(0px)")}}>
                    <img src={"/icons/course" + ideasIcon[i] + ".png"} alt="" height="10%" style={{maxHeight: "48px"}}/>
                    {' ' + item}
                </p>
            })
            }
        </div>
        }
    </div>
    </>
}

function Teacher({item, routeChange}) {
    const [foundImg, setFoundImg] = useState(true);
    const url = getAPIurl('media/userPhoto', {user: item.teacher});
    return <div className="teachercolumn">
        <p className="teacherRole">{item.role}</p>
        <div style={{display: "flex", alignItems: "center", flexDirection: "row"}}>
            <img alt={item.name} src={foundImg ? url : "/icons/nouser.png"} className="teacherImg"
                style={{marginRight: "16px"}} onError={()=>setFoundImg(false)}/>
            <div style={{textAlign: "center", width: "60%"}}>
                <p className="teacherName">{item.name}</p>
                <Button variant="outline-secondary" onClick={() => {routeChange("/profile/" + item.teacher)}}>
                    <Translate>Дізнатися більше</Translate></Button>
            </div>
        </div>
    </div>
}

function Team({course, routeChange}) {
    const [teachers, setTeachers] = useState(null);

    useEffect(() => {
        getAPICall('course/teachers', {courseId: course}, 
            (data) => { setTeachers(data); });
    }, [course]);

    return teachers && teachers.length > 0 ?
        <div className="noCommentDivPost">
            <p className="textPost boldPost"><Translate>Команда курсу</Translate>:</p>            
            <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-around"}}>
            {
            teachers.map((item, i) => {
                return <Teacher key={i} item={item} routeChange={routeChange}/>
            })}
            </div>
        </div>
        : <></>
}

function Package({index, data, options, student, numDays}) {
    const { dicLang } = useContext(AppContext);
    const [startDates, setStartDates] = useState(null);
    const [groupId, setGroupId] = useState(-1);

    useEffect(() => {
        if (data.plan === 1) {
            getAPICall('group/start', {courseId: data.course}, (datag) => {
                if (datag) {
                    var dates = [];
                    for (var i = 0; i < datag.length; i++) {
                        const row = datag[i];
                        if (row.elapsedDays < row.numDays - 1) {
                            dates.unshift(row);
                        } else break;
                    }
                    setStartDates(dates);
                    if (dates.length > 0) setGroupId(dates[0].id);
                }
            });
        }
    }, [data.course, data.plan]);

    const months = Math.round(data.daysToExpire / 3) / 10;
    const expiration = data.daysToExpire >= 365000 ? translate("Постійний доступ до всіх уроків і матеріалів курсу", dicLang)
        : translateWithNumber("Доступ до всіх уроків і матеріалів курсу протягом * місяців", months, dicLang);

    return <div className="package-item" style={{backgroundColor: index % 2 === 0 ? '#fff9f2' : '#fff3e5'}}>
        <h3 style={{textAlign: "center"}}>{data.title}</h3>
        <hr/>
        <PlanItem text={expiration} desc={data.daysToExpire >= 365000 ? null :
            translateWithNumber("Гарантований доступ * днів", data.daysToExpire, dicLang)}/>
        <PlanItem text={data.plan === 0 ? translate("Навчайтеся у своєму темпі", dicLang) :
            translateWithNumber("Програма курсу розрахована на * день", numDays, dicLang)}/>        
        {data.plan === 1 &&  <PlanItem text="Творча спільнота" translateText={true} translateDesc={true}
            desc="Доступ до чату для обговорення матеріалів курсу з однокурсниками та викладачами"/>}
        {data.options.includes('Y') && <PlanItem text="Отримуйте відгуки від викладачів щодо ваших завдань" translateText={true}/>}
        {data.options.includes('Z') && <PlanItem text="Підсумковий проект курсу" translateText={true}/>}
        {data.options.includes('Z') && <PlanItem text="Диплом" translateText={true}/>}
        {data.options.split('').map((c, index) => (
            c !== 'Y' && c !== 'Z' && c in options &&
                <PlanItem key={index} text={options[c].title} 
                    desc={options[c].description && options[c].description.length > 0 ?
                        <PostMessage msg={options[c].description} sep={false} open={false} textStyle="smallTextPost"/> : null}/>
        ))}
        <p style={{textAlign: "center", fontSize: "large", fontWeight: "600", fontFamily: "Mak", marginTop: "8px"}}>
            {data.price} ГРН.</p>
        {data.plan === 1 && startDates && <>
            <hr/>
            <p style={{fontStyle: "italic"}}><RiGroup2Fill size={20} style={{marginRight: "6px"}}
                color="darkgray"/><Translate>Групи за розкладом</Translate></p>
            <StartDates dates={startDates} setGroupId={setGroupId} groupId={groupId} pkgId={data.id}/>
        </>}
        {(data.plan === 0 || groupId >= 0) &&
        <div style={{display: "flex", justifyContent: "center", marginTop: "8px"}}>
            <Add2Cart data={{kind: "plan", packageId: data.id, courseId: data.course, groupId: groupId}}
                student={student} text={translate("ПРИДБАТИ КУРС", dicLang)} icon="/icons/up.png"/>
        </div>}
    </div>
}

function GeneralCourseInfo({courseId, student, info, goToPlans}) {
    const [packages, setPackages] = useState([]);
    const [options, setOptions] = useState({});
    const plansRef = useRef(null);
    let navigate = useNavigate(); 

    useEffect(() => {
        getAPICall('course/packages', {courseId: courseId, order: 1}, (data) => {
            if (data && data.length > 0) setPackages(data);
        });
        getAPICall('course/options', {course: courseId}, async (data) => {
            if (data) {
                var opt = {};
                for (var i = 0; i < data.length; i++) {
                    const row = data[i];
                    opt[row.code] = row;
                }
                setOptions(opt);
            }
        });
    }, [courseId]);

    useEffect(() => {
        if (goToPlans && plansRef.current && packages.length > 0) {
            plansRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            const checkPosition = () => {
                const rect = plansRef.current.getBoundingClientRect();
                if (rect.top !== 0) {
                    plansRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            };
            setTimeout(checkPosition, 300);
        }
    }, [goToPlans, packages, plansRef]);

    const routeChange = (path) => { 
        navigate(path);
    }

    var path = new MediaPath(MediaPath.kind_openMediaCourse);
    path.add(courseId);

    return <div style={{backgroundColor: "white"}}>
        <Title key="title" mission={info.mission} ideas={info.ideas}/>
        <div style={{padding: "16px"}}>
            <PostMessage msg={info.description} path={path}/>
            <PostMessage msg={info.goals} path={path}/>
            <PostMessage msg={info.audience} path={path}/>
            <PostMessage msg={info.requirements} path={path}/>
            <Team course={courseId} routeChange={routeChange}/>
            <hr style={{marginTop: "32px"}}/>
            {packages.length > 0 && <>
                <h1 ref={plansRef} style={{fontWeight: "600"}}><Translate>Пакети курсів</Translate></h1>
                <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-around", alignItems: "flex-start"}}>
                    {packages.map((pkg, i) => {
                        return <Package key={i} index={i} data={pkg} student={student} options={options}
                            numDays={info.numDays}/>
                    })}
                </div>
            </>}
            <CourseOpinions courseId={courseId} score={info.score} numOpinions={info.numOpinions}/>
        </div>
    </div>
}

export default GeneralCourseInfo