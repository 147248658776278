import { PiInfo } from "react-icons/pi";
import Button from 'react-bootstrap/Button';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Translate from "../AppContext/Translate";

function InfoButton({text, translateText=true, place="bottom"}) {
    const popover = (
        <Tooltip>{translateText ? <Translate>{text}</Translate> : text}</Tooltip>
    );

    return <OverlayTrigger placement={place} delay={{ show: 400, hide: 400 }} overlay={popover}>
        <Button variant="link" size="sm" style={{marginTop: "-4px", paddingLeft: "4px"}}><PiInfo/></Button>
    </OverlayTrigger>
}

export default InfoButton