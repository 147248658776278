import MimeIcon from "./MimeIcon";
import Video from "./VideoPlayer";

class AttFile {
    constructor(kind, name, source={}) {
        this.kind = kind;
        this.name = name;
        this.source = source;
        this.deleted = false;
    }

    isImage() {
        return this.kind === 'i';
    }
    isFile() {
        return this.kind === 'f';
    }
    isVideo() {
        return this.kind === 'v';
    }
    getVideoId() {
        if (this.isVideo()) {
            const parsed = Number.parseInt(this.name);
            if (Number.isNaN(parsed)) return -1;
            return parsed;
        }
        return -1;
    }
    getExtension() {
        return this.name.split('.').pop().toLowerCase();
    }
    hasBuffer() {
        return 'buffer' in this.source;
    }
    isUploaded() {
        return !this.hasBuffer() || (('uploaded' in this && this.uploaded === 1));
    }
    getPreview() {
        if (this.isImage()) {
            if (this.hasBuffer()) {
                return <img className="chatImageUpload" src={this.source.buffer} alt=""/>
            } else {
                return <img className="chatImageUpload" src={this.source.url} alt=""/>
            }
        } else if (this.isFile()) {
            const size = this.hasBuffer() ? this.source.size : 0;
            return <div className="chatImageUpload">
                <MimeIcon name={this.name} size={size}/>
            </div>
        } else {
            if (this.hasBuffer()) {
                return <video className="chatImageUpload" controls>
                    <source src={this.source.buffer} type={"video/" + this.getExtension()}/>
                </video>
            } else {
                return <div className="chatImageUpload" style={{width: "200px"}}>
                    <Video name={this.name} url={this.source.url}/>
                </div>
            }
        }
    }
    markAsDeleted() { // Return true if can be removed from the list
        if (this.deleted) return false;
        this.deleted = true;
        if ('buffer' in this.source) return true;
        return false;
    }

    classifyByName(files) {
        for (var i = 0; i < files.length; i++) {
            if (files[i].name === this.name) {
                files[i].files.push(this);
                return;
            }
        }
        files.push({name: this.name, files: [this], id: this.getVideoId()});
    }

    static getChange(file) { // {name: ___, files: [f1, f2, ...]}
        var n = file.files.length;
        var toDelete = 0;
        var toUpload = 0;
        for (var i = 0; i < n; i++) {
            const f = file.files[i];
            if (f.deleted) toDelete++;
            else if (!f.isUploaded()) toUpload++;
        }
        if (toDelete === n) return 'del';
        if (toUpload > 0) return 'upl';
        return '-';
    }

    static getBuffer(file) {
        for (var i = 0; i < file.files.length; i++) {
            if ('buffer' in file.files[i].source && !file.files[i].deleted)
                return {name: file.name, buffer: file.files[i].source.buffer};
        }
        return null;
    }
}

export default AttFile