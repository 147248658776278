const icon_unknown = 0;
const icon_image = 1;
const icon_video = 2;
const icon_text = 3;
const icon_pdf = 4;
const icon_powerpoint = 5;
const icon_word = 6;
const icon_excel = 7;
const icon_audio = 8;
const icon_compressed = 9;

export function getFileExtension(name) {
    const pos = name.lastIndexOf('.');
    if (pos > 0) return name.substring(pos + 1).toLowerCase();
    else return '';
}

function getFileType(ext) {
    if (['jpg', 'jpeg', 'png', 'bmp', 'gif', 'svg', 'tif', 'tiff', 'webp'].includes(ext)) return icon_image;
    else if (['avi', 'mp4', 'mpeg', 'mpg', 'ogv', 'webm', 'mkv', 'mov'].includes(ext)) return icon_video;
    else if (['txt'].includes(ext)) return icon_text;
    else if (['pdf'].includes(ext)) return icon_pdf;
    else if (['ppt', 'pptx'].includes(ext)) return icon_powerpoint;
    else if (['doc', 'docx'].includes(ext)) return icon_word;
    else if (['xls', 'xlsx'].includes(ext)) return icon_excel;
    else if (['mp3', 'wav', 'aac', 'weba'].includes(ext)) return icon_audio;
    else if (['zip', 'rar', '7z', 'gz'].includes(ext)) return icon_compressed;
    else return icon_unknown;
}

export function getFileTypeByName(name) {
    const ext = getFileExtension(name);
    return {kind: getFileType(ext), ext: ext};
}

function getSize(size) {
    if (size < 1) {
        var s = size * 1024;
        if (s < 1) return Math.round(s * 1024) + ' b';
        else return s.toFixed(2) + ' kB';
    }
    else return size.toFixed(2) + ' MB';    
}

function MimeIcon({name, size}) {
    const ext = getFileExtension(name);
    const kind = getFileType(ext);
    const name2show = name.length > 19 ? name.substring(0, 8) + '...' + name.substring(name.length - 8) : name;
    const size2show = getSize(size); 
    return <div className="mimeButton">
        <img alt={ext} src={"/icons/mime" + kind + ".png"} width="64"/>
        <p className="mimeCaption">{name2show}</p>
        {size > 0 && <p className="mimeSize">({size2show})</p>}
    </div>
}

export function MimeIconSmall({name, onPreview}) {
    const ext = getFileExtension(name);
    const kind = getFileType(ext);
    const name2show = name.length > 19 ? name.substring(0, 8) + '...' + name.substring(name.length - 8) : name;
    return <div className="mimeButtonSmall" style={{cursor: onPreview ? 'pointer' : 'auto'}}
        onClick={() => { if (onPreview) onPreview({kind: 'f', name: name}); }}>
        <img alt={ext} src={"/icons/mime" + kind + ".png"} width="64"/>
        <p className="mimeCaptionSmall">{name2show}</p>
    </div>
}

export function MimeIconNoText({name, width}) {
    const ext = getFileExtension(name);
    const kind = getFileType(ext);
    return <img alt={ext} src={"/icons/mime" + kind + ".png"} width={width}/>
}

export default MimeIcon