import Modal from 'react-bootstrap/Modal';
import RTEditor from '../../Components/RTEditor';
import Translate, { translate } from '../../AppContext/Translate';
import { useState, useEffect, useContext } from 'react';
import { MediaPath } from '../../Components/MediaPath';
import { AppContext } from '../../AppContext/AppContext';
import WriteHelper from '../../Components/WriteHelper';
import Button from 'react-bootstrap/Button';
import { FiUpload } from 'react-icons/fi';
import UploadProgress from '../../Components/UploadProgress';
import rtEditorUploader from '../../Components/RTEditorUploader';
import { postAPICall } from '../../Components/APICall';
import ShakeOnError from '../../Components/ShakeOnError';
import { AiOutlineNotification } from 'react-icons/ai';

const getPath = (userId, groupId, commentId) => {
    var path = new MediaPath(MediaPath.kind_privateCommentPost);
    path.add(userId);
    path.add(groupId);
    path.add(commentId);
    return path;
}

function WriteComment({show, setShow, groupId, courseId, parentId, commentId = -1, parentUser = -1, msg=''}) {    
    const { userId, token, dicLang } = useContext(AppContext);
    const [description, setDescription] = useState({text: '', files: []});
    const [progress, setProgress] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const parseMessage = async () => {
            const path = getPath(userId, groupId, commentId);
            setDescription(await WriteHelper.parseMessage(msg, path, userId, token));
        };
        if (msg !== '' && commentId > 0) parseMessage();
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, token, msg, groupId]);

    const hideModal = () => {
        setShow(false);
        setDescription({text: '', files: []});
        setProgress(null);
    }

    const saveSuccess = () => {
        setError(null);
        hideModal();
    }

    const saveError = () => {
        setError(translate("Помилка", dicLang) + ": " + translate("не вдалося зберегти коментар", dicLang));
        setProgress(null);
    }

    const save = () => {
        if (commentId < 0) { // New comment
            postAPICall('comments/new', {userId: userId, token: token, postId: parentId, groupId: groupId,
                parentUser: parentUser, courseId: courseId}, (data) => {
                    if (data && 'commentId' in data) {
                        const id = data.commentId;
                        const path = getPath(userId, groupId, id);
                        rtEditorUploader([description], [path.jsonPath], setProgress, userId, token,
                            [{apifnc: 'comments/update', params: {userId: userId, token: token, groupId: groupId,
                            commentId: id, parentUser: parentUser, parentPost: parentId, notify: 1, courseId: courseId}}],
                            saveSuccess,
                            () => {
                                postAPICall('comments/delete', {userId: userId, token: token, commentId: id,
                                    groupId: groupId}, () => {});
                                saveError();
                            });
                    } else saveError();
                }, saveError);
        } else {
            const path = getPath(userId, groupId, commentId);
            rtEditorUploader([description], [path.jsonPath], setProgress, userId, token,
                [{apifnc: 'comments/update', params: {userId: userId, token: token, groupId: groupId,
                commentId: commentId, parentUser: parentUser, parentPost: parentId, notify: 0, courseId: courseId}}],
                saveSuccess, saveError);
        }
    }

    return <Modal show={show} onHide={hideModal}>
        <ShakeOnError error={error !== null}>
            {error !== null && 
                <div style={{backgroundColor: "#ffcfcf", borderTopLeftRadius: "var(--bs-modal-border-radius)",
                    borderTopRightRadius: "var(--bs-modal-border-radius)", textAlign: "center", padding: "12px",
                    display: "flex"}}>
                    <AiOutlineNotification size={28}/>
                    <p style={{margin: "0px 0px 0px 8px", fontWeight: "bold"}}>{error}.</p>
                </div>
            }
            <Modal.Header closeButton>
                <Modal.Title><Translate>{commentId >= 0 ? 'Відредагуйте свій коментар' : 'Напишіть свій коментар'}</Translate></Modal.Title>
            </Modal.Header>
            <Modal.Body style={{backgroundColor: '#ebe7d6'}}>
                <RTEditor item={description} bkgColor="#ebe7d6" onChange={setDescription} multipleFiles={false}
                    maxVideoSize={12}/>
                <UploadProgress progress={progress}/>
                <div style={{display: "flex", justifyContent: "flex-end"}} className='top8'>
                    <Button style={{display: "flex", alignItems: "center"}} disabled={description.text.length === 0 &&
                        description.files.length === 0} onClick={save}>
                        <Translate>{commentId >= 0 ? 'Оновити' : 'Надіслати'}</Translate> <FiUpload/>
                    </Button>
                </div>
            </Modal.Body>
        </ShakeOnError>
    </Modal>
}

export default WriteComment