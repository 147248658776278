import Translate from "../AppContext/Translate"
import Banner from "../Components/Banner"
import Footer from "../Components/Footer"
import HomeButton from "../Components/HomeButton"
import CourseList from "../Lists/CourseList"
import Menu from "../Menu/Menu"

function Courses() {
    return <div className='mainContainer'>
        <Menu/>
        <Banner srcImg='/bkg/banners/courses.jpg'
            title={<h1 className="huge"><Translate>KУРСИ</Translate></h1>}/>
        <div style={{padding: "0px 24px 8px 24px", backgroundColor: "white"}}>
            <CourseList filter={{show: true, school: 0, cat: 0, lang: ''}}/>
            <HomeButton/>
        </div>
        <Footer/>
    </div>
}

export default Courses