const open_media_course = 'oc';
const private_course_post = 'pc';
const private_comment_post = 'cp';
const private_task_delivery = 'td';
const private_task_review = 'tr';
const kind_public_school = 'ps';
const private_option_request = 'or';
const private_option_shared = 'os';
const private_option_ind = 'oi';

export class MediaPath {
    constructor(kind) {
        this.path = [kind];
    }

    add(x) {
        if (typeof x === 'string') this.path.push(parseInt(x));
        else this.path.push(x);
    }

    addStr(x) {
        this.path.push(x);
    }

    get jsonPath() {
        return JSON.stringify(this.path);
    }

    static get kind_openMediaCourse() { return open_media_course; }
    static get kind_privateCoursePost() { return private_course_post; }
    static get kind_privateCommentPost() { return private_comment_post; }
    static get kind_privateTaskDelivery() { return private_task_delivery; }
    static get kind_privateTaskReview() { return private_task_review; }
    static get kind_publicSchool() { return kind_public_school; }
    static get kind_privateOptionRequest() { return private_option_request; }
    static get kind_privateOptionShared() { return private_option_shared; }
    static get kind_privateOptionInd() { return private_option_ind; }
}