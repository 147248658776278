import { HiOutlineChatBubbleLeftRight } from "react-icons/hi2";
import { BsCardList } from "react-icons/bs";
import Button from "react-bootstrap/Button";

function ChoosePackage({student, selectedPackage, setSelectedPackage}) {
    return (student && student.length > 1) ?   
    <div style={{display: "flex", flexWrap: "wrap", padding: "8px"}}>
        {student.map((pkg, i) => {
            return <Button key={i} size="sm" variant={pkg.package === selectedPackage ? "primary" : "secondary"}
                style={{margin: "4px", fontSize: "small"}}
                onClick={() => { setSelectedPackage(pkg.package); }}>
                {pkg.plan === 1 ? <HiOutlineChatBubbleLeftRight size="22"/> 
                : <BsCardList size="22"/>}<br/>{pkg.title}
            </Button>
        })}
    </div> : <div style={{height: "16px"}}/>
}

export default ChoosePackage