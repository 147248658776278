import Translate from "../../AppContext/Translate"
import MenuButton from "./MenuButton"

function StudentMenu({own, width, userId}) {
    return <div className="pad12 center" style={{width: width}}>
        <img alt="учень" src="/icons/student.png" height="48px"/>
        <p className="fmak large bold top4"><Translate>{own ? "Вивчаю" : "Вивчає"}</Translate></p>
        <div style={{backgroundColor: "#d9f5ed", borderRadius: "4px", display: "flex", alignItems: "flex-start",
            flexDirection: "column"}} className="fkenyan top12">
            <MenuButton icon="/icons/story.png" text="Історія" link={"/profile/activity/" + userId}/>
            <MenuButton icon="/icons/course.png" text="Курси" link={own ? "/user/courses/" : "/profile/courses/" + userId}/>
            <MenuButton icon="/icons/project.png" text="Проєкти" link={"/profile/projects/" + userId}/>
            {own && <MenuButton icon="/icons/reward.png" text="Винагороди" link="/user/rewards"/>}
            {own && <MenuButton icon="/icons/order.png" text="Замовлення" link="/user/orders"/>}
            {own && <MenuButton icon="/icons/products.png" text="Товари" link="/user/products"/>}
        </div>
    </div>
}

export default StudentMenu