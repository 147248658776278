import { endDateFormat } from "../../Components/DateFormat";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Translate from "../../AppContext/Translate";
import { IoTodayOutline } from 'react-icons/io5';

function DayInfoModal({index, showDayModal, startDate, difference, setShowDay, setTopic}) {
    if (showDayModal && showDayModal > 0) {
        const day = showDayModal;
        var kind, color;
        if (!(day in index)) { kind = 'rest'; color = 'whitesmoke'; }
        else if (day < difference) { kind = 'available'; color = '#12c191'; }
        else if (day === difference) { kind = 'current'; color = 'darksalmon'; }
        else { kind = 'non_available'; color = 'gainsboro'; }        
        const date = startDate ? endDateFormat(new Date(startDate), day) : '';

        var description = [];
        if (day in index && 'description' in index[day]) {
            description = index[day].description.split('\n');
        }

        return <Modal show={true} onHide={() => setShowDay(-1)} centered>
            <Modal.Header closeButton>
                <Modal.Title style={{display: "flex", alignItems: "center"}}>
                    <IoTodayOutline color={color} size={32} style={{marginRight: "8px"}}/><Translate>День</Translate> {day}
                    <span style={{fontSize: "small", alignSelf: "end", marginLeft: "8px"}}>
                        (<Translate>{kind==='non_available' ? "матеріали курсу ще не опубліковані" : "матеріали курсу у доступі"}</Translate>)
                    </span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{backgroundColor: "oldlace"}}>
                <p>{date}</p>
                <h3>{day in index && 'title' in index[day] && index[day].title}</h3>
                <div className="smallTextPost">
                    {description.map((line, i) => {
                        return <p key={i}>{line}</p>
                    })}
                </div>
            </Modal.Body>
            <Modal.Footer>
                {kind !== 'non_available' && 
                    <Button variant="primary" onClick={() => {setTopic(day); setShowDay(-1);}}><Translate>Подивитися вміст курс</Translate></Button>}
                <Button variant="secondary" onClick={() => setShowDay(-1)}><Translate>Закрити</Translate></Button>
            </Modal.Footer>
        </Modal>
    } else return <></>
}

export default DayInfoModal